import type { AppDispatch, AppGetState } from 'redux-store';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage } from 'utils/alerts';
import { TAG_CATEGORY_ID, TAG_TYPE } from 'modules/ReservationsList/constants';
import tagsApis from './apis';
import * as ActionTypes from './action-types';

const getTags = async ({ venueId, dispatch, type }: any) => {
  const isGuestTags = type === TAG_TYPE.GUEST;

  try {
    dispatch({
      type: isGuestTags
        ? ActionTypes.SET_GUEST_TAGS_LOADING
        : ActionTypes.SET_RESERVATION_TAGS_LOADING,
      payload: true,
    });

    // @ts-expect-error TS(2339): Property 'tags' does not exist on type 'unknown'.
    const { tags } = await tagsApis.getTags({ tagType: type, venueId });
    if (tags?.length) {
      // sorting tags category by id
      tags.sort((tagA: any, tagB: any) => +tagA.tagCategoryId - +tagB.tagCategoryId);
      // sorting the guest tags list by name
      if (isGuestTags) {
        tags.forEach(({ tags: tagList = [], tagCategoryId, ...categoryData }: any) => ({
          ...categoryData,
          tags:
            // if special tags then move VIP to top
            tagCategoryId === TAG_CATEGORY_ID.SPECIAL_TAGS
              ? // @ts-expect-error TS(7006): Parameter 'tagA' implicitly has an 'any' type.
                tagList.sort((tagA, tagB) => {
                  // keep the vip tags always on top of list
                  if (tagA.name === 'VIP' || tagB.name === 'VIP') return -1;
                  // sort the rest of the tags by alphabetically by name
                  return tagA.name.localeCompare(tagB.name);
                })
              : tagList,
        }));
      }
    }

    dispatch({
      type: isGuestTags ? ActionTypes.GET_GUEST_TAGS : ActionTypes.GET_RESERVATION_TAGS,
      payload: { tags },
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
    dispatch({
      type: ActionTypes.SET_TAGS_ERROR,
      payload: { error },
    });
  } finally {
    dispatch({
      type: isGuestTags
        ? ActionTypes.SET_GUEST_TAGS_LOADING
        : ActionTypes.SET_RESERVATION_TAGS_LOADING,
      payload: false,
    });
  }
};

export const getReservationTags =
  (venueId: any, forceUpdate = false) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    if (!getState().tags.reservationTags?.length || forceUpdate) {
      getTags({ venueId, dispatch, type: TAG_TYPE.RESERVATION });
    }
  };

export const getGuestTags =
  (venueId: any, forceUpdate = false) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    if (!getState().tags.guestTags?.length || forceUpdate) {
      getTags({ venueId, dispatch, type: TAG_TYPE.GUEST });
    }
  };
