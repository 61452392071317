import type { RootState } from 'redux-store';
import { createSelector } from 'reselect';
import { getIsAllGuestsLoaded } from './services';

export const selectTotalMasterGuestCount = ({ masterGuests }: RootState) =>
  masterGuests.totalMasterGuestsCount;
export const selectSelectedMasterGuestId = ({ masterGuests }: RootState) =>
  masterGuests.selectedMasterGuestId;
export const selectSearchText = ({ masterGuests }: RootState) => masterGuests.searchText;
export const selectIsMasterGuestsLoading = ({ masterGuests }: RootState) => masterGuests.isLoading;
export const selectPageNumber = ({ masterGuests }: RootState) => masterGuests.pageNumber;
export const selectMasterGuestSuccess = ({ masterGuests }: RootState) =>
  masterGuests.masterGuestSuccess;
export const selectGuestsList = ({ masterGuests }: RootState) =>
  masterGuests.masterGuestsList?.guests || {};

const selectMasterGuests = ({ masterGuests }: RootState) =>
  masterGuests.masterGuestsList?.masterGuests || {};

export const selectIsLoadingMore = ({ masterGuests }: RootState) => masterGuests.isLoadingMore;

export const selectGuestById = (guestId: any) =>
  createSelector(selectMasterGuests, (masterGuest) => masterGuest[guestId]);

export const selectIsAllLoaded = createSelector(
  selectTotalMasterGuestCount,
  selectMasterGuests,
  (totalGuestCount, masterGuest) => getIsAllGuestsLoaded(totalGuestCount, masterGuest)
);
