import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';

class Queue extends HttpRequest {
  constructor() {
    super(`${API_ENDPOINT_PARTNER_V2}/offline_waitlists`);
  }

  createQueue(data: any, venueId: any) {
    // @ts-expect-error TS(2345): Argument of type '{ venueId: any; }' is not assign... Remove this comment to see the full error message
    return this.create('', data, { venueId });
  }

  getQueueItems({ venueId, date }: any, config: any) {
    return this.fetch('', { venueId, date }, config);
  }

  getQueueItem({ venueId, id }: any) {
    return this.fetch(`/${id}`, { venueId });
  }

  updateQueue(data: any, venueId: any, id: any) {
    // @ts-expect-error TS(2345): Argument of type '{ venueId: any; }' is not assign... Remove this comment to see the full error message
    return this.update(`/${id}`, data, { venueId });
  }

  sendQueueCommunication(id: any, smsType: any, venueId: any) {
    return this.update(`/${id}/notify_guest`, { smsType, venueId });
  }

  getQueueActivities(id: any, venueId: any, config: any) {
    return this.fetch(`/${id}/activities`, { venueId }, config);
  }
}

export default new Queue();
