import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';

class GuestsCrm extends HttpRequest {
  constructor() {
    super(`${API_ENDPOINT_PARTNER_V2}/master_guests`);
  }

  createGuest(data: any) {
    return this.create(`${API_ENDPOINT_PARTNER_V2}/permanent_guests`, data);
  }

  fetchGuestsCrm({ page, searchText, venueId }: any, config: any) {
    return this.fetch('/search', { page, searchText, venueId }, config);
  }
}

export default new GuestsCrm();
