import React, { useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';
import SearchIcon from 'components/icons/SearchIcon';
import CloseIcon from 'components/icons/CloseIcon';
import styles from './SearchInput.module.scss';

interface SearchInputProps {
  placeholder?: string;
  value?: string;
  searchIconSize?: string | number;
  searchIconColor?: string;
  className?: string;
  onChange?(...args: unknown[]): unknown;
  onFocus?(...args: unknown[]): unknown;
  onBlur?(...args: unknown[]): unknown;
}

const SearchInput = ({
  className,
  placeholder = 'Search',
  value = '',
  searchIconSize,
  searchIconColor,
  onChange,
  onFocus,
  onBlur,
}: SearchInputProps) => {
  const inputRef = useRef();

  const resetSearch = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    inputRef.current.focus();
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onChange({ target: { value: '' } });
  };

  return (
    <span aria-label="search input box" className={classnames(styles.searchInput, className)}>
      <SearchIcon size={searchIconSize} className={styles.searchIcon} color={searchIconColor} />
      <input
        type="text"
        aria-label="search input"
        className="input"
        placeholder={placeholder}
        // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={inputRef}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {value.length > 0 && (
        <button
          aria-label="clear search"
          type="button"
          className={styles.clearSearch}
          onClick={() => {
            resetSearch();
          }}
        >
          <CloseIcon width={14} height={14} color={COLORS.SECONDARY} />
        </button>
      )}
    </span>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  searchIconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchIconColor: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
export default SearchInput;
