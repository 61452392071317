import { DataSet } from 'vis-data';
import moment from 'moment-timezone';
import { STATUSES, BLOCKED_STATUS } from 'modules/ReservationsList/constants';
import {
  DEFAULT_GROUP,
  DRAG_ACTIONS,
  GROUP_TYPES,
  NEW_TIMELINE_ID,
  TIMELINE_LIST_TYPE,
  TIMELINE_TIME_INTERVAL,
  HOURS_IN_MILLISECOND,
  TIMELINE_ITEM_TYPES,
  ROOM_HEIGHT,
  TABLE_HEIGHT,
} from 'modules/Timeline/constants';
import { SERVER_TIME, TIME_SLOT_SERVER_FORMAT } from 'constants/time-and-date';
import { RESERVATION_FUNNEL_STEPS } from 'modules/ReservationFunnel/constants';
import { formatDateToServerDateTime, adjustDayAfterMidNight } from 'utils/date-and-time';
import { formatName } from 'utils/string-helpers';
import { getIsExpiredWaitlist } from 'modules/Waitlist/services';
import { getTableLockStatus } from '../ReservationsList/services';

// Create an empty DataSet. These DataSet is used for two way data binding with the Timeline.
export const itemDataSet = new DataSet();
export const groupDataSet = new DataSet();

export const isDefaultGroup = (tableId: any) =>
  DEFAULT_GROUP.waitlist.id === tableId || DEFAULT_GROUP.noTables.id === tableId;

export const getItemTable = (tableId: any, tableName: any) => {
  switch (tableId) {
    case DEFAULT_GROUP.waitlist.id:
    case DEFAULT_GROUP.noTables.id:
      return [];
    default:
      return [{ name: tableName, id: tableId }];
  }
};

export const getOverlappingItems = (overlappingKey: any) =>
  // @ts-expect-error TS(2339): Property 'overlappingKey' does not exist on type '... Remove this comment to see the full error message
  itemDataSet.get({ filter: (i) => i.overlappingKey === overlappingKey });

export const getOverlappingHeader = (reservationListType: any) => {
  switch (reservationListType) {
    case TIMELINE_ITEM_TYPES.WAITLIST:
      return 'Choose Waitlist';
    case TIMELINE_ITEM_TYPES.RESERVED:
    case TIMELINE_ITEM_TYPES.FINISHED:
      return 'Choose Reservation';
    default:
      return 'Choose Items';
  }
};

export const getItemName = ({ temporaryGuest, firstName, lastName, guestName }: any) =>
  temporaryGuest ? formatName(firstName, lastName) || 'Temporary guest' : guestName;

// the order of the conditions are important to show which options on the drag and drop
export const getDragOptions = ({ toItem, fromItem, isSwapItem }: any) => {
  const isTimeSame =
    moment(toItem.start).isSame(moment(fromItem.start)) &&
    moment(toItem.end).isSame(moment(fromItem.end));
  const isTableSame = fromItem.group === toItem.group;
  const isLinkTableEnable =
    toItem.group !== DEFAULT_GROUP.noTables.id && fromItem.group !== DEFAULT_GROUP.noTables.id;

  // if its dragged from waitlist
  if (fromItem.group === DEFAULT_GROUP.waitlist.id) {
    if (toItem.group !== DEFAULT_GROUP.waitlist.id) {
      return { [DRAG_ACTIONS.toReservations]: DRAG_ACTIONS.toReservations };
    }
    return { [DRAG_ACTIONS.waitlistTime]: DRAG_ACTIONS.waitlistTime };
  }

  // dragged item to existing table + time -> show swap options
  if (isSwapItem) {
    // same table & different time
    if (isTableSame && !isTimeSame) {
      return {
        [DRAG_ACTIONS.swapGuestTime]: DRAG_ACTIONS.swapGuestTime,
      };
    }

    // different table & same time
    if (!isTableSame && isTimeSame) {
      return { [DRAG_ACTIONS.swapTables]: DRAG_ACTIONS.swapTables };
    }

    // different table & different time
    if (!isTableSame && !isTimeSame) {
      return {
        [DRAG_ACTIONS.swapTablesAndTime]: DRAG_ACTIONS.swapTablesAndTime,
        [DRAG_ACTIONS.swapTables]: DRAG_ACTIONS.swapTables,
        [DRAG_ACTIONS.time]: DRAG_ACTIONS.time,
      };
    }
  }

  // same table & different time
  if (isTableSame && !isTimeSame) {
    return { [DRAG_ACTIONS.time]: DRAG_ACTIONS.time };
  }

  // different table & same time
  if (!isTableSame && isTimeSame) {
    return {
      [DRAG_ACTIONS.table]: DRAG_ACTIONS.table,
      ...(isLinkTableEnable && { [DRAG_ACTIONS.linkTables]: DRAG_ACTIONS.linkTables }),
    };
  }

  // different table & different time
  if (!isTableSame && !isTimeSame) {
    return {
      ...(isLinkTableEnable && {
        [DRAG_ACTIONS.timeAndLinkTables]: DRAG_ACTIONS.timeAndLinkTables,
      }),
      [DRAG_ACTIONS.timeAndTable]: DRAG_ACTIONS.timeAndTable,
      [DRAG_ACTIONS.table]: DRAG_ACTIONS.table,
      [DRAG_ACTIONS.time]: DRAG_ACTIONS.time,
    };
  }

  return {};
};

export const getSwapItems = ({ itemId, toTable, toStart, toEnd, tableIds = [], fields }: any) => {
  const momentStart = moment(toStart);
  const momentEnd = moment(toEnd);

  return itemDataSet.get({
    fields,
    filter: (item) => {
      // @ts-expect-error TS(2339): Property 'start' does not exist on type 'Partial<R... Remove this comment to see the full error message
      const start = moment(item.start);
      // @ts-expect-error TS(2339): Property 'end' does not exist on type 'Partial<Rec... Remove this comment to see the full error message
      const end = moment(item.end);

      return (
        // ignore item which is dragged
        item.id !== itemId &&
        // select item from dataset of the same table (item.group) to the table where the item is dragged (toTable),
        // or the item dragged is linked reservation then check its tables (tableIds) belong to the table of the item (item.group)
        // @ts-expect-error TS(2339): Property 'group' does not exist on type 'Partial<R... Remove this comment to see the full error message
        (toTable === item.group || tableIds.includes(item.group)) &&
        // check dragged item momentStart or momentEnd time to be inclusive from start and exclusive from end time of the dataset item
        (momentStart.isBetween(start, end, undefined, '[)') ||
          momentEnd.isBetween(start, end, undefined, '(]') ||
          // or other scenario where dataset item start or end lies between the dragged item momentStart and momentEnd time
          start.isBetween(momentStart, momentEnd, undefined, '[)') ||
          end.isBetween(momentStart, momentEnd, undefined, '(]'))
      );
    },
  });
};

const isSameTimeAndTable = ({ toStart, toEnd, toTable, fromStart, fromEnd, fromTable }: any) =>
  moment(toStart).isSame(moment(fromStart)) &&
  moment(toEnd).isSame(moment(fromEnd)) &&
  fromTable === toTable;

export const isItemDraggedInvalid = (toItem: any, roomWithTableIds: any) => {
  const { id, group: toTable, start: toStart, end: toEnd, reservationId, tableIds } = toItem;
  const {
    // @ts-expect-error TS(2339): Property 'start' does not exist on type 'FullItem<... Remove this comment to see the full error message
    start: fromStart,
    // @ts-expect-error TS(2339): Property 'end' does not exist on type 'FullItem<Pa... Remove this comment to see the full error message
    end: fromEnd,
    // @ts-expect-error TS(2339): Property 'group' does not exist on type 'FullItem<... Remove this comment to see the full error message
    group: fromTable,
  } = itemDataSet.get(id, {
    fields: ['start', 'end', 'group'],
  });
  const swapItems = getSwapItems({
    itemId: id,
    toTable,
    toStart,
    toEnd,
    tableIds,
    fields: ['reservationListType', 'reservationId', 'start', 'end', 'group'],
  });

  // Cancel the move action when a reservation is dragged:
  return (
    // at same time and table
    isSameTimeAndTable({ toStart, toEnd, toTable, fromStart, fromEnd, fromTable }) ||
    // to waitlist
    (toTable === DEFAULT_GROUP.waitlist.id && fromTable !== DEFAULT_GROUP.waitlist.id) ||
    // to blocked tables (swap items)
    swapItems.filter(
      // @ts-expect-error TS(2339): Property 'reservationListType' does not exist on t... Remove this comment to see the full error message
      (s) => s.reservationListType === TIMELINE_ITEM_TYPES.BLOCKED_STATUS && s.group === toTable
    ).length ||
    // FIXME: do we need to check same link reservation ?
    // to a same linked reservation
    swapItems.filter(
      // item is same as reservation dragged and at same time
      (s) =>
        // @ts-expect-error TS(2339): Property 'reservationId' does not exist on type 'F... Remove this comment to see the full error message
        s.reservationId &&
        // @ts-expect-error TS(2339): Property 'reservationId' does not exist on type 'F... Remove this comment to see the full error message
        s.reservationId === reservationId &&
        // @ts-expect-error TS(2339): Property 'group' does not exist on type 'FullItem<... Remove this comment to see the full error message
        s.group === toTable &&
        // @ts-expect-error TS(2339): Property 'start' does not exist on type 'FullItem<... Remove this comment to see the full error message
        moment(toStart).isSame(moment(s.start)) &&
        // @ts-expect-error TS(2339): Property 'end' does not exist on type 'FullItem<Pa... Remove this comment to see the full error message
        moment(toEnd).isSame(moment(s.end))
    ).length ||
    // to a room
    roomWithTableIds
  );
};

/**
 * zoomMin shows the hours in the viewport or visible screen after zooming in.
 * zoomMin: 1000 * 60 * 60 * 6,  ---> 6 hours in milliseconds   (6 for desktop , 3 for ipad and 2 for mobile)
 *
 * zoomMax shows the hours visible in the viewport or the visible screen on mount of timeline
 * zoomMax: 1000 * 60 * 60 * 12, ---> 12 hour in milliseconds (16 for desktop, 12 for ipad, 6 for mobile )
 */
export const getTimelineZoom = ({ mobile, largeDesktop }: any) => {
  if (mobile) {
    return { zoomMin: HOURS_IN_MILLISECOND * 2, zoomMax: HOURS_IN_MILLISECOND * 6 };
  }

  if (largeDesktop) {
    return { zoomMin: HOURS_IN_MILLISECOND * 6, zoomMax: HOURS_IN_MILLISECOND * 16 };
  }

  return { zoomMin: HOURS_IN_MILLISECOND * 3, zoomMax: HOURS_IN_MILLISECOND * 12 };
};

export const defaultOptions = {
  moment: (date: any) => moment(date),
  locale: moment.locale(),
  // to avoid margin of items in the grid
  margin: { axis: 0, item: 0 },
  timeAxis: { scale: 'minute', step: TIMELINE_TIME_INTERVAL },
  // always snap to timeline interval minutes, independent of the scale
  snap: (date: any) => {
    const minute = TIMELINE_TIME_INTERVAL * 60 * 1000;
    return Math.floor(date / minute) * minute;
  },
  format: {
    majorLabels: () => '', // empty string to hide text of major labels..
    minorLabels: (date: any) => (date.minute() === 0 ? date.format('LT') : ''),
  },
  zoomKey: 'ctrlKey',
  verticalScroll: true,
  horizontalScroll: true,
  stack: false,
  stackSubgroups: false,
  orientation: 'top',
  itemsAlwaysDraggable: true,
  selectable: false,
  editable: {
    add: false, // add new items by double tapping, only works when selectable is true
    updateTime: true, // drag items horizontally
    updateGroup: true, // drag items from one group to another
    remove: false, // delete an item by tapping the delete button top right: ;
    overrideItems: false, // allow these options to override item.editable
  },
  groupEditable: false,
  groupHeightMode: 'fixed',
  width: '100%',
  height: 'calc(100vh - 60px)',
};

// checking tables in the range of 6 am to 6 am, where table start time is before end of footer day
// and end time is after start of footer day
export const getBlockTableInFooterDate = (
  blockedTables: any,
  { footerStartDate, footerEndDate }: any
) =>
  blockedTables.filter(
    ({ dateTimeEnd, dateTimeStart }: any) =>
      moment(dateTimeStart).isBefore(footerEndDate) && moment(dateTimeEnd).isAfter(footerStartDate)
  );

// check if footer-date 6am - 5:59 am is in which day
export const getTimelineInFooterDate = ({ footerStartDate, footerEndDate }: any) => {
  const currentDate = moment();

  if (currentDate.isBetween(footerStartDate, footerEndDate, null, '[)'))
    return TIMELINE_LIST_TYPE.today;

  if (footerStartDate.isBefore(currentDate, 'day')) return TIMELINE_LIST_TYPE.yesterday;

  return TIMELINE_LIST_TYPE.tomorrow;
};

export const timelineGuestCount = (list: any) =>
  list.reduce((result: any, item: any) => result + (item?.guestCount || 0), 0);

const defaultItem = {
  id: 'item',
  className: 'reservation-wrapper',
  start: '',
  end: '',
  group: '',
  type: 'range',
};

// Create a reservation item for current or finish reservation
// 1. create a copy for more then one tables reservation item
// 2. find reservation with no tables and separate from reservations to noTables group
export const transformReservationsToItems = (reservations: any) => {
  let linkedItemCount = 0;
  const items: any = [];

  reservations.forEach((reservation: any) => {
    const { id: reservationId, status, tables, guestCount, slotStartTime, turnTime } = reservation;
    // keeping "reservation-[id]" because need to keep unique id's between reservation, block and waitlist items,
    // Moreover, dont know what DataSet used to display sort data, and the order is needed for overlapping count,
    // TODO: find a way to always show sorted items
    const id = `reservation-${reservationId}`;
    const isFinishReservation = status?.toUpperCase() === STATUSES.FINISH;
    const isTableLocked = getTableLockStatus(tables);
    const isEditable = isFinishReservation || isTableLocked;
    const item = {
      ...defaultItem,
      id,
      reservationId,
      status,
      guestCount,
      start: moment(slotStartTime),
      end: moment(slotStartTime).add(Math.abs(turnTime), 'minutes'),
      turnTime,
      // Do not allow finished reservation or reservation with locked tables to be dragged
      editable: {
        updateTime: !isEditable,
        updateGroup: !isEditable,
        remove: false,
      },
      reservationListType: isFinishReservation
        ? TIMELINE_ITEM_TYPES.FINISHED
        : TIMELINE_ITEM_TYPES.RESERVED,
      tableIds: tables.map((t: any) => t.id),
    };

    if (tables?.length > 1) {
      linkedItemCount += 1;
      // If there is more then one table assigned to this reservation
      tables.forEach(({ id: tableId, name }: any) => {
        items.push({
          ...item,
          // to make id unique so that vis DataSet does not break
          id: `${id}-${tableId}`,
          group: tableId,
          tableName: name,
          linkedItemCount,
          overlappingKey: `${slotStartTime}-${tableId}`,
        });
      });
      // If there is only ONE table assigned to this reservation
    } else if (tables?.length === 1) {
      const { id: tableId, name } = tables[0];
      items.push({
        ...item,
        group: tableId,
        tableName: name,
        overlappingKey: `${slotStartTime}-${tableId}`,
      });
      // If there is NO table assigned to this reservation
    } else {
      items.push({
        ...item,
        group: DEFAULT_GROUP.noTables.id,
        tableName: DEFAULT_GROUP.noTables.name,
        overlappingKey: `${slotStartTime}-${DEFAULT_GROUP.noTables.id}`,
      });
    }
  });

  return items;
};

// NOTE: there is no blocked reservations, this is just a way around to show blocked tables in timeline
export const transformBlockedTablesToItems = (
  blockedTables: any,
  { footerStartDate, footerEndWithinDate }: any
) => {
  let item = {
    ...defaultItem,
    className: `${defaultItem.className} blocked-reservation`,
    status: BLOCKED_STATUS,
    guestCount: null,
    reservationListType: TIMELINE_ITEM_TYPES.BLOCKED_STATUS,
    editable: false,
  };

  return blockedTables.map(({ id, dateTimeStart, dateTimeEnd, tableId, tableName }: any) => {
    const momentStart = moment(dateTimeStart);
    const momentEnd = moment(dateTimeEnd);

    item = {
      ...item,
      id: `blocked-${id}`,
      blockedId: id,
      // @ts-expect-error TS(2322): Type 'Moment' is not assignable to type 'string'.
      start: momentStart,
      // @ts-expect-error TS(2322): Type 'Moment' is not assignable to type 'string'.
      end: momentEnd,
      turnTime: momentEnd.diff(momentStart, 'minutes'),
      group: tableId,
      tableName,
      overlappingKey: `${dateTimeStart}-${tableId}`,
    };

    if (momentStart.isBefore(footerStartDate)) {
      item.start = footerStartDate.clone();
    }

    if (momentEnd.isAfter(footerEndWithinDate)) {
      item.end = footerEndWithinDate.clone();
    }

    return item;
  });
};

export const transformWaitlistToItems = (waitlists: any) =>
  waitlists.map((waitlistItem: any) => {
    const { id, status, guestCount, startTimeWithDate, endTimeWithDate } = waitlistItem;
    const start = moment(startTimeWithDate);
    const end = moment(endTimeWithDate);
    const isExpiredWaitlist = getIsExpiredWaitlist(status);

    return {
      ...defaultItem,
      // keeping "waitlist-[id]"" because need to keep unique id's between reservation, block and waitlist items,
      // Moreover, dont know what DataSet used to display sort data, and the order is needed for overlapping count,
      // TODO: find a way to always show sorted items
      id: `waitlist-${id}`,
      waitlistId: id,
      status,
      guestCount,
      start,
      end,
      turnTime: end.diff(start, 'minutes'),
      group: DEFAULT_GROUP.waitlist.id,
      tableIds: [],
      tableName: DEFAULT_GROUP.waitlist.name,
      reservationListType: TIMELINE_ITEM_TYPES.WAITLIST,
      overlappingKey: startTimeWithDate,
      editable: {
        updateTime: !isExpiredWaitlist,
        updateGroup: !isExpiredWaitlist,
        remove: false,
      },
    };
  });

// @ts-expect-error TS(7006): Parameter 'footerStartDate' implicitly has an 'any... Remove this comment to see the full error message
const createDefaultRoomSummaryItem = (footerStartDate, footerEndDate) => ({
  id: 'id',
  roomId: 'roomId',
  start: footerStartDate.clone(),
  end: footerEndDate.clone(),
  reservationCount: 0,
  guestCount: 0,
  className: 'room-wrapper',
  group: 'roomId',
  type: 'range',
  editable: false,
  reservationListType: TIMELINE_ITEM_TYPES.ROOM_SUMMARY,
});

export const transformReservationRoomSummaryToItems = (reservations: any, footerDateRange: any) => {
  const { footerStartDate, footerEndDate } = footerDateRange;

  if (!reservations.length) {
    return [];
  }

  const items = reservations.reduce((result: any, reservation: any) => {
    const { rooms = [], guestCount = 0 } = reservation;
    // noTables reservation doesn't have rooms attached.
    if (rooms?.length) {
      // @ts-expect-error TS(7031): Binding element 'roomId' implicitly has an 'any' t... Remove this comment to see the full error message
      rooms?.forEach(({ id: roomId }) => {
        if (!result[roomId]) {
          result[roomId] = {
            ...createDefaultRoomSummaryItem(footerStartDate, footerEndDate),
            id: `room-${roomId}`,
            roomId,
            group: roomId,
          };
        }
        result[roomId].guestCount += guestCount || 0;
        result[roomId].reservationCount += 1;
      });
    } else if (!result[DEFAULT_GROUP.noTables.roomId]) {
      result[DEFAULT_GROUP.noTables.roomId] = {
        ...createDefaultRoomSummaryItem(footerStartDate, footerEndDate),
        id: `room-${DEFAULT_GROUP.noTables.roomId}`,
        roomId: DEFAULT_GROUP.noTables.roomId,
        reservationCount: 1,
        guestCount,
        group: DEFAULT_GROUP.noTables.roomId,
      };
    } else {
      result[DEFAULT_GROUP.noTables.roomId].guestCount += guestCount || 0;
      result[DEFAULT_GROUP.noTables.roomId].reservationCount += 1;
    }
    return result;
  }, {});
  return Object.values(items);
};

export const transformWaitlistRoomSummaryToItems = (waitlists: any, footerDateRange: any) => {
  const { footerStartDate, footerEndDate } = footerDateRange;

  if (!waitlists.length) {
    return [];
  }

  const items = waitlists.reduce((result: any, waitlist: any) => {
    const { guestCount = 0 } = waitlist;
    // waitlist reservation doesn't have rooms attached.
    // expired waitlists are filtered out
    if (!result[DEFAULT_GROUP.waitlist.roomId]) {
      result[DEFAULT_GROUP.waitlist.roomId] = {
        ...createDefaultRoomSummaryItem(footerStartDate, footerEndDate),
        id: `room-${DEFAULT_GROUP.waitlist.roomId}`,
        roomId: DEFAULT_GROUP.waitlist.roomId,
        reservationCount: 1,
        guestCount,
        group: DEFAULT_GROUP.waitlist.roomId,
      };
    } else {
      result[DEFAULT_GROUP.waitlist.roomId].guestCount += guestCount || 0;
      result[DEFAULT_GROUP.waitlist.roomId].reservationCount += 1;
    }
    return result;
  }, {});
  return Object.values(items);
};

// Type "background" is used to show background color for active availabilities on the day.
// Moreover, we are breaking the availability range in TIMELINE_TIME_INTERVAL (15 mins) in order to have the vertical grid lines on the timeline.
// Because the current design of vis-timeline makes the vertical grid hidden behind the background
export const transformAvailabilitiesToBackgroundItems = ({
  availabilities,
  weekday,
  footerStartDate,
}: any) => {
  const items: any = [];

  availabilities.forEach((availability: any) => {
    const { id, startTime, endTime, timeRange } = availability;
    const startTimeMoment = moment(timeRange[weekday]?.startTime || startTime, SERVER_TIME);
    const endTimeMoment = moment(timeRange[weekday]?.endTime || endTime, SERVER_TIME);
    const start = adjustDayAfterMidNight({
      time: footerStartDate.clone().set({
        hour: startTimeMoment.get('hour'),
        minute: startTimeMoment.get('minute'),
        second: 0,
        millisecond: 0,
      }),
    });
    const end = adjustDayAfterMidNight({
      time: footerStartDate.clone().set({
        hour: endTimeMoment.get('hour'),
        minute: endTimeMoment.get('minute'),
        second: 0,
        millisecond: 0,
      }),
    });
    const range = end.diff(start, 'minutes');

    for (let i = 0; i < range; i += TIMELINE_TIME_INTERVAL) {
      const rangeStart = start.clone().add(i, 'minutes');
      const rangeEnd = start.clone().add(i + TIMELINE_TIME_INTERVAL, 'minutes');
      items.push({
        id: `availability-${id}-${i}`,
        start: rangeStart,
        end: rangeEnd,
        type: 'background',
        className: 'open-day',
        reservationListType: TIMELINE_ITEM_TYPES.BACKGROUND,
      });
    }
  });

  return items;
};

const defaultRoom = {
  id: 'room',
  name: 'room',
  title: 'room',
  editable: false,
  className: 'room',
  showNested: true,
  nestedGroups: [],
  type: GROUP_TYPES.room,
};

const defaultTable = {
  id: 'table',
  name: 'table',
  title: 'table',
  className: 'table',
  type: GROUP_TYPES.table,
};

export const createGroups = ({ rooms = [], tables = [], roomTables = {} }) => [
  {
    ...defaultRoom,
    id: DEFAULT_GROUP.waitlist.roomId,
    order: -2,
    showNested: false,
    name: DEFAULT_GROUP.waitlist.name,
    title: DEFAULT_GROUP.waitlist.name,
    nestedGroups: [DEFAULT_GROUP.waitlist.id],
  },
  {
    ...defaultRoom,
    id: DEFAULT_GROUP.noTables.roomId,
    order: -1,
    showNested: false,
    className: `${defaultRoom.className}  no-table-room`,
    name: DEFAULT_GROUP.noTables.name,
    title: DEFAULT_GROUP.noTables.name,
    nestedGroups: [DEFAULT_GROUP.noTables.id],
  },
  {
    ...defaultTable,
    id: DEFAULT_GROUP.waitlist.id,
    name: DEFAULT_GROUP.waitlist.name,
    title: DEFAULT_GROUP.waitlist.name,
    order: 0,
    room: DEFAULT_GROUP.waitlist.roomId,
    roomName: DEFAULT_GROUP.waitlist.name,
  },
  {
    ...defaultTable,
    id: DEFAULT_GROUP.noTables.id,
    name: DEFAULT_GROUP.noTables.name,
    title: DEFAULT_GROUP.noTables.name,
    className: `${defaultTable.className} no-table`,
    order: 0,
    room: DEFAULT_GROUP.noTables.roomId,
    roomName: DEFAULT_GROUP.noTables.name,
  },
  ...rooms
    // @ts-expect-error TS(2339): Property 'length' does not exist on type 'never'.
    .filter(({ id }) => roomTables[id]?.length)
    .map(({ id, order, name }) => ({
      ...defaultRoom,
      id,
      order,
      name,
      title: name,
      nestedGroups: roomTables[id],
    })),
  ...tables.map(({ id, name, order, room, minSize, maxSize, availableOnline }) => ({
    ...defaultTable,
    id,
    name,
    title: name,
    order,
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    room: room.id,
    // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
    roomName: room.name,
    minSize,
    maxSize,
    availableOnline,
  })),
];

export const isAddItemValid = ({ what = '', item, snappedTime, group }: any, roomIds: any) =>
  what === 'background' &&
  !item &&
  moment(snappedTime).isSameOrAfter(moment()) &&
  ![...roomIds, DEFAULT_GROUP.waitlist.roomId, DEFAULT_GROUP.noTables.roomId].includes(group) &&
  DEFAULT_GROUP.noTables.id !== group;

export const transformToAddNewItem = ({ snappedTime, group }: any) => ({
  ...defaultItem,
  className: 'add-item-wrapper',
  id: NEW_TIMELINE_ID,
  start: moment(snappedTime),
  end: moment(snappedTime).add(TIMELINE_TIME_INTERVAL, 'minutes'),
  reservationListType: TIMELINE_ITEM_TYPES.ADD_NEW_ITEM,
  group,
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  tableName: groupDataSet.get(group, { fields: ['name'] }).name,
  editable: false,
});

export const createBlockedTimeSelectOptions = ({
  selectedDate,
  isAfterDate,
  blockedItems = [],
}: any) => {
  const options = [];
  for (let hour = 0; hour < 24; hour += 1) {
    for (let minute = 0; minute < 60; minute += TIMELINE_TIME_INTERVAL) {
      const date = moment(selectedDate).set({ hour, minute });
      if (date.isAfter(isAfterDate || moment())) {
        options.push({
          date,
          value: date.format(),
          label: date.format('LT'),
          // @ts-expect-error TS(7031): Binding element 'start' implicitly has an 'any' ty... Remove this comment to see the full error message
          isDisabled: blockedItems?.some(({ start, end }) =>
            date.isBetween(start, end, 'minutes', '[]')
          ),
        });
      }
    }
  }
  return options;
};

export const syncDataSetWithStore = (dataset: any, data: any, dataSetIds: any) => {
  if (dataSetIds?.length) {
    const dataIds = data.map((i: any) => i.id);
    // remove from the vis-timeline "items/groups" DataSet, to sync it with itemData/groupData coming from redux-store
    dataset.remove(dataSetIds.filter((id: any) => !dataIds.includes(id)));
  }
  // Update a data item or an array with items and creating items that don't exist yet.
  // NOTE: it does not remove items.. so for that we have remove the items done above
  dataset.update(data);
};

export const setClassToTimeline = (timelineRef: any, className = '', addToList = false) => {
  if (timelineRef?.classList) {
    if (addToList) {
      timelineRef.classList.add(className);
    } else {
      timelineRef.classList.remove(className);
    }
  }
};

export const setTimelineWidth = (timeline: any, width = '0px') => {
  timeline.setOptions({ width: `calc(100% - ${width})` });
  timeline.dom.container.style.left = width;
  timeline.dom.top.style.marginLeft = width;
};

export const getTimelineWaitlistFunnelPayload = (toItem: any, waitlistItem: any) => {
  const { guestCount, waitlistId, group, start: toStart } = toItem;
  const { masterGuest, notes, tags, sourceOfCreate } = waitlistItem;

  return {
    partySize: guestCount,
    guest: { ...(masterGuest || {}) },
    step: RESERVATION_FUNNEL_STEPS.SELECT_TABLES,
    timeSlot: formatDateToServerDateTime(toStart, TIME_SLOT_SERVER_FORMAT),
    notifylistId: waitlistId,
    notes,
    tagIds: tags?.map((tag: any) => tag.id) || [],
    selectedTableIds: group === DEFAULT_GROUP.noTables.id ? [] : [group],
    sourceOfReservation: sourceOfCreate,
  };
};

export const getRoomHeight = () =>
  parseFloat(
    // @ts-expect-error TS(2345): Argument of type 'string | 30' is not assignable t... Remove this comment to see the full error message
    document.documentElement.style.getPropertyValue('--timeline-height-room') || ROOM_HEIGHT
  );

export const getTableHeight = () =>
  parseFloat(
    // @ts-expect-error TS(2345): Argument of type 'string | 57' is not assignable t... Remove this comment to see the full error message
    document.documentElement.style.getPropertyValue('--timeline-height-table') || TABLE_HEIGHT
  );

export const getTimelineHeight = (groups = []) =>
  // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
  groups.filter((g) => g.type === GROUP_TYPES.table).length * Math.ceil(getTableHeight()) +
  // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
  groups.filter((g) => g.type === GROUP_TYPES.room).length * Math.ceil(getRoomHeight());

export const calculateTimelineHeight = (timeline: any, groups: any) =>
  `calc(${getTimelineHeight(groups)}px + ${timeline?.props?.top?.height ?? 21}px)`;

export const adjustTimelineOnTableZoom = ({
  yAxisWidth = '75px',
  yAxisDisplay = 'inline-block',
  yAxisTableWidth = '100%',
  yAxisPaxWidth = '100%',
  overlapCountSize = '20px',
  linkedWidth = '27px',
  itemHeightDiff = '5px',
  itemNameFontSize = '1rem', // 16px
  itemTimeFontSize = '0.875rem', // 14px
  itemChargeFontSize = '0.875rem', // 14px
  groupTableFontSize = '0.875rem', // 14px
  groupSummaryFontSize = '0.875rem', // 14px
} = {}) => {
  document.documentElement.style.setProperty('--timeline-y-axis-width', yAxisWidth);
  document.documentElement.style.setProperty('--timeline-y-axis-display', yAxisDisplay);
  document.documentElement.style.setProperty('--timeline-y-axis-table-width', yAxisTableWidth);
  document.documentElement.style.setProperty('--timeline-y-axis-pax-width', yAxisPaxWidth);
  document.documentElement.style.setProperty('--timeline-overlap-count-size', overlapCountSize);
  document.documentElement.style.setProperty('--timeline-item-icon-width', linkedWidth);
  document.documentElement.style.setProperty('--timeline-item-height-diff', itemHeightDiff);
  document.documentElement.style.setProperty('--timeline-item-name-font-size', itemNameFontSize);
  document.documentElement.style.setProperty('--timeline-item-time-font-size', itemTimeFontSize);
  document.documentElement.style.setProperty(
    '--timeline-item-charge-font-size',
    itemChargeFontSize
  );
  document.documentElement.style.setProperty(
    '--timeline-group-table-font-size',
    groupTableFontSize
  );
  document.documentElement.style.setProperty(
    '--timeline-group-summary-font-size',
    groupSummaryFontSize
  );
};

export const isItemDraggedRight = ({ fromStart, fromEnd, toStart, toEnd }: any) =>
  moment(toStart).isSame(moment(fromStart)) && !moment(toEnd).isSame(moment(fromEnd));
