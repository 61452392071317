import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { getReservationTags } from 'modules/Tag/actions';
import { MESSAGE_EVENTS } from 'constants/socket';

const useSocketReservationTags = ({ venueId }: any) => {
  const dispatch = useAppDispatch();
  const reservationTagsUpdate = ({ detail = {} }) => {
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      const params = detail?.params || {};
      // eslint-disable-next-line
      console.debug('RESERVATION TAGS: socket update: ', detail, params?.venue_id);
      if (+(params?.venue_id || 0) === +venueId) {
        dispatch(getReservationTags(venueId, true));
      }
    } catch (error) {
      console.error('Error in update tags from socket', error);
    }
  };

  useEffect(() => {
    // @ts-expect-error TS(2769): No overload matches this call.
    document.addEventListener(MESSAGE_EVENTS.tags, reservationTagsUpdate, false);
    return () => {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(MESSAGE_EVENTS.tags, reservationTagsUpdate, false);
    };
  }, [venueId]);
};

export default useSocketReservationTags;
