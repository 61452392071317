// TODO: Refactor and remove unnecessary complexity in this file
import React, { createContext, useContext, useMemo, useReducer } from 'react';
import type { ActionMap } from 'types/common';

type DrawerComponentNames = (typeof DRAWER_COMPONENTS_NAMES)[keyof typeof DRAWER_COMPONENTS_NAMES];

type DrawerState<T = unknown> = {
  isOpen: boolean;
  drawerComponent: null | DrawerComponentNames;
  data: T;
};

type DrawerProviderProps = {
  children?: React.ReactNode;
};

type DrawerContextProps<T> = {
  state: DrawerState<T>;
  dispatch: React.Dispatch<any>;
};

enum Types {
  OPEN_DRAWER = 'OPEN_DRAWER',
  CLOSE_DRAWER = 'CLOSE_DRAWER',
}

type DrawerPayload = {
  [Types.OPEN_DRAWER]: DrawerState;
  [Types.CLOSE_DRAWER]: void;
};

type DrawerActions = ActionMap<DrawerPayload>[keyof ActionMap<DrawerPayload>];

const DRAWER_COMPONENTS_NAMES = {
  RESERVATION_DETAILS: 'RESERVATION_DETAILS',
  WAIT_LIST_DETAILS: 'WAIT_LIST_DETAILS',
  QUEUE_LIST_DETAILS: 'QUEUE_LIST_DETAILS',
  ADD_NEW_GUEST: 'ADD_NEW_GUEST',
  MASTER_GUEST_DETAILS: 'MASTER_GUEST_DETAILS',
} as const;

const initialState: DrawerState = {
  isOpen: false,
  drawerComponent: null,
  data: {},
};

function reducer(state: DrawerState, action: DrawerActions): DrawerState {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return {
        ...state,
        isOpen: true,
        drawerComponent: action.payload.drawerComponent,
        data: action.payload.data,
      };
    case 'CLOSE_DRAWER':
      return {
        ...state,
        isOpen: false,
        drawerComponent: null,
        data: {},
      };
    default:
      return state;
  }
}

const DrawerContext = createContext<DrawerContextProps<unknown>>({
  state: initialState,
  dispatch: () => null,
});

DrawerContext.displayName = 'DrawerProvider';

// Drawer Provider
function DrawerProvider({ children }: DrawerProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const drawerState = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <DrawerContext.Provider value={drawerState}>{children}</DrawerContext.Provider>;
}

// use Drawer Context
function useDrawer<T>(): DrawerContextProps<T>;
// eslint-disable-next-line no-redeclare
function useDrawer<T, K extends keyof DrawerContextProps<T>>(key: K): DrawerContextProps<T>[K];
// eslint-disable-next-line no-redeclare
function useDrawer<T>(key?: keyof DrawerContextProps<T>) {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerContextProvider');
  }

  return key ? context[key] : context;
}

// Drawer actions creators
const openDrawer = ({ drawerComponent, data = {} }: Partial<DrawerState>) => ({
  type: Types.OPEN_DRAWER,
  payload: {
    drawerComponent,
    data,
  },
});

const closeDrawer = () => ({ type: Types.CLOSE_DRAWER });

export { useDrawer, DrawerProvider, openDrawer, closeDrawer };
export type { DrawerComponentNames };
