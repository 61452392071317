/* eslint-disable camelcase, no-console */
import store from 'redux-store';
import { batch } from 'react-redux';
import { socketWorker } from 'workers';
import { orderUpdatesFromSocket } from 'modules/Order/services';
import {
  CHANNELS,
  MESSAGE_TYPES,
  MESSAGE_EVENTS,
  SOCKET_ACTION,
  MESSAGE_TYPE_TO_AVOID,
} from 'constants/socket';
import { createCustomEventListener } from 'utils/event-listeners';
import { getPartnerSession } from 'utils/session';
import { getCurrentDateAndTime } from 'utils/date-and-time';
import { changeDate } from 'modules/Footer/actions';
import { resetWaitlistCache } from 'modules/Waitlist/actions';
import { resetQueueCache } from 'modules/QueueList/actions';
import { getPaymentProfiles, getVenueDetails } from 'modules/Partner/actions';

const fetchDataOnDateChange = () => {
  const { currentVenueId } = getPartnerSession();
  // reset cache for waitlist and queue to fetch again on socket update and fetch the payment profile to show warning/blocked
  batch(() => {
    store.dispatch(resetWaitlistCache());
    store.dispatch(resetQueueCache());
    store.dispatch(getPaymentProfiles());
    if (currentVenueId) {
      store.dispatch(getVenueDetails(currentVenueId));
    }
  });
  // the purpose of changing date here to same date as footer is to fire all the subscribed event to re-render view's
  // and update store which are dependent on date which includes ( availabilities, availabilitySummary, reservation list, waitlist )
  // why ? because since the socket were closed so we need to update data and views for these
  store.dispatch(changeDate(store?.getState()?.footer?.date?.clone() || getCurrentDateAndTime()));
};

export const socketWorkerMessage = ({
  data: {
    isSocketClosed = false,
    refetchDataIfSocketClosed = true,
    fetchDataOnReconnection = false,
  } = {},
}) => {
  // Checking if the socket is closed on onVisibilityChange and also on webrmsDashboardSocket.onclose event
  if (isSocketClosed) {
    const { accessToken, currentVenueId } = getPartnerSession();
    console.debug('WEBRMS SOCKET:- IS SOCKET CLOSED venue_id: ', currentVenueId, {
      isSocketClosed,
    });

    socketWorker.postMessage({
      ...SOCKET_ACTION.subscribe,
      venueId: currentVenueId,
      channel: CHANNELS.generalChannel,
      accessToken,
    });

    if (refetchDataIfSocketClosed) {
      fetchDataOnDateChange();
    }
  }

  if (fetchDataOnReconnection) {
    fetchDataOnDateChange();
  }
};

export const unSubscribeSocketWorker = () => {
  socketWorker.postMessage(SOCKET_ACTION.unsubscribe);
  socketWorker.removeEventListener('message', socketWorkerMessage, false);
  socketWorker.terminate();
};

const messageTypeToAvoidFromSameDevice = ({ deviceId, result }: any) => {
  // compare device_identifier from socket with deviceId from getPartnerSession to avoid socket events from same device in case of updates
  if (
    deviceId === result?.message?.device_identifier &&
    MESSAGE_TYPE_TO_AVOID.includes(result?.message?.message_type)
  )
    return false;
  return true;
};

// NOTE: createCustomEventListener can only pass data inside the "detail" object.. cannot pass anything outside
const socketMessage = ({ venue, venueId }: any) => {
  const { accessToken, deviceId } = getPartnerSession();
  socketWorker.postMessage({
    ...SOCKET_ACTION.subscribe,
    venueId,
    channel: CHANNELS.generalChannel,
    accessToken,
  });
  if (venueId) {
    socketWorker.onmessage = ({ data: result }) => {
      try {
        console.debug('WEBRMS SOCKET:-  venue_id: ', venueId, result);
        if (
          // only fire event for the MESSAGE_TYPES registered here..
          Object.values(MESSAGE_TYPES).includes(result?.message?.message_type) &&
          messageTypeToAvoidFromSameDevice({ deviceId, result })
        ) {
          switch (result.message.message_type) {
            case MESSAGE_TYPES.orders:
              orderUpdatesFromSocket({ message: result.message, venue });
              break;
            case MESSAGE_TYPES.slots:
              createCustomEventListener(MESSAGE_EVENTS.reservation, { detail: result.message });
              createCustomEventListener(MESSAGE_EVENTS.funnelTables, { detail: result.message });
              createCustomEventListener(MESSAGE_EVENTS.slots, { detail: result.message });
              break;
            case MESSAGE_TYPES.traffic:
              createCustomEventListener(MESSAGE_EVENTS.reservationActivities, {
                detail: result.message,
              });
              break;
            case MESSAGE_TYPES.rooms:
              createCustomEventListener(MESSAGE_EVENTS.rooms, { detail: result.message });
              break;
            case MESSAGE_TYPES.notifyLists:
              createCustomEventListener(MESSAGE_EVENTS.waitlist, { detail: result.message });
              break;
            case MESSAGE_TYPES.venueUpdate:
              createCustomEventListener(MESSAGE_EVENTS.venueAndAvailabilities, {
                detail: result.message,
              });
              // NOTE: if there was an update in reservation availability then update the SLOTS again in funnel
              if (result.message?.params?.reservation_availability_id) {
                createCustomEventListener(MESSAGE_EVENTS.slots, { detail: result.message });
              }
              break;
            case MESSAGE_TYPES.masterGuest:
              createCustomEventListener(MESSAGE_EVENTS.masterGuest, { detail: result.message });
              break;
            case MESSAGE_TYPES.footer:
              createCustomEventListener(MESSAGE_EVENTS.availabilitySummary, {
                detail: result.message,
              });
              break;
            case MESSAGE_TYPES.calendar:
              createCustomEventListener(MESSAGE_EVENTS.calendar, { detail: result.message });
              createCustomEventListener(MESSAGE_EVENTS.funnelCalendar, { detail: result.message });
              break;
            case MESSAGE_TYPES.tags:
              createCustomEventListener(MESSAGE_EVENTS.tags, { detail: result.message });
              break;
            case MESSAGE_TYPES.offlineWaitList:
              createCustomEventListener(MESSAGE_EVENTS.queueList, { detail: result.message });
              break;
            case MESSAGE_TYPES.tables:
              createCustomEventListener(MESSAGE_EVENTS.funnelTables, { detail: result.message });
              createCustomEventListener(MESSAGE_EVENTS.tables, { detail: result.message });
              break;
            case MESSAGE_TYPES.blockedTable:
              createCustomEventListener(MESSAGE_EVENTS.funnelTables, {
                detail: { ...(result?.message || {}), isBlockedTable: true },
              });
              createCustomEventListener(MESSAGE_EVENTS.tables, { detail: result.message });
              break;
            default:
              console.info(
                `"message.message_type": ${result.message.message_type} not match in socket-message`
              );
              break;
          }
        }
      } catch (error) {
        console.error('Error in update data from socket-message: ', error);
      }
    };
  }
};

export default socketMessage;
