import React, { Suspense, lazy, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SkeletonLoader } from '@umai/common';
import { selectIsWalkin } from 'modules/ReservationFunnel/selectors';
import { startWalkin } from 'modules/ReservationFunnel/actions';

const ReservationFunnelModule = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-reservation-funnel" */ 'modules/ReservationFunnel'
    )
);

// Using this wrapper around the ReservationFunnel component to make sure
// ReservationFunnel only gets rendered after the startWalkin action is dispatched
const WalkinFunnel = () => {
  const dispatch = useAppDispatch();
  const isWalkin = useAppSelector(selectIsWalkin);

  useEffect(() => {
    if (!isWalkin) {
      dispatch(startWalkin());
    }
  }, []);

  return isWalkin ? (
    <Suspense fallback={<SkeletonLoader.List />}>
      <ReservationFunnelModule isWalkin />
    </Suspense>
  ) : null;
};

export default WalkinFunnel;
