import type { RootState } from 'redux-store';
import { createSelector } from 'reselect';
import {
  applyWaitlistFilter,
  getWaitlistCount,
  getWaitlistGuestCount,
  getWaitlistNotificationCount,
} from 'modules/Waitlist/services';
import { WAITLIST_STATUSES } from 'modules/ReservationsList/constants';

export const selectWaitlist = ({ waitlist }: RootState) => waitlist.notifylists;

export const selectActiveWaitlist = createSelector(selectWaitlist, (notifylists) =>
  Object.values(notifylists.lists || {}).filter(
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    (w) => w.status.toUpperCase() === WAITLIST_STATUSES.NOTIFY
  )
);

export const selectWaitlistSearchText = ({ waitlist }: RootState) => waitlist.searchText;

const selectWaitlistItemsSlots = createSelector(selectWaitlist, (notifylists) =>
  Object.values(notifylists.availabilities || {}).map((availability) => ({
    // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
    ...availability,
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    list: availability.list.map((wId: any) => notifylists.lists?.[wId] || {}),
  }))
);

export const selectFilteredWaitlist = (availabilityId: any) =>
  createSelector(
    selectWaitlistItemsSlots,
    selectWaitlistSearchText,
    (waitlistItemsSlots, searchText) =>
      applyWaitlistFilter(waitlistItemsSlots, availabilityId, searchText)
  );

export const selectViewWaitlistId = ({ waitlist }: RootState) => waitlist.selectedWaitlistId;

export const selectWaitlistLoading = ({ waitlist }: RootState) => waitlist.isLoading;

export const selectWaitListNotificationCount = createSelector(
  [(state) => state.waitlist.cacheKey, selectWaitlist],
  (cacheKey, lists) => ({
    waitlistCount: getWaitlistNotificationCount(lists),
    cacheKey,
  })
);

export const selectWaitListCount = (availabilityId: any) =>
  createSelector(selectFilteredWaitlist(availabilityId), (filteredWaitList) => ({
    guestCount: getWaitlistGuestCount(filteredWaitList),
    reservationCount: getWaitlistCount(filteredWaitList),
  }));

export const selectWaitlistById = (waitlistId: any) =>
  createSelector(selectWaitlist, (notifylists) => notifylists.lists[waitlistId] || {});
