import hexToRgba from 'hex-to-rgba';
import {
  STATUSES,
  SEATED_SUBSTATUSES,
  RESERVED_SUBSTATUSES,
  FINISHED_STATUSES,
  RESERVATION_LIST_TYPES,
  WAITLIST_STATUSES,
  FINISHED_LIST_KEY,
  CURRENT_LIST_KEY,
  QUEUE_LIST_STATUSES,
  ATTENTION_STATUS,
} from 'modules/ReservationsList/constants';
import { COLORS, STATUSES_COLOR } from 'constants/colors';

export const getReservationStatusBackgroundColor = (status = '') => {
  try {
    return {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      backgroundColor: STATUSES_COLOR[status?.toUpperCase()],
    };
  } catch (err) {
    console.error(err);
    return {
      backgroundColor: COLORS.WHITE,
    };
  }
};

export const getReservationStatusTextColor = (status: any) => {
  try {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const color = STATUSES_COLOR[status.toUpperCase()];

    return { color };
  } catch (err) {
    console.error(err);
    return {
      color: COLORS.BLACK,
    };
  }
};

// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
export const getReservationStatusColor = (status: any) => STATUSES_COLOR[status?.toUpperCase()];

export const getHumanizedStatus = (status: any) => {
  const checkStatus = status?.toUpperCase();
  switch (checkStatus) {
    case STATUSES.AWAITING_PAYMENT:
      return 'Awaiting payment';
    case STATUSES.REJECTED:
      return 'Rejected';
    case STATUSES.FINISH:
      return 'Finished';
    case STATUSES['NO-SHOW']:
      return 'No Show';
    case STATUSES.CANCELLED:
    case QUEUE_LIST_STATUSES.CANCELLED_QUEUE:
      return 'Cancelled';
    case WAITLIST_STATUSES.NOTIFY:
      return 'Waitlist';
    case WAITLIST_STATUSES.EXPIRED_NOTIFY:
    case STATUSES.EXPIRED:
      return 'Expired';
    case WAITLIST_STATUSES.CANCELLED_NOTIFY:
      return 'Cancelled';
    case QUEUE_LIST_STATUSES.ACTIVE:
      return 'Queue';
    case QUEUE_LIST_STATUSES.REJECTED_QUEUE:
      return 'Rejected';
    case QUEUE_LIST_STATUSES.QUEUE_TURN_CONFIRMATION_SMS_SENT:
      return 'Queue Confirmed';
    case QUEUE_LIST_STATUSES.HEAD_TO_VENUE_SMS_SENT:
      return 'Head to venue';
    case QUEUE_LIST_STATUSES.TABLE_READY_SMS_SENT:
      return 'Table Ready';
    case QUEUE_LIST_STATUSES.CUSTOM_TEMPLATE_SMS_SENT:
      return 'Custom sms sent';
    case QUEUE_LIST_STATUSES.REMOVED:
      return 'Removed';
    case STATUSES.FAILED:
      return 'Failed Payment';
    case STATUSES.RESERVED:
      return 'Reserved';
    case STATUSES.CONFIRMED:
      return 'Confirmed';
    case STATUSES.LATE:
      return 'Late';
    case STATUSES.LEFT_MESSAGE:
      return 'Left Message';
    case STATUSES.SENT_MESSAGE:
      return 'Sent Message';
    case STATUSES.ARRIVED:
      return 'Arrived';
    case STATUSES.PARTIALLY_SEATED:
      return 'Partially Seated';
    case STATUSES.SEATED:
      return 'Seated';
    case STATUSES.STARTER:
      return 'Starter';
    case STATUSES.MAIN:
      return 'Main';
    case STATUSES.DESSERT:
      return 'Dessert';
    case STATUSES.CLEARED:
      return 'Cleared';
    case STATUSES.PAID:
      return 'Paid';
    case STATUSES.BILL_DROPPED:
      return 'Bill Dropped';
    case ATTENTION_STATUS:
      return 'Attention!';
    default:
      return status;
  }
};

export const getReservationListTypeByStatus = (status: any) => {
  if (FINISHED_STATUSES.includes(status?.toUpperCase())) return RESERVATION_LIST_TYPES.FINISHED;
  return RESERVATION_LIST_TYPES.RESERVED;
};

export const getListKeyByReservationStatus = (status = '') => {
  if (FINISHED_STATUSES.includes(status?.toUpperCase())) return FINISHED_LIST_KEY;
  return CURRENT_LIST_KEY;
};

export const getNextReservationStatus = (status: any) => {
  const reservationStatus = status?.toUpperCase() || '';
  let indexOfStatus = null;
  let nextStatus = null;

  if (STATUSES.RESERVED === reservationStatus) return [RESERVED_SUBSTATUSES[0], STATUSES.SEATED];

  // return next status for all  reserved statuses except no-show
  if (RESERVED_SUBSTATUSES.filter((s) => s !== STATUSES['NO-SHOW']).includes(status)) {
    indexOfStatus = RESERVED_SUBSTATUSES.indexOf(status);
    nextStatus = RESERVED_SUBSTATUSES?.[indexOfStatus + 1];
    // if next status is no-show then return next of no-show
    if (nextStatus === STATUSES['NO-SHOW']) nextStatus = RESERVED_SUBSTATUSES?.[indexOfStatus + 2];
    if (nextStatus) return [nextStatus, STATUSES.SEATED];
    return [STATUSES.SEATED];
  }

  if (STATUSES.SEATED === reservationStatus) return [SEATED_SUBSTATUSES[0], STATUSES.FINISH];

  if (SEATED_SUBSTATUSES.includes(status)) {
    indexOfStatus = SEATED_SUBSTATUSES.indexOf(status);
    nextStatus = SEATED_SUBSTATUSES?.[indexOfStatus + 1];
    if (nextStatus) return [nextStatus, STATUSES.FINISH];
    return [STATUSES.FINISH];
  }

  return [];
};

export const getStatusStyle = (status: any, currentStatusUC: any) => {
  const color = getReservationStatusColor(status);
  const textStyles = getReservationStatusTextColor(status);
  const selectedStatusStyles = {
    backgroundColor: hexToRgba(color, 0.1),
    border: `2px solid ${color}`,
  };

  if (status?.toUpperCase() === currentStatusUC) {
    return { ...textStyles, ...selectedStatusStyles };
  }
  return textStyles;
};
