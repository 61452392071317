import React, { Suspense, lazy } from 'react';
import { useAppSelector } from 'hooks/redux';
import { selectVenueIsFreemium } from 'modules/Partner/selectors';
import Loader from 'components/ui/Loader';
import FreemiumTimeline from './FreemiumTimeline';
import './Timeline.styles.scss';

const Timeline = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "umai-timeline" */ './Timeline')
);

const TimelineModule = () => {
  const isFreemium = useAppSelector(selectVenueIsFreemium);

  if (isFreemium) {
    return <FreemiumTimeline />;
  }

  return (
    <Suspense
      fallback={
        <div className="timeline-loader">
          <Loader.Circle />
        </div>
      }
    >
      <Timeline />
    </Suspense>
  );
};

export default TimelineModule;
