import React from 'react';
import PropTypes from 'prop-types';

interface PublicLayoutProps {
  children: React.ReactNode;
}

const PublicLayout = ({ children }: PublicLayoutProps) => (
  <main id="public-layout-wrapper" className="hero is-fullheight">
    <div className="hero-body" id="public-layout-body">
      <div className="container">{children}</div>
    </div>
  </main>
);

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicLayout;
