/* eslint-disable no-console, camelcase */
import { initializeApp } from 'firebase/app';
import { getToken, onMessage, deleteToken, isSupported, getMessaging } from 'firebase/messaging';
import { ORDER_NOTIFICATION_AUDIO_ID } from 'modules/Order/constants';
import OrderNotification from 'utils/notification';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage } from 'utils/alerts';
import { getPartnerSession } from 'utils/session';
import notificationApis from 'apis/notification';

// [START initialize_firebase_in_sw]
initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
});

let onMessageOrderNotify: any = null;

const initializeOrderNotify = () => {
  onMessageOrderNotify = new OrderNotification({ audioId: ORDER_NOTIFICATION_AUDIO_ID });
};

const isTokenSentToServer = () => {
  try {
    const { currentVenueId } = getPartnerSession();
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    const venueId = +(JSON.parse(window?.localStorage?.getItem?.('deviceToken'))?.venueId || 0);
    return (
      window?.localStorage?.getItem('sentDeviceTokenToServer') === '1' &&
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      venueId === +currentVenueId
    );
  } catch (error) {
    console.error('Error parsing in function: isTokenSentToServer', error);
    return false;
  }
};

const setTokenSentToServer = (sent: any, token = '') => {
  try {
    // @ts-expect-error TS(2774): This condition will always return true since this ... Remove this comment to see the full error message
    if (window?.localStorage?.setItem) {
      const { localStorage } = window;
      localStorage.setItem('sentDeviceTokenToServer', sent ? '1' : '0');
      if (sent) localStorage.setItem('deviceToken', JSON.stringify(token));
    }
  } catch (error) {
    console.error('Could not set token to localstorage in function: setTokenSentToServer', error);
  }
};

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
const sendTokenToServer = async (currentToken: any) => {
  if (!isTokenSentToServer()) {
    console.debug('Sending token to server...');
    // TODO(developer): Send the current token to your server.
    try {
      const { currentVenueId } = getPartnerSession();
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const data = { token: currentToken, venueId: +currentVenueId };
      await notificationApis.saveDeviceTokenToServer(data);
      // @ts-expect-error TS(2345): Argument of type '{ token: any; venueId: number; }... Remove this comment to see the full error message
      setTokenSentToServer(true, data);
    } catch (error) {
      console.error('Error sending the token to server');
      showErrorMessage(getErrorMessage(error));
      setTokenSentToServer(false);
    }
  } else {
    console.debug("Token already sent to server so won't send it again unless it changes");
  }
};

const requestPermission = () => {
  console.debug('Requesting permission...');
  // [START request_permission]
  if (window?.Notification?.requestPermission) {
    window.Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.debug('Notification permission granted.');
        const messaging = getMessaging();
        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY })
          .then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              sendTokenToServer(currentToken);
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
              setTokenSentToServer(false);
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            setTokenSentToServer(false);
          });
      } else {
        console.debug('Unable to get permission to notify.');
      }
    });
  }
  // [END request_permission]
};

const getFirebaseToken = () => {
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  isSupported().then((isWindowSupported) => {
    const messaging = isWindowSupported ? getMessaging() : null;
    if (messaging) {
      getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            sendTokenToServer(currentToken);
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            setTokenSentToServer(false);
            requestPermission();
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          setTokenSentToServer(false);
        });
    }
  });
};

const deleteNotificationToken = () => {
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.

  isSupported().then((isWindowSupported) => {
    const messaging = isWindowSupported ? getMessaging() : null;
    if (messaging) {
      getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            deleteToken(messaging)
              .then(() => {
                console.debug('Token deleted.');
                setTokenSentToServer(false);
                // [START_EXCLUDE]
                // Once token is deleted update UI.
                // getFirebaseToken();
                // [END_EXCLUDE]
              })
              .catch((err) => {
                console.debug('Unable to delete token. ', err);
              });
            // [END delete_token]
          }
        })
        .catch((err) => {
          console.debug('Error retrieving Instance ID token. ', err);
        });
    }
  });
};

// [START receive_message]
// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
//   `messaging.setBackgroundMessageHandler` handler.
function onMessageHandler() {
  isSupported().then((isWindowSupported) => {
    const messaging = isWindowSupported ? getMessaging() : null;
    if (messaging) {
      onMessage(messaging, (payload) => {
        // [START_EXCLUDE]
        // Update the UI to include the received message.
        const { data } = payload;
        if (onMessageOrderNotify) {
          onMessageOrderNotify.show(data?.title || '🎉 You have a new order !', {
            tag: data?.date || new Date().toString(),
            body: data?.body || '',
            icon: `${process.env.PUBLIC_URL}/order_received_notify.png`,
            badge: `${process.env.PUBLIC_URL}/order_received_notify.png`,
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            sound: `${process.env.PUBLIC_URL}/order-notifcation-sound.mp3`,
            requireInteraction: true,
          });
        }
        // [END_EXCLUDE]
      });
    }
  });
}

export { initializeOrderNotify, getFirebaseToken, deleteNotificationToken, onMessageHandler };
