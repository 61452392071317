import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface ForgotPasswordLockProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}

function ForgotPasswordLock({
  width = 16,
  height = 20,
  color = COLORS.LIGHT_BLUE,
}: ForgotPasswordLockProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Forgot Password</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64286 14.0108V15.9326C7.64286 16.1335 7.80852 16.2963 8 16.2963C8.19724 16.2963 8.35714 16.1275 8.35714 15.9326V14.0108C8.77328 13.8583 9.07143 13.4467 9.07143 12.963C9.07143 12.3493 8.59173 11.8519 8 11.8519C7.40827 11.8519 6.92857 12.3493 6.92857 12.963C6.92857 13.4467 7.22672 13.8583 7.64286 14.0108ZM6.92857 14.4446V15.9247C6.92857 16.539 7.40414 17.037 8 17.037C8.59173 17.037 9.07143 16.5399 9.07143 15.9247V14.4446C9.50516 14.1067 9.78571 13.5688 9.78571 12.963C9.78571 11.9402 8.98622 11.1111 8 11.1111C7.01378 11.1111 6.21429 11.9402 6.21429 12.963C6.21429 13.5688 6.49484 14.1067 6.92857 14.4446ZM2.64286 8.14816V5.55499C2.64286 2.48645 5.04133 0 8 0C10.9526 0 13.3571 2.48705 13.3571 5.55499V8.14816C14.5422 8.15167 15.5 9.14838 15.5 10.3774V17.7708C15.5 19.0099 14.5378 20 13.3509 20H2.64913C1.46114 20 0.5 19.0019 0.5 17.7708V10.3774C0.5 9.14043 1.45881 8.15164 2.64286 8.14816ZM3.35714 8.14815V5.55069C3.35714 2.89494 5.43582 0.740741 8 0.740741C10.559 0.740741 12.6429 2.89423 12.6429 5.55069V8.14815H11.9286V5.56228C11.9286 3.30852 10.1653 1.48148 8 1.48148C5.83031 1.48148 4.07143 3.30474 4.07143 5.56228V8.14815H3.35714ZM4.78571 8.14815V5.55619C4.78571 3.71055 6.2248 2.22222 8 2.22222C9.77159 2.22222 11.2143 3.71489 11.2143 5.55619V8.14815H4.78571ZM2.64102 8.88889C1.85305 8.88889 1.21429 9.55338 1.21429 10.3747V17.7734C1.21429 18.594 1.85066 19.2593 2.64102 19.2593H13.359C14.1469 19.2593 14.7857 18.5948 14.7857 17.7734V10.3747C14.7857 9.55411 14.1493 8.88889 13.359 8.88889H2.64102Z"
        fill={color}
      />
    </svg>
  );
}

ForgotPasswordLock.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default ForgotPasswordLock;
