import React from 'react';
import { SkeletonLoader } from '@umai/common';
import './GuestLoyaltySummary.styles.scss';

function GuestLoyaltySummaryLoader() {
  return (
    <div className="guest-loyalty-summary-wrapper skeleton">
      <SkeletonLoader.Blocks
        numBlocks={3}
        lines={['75%', '60%']}
        // @ts-expect-error TS(2322): Type '{ width: string; height: string; margin: str... Remove this comment to see the full error message
        blockStyles={{ width: '100px', height: '50px', margin: '0px 10px' }}
      />
    </div>
  );
}

export default GuestLoyaltySummaryLoader;
