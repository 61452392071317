import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { COLORS } from 'constants/colors';
import { selectIsAuthenticated } from '../../selectors';
import { login } from '../../actions';
import type { FormikHelpers } from 'formik';
import type { LoginFormValues } from 'modules/Auth/types';
import { ReactComponent as LogoIcon } from 'assets/images/icons/logo.svg';
import ForgotPasswordLockIcon from 'components/icons/ForgotPasswordLockIcon';
import LoginForm from './LoginForm';

const Login = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  // @ts-expect-error TS(2339): Property 'from' does not exist on type 'unknown'.
  const { from } = location.state || { from: { pathname: ROUTES.HOME } };

  const handleLoginSubmit = (
    values: LoginFormValues,
    { resetForm }: Pick<FormikHelpers<LoginFormValues>, 'resetForm'>
  ) => {
    dispatch(login(values, { resetForm }));
  };

  if (isAuthenticated) return <Redirect to={from} />;

  return (
    <>
      <div id="logo-wrapper">
        <LogoIcon />
      </div>
      <div className="login-component" id="form-wrapper">
        <h1>Login</h1>
        <p>Welcome back! Please enter your details</p>
        <LoginForm onSubmit={handleLoginSubmit} />
        <div id="button-link-wrapper">
          <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
          <ForgotPasswordLockIcon color={COLORS.WHITE} />
        </div>
      </div>
    </>
  );
};
export default Login;
