import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EMPTY_STATE_ICON_TYPE } from 'constants/app';
import { ReactComponent as BeanManPizzaIcon } from 'assets/images/icons/beanman-pizza.svg';
import { ReactComponent as BeanManHappyIcon } from 'assets/images/icons/beanman-happy.svg';
import { ReactComponent as BeanManUnHappyIcon } from 'assets/images/icons/beanman-unhappy.svg';
import { ReactComponent as BeanManNotificationIcon } from 'assets/images/icons/beanman-notification.svg';
import { ReactComponent as BeanManBalloonsIcon } from 'assets/images/icons/beanman-balloons.svg';
import './EmptyState.styles.scss';

interface EmptyStateProps {
  className?: string;
  iconType?: string;
  title: React.ReactNode;
  description?: React.ReactNode;
  isRelative?: boolean;
  children?: React.ReactNode;
}

const EmptyState = ({
  className,
  iconType = EMPTY_STATE_ICON_TYPE.pizza,
  title = '',
  description = '',
  isRelative = false,
  children = null,
}: EmptyStateProps) => {
  function renderIconType() {
    switch (iconType) {
      case EMPTY_STATE_ICON_TYPE.pizza:
        return <BeanManPizzaIcon />;
      case EMPTY_STATE_ICON_TYPE.happy:
        return <BeanManHappyIcon />;
      case EMPTY_STATE_ICON_TYPE.unhappy:
        return <BeanManUnHappyIcon />;
      case EMPTY_STATE_ICON_TYPE.notification:
        return <BeanManNotificationIcon />;
      case EMPTY_STATE_ICON_TYPE.balloons:
        return <BeanManBalloonsIcon />;
      default:
        return <BeanManPizzaIcon />;
    }
  }

  return (
    <div
      className={classnames('empty-state-wrapper', className, {
        'relative-positioned': isRelative,
      })}
    >
      <div>{renderIconType()}</div>
      <div className="title">{title}</div>
      {description ? <div className="description">{description}</div> : null}
      {children}
    </div>
  );
};

EmptyState.propTypes = {
  className: PropTypes.string,
  iconType: PropTypes.string,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  isRelative: PropTypes.bool,
  children: PropTypes.node,
};

export default EmptyState;
