export const initTagManager = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };

  const initialize = async () => {
    const TagManager = await import(
      /* webpackChunkName: "umai-google-tag-manager" */
      /* webpackMode: "lazy" */
      'react-gtm-module'
    );

    // @ts-expect-error TS(2339): Property 'initialize' does not exist on type 'type... Remove this comment to see the full error message
    TagManager.initialize(tagManagerArgs);
  };

  // @ts-expect-error TS(2339): Property 'attachEvent' does not exist on type 'Win... Remove this comment to see the full error message
  if (window.attachEvent) {
    // @ts-expect-error TS(2339): Property 'attachEvent' does not exist on type 'Win... Remove this comment to see the full error message
    window.attachEvent('onload', initialize);
  } else {
    window.addEventListener('load', initialize, false);
  }
};
