import React from 'react';
import { SkeletonLoader } from '@umai/common';
import './TabletFooter.styles.scss';

// Loader UI is needed to lazy load the tablet footer for the suspense fallback
// However as for the date of commit, tablet footer is mounted in two layouts:
// 1. src/layouts/ThirdPartyAppLayout
// 2. src/layouts/TabletLayout
// NOTE: Rest of all the places the TabletFooter is passed as a prop, so we do not need to wrap the suspense around those
function TabletFooterLoader() {
  return (
    <footer className="footer-menu-wrapper" id="tablet-footer">
      <nav className="footer-menu">
        <ul className="left-content">
          {[1, 2, 3, 4].map((index) => (
            <li key={index}>
              <SkeletonLoader.Skeleton
                style={{ width: '110px', height: '10px' }}
                // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
                wrapper="button"
                enableAnimation
                baseColor="#1e1e36"
                highlightColor="#ebebeb"
                containerClassName="button footer-button"
              />
            </li>
          ))}
        </ul>
        <ul className="right-content">
          <li>
            <SkeletonLoader.Skeleton
              style={{ width: '150px', height: '10px' }}
              // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
              wrapper="button"
              enableAnimation
              baseColor="#1e1e36"
              highlightColor="#ebebeb"
              containerClassName="button footer-button date-selector"
            />
          </li>
          <li>
            <SkeletonLoader.Skeleton
              style={{ width: '68px', height: '10px' }}
              enableAnimation
              baseColor="#1e1e36"
              highlightColor="#ebebeb"
              containerClassName="button footer-button"
            />

            <SkeletonLoader.Skeleton
              style={{ width: '80px', height: '10px' }}
              // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
              wrapper="button"
              enableAnimation
              baseColor="#1e1e36"
              highlightColor="#ebebeb"
              containerClassName="button footer-button"
            />
          </li>
        </ul>
      </nav>
    </footer>
  );
}

export default TabletFooterLoader;
