import { normalize, schema } from 'normalizr';
import * as ActionTypes from './action-types';
import { waitlistSlice, removeWaitlistItemFromList, formatAvailability } from './slice';
import { FINISHED_WAITLIST_AVAILABILITY } from './constants';

const listSchema = new schema.Entity(
  'lists',
  {},
  {
    processStrategy: (entity) => ({
      ...entity,
      reservationAvailability: formatAvailability(entity.reservationAvailability),
    }),
  }
);

const availabilitySchema = new schema.Entity(
  'availabilities',
  { list: [listSchema] },
  {
    // finished waitlist availability id is always null so we replace it to better handle the ids
    processStrategy: (entity) => ({
      ...entity,
      reservationAvailabilityId:
        entity.reservationAvailabilityId || FINISHED_WAITLIST_AVAILABILITY.id,
    }),
    idAttribute: ({ reservationAvailabilityId }) =>
      reservationAvailabilityId || FINISHED_WAITLIST_AVAILABILITY.id,
  }
);

const normalizeWaitlist = (waitlists: any) => {
  const normalized = normalize(waitlists, [availabilitySchema]);
  // normalized data format will be
  // {
  //   availabilities: {
  //     2: {
  //       reservationAvailabilityId: 2,
  //       reservationAvailabilityName: 'Lunch',
  //       list: [ 3283, 3284,... ],
  //     },
  //    ...
  //   },
  //   lists: {
  //     3283: { id: 3283, guestCount: 30,... },
  //     3284: { id: 3284,guestCount: 2,... },
  //     ...
  //   },
  // };
  if (!normalized.entities.lists) {
    return {
      availabilities: { ...normalized.entities.availabilities },
      lists: {},
    };
  }
  return normalized.entities;
};

const INITIAL_STATE = {
  cacheKey: null,
  notifylists: { availabilities: {}, lists: {} },
  selectedWaitlistId: null,
  searchText: '',
  isLoading: true,
  error: null,
};

// eslint-disable-next-line default-param-last
const waitlistReducer = (state = INITIAL_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_WAITLIST_ITEMS:
      return {
        ...state,
        notifylists: normalizeWaitlist(payload.notifylists ?? {}) || {},
        cacheKey: payload.cacheKey,
      };
    case ActionTypes.RESET_WAITLIST_CACHE:
      return { ...state, cacheKey: null };
    case ActionTypes.SET_WAITLIST_ERROR:
      return { ...state, isLoading: false, error: payload.error };

    case ActionTypes.SET_WAITLIST_LOADING:
      return { ...state, isLoading: payload.isLoading };

    case ActionTypes.VIEW_WAITLIST_ITEM:
      return { ...state, selectedWaitlistId: payload.waitlistId };
    case ActionTypes.UPDATE_WAITLIST_ITEM: {
      const { waitlistItem, footerDate } = payload;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      let currentWaitlistItem = state.notifylists.lists[waitlistItem.id];
      if (!currentWaitlistItem) return state;
      currentWaitlistItem = { ...currentWaitlistItem, ...waitlistItem };
      return {
        ...state,
        notifylists: {
          ...waitlistSlice({
            currentWaitlistItem,
            waitlists: state.notifylists,
            footerDate,
          }),
        },
      };
    }
    case ActionTypes.UPDATE_WAITLIST_FROM_SOCKET: {
      const { waitlistItem, footerDate } = payload;
      return {
        ...state,
        notifylists: {
          ...waitlistSlice({
            currentWaitlistItem: waitlistItem,
            waitlists: state.notifylists,
            footerDate,
          }),
        },
      };
    }
    case ActionTypes.REMOVE_FROM_WAITLIST: {
      const { waitlistId } = payload;
      // Remove waitlist that is converted to reservation from the reducer
      return {
        ...state,
        notifylists: {
          ...removeWaitlistItemFromList({ waitlistId, waitlists: state.notifylists }),
        },
      };
    }
    case ActionTypes.UPDATE_WAITLIST_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload.waitlistSearchText,
      };
    default:
      return state;
  }
};

export default waitlistReducer;
