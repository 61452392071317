/* eslint-disable react/jsx-props-no-spreading  */
import React, { Suspense, lazy, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SkeletonLoader, useViewPortContext } from '@umai/common';
import { useDrawer, closeDrawer, type DrawerComponentNames } from 'contexts/drawer';
import Drawer from 'components/shared/Drawer';
import GuestCrmListViewLoader from 'modules/GuestsCrm/components/GuestsCrmListView/Loader';
import DetailsLoader from 'layouts/Details/Loader';
import type { IDrawerProps } from 'rc-drawer/lib/IDrawerPropTypes';

/** Drawer Components */

const ReservationView = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-reservation-view" */ 'modules/ReservationsList/components/ReservationView'
    )
);

const WaitlistView = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-waitlist-view" */ 'modules/Waitlist/components/WaitlistView'
    )
);

const QueueListView = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-queue-view" */ 'modules/QueueList/components/QueueListView'
    )
);

const AddNewGuest = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-add-new-guest" */ 'modules/GuestsCrm/components/AddNewGuest'
    )
);

const GuestsCrmListView = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-guest-crm-list-view" */ 'modules/GuestsCrm/components/GuestsCrmListView'
    )
);

const specificDrawerContent = (drawerComponent: DrawerComponentNames, props: any) => {
  switch (drawerComponent) {
    case 'RESERVATION_DETAILS':
      return (
        <Suspense fallback={<DetailsLoader />}>
          <ReservationView {...props} />
        </Suspense>
      );
    case 'WAIT_LIST_DETAILS':
      return (
        <Suspense fallback={<DetailsLoader />}>
          <WaitlistView {...props} />
        </Suspense>
      );
    case 'QUEUE_LIST_DETAILS':
      return (
        <Suspense fallback={<DetailsLoader />}>
          <QueueListView {...props} />
        </Suspense>
      );
    case 'ADD_NEW_GUEST':
      return (
        <Suspense fallback={<SkeletonLoader.List />}>
          <AddNewGuest {...props} />
        </Suspense>
      );
    case 'MASTER_GUEST_DETAILS':
      return (
        <Suspense fallback={<GuestCrmListViewLoader />}>
          <GuestsCrmListView {...props} />
        </Suspense>
      );
    default:
      return null;
  }
};

interface DrawerItemsProps {
  placement?: IDrawerProps['placement'];
  defaultOpen?: boolean;
  showMask?: boolean;
  width?: string;
  handler?: IDrawerProps['handler'];
  level?: string | string[];
  wrapperClassName?: string;
  onHandleClick?(...args: unknown[]): unknown;
}

const DrawerItems = ({
  placement = 'right',
  defaultOpen = false,
  showMask = false,
  width,
  handler,
  level,
  wrapperClassName = '',
  onHandleClick,
  ...props
}: DrawerItemsProps) => {
  const { mobile } = useViewPortContext();
  const {
    state: { isOpen, drawerComponent, data },
    dispatch,
  } = useDrawer();

  const handleCloseDrawer = useCallback(() => dispatch(closeDrawer()), [dispatch]);

  const getWidth = () => {
    if (width) return width;
    return mobile ? '100vw' : '60vw';
  };

  return (
    <Drawer
      open={isOpen}
      width={getWidth()}
      placement={placement}
      onClose={handleCloseDrawer}
      showMask={showMask}
      wrapperClassName={wrapperClassName}
      defaultOpen={defaultOpen}
      handler={handler}
      level={level}
      onHandleClick={onHandleClick}
      {...props}
    >
      {drawerComponent &&
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        specificDrawerContent(drawerComponent, { closeDrawer: handleCloseDrawer, ...data })}
    </Drawer>
  );
};

DrawerItems.propTypes = {
  placement: PropTypes.string,
  defaultOpen: PropTypes.bool,
  showMask: PropTypes.bool,
  width: PropTypes.string,
  handler: PropTypes.node,
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  wrapperClassName: PropTypes.string,
  onHandleClick: PropTypes.func,
};
export default DrawerItems;
