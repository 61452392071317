/* eslint-disable no-console */
import Notify from 'notifyjs';
import { ORDER_NOTIFICATION_AUDIO_ID } from 'modules/Order/constants';

export default class OrderNotification {
  audioId: any;

  orderNotification: any;

  sound: any;

  constructor({ audioId = ORDER_NOTIFICATION_AUDIO_ID }) {
    this.audioId = audioId;
    this.orderNotification = null;
    this.sound = document.getElementById(audioId);
  }

  playSound = async () => {
    if (this.sound) {
      this.sound.loop = true;
      this.sound.muted = false;
      const isPlaying =
        this.sound?.currentTime > 0 &&
        !this.sound?.paused &&
        !this.sound?.ended &&
        this.sound?.readyState > 2;
      // if the sound is NOT playing then
      if (!isPlaying) {
        const soundPlay = this.sound.play();
        if (soundPlay !== undefined) {
          try {
            await soundPlay;
          } catch (error) {
            console.error('Error playing: ', error);
            this.pauseSound();
          }
        }
      }
    }
  };

  pauseSound = () => {
    if (this.sound) {
      this.sound.loop = false;
      this.sound.pause();
      this.sound.currentTime = 0;
    }
  };

  onShowNotification = () => {
    console.debug('notification is shown!');
    this.playSound();
  };

  onCloseNotification = (e: any) => {
    console.debug('notification is closed!', e);
    this.pauseSound();
  };

  onClickNotification = (e: any) => {
    console.debug('notification was clicked!', e);
    if (window?.focus) window.focus();
    this.pauseSound();
  };

  onErrorNotification = () => {
    console.error('Error showing notification. You may need to request permission.');
  };

  onPermissionGranted = (title = '', options = {}) => {
    console.debug('Permission has been granted by the user');
    this.doNotification(title, options);
  };

  onPermissionDenied = () => {
    console.warn('Permission has been denied by the user');
  };

  doNotification = (title = '', options = {}) => {
    if (this.orderNotification?.close) this.orderNotification.close();

    this.orderNotification = new Notify(title, {
      notifyShow: this.onShowNotification,
      notifyClose: this.onCloseNotification,
      notifyClick: this.onClickNotification,
      notifyError: this.onErrorNotification,
      ...options,
    });
    this.orderNotification.show();
  };

  show = (title = '', options = {}) => {
    try {
      if (!Notify.needsPermission) {
        this.doNotification(title, options);
      } else if (Notify.isSupported()) {
        Notify.requestPermission(
          this.onPermissionGranted.bind(this, title, options),
          this.onPermissionDenied
        );
      }
    } catch (error) {
      console.error('Something went wrong with show notification in notifyjs.', error);
    }
  };
}
