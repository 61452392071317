import React, { lazy } from 'react';
import ThirdPartyAppLayout from 'layouts/ThirdPartyAppLayout';

const ShopDashBoard = lazy(
  () =>
    import(
      // @ts-expect-error TS(7016): Could not find a declaration file for module '@uma... Remove this comment to see the full error message
      /* webpackPrefetch: true, webpackChunkName: "umai-shop-dashboard" */ '@umai/shop-dashboard'
    )
);

function OnlineOrder() {
  return (
    <ThirdPartyAppLayout id="shop-dashboard-root" className="umaiShopDashBoardParent" hasNoFooter>
      <ShopDashBoard />
    </ThirdPartyAppLayout>
  );
}

export default OnlineOrder;
