import React, { Suspense, lazy } from 'react';
import { useAppSelector } from 'hooks/redux';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useViewPortContext } from '@umai/common';
import { type Routes, ROUTES } from 'constants/routes';
import { RESERVATION_LIST_TYPES } from 'modules/ReservationsList/constants';
import useFooterDateInSearchParams from 'hooks/useFooterDateInSearchParams';
import useReservationListType from 'hooks/useReservationListType';
import { selectReservationListType } from 'modules/ReservationsList/selectors';
import { selectIsQueueSubscribed } from 'modules/Partner/selectors';
import { useDrawer } from 'contexts/drawer';
import ReservationsHeaderLoader from 'components/shared/ReservationsHeader/Loader';
import ReservationListLoader from 'components/shared/ReservationListLoader';
import DrawerItems from 'layouts/DrawerItems';
import Layout from 'layouts/Layout';
import './Reservations.styles.scss';

const TabletFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-tablet-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/TabletFooter'
    )
);

const MobileFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-mobile-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/MobileFooter'
    )
);

const ReservationsHeader = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-reservation-header" */
      /* webpackFetchPriority: "high" */
      'components/shared/ReservationsHeader'
    )
);

const ReservationsList = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-reservation-list" */ 'modules/ReservationsList'
    )
);

const Waitlist = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "umai-wait-list" */ 'modules/Waitlist')
);

const QueueList = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "umai-queue-list" */ 'modules/QueueList')
);

const Reservations = () => {
  const location = useLocation();
  const history = useHistory();
  const reservationListType = useAppSelector(selectReservationListType);
  const isQueueSubscribed = useAppSelector(selectIsQueueSubscribed);
  const { desktopAndAbove } = useViewPortContext();
  const { isOpen: isDrawerOpen } = useDrawer('state');

  useFooterDateInSearchParams();
  useReservationListType();

  const navigateTo = (route: Routes) => {
    history.push({
      ...location,
      pathname: route,
    });
  };

  const renderBody = () => {
    switch (reservationListType) {
      case RESERVATION_LIST_TYPES.WAITLIST:
        return <Waitlist />;
      case RESERVATION_LIST_TYPES.RESERVED:
      case RESERVATION_LIST_TYPES.FINISHED:
        return <ReservationsList />;
      case RESERVATION_LIST_TYPES.QUEUE:
        return <QueueList />;
      default:
        return <>No route matched for reservation</>;
    }
  };

  // Quick filters is only available for current and finished reservations
  const hasQuickFilter =
    reservationListType === RESERVATION_LIST_TYPES.RESERVED ||
    reservationListType === RESERVATION_LIST_TYPES.FINISHED;

  return (
    <Layout
      header={
        <Suspense fallback={<ReservationsHeaderLoader isMobile={false} />}>
          <ReservationsHeader hideFilters />
        </Suspense>
      }
      footer={<TabletFooter hasDate hasAvailabilities />}
      mobileHeader={
        <Suspense fallback={<ReservationsHeaderLoader isMobile />}>
          <ReservationsHeader />
        </Suspense>
      }
      mobileFooter={<MobileFooter showFloatingButton />}
      className={classnames('is-details', {
        'has-quick-filter': hasQuickFilter && !desktopAndAbove && !isDrawerOpen,
      })}
      customBodyClasses="is-details"
      onSwipedRight={() => {
        if (reservationListType === RESERVATION_LIST_TYPES.FINISHED) {
          navigateTo(ROUTES.RESERVATIONS);
        }

        if (reservationListType === RESERVATION_LIST_TYPES.WAITLIST) {
          navigateTo(ROUTES.FINISHED_RESERVATIONS);
        }
        if (reservationListType === RESERVATION_LIST_TYPES.QUEUE && isQueueSubscribed) {
          navigateTo(ROUTES.WAITLIST);
        }
      }}
      onSwipedLeft={() => {
        if (reservationListType === RESERVATION_LIST_TYPES.RESERVED) {
          navigateTo(ROUTES.FINISHED_RESERVATIONS);
        }
        if (reservationListType === RESERVATION_LIST_TYPES.FINISHED) {
          navigateTo(ROUTES.WAITLIST);
        }
        if (reservationListType === RESERVATION_LIST_TYPES.WAITLIST && isQueueSubscribed) {
          navigateTo(ROUTES.QUEUE_LIST);
        }
      }}
    >
      <Suspense fallback={<ReservationListLoader />}>{renderBody()}</Suspense>
      <DrawerItems wrapperClassName="reservation-detail-drawer" />
    </Layout>
  );
};

export default Reservations;
