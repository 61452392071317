// Combines 2 arrays into 1 with only unique values
export const combineArrays = (arr1: any, arr2: any) => [
  // @ts-expect-error TS(2802): Type 'Set<unknown>' can only be iterated through w... Remove this comment to see the full error message
  ...new Set((arr1 || []).concat(arr2 || [])),
];

// When array contains item remove it, otherwise add it
export const toggleItem = (arr: any, item: any) =>
  arr.includes(item) ? arr.filter((i: any) => i !== item) : [...arr, item];

// Adds isAutoTag property to automated Tag object while combining 2 arrays of tag objects
export const combineTagArrays = ({ autoTags, tags }: any) => [
  // @ts-expect-error TS(2802): Type 'Set<unknown>' can only be iterated through w... Remove this comment to see the full error message
  ...new Set(
    (
      autoTags?.map((tag: any) => ({
        ...tag,
        isAutoTag: true,
      })) || []
    ).concat(tags || [])
  ),
];
