import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { COLORS } from 'constants/colors';
import { selectIsAuthenticated } from '../../selectors';
import { sendPasswordResetInstructions } from '../../actions';
import { ReactComponent as LogoIcon } from 'assets/images/icons/logo.svg';
import ArrowBackIcon from 'components/icons/ArrowBackIcon';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  // @ts-expect-error TS(2339): Property 'from' does not exist on type 'unknown'.
  const { from } = location.state || { from: { pathname: ROUTES.HOME } };

  const handleForgotPasswordSubmit = ({ email }: any, { resetForm }: any) => {
    dispatch(sendPasswordResetInstructions(email));
    resetForm({ values: { email } });
  };

  if (isAuthenticated) return <Redirect to={from} />;

  return (
    <>
      <button
        type="button"
        className="back-arrow"
        onClick={() => {
          history.push(ROUTES.LOGIN);
        }}
      >
        <ArrowBackIcon color={COLORS.WHITE} />
      </button>
      <div id="logo-wrapper">
        <LogoIcon />
      </div>
      <div className="forgot-password-component" id="form-wrapper">
        <ForgotPasswordForm onSubmit={handleForgotPasswordSubmit} />
      </div>
    </>
  );
};

export default ForgotPassword;
