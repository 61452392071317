import type { AppDispatch, AppGetState } from 'redux-store';
import { batch } from 'react-redux';
import debounce from 'lodash-es/debounce';
import omit from 'lodash-es/omit';
import pick from 'lodash-es/pick';
import reservationApis from 'modules/ReservationsList/apis';
import guestApis from 'modules/Guest/apis';
import * as ActionTypes from 'modules/ReservationsList/action-types';
import { RESERVATION_FUNNEL_STEPS } from 'modules/ReservationFunnel/constants';
import { editReservation } from 'modules/ReservationFunnel/actions';
import { CHARGE_TYPES } from 'constants/charge';
import { STATUS_LOADING_KEY, STATUSES } from 'modules/ReservationsList/constants';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage, showSuccessMessage } from 'utils/alerts';
import { selectReservationById } from './selectors';
import { ROUTES } from 'constants/routes';

export const setReservationListLoading = ({
  currentListLoading = true,
  finishedListLoading = true,
} = {}) => ({
  type: ActionTypes.SET_RESERVATIONS_LIST_LOADING,
  payload: { loading: { currentListLoading, finishedListLoading } },
});

const getCurrentReservationsListDebounce = debounce(
  async ({ date, allDay, venueId }, dispatch, { signal }) => {
    try {
      const reservationList = await reservationApis.getCurrentReservations(
        { date, allDay, venueId },
        { signal }
      );
      dispatch({ type: ActionTypes.GET_CURRENT_RESERVATIONS_LIST, payload: { reservationList } });
    } catch (error) {
      if (reservationApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
      dispatch({
        type: ActionTypes.SET_RESERVATIONS_LIST_ERROR,
        payload: { error },
      });
    } finally {
      dispatch({
        type: ActionTypes.SET_RESERVATIONS_LIST_LOADING,
        payload: { loading: { currentListLoading: false } },
      });
    }
  },
  400
);

const getFinishedReservationsListDebounce = debounce(
  async ({ date, allDay, venueId }, dispatch, { signal }) => {
    try {
      const reservationList = await reservationApis.getFinishedReservations(
        { date, allDay, venueId },
        { signal }
      );
      dispatch({ type: ActionTypes.GET_FINISHED_RESERVATIONS_LIST, payload: { reservationList } });
    } catch (error) {
      if (reservationApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
      dispatch({
        type: ActionTypes.SET_RESERVATIONS_LIST_ERROR,
        payload: { error },
      });
    } finally {
      dispatch({
        type: ActionTypes.SET_RESERVATIONS_LIST_LOADING,
        payload: { loading: { finishedListLoading: false } },
      });
    }
  },
  400
);

export const getCurrentReservations = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  getCurrentReservationsListDebounce(params, dispatch, controller);
  // NOTE: make sure the function is not return an async method
  return controller;
};

export const getFinishedReservations = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  getFinishedReservationsListDebounce(params, dispatch, controller);
  return controller;
};

const getReservationActivitiesDebounced = debounce(
  async ({ reservationId, venueId }, dispatch, { signal }) => {
    try {
      const activities = await reservationApis.getReservationActivities(reservationId, venueId, {
        signal,
      });
      dispatch({ type: ActionTypes.GET_RESERVATION_ACTIVITIES, payload: activities });
    } catch (error) {
      if (reservationApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
    } finally {
      dispatch({
        type: ActionTypes.SET_RESERVATION_ACTIVITIES_LOADING,
        payload: false,
      });
    }
  },
  500
);

export const getReservationActivities = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  getReservationActivitiesDebounced(params, dispatch, controller);
  return controller;
};

export const setReservationStatusError = (error: any) => (dispatch: AppDispatch) => {
  dispatch({ type: ActionTypes.SET_RESERVATION_STATUS_ERROR, payload: { error } });
};

export const dispatchReservationUpdate = ({
  dispatch,
  getState,
  // @ts-expect-error TS(2339): Property 'reservation' does not exist on type '{ d... Remove this comment to see the full error message
  reservation,
}: {
  dispatch: AppDispatch;
  getState: AppGetState;
}) => {
  dispatch({
    type: ActionTypes.UPDATE_RESERVATION,
    payload: { reservation, footerDate: getState().footer.date },
  });
};

export const setViewReservationId = (reservationId: any) => ({
  type: ActionTypes.VIEW_RESERVATION,
  payload: { reservationId },
});

export const changeReservationStatus =
  ({ reservationId, status, recordSpend = {}, history }: any) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      dispatch({
        type: ActionTypes.SET_STATUS_LOADING,
        payload: { [STATUS_LOADING_KEY(reservationId)]: true },
      });
      let response = {};
      if (recordSpend?.reservation?.status) {
        // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type '{}'.
        response = await reservationApis.editReservation(recordSpend, reservationId);
      } else {
        // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type '{}'.
        response = await reservationApis.updateReservationStatus(
          { reservation: { status: status?.toLowerCase() } },
          reservationId
        );
      }
      // @ts-expect-error TS(2339): Property 'reservation' does not exist on type '{}'... Remove this comment to see the full error message
      const { reservation } = response;

      dispatchReservationUpdate({
        dispatch,
        getState,
        // remove data which conflicts with changing status and update store with restOfReservation
        // @ts-expect-error TS(2345): Argument of type '{ dispatch: import("/home/murtaz... Remove this comment to see the full error message
        reservation: omit(reservation, ['endWarning', 'startWarning']),
      });
    } catch (error) {
      // When reverting from cancelled/finished reservation, error can be thrown iF the previous table is now occupied
      // To resolve this, we reopen the edit reservationFunnel to resign another table to the reservation
      if (status === STATUSES.RESERVED || status === STATUSES.SEATED) {
        const reservationData = selectReservationById(getState(), reservationId);
        dispatch(
          editReservation({
            reservation: {
              ...reservationData,
              status: status?.toLowerCase(),
            },
            step: RESERVATION_FUNNEL_STEPS.SELECT_TABLES,
            isTimeEdit: false,
          })
        );
        history.push(ROUTES.RESERVATION_FUNNEL);
      } else {
        showErrorMessage(getErrorMessage(error));
      }
    } finally {
      dispatch({
        type: ActionTypes.SET_STATUS_LOADING,
        payload: { [STATUS_LOADING_KEY(reservationId)]: false },
      });
    }
  };

export const changeReservationStatusWithCharge =
  ({ reservationId, depositPayableFee, data }: any) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      dispatch({
        type: ActionTypes.SET_STATUS_LOADING,
        payload: { [STATUS_LOADING_KEY(reservationId)]: true },
      });
      const { payableType = CHARGE_TYPES.cancellation } = depositPayableFee || {};
      const { chargeAmount, ...restOfData } = data;
      const amountKey =
        (payableType === CHARGE_TYPES.cancellation && 'chargeAmount') || 'refundAmount';
      const requestPayload = { [amountKey]: chargeAmount, ...restOfData };
      // @ts-expect-error TS(2339): Property 'reservation' does not exist on type 'unk... Remove this comment to see the full error message
      const { reservation } = await reservationApis.updateReservationStatusWithCharge(
        requestPayload,
        reservationId
      );
      batch(() => {
        // @ts-expect-error TS(2345): Argument of type '{ dispatch: import("/home/murtaz... Remove this comment to see the full error message
        dispatchReservationUpdate({ dispatch, getState, reservation });
        dispatch(setReservationStatusError(null));
      });
    } catch (error) {
      // passing the error directly here to make sure the selectReservationStatusError calls on error
      // as passing string does not update the ChargePopup
      dispatch(setReservationStatusError(error));
    } finally {
      dispatch({
        type: ActionTypes.SET_STATUS_LOADING,
        payload: { [STATUS_LOADING_KEY(reservationId)]: false },
      });
    }
  };

export const updateReservationInfo =
  (data: any, reservationId: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const requestPayload = { reservation: data };
      // @ts-expect-error TS(2339): Property 'reservation' does not exist on type 'unk... Remove this comment to see the full error message
      const { reservation } = await reservationApis.updateReservationInfo(
        requestPayload,
        reservationId
      );
      // @ts-expect-error TS(2345): Argument of type '{ dispatch: import("/home/murtaz... Remove this comment to see the full error message
      dispatchReservationUpdate({ dispatch, getState, reservation });
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

export const updateReservationToLateOrAttention =
  (reservation: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      // @ts-expect-error TS(2345): Argument of type '{ dispatch: import("/home/murtaz... Remove this comment to see the full error message
      dispatchReservationUpdate({ dispatch, getState, reservation });
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

export const updateReservationGuest =
  (reservation: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      // @ts-expect-error TS(2345): Argument of type '{ dispatch: import("/home/murtaz... Remove this comment to see the full error message
      dispatchReservationUpdate({ dispatch, getState, reservation });
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

export const updateReservation = async ({
  reservationId,
  data = {},
  venueId,
  dispatch,
  getState,
  fields = [],
  payload = {},
}: any) => {
  try {
    const requestPayload = {
      venueId,
      reservation: { ...data, errorOverride: true },
      ...payload,
    };
    // @ts-expect-error TS(2339): Property 'reservation' does not exist on type 'unk... Remove this comment to see the full error message
    const { reservation } = await reservationApis.editReservation(requestPayload, reservationId);
    const updatedReservationData = pick(reservation, [
      'id',
      ...(fields?.length ? fields : Object.keys(data)),
    ]);
    // @ts-expect-error TS(2345): Argument of type '{ dispatch: any; getState: any; ... Remove this comment to see the full error message
    dispatchReservationUpdate({ dispatch, getState, reservation: updatedReservationData });
  } catch (err) {
    showErrorMessage(getErrorMessage(err));
  }
};

export const updateReservationToAttentionWarning =
  (reservationId: any, data: any, venueId: any) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    updateReservation({ reservationId, data, venueId, dispatch, getState });

export const updateReservationTurnTime =
  (reservationId: any, data: any, venueId: any) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    updateReservation({ reservationId, data, venueId, dispatch, getState });

export const updateReservationPartySize =
  (reservationId: any, data: any, venueId: any) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    updateReservation({ reservationId, data, venueId, dispatch, getState });

export const updateReservationNote =
  (reservationId: any, data: any, venueId: any) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    updateReservation({ reservationId, data, venueId, dispatch, getState });

export const updateReservationTags =
  (reservationId: any, data: any, venueId: any) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    updateReservation({ reservationId, data, venueId, dispatch, getState, fields: ['tags'] });

export const updateSearchText = (searchText: any) => async (dispatch: AppDispatch) => {
  dispatch({ type: ActionTypes.UPDATE_SEARCH_TEXT, payload: { searchText } });
};

export const updateReservationListType =
  (reservationListType: any) => async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.UPDATE_RESERVATION_LIST_TYPE, payload: { reservationListType } });
  };

export const updateReservationFromSocket =
  (socketReservation: any) => async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.UPDATE_RESERVATION_FROM_SOCKET, payload: socketReservation });
  };

export const updateReservationThirdPartyBooker =
  (reservationId: any, payload: any, venueId: any) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    updateReservation({
      reservationId,
      venueId,
      dispatch,
      getState,
      fields: ['thirdParty'],
      payload,
    });

export const createReservationThirdPartyBooker =
  (reservationId: any, data: any, venueId: any) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      // @ts-expect-error TS(2339): Property 'masterGuest' does not exist on type 'unk... Remove this comment to see the full error message
      const { masterGuest } = await guestApis.createGuest(data);
      const payload = { thirdPartyId: masterGuest.id };
      updateReservation({
        reservationId,
        venueId,
        dispatch,
        getState,
        fields: ['thirdParty'],
        payload,
      });
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    }
  };

export const sendEmailConfirmation = async ({ reservationId, venueId }: any) => {
  try {
    await reservationApis.sendEmailConfirmation(reservationId, venueId);
    showSuccessMessage(`Email notification sent to the guest!`);
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

export const exportReservations = (params: any) => async (dispatch: AppDispatch) => {
  try {
    const controller = new AbortController();
    dispatch({
      type: ActionTypes.EXPORT_RESERVATIONS,
      payload: {
        abortController: controller,
        isExportingReservations: true,
      },
    });

    // TODO: This hardcoded params assignment will be removed once filter is implemented
    params.statuses = [
      'reserved',
      'confirmed',
      'late',
      'left_message',
      'sent_message',
      'arrived',
      'partially_seated',
      'seated',
      'starter',
      'main',
      'dessert',
      'cleared',
      'bill_dropped',
    ];
    params.allDay = true;
    params.time = '';

    const response = await reservationApis.exportReservations(params, {
      responseType: 'blob',
      signal: controller.signal,
    });

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const blob = new Blob([response.data], {
      type: 'application/pdf',
      // @ts-expect-error TS(2345): Argument of type '{ type: string; encoding: string... Remove this comment to see the full error message
      encoding: 'UTF-8',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${params.date}-reservations-list.pdf`;
    link.click();
  } catch (error) {
    if (reservationApis.isCancel(error)) {
      console.error(error);
    } else {
      showErrorMessage(getErrorMessage(error));
    }
  } finally {
    dispatch({
      type: ActionTypes.EXPORT_RESERVATIONS,
      payload: { isExportingReservations: false },
    });
  }
};

export const cancelExportReservation = () => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionTypes.CANCEL_EXPORT_RESERVATIONS,
    payload: { abortController: null, isExportingReservations: false },
  });
};
