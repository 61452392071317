import { getPartnerSession } from 'utils/session';

function setWootric() {
  const { currentVenueId, email } = getPartnerSession();
  window.wootricSettings = {
    email: `${currentVenueId}.${email}`, // Required to uniquely identify a user. Email is recommended but this can be any unique identifier.
    external_id: currentVenueId, // Reference field for external integrations only. Send it along with email. OPTIONAL
    account_token: process.env.REACT_APP_WOOTRIC_TOKEN, // This is your unique account token
    product_name: 'umai360', // The name of the product or service.
    survey_type_scale: 1, // To show scale upto 10 values
    modal_theme: 'dark',
  };
  // This loads the Wootric survey
  if (window.wootric) {
    window.wootric('run');
  }
}

export function initWootric() {
  const wootricCdnId = 'wootric-beacon';
  const w = window;
  const d = document;
  const l = () => {
    if (!document.getElementById(wootricCdnId)) {
      const s = d.createElement('script');
      s.id = wootricCdnId;
      s.type = 'text/javascript';
      s.defer = true;
      s.src = 'https://cdn.wootric.com/wootric-sdk.js';
      const x = d.getElementsByTagName('script')[0];
      x?.parentNode?.insertBefore(s, x);

      s.addEventListener('load', () => {
        setWootric();
      });
    }
  };

  w.addEventListener('load', l, false);
}
