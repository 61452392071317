/* eslint-disable no-console,camelcase */
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { MESSAGE_EVENTS } from 'constants/socket';
import { getVenueAvailabilitySummary } from 'modules/Footer/actions';
import { formatDateToServerDate } from 'utils/date-and-time';

const useSocketAvailabilitySummary = ({ venueId, footerDate }: any) => {
  const dispatch = useAppDispatch();
  let availabilitySummaryController: any = null;

  const getAvailabilitySummary = ({ detail = {} }) => {
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      if (detail?.params && footerDate) {
        // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
        const { params } = detail;
        const fDate = formatDateToServerDate(footerDate);
        console.debug(
          'AVAILABILITY SUMMARY: socket update: ',
          params,
          params?.date,
          fDate,
          params?.reservation_status?.toUpperCase()
        );
        availabilitySummaryController = dispatch(getVenueAvailabilitySummary(venueId, footerDate));
      }
    } catch (error) {
      console.error('Error in update availability summary from socket', error);
    }
  };

  useEffect(() => {
    if (venueId)
      // @ts-expect-error TS(2769): No overload matches this call.
      document.addEventListener(MESSAGE_EVENTS.availabilitySummary, getAvailabilitySummary, false);
    return () => {
      availabilitySummaryController?.abort();
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(
        MESSAGE_EVENTS.availabilitySummary,
        getAvailabilitySummary,
        false
      );
    };
  }, [venueId, footerDate]);
};

export default useSocketAvailabilitySummary;
