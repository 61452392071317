import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import CloseIcon from 'components/icons/CloseIcon';
import styles from './WarningsContainer.module.scss';

type Props = {
  onClose: () => void;
  buttonLabel: string;
  onButtonClick: () => void;
  messages?: string[];
};

const Warning = ({ onClose, buttonLabel, onButtonClick, messages }: Props) => {
  const location = useLocation();

  if (!messages?.length) {
    return null;
  }

  return (
    <div className={styles.warning} role="alert">
      <ul className={styles.warningMessages}>
        {messages?.map((warning, index) => (
          <li
            key={warning}
            className={classnames(styles.message, { [styles.firstMessage]: index === 0 })}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: warning }}
          />
        ))}
      </ul>
      {/* Do not show button if we already on settings app  */}
      {!location.pathname.includes(ROUTES.SETTINGS) && (
        <button type="button" className={styles.actionButton} onClick={onButtonClick}>
          {buttonLabel}
        </button>
      )}
      <CloseIcon className={styles.closeIcon} onClick={onClose} />
    </div>
  );
};

export default Warning;
