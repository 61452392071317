import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { getReservationListType } from 'modules/ReservationsList/services';
import { updateReservationListType } from 'modules/ReservationsList/actions';
import { closeDrawer, useDrawer } from 'contexts/drawer';

const useReservationListType = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const drawerDispatch = useDrawer('dispatch');

  useEffect(() => {
    const reservationListType = getReservationListType(location.pathname);
    if (reservationListType) {
      dispatch(updateReservationListType(reservationListType));
      drawerDispatch(closeDrawer());
    }
  }, [dispatch, drawerDispatch, location.pathname]);
};

export default useReservationListType;
