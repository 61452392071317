import React, { lazy } from 'react';
import useFooterDateInSearchParams from 'hooks/useFooterDateInSearchParams';
import Layout from 'layouts/Layout';
import TimelineModule from 'modules/Timeline';

const TabletFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-tablet-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/TabletFooter'
    )
);

const MobileFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-mobile-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/MobileFooter'
    )
);

function Timeline() {
  useFooterDateInSearchParams();

  return (
    <Layout
      footer={<TabletFooter hasDate hasAvailabilities />}
      mobileFooter={<MobileFooter hideAvailabilities hideNavigation />}
      className="timeline-layout"
    >
      <TimelineModule />
    </Layout>
  );
}

export default Timeline;
