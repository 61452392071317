import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';

class Room extends HttpRequest {
  constructor() {
    super(API_ENDPOINT_PARTNER_V2);
  }

  getVenueRooms(venueId: any, config: any) {
    return this.fetch('/rooms', { venueId }, config);
  }

  getRoomTables(venueId: any, config: any) {
    return this.fetch(`/tables`, { venueId }, config);
  }

  blockTable({ tableId, blockTimeStart, blockTimeEnd }: any) {
    return this.create(`/tables/${tableId}/block_table/`, {
      blockTimeStart,
      blockTimeEnd,
      errorOverwrite: true,
    });
  }

  unBlockTable({ tableId, blockedTableId }: any) {
    return this.remove(`/tables/${tableId}/unblock_table`, { blockedTableId });
  }
}

export default new Room();
