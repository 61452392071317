import React from 'react';
import { createRoot } from 'react-dom/client';
import initIntercom from 'utils/intercom';
import { addSentryIntegrations, initSentry } from 'utils/sentry';
import { setWebView } from 'utils/devices';
import { initTagManager } from 'utils/google-tag-manager';
import { initBeamer } from 'utils/beamer';
import App from './App';
import * as serviceWorker from './serviceWorker';
import registerServiceWorker from './register-sw';
import reportWebVitals from './reportWebVitals';
import 'utils/pollyfills';
import 'styles/index.scss';

const element = document.getElementById('webrms-root') as HTMLElement;

const root = createRoot(element);

root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Initiate third party integration
setWebView();
registerServiceWorker();
initIntercom();
initSentry();
initTagManager();
addSentryIntegrations();
initBeamer();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
