export const GET_QUEUE_ITEMS = 'GET_QUEUE_ITEMS';
export const SET_QUEUE_ERROR = 'SET_QUEUE_ERROR';
export const SET_QUEUE_LIST_LOADING = 'SET_QUEUE_LIST_LOADING';
export const VIEW_QUEUE_RESERVATION = 'VIEW_QUEUE_RESERVATION';
export const UPDATE_QUEUE_ITEM = 'UPDATE_QUEUE_ITEM';
export const UPDATE_QUEUE_STATUS = 'UPDATE_QUEUE_STATUS';
export const UPDATE_QUEUE_SEARCH_TEXT = 'UPDATE_QUEUE_SEARCH_TEXT';
export const UPDATE_QUEUE_FROM_SOCKET = 'UPDATE_QUEUE_FROM_SOCKET';
export const REMOVE_FROM_QUEUE = 'REMOVE_FROM_QUEUE';
export const GET_QUEUE_ACTIVITIES = 'GET_QUEUE_ACTIVITIES';
export const SET_QUEUE_ACTIVITIES_LOADING = 'SET_QUEUE_ACTIVITIES_LOADING';
export const RESET_QUEUE_CACHE = 'RESET_QUEUE_CACHE';
