import * as ActionTypes from './action-types';

const INITIAL_STATE = {
  error: null,
  isGuestTagsLoading: false,
  isReservationTagsLoading: false,
  guestTags: [],
  reservationTags: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = INITIAL_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_RESERVATION_TAGS_LOADING:
      return {
        ...state,
        isReservationTagsLoading: payload,
      };
    case ActionTypes.SET_GUEST_TAGS_LOADING:
      return {
        ...state,
        isGuestTagsLoading: payload,
      };
    case ActionTypes.SET_TAGS_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case ActionTypes.GET_GUEST_TAGS:
      return {
        ...state,
        guestTags: [...payload.tags],
      };
    case ActionTypes.GET_RESERVATION_TAGS:
      return {
        ...state,
        reservationTags: [...payload.tags],
      };
    default:
      return state;
  }
};

export default reducer;
