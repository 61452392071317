export const GENDER = {
  male: 'Male',
  female: 'Female',
  other: 'Other',
};

export const GUEST_RESERVATION_TYPE = {
  upcoming: 'upcoming',
  past: 'past',
} as const;

export const HISTORY_TRANSACTION_TYPE = {
  redemption: 'redemption',
  deduction: 'deduction',
  collection: 'collection',
  refund: 'refund',
  addition: 'addition',
  delivery: 'delivery',
  takeaway: 'takeaway',
  giftCard: 'gift_card',
  reservation: 'reservation',
};

export const GUEST_COMMUNICATION = {
  sms: 'sms',
  email: 'email',
  automation: 'automation',
  tableReady: 'table_ready',
  queueTurnConfirmation: 'queue_turn_confirmation',
  customTemplate: 'custom_template',
  headToVenue: 'head_to_venue',
} as const;

export const GUEST_FIRST_VISIT = '1st Visit';
