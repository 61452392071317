import React from 'react';
import { SkeletonLoader, useViewPortContext } from '@umai/common';

function Loader() {
  const { mobile } = useViewPortContext();

  const renderHeader = () => {
    const basic = (
      <section className="reservation-basic-info">
        {[1, 2, 3].map((index) => (
          // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
          <SkeletonLoader.Skeleton key={index} wrapper={'div'} width={'80px'} />
        ))}
      </section>
    );

    if (mobile) return <div className="reservation-slot-header">{basic}</div>;

    return (
      <div className="reservation-slot-header">
        {basic}
        <section className="reservation-extra-info">
          {[1, 2, 3].map((index) => (
            <div key={index}>
              <SkeletonLoader.Skeleton width={'50px'} />
            </div>
          ))}
          <div className="source-wrapper">
            <SkeletonLoader.Skeleton width={'50px'} />
          </div>
        </section>
      </div>
    );
  };

  const renderExtraInfo = () => {
    if (mobile) return null;

    return (
      <section className="reservation-extra-info">
        <div className="rsv-notes">
          <SkeletonLoader.Skeleton width={'100px'} />
          <SkeletonLoader.Skeleton width={'70px'} />
        </div>
        <div className="rsv-notes">
          <SkeletonLoader.Skeleton width={'100px'} />
          <SkeletonLoader.Skeleton width={'70px'} />
        </div>
        <div className="tags-wrapper">
          {[1, 2, 3].map((index) => (
            <SkeletonLoader.Skeleton
              key={index}
              width={'40px'}
              height={'30px'}
              style={{ margin: '0 3px' }}
            />
          ))}
        </div>
        <div className="source-wrapper">
          <SkeletonLoader.Skeleton width={'40px'} />
          <SkeletonLoader.Skeleton width={'60px'} />
        </div>
      </section>
    );
  };

  return (
    <div id="reservations-list">
      {[1, 2, 3, 4, 5, 6].map((index) => (
        <section key={index} className="reservation-slot">
          {renderHeader()}
          <div className="reservation-slot-wrapper">
            <div className="reservation-slot-item">
              <div className="reservation-basic-info">
                <div>
                  <SkeletonLoader.Skeleton width={'150px'} />
                  <SkeletonLoader.Skeleton width={'100px'} />
                  <SkeletonLoader.Skeleton width={'70px'} />
                </div>
                <div className="guest-count-wrapper">
                  <SkeletonLoader.Skeleton width={'30px'} />
                </div>
                <div>
                  <button type="button" style={{ width: '100px', height: '60px', border: 'none' }}>
                    <SkeletonLoader.Skeleton
                      width={'50px'}
                      baseColor="#fff"
                      highlightColor="#f4f4f4"
                    />
                    <SkeletonLoader.Skeleton
                      width={'70px'}
                      baseColor="#fff"
                      highlightColor="#f4f4f4"
                    />
                  </button>
                </div>
              </div>
              {renderExtraInfo()}
            </div>
          </div>
        </section>
      ))}
    </div>
  );
}

export default Loader;
