import type { RootState } from 'redux-store';
import { createSelector } from 'reselect';
import { checkIsFiltersAdded } from './services';

export const selectFilterView = ({ filter }: RootState) => filter.filterView;
export const selectSelectedStatuses = ({ filter }: RootState) => filter.selectedStatuses;
export const selectSelectedRooms = ({ filter }: RootState) => filter.selectedRooms;
export const selectSelectedPartySizes = ({ filter }: RootState) => filter.selectedPartySizes;
export const selectSelectedFilters = ({ filter }: RootState) => filter.selectedFilters;
export const selectHasTags = ({ filter }: RootState) => filter.hasTags;
export const selectHasNotes = ({ filter }: RootState) => filter.hasNotes;
export const selectReservationFilters = ({ filter }: RootState) => filter.reservationFilters;

export const selectIsFiltered = createSelector(selectSelectedFilters, (selectFiltered) =>
  checkIsFiltersAdded(selectFiltered)
);
