import React from 'react';
// @ts-expect-error TS(2305): Module '"prop-types"' has no exported member 'Prop... Remove this comment to see the full error message
import { PropTypes } from 'prop-types';
import { COLORS } from 'constants/colors';

interface SearchIconProps {
  size?: number | string;
  color?: string;
  className?: string;
}

function SearchIcon({ size = 18, color = COLORS.DISABLED, className }: SearchIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <title>Search</title>
      <path
        d="M13.69 14a.431.431 0 01-.3-.124l-3.429-3.4A6.025 6.025 0 010 5.97a6.017 6.017 0 0112.034 0c0 1.44-.526 2.832-1.477 3.914l3.43 3.4a.418.418 0 01-.136.683.418.418 0 01-.161.033zM6.014.838a5.133 5.133 0 105.172 5.132A5.158 5.158 0 006.014.838z"
        fill={color}
      />
    </svg>
  );
}

SearchIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  className: PropTypes.string,
};

export default SearchIcon;
