import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import * as Sentry from '@sentry/react';
import reducers from './reducers';

// NOTE: maybe can add the following middleware in future if required, like:
// errorMiddleware,
// notificationMiddleware,
// loggerMiddleware
const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const defaultMiddleware = [thunk];
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const storeEnhancers = composeEnhancers(applyMiddleware(...defaultMiddleware), sentryReduxEnhancer);

const store = createStore(reducers, storeEnhancers);

export const setupStore = (preloadedState?: Partial<RootState>) =>
  createStore(reducers, preloadedState, storeEnhancers);

export type AppGetState = typeof store.getState;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppGetState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
