import { useAppSelector } from 'hooks/redux';
import { selectFooterDate } from 'modules/Footer/selectors';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import useSocketRooms from './hooks/useSocketRooms';
import useSocketVenueAndAvailabilities from './hooks/useSocketVenueAndAvailabilites';
import useSocketReservationTags from './hooks/useSocketReservationTags';
import useSocketCalendar from './hooks/useSocketCalendar';
import useSocketAvailabilitySummary from './hooks/useSocketAvailabilitySummary';
import useFetchReservationList from './hooks/useFetchReservationList';
import useFetchWaitlist from 'modules/Waitlist/hooks/useFetchWaitlist';
import useSocketReservationList from './hooks/useSocketReservationList';
import useVenueChange from './hooks/useVenueChange';
import useFooterDateChange from './hooks/useFooterDateChange';
import useSocketWaitlist from './hooks/useSocketWaitlist';
import useSocketQueueList from './hooks/useSocketQueueList';
import useSocketTables from './hooks/useSocketTables';
import useSocketReservationMasterGuest from './hooks/useSocketReservationMasterGuest';

const BootstrapReservations = () => {
  const footerDate = useAppSelector(selectFooterDate);
  const venueId = useAppSelector(selectCurrentVenueId);
  useFetchReservationList({ venueId, footerDate });
  useFooterDateChange({ venueId, footerDate });
  useVenueChange({ venueId, footerDate });
  useFetchWaitlist();
  useSocketRooms({ venueId });
  useSocketVenueAndAvailabilities({ venueId, footerDate });
  useSocketReservationTags({ venueId });
  useSocketCalendar({ venueId });
  useSocketAvailabilitySummary({ venueId, footerDate });
  useSocketReservationList({ venueId, footerDate });
  useSocketReservationMasterGuest({ venueId });
  useSocketWaitlist({ venueId, footerDate });
  useSocketQueueList({ venueId, footerDate });
  useSocketTables({ venueId });

  return null;
};

export default BootstrapReservations;
