import React from 'react';
import { SkeletonLoader, useViewPortContext } from '@umai/common';
import GuestBasicInfoLoader from 'components/shared/GuestBasicInfo/Loader';
import GuestTransactionsSummaryLoader from 'components/shared/GuestTransactionsSummary/Loader';
import GuestLoyaltySummaryLoader from 'components/shared/GuestLoyaltySummary/Loader';
import PanelLoader from 'components/panels/Panel/PanelLoader';
import classNames from 'classnames';
import './Details.styles.scss';

const Loader = () => {
  const { mobile } = useViewPortContext();

  return (
    <div className="details-view-wrapper">
      <header id="details-header">
        {!mobile && (
          <div className="left-buttons">
            <SkeletonLoader.Blocks
              numBlocks={2}
              lines={['90%']}
              // @ts-expect-error TS(2322): Type '{ width: string; height: string; margin: str... Remove this comment to see the full error message
              blockStyles={{ width: '80px', height: '40px', margin: '0px 5px' }}
            />
          </div>
        )}
        <div className="right-buttons">
          <SkeletonLoader.Blocks
            numBlocks={1}
            lines={['90%']}
            // @ts-expect-error TS(2322): Type '{ width: string; height: string; margin: str... Remove this comment to see the full error message
            blockStyles={{ width: '80px', height: '40px', margin: '0px' }}
          />
        </div>
      </header>
      <div className="details-tabs-wrapper">
        <div className="reservation-details-component">
          <ul className="details-tabs">
            {[1, 2].map((index) => (
              <li key={index}>
                <button type="button">
                  <SkeletonLoader.Skeleton />
                </button>
              </li>
            ))}
          </ul>
          <GuestBasicInfoLoader />
          <div
            className={classNames('guest-venue-and-partner-summary', {
              'in-two-rows': !mobile,
            })}
          >
            <GuestTransactionsSummaryLoader
              isPartner={false}
              header
              isPartnerVisible
              showZeroSpend
            />
            <GuestTransactionsSummaryLoader isPartner header isPartnerVisible showZeroSpend />
          </div>
          <div className="reservation-details-wrapper">
            {[1, 2, 3, 4].map((index) => (
              <button type="button" key={index} className="button">
                <SkeletonLoader.Skeleton
                  // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
                  wrapper={'div'}
                  style={{ width: '30px', height: '10px', margin: '0px 10px' }}
                />
                <SkeletonLoader.Skeleton
                  // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
                  wrapper={'div'}
                  style={{ width: '60px', height: '10px', margin: '0px 10px' }}
                />
              </button>
            ))}
          </div>
          <GuestLoyaltySummaryLoader />
          <PanelLoader />
        </div>
      </div>
    </div>
  );
};

export default Loader;
