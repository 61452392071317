import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { DATE_FORMAT } from 'constants/time-and-date';
import { selectFooterDate } from 'modules/Footer/selectors';

const useFooterDateInSearchParams = () => {
  const location = useLocation();
  const history = useHistory();
  const footerDate = useAppSelector(selectFooterDate);

  useEffect(() => {
    // update current search params with date,
    // Its  need to use URLSearchParams keep the existing search params and add date params to it
    const params = new URLSearchParams(location.search);
    params.set('date', footerDate.format(DATE_FORMAT));

    // update url with footer date, so on refresh or sharing the url can open on the same footer date
    history.push({ ...location, search: params.toString() });
  }, [footerDate]);
};

export default useFooterDateInSearchParams;
