import { isWebview } from '@umai/common';

const ORIENTATION_TYPE = {
  landscape: 'landscape',
  portrait: 'portrait',
};

const makeFullScreenMode = async () => {
  try {
    // @ts-expect-error TS(2339): Property 'webkitFullscreenElement' does not exist ... Remove this comment to see the full error message
    if (document?.fullscreenElement || document?.webkitFullscreenElement) return false;
    if (document?.documentElement?.requestFullscreen) {
      await document.documentElement.requestFullscreen();
      // @ts-expect-error TS(2339): Property 'webkitRequestFullscreen' does not exist ... Remove this comment to see the full error message
    } else if (document?.documentElement?.webkitRequestFullscreen) {
      // @ts-expect-error TS(2339): Property 'webkitRequestFullscreen' does not exist ... Remove this comment to see the full error message
      document.documentElement.webkitRequestFullscreen();
    }

    return true;
  } catch (err) {
    console.error('error getting fullscreen:', err);
    return false;
  }
};

const exitFullScreen = () => {
  // @ts-expect-error TS(2339): Property 'webkitFullscreenEnabled' does not exist ... Remove this comment to see the full error message
  if (document?.fullscreenEnabled || document?.webkitFullscreenEnabled) {
    if (document?.exitFullscreen && document?.fullscreenElement) {
      document.exitFullscreen();
      // @ts-expect-error TS(2339): Property 'webkitExitFullscreen' does not exist on ... Remove this comment to see the full error message
    } else if (document?.webkitExitFullscreen && document?.webkitFullscreenElement) {
      // @ts-expect-error TS(2339): Property 'webkitExitFullscreen' does not exist on ... Remove this comment to see the full error message
      document.webkitExitFullscreen();
    }
  }
};

const lockScreenMode = async (mode: any) => {
  try {
    if (window?.screen?.orientation?.lock) {
      await window.screen.orientation.lock(mode);
      return true;
    }
    return false;
  } catch (error) {
    console.error('error rotating:', error);
    return false;
  }
};

const unlockScreenMode = () => {
  try {
    // For android browsers, unlock orientation and exit fullscreen
    // For details: https://w3c.github.io/screen-orientation/#unlock-method-unlock-screen-to-default-orientation
    if (window.screen?.orientation?.unlock) window.screen.orientation.unlock();
  } catch (error) {
    console.error('error unlocking:', error);
  }
};

const rotateNativeApps = (orientation = ORIENTATION_TYPE.landscape) => {
  // For iphone's we send a message from webview to change to landscape/portrait
  // @ts-expect-error TS(2339): Property 'webkit' does not exist on type 'Window &... Remove this comment to see the full error message
  window?.webkit?.messageHandlers?.changeOrientation?.postMessage?.(
    orientation === ORIENTATION_TYPE.landscape
  );

  // for android native apps to change landscape/portrait
  // @ts-expect-error TS(2339): Property 'AndroidOrientation' does not exist on ty... Remove this comment to see the full error message
  window?.AndroidOrientation?.changeOrientation?.(orientation === ORIENTATION_TYPE.landscape);
};

export const fullScreenAndRotateLandscape = async () => {
  if (isWebview()) {
    rotateNativeApps(ORIENTATION_TYPE.landscape);
    // For android browser, fullscreen can work for desktop too
  } else if (await makeFullScreenMode()) {
    if (await lockScreenMode(ORIENTATION_TYPE.landscape)) {
      return true;
    }
  }
  return false;
};

export const unlockExitFullScreenAndRotatePortrait = () => {
  // this works for all apps switching from landscape to portrait, not just the timeline
  if (isWebview()) {
    rotateNativeApps(ORIENTATION_TYPE.portrait);
  } else {
    unlockScreenMode();
    setTimeout(() => {
      exitFullScreen();
    }, 0);
  }
};
