import React, { lazy } from 'react';
import Layout from 'layouts/Layout';
import PageNotFoundModule from 'modules/Auth/components/PageNotFound';

const TabletFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-tablet-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/TabletFooter'
    )
);

const MobileFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-mobile-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/MobileFooter'
    )
);

function PageNotFound() {
  return (
    <Layout
      footer={<TabletFooter showGuestCount={false} />}
      mobileFooter={<MobileFooter hideAvailabilities hideMobileFilters />}
    >
      <PageNotFoundModule />
    </Layout>
  );
}

export default PageNotFound;
