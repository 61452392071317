export type Products = (typeof PRODUCTS)[keyof typeof PRODUCTS];

export const FEATURES = {
  RESERVATION_LISTING: 'RESERVATION_LISTING',
  RESERVATION_FUNNEL: 'RESERVATION_FUNNEL',
  GUESTS: 'GUESTS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  CALENDAR: 'CALENDAR',
  FEEDBACK: 'FEEDBACK',
  ANALYTICS: 'ANALYTICS',
  SETTINGS: 'SETTINGS',
  DELIVERY_AND_TAKEAWAY: 'DELIVERY_AND_TAKEAWAY',
  TIMELINE: 'TIMELINE',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
} as const;

export type FeatureType = (typeof FEATURES)[keyof typeof FEATURES];

export const PRODUCTS = {
  GUEST_CENTER: 'GUEST_CENTER',
  LOYALTY: 'LOYALTY',
  SHOP_DASHBOARD: 'SHOP_DASHBOARD',
} as const;

const PRODUCT_FEATURES = {
  GUEST_CENTER: [
    FEATURES.RESERVATION_LISTING,
    FEATURES.RESERVATION_FUNNEL,
    FEATURES.GUESTS,
    FEATURES.CALENDAR,
    FEATURES.FEEDBACK,
    FEATURES.ANALYTICS,
    FEATURES.SETTINGS,
    FEATURES.TIMELINE,
    FEATURES.PAGE_NOT_FOUND,
  ] as const,
  LOYALTY: [
    FEATURES.GUESTS,
    FEATURES.ANALYTICS,
    FEATURES.SETTINGS,
    FEATURES.PAGE_NOT_FOUND,
  ] as const,
  SHOP_DASHBOARD: [
    FEATURES.DELIVERY_AND_TAKEAWAY,
    FEATURES.SETTINGS,
    FEATURES.PAGE_NOT_FOUND,
  ] as const,
};

export const isProductFeatureAvailable = (feature = '', subscribedProducts: Products[] = []) => {
  let isFeatureAvailable = false;

  // Enabling settings for 360 so that users can logout.
  // Moreover, partner subscription and user permission related things are handled inside settings app.
  if (feature === FEATURES.SETTINGS) return true;

  // Notifications are available for all venues
  if (feature === FEATURES.NOTIFICATIONS) {
    return true;
  }

  subscribedProducts.forEach((product) => {
    if (!isFeatureAvailable) {
      // @ts-expect-error TS(2339): Property 'indexOf' does not exist on type 'never'.
      isFeatureAvailable = PRODUCT_FEATURES[product]?.indexOf(feature) >= 0;
    }
  });
  return isFeatureAvailable;
};

export const setProductSubscription = (venue: any) => {
  const {
    isGuestcenterEnabled = false,
    isLoyaltyProgramEnabled = false,
    isTakeawayProgramEnabled = false,
    isDeliveryProgramEnabled = false,
  } = venue || {};
  const productSubscriptions = [];

  if (isGuestcenterEnabled) productSubscriptions.push(PRODUCTS.GUEST_CENTER);
  if (isLoyaltyProgramEnabled) productSubscriptions.push(PRODUCTS.LOYALTY);
  if (isTakeawayProgramEnabled || isDeliveryProgramEnabled)
    productSubscriptions.push(PRODUCTS.SHOP_DASHBOARD);

  return productSubscriptions;
};
