import { DATE_FORMAT } from 'constants/time-and-date';
import moment from 'moment-timezone';
import { getCurrentDateAndTime } from 'utils/date-and-time';

export const getInitialDate = (footerDate?: string) => {
  const params = new URLSearchParams(window.location.search);
  const date = params.get('date');
  const momentDate = moment(date, DATE_FORMAT);

  if (date && momentDate?.isValid()) {
    return momentDate;
  }

  // footerDate is used here from partner actions in order to keep on the same date on changing venue,
  // otherwise move to current date-time
  return footerDate || getCurrentDateAndTime();
};
