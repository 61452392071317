import { useAppDispatch } from 'hooks/redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { clearOnLogout } from 'modules/Auth/services';
import { logout } from 'modules/Auth/actions';
import { unlockExitFullScreenAndRotatePortrait } from 'utils/screen-orientation';

const Logout = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  unlockExitFullScreenAndRotatePortrait();
  clearOnLogout();
  dispatch(logout());
  history.push(ROUTES.LOGIN);
  return null;
};

export default Logout;
