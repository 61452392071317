import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface EyeHideProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}

function EyeHide({ width = 20, height = 20, color = COLORS.BLACK }: EyeHideProps) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Hide password</title>
      <path
        d="M3.746 6.2a12.58 12.58 0 00-1.922 1.7 3.065 3.065 0 000 4.2c1.44 1.56 4.167 3.733 8.176 3.733 1.08 0 2.067-.157 2.96-.418l-1.385-1.386a8.969 8.969 0 01-1.575.138c-3.361 0-5.676-1.817-6.951-3.197a1.4 1.4 0 010-1.94 10.81 10.81 0 011.894-1.632L3.746 6.2z"
        fill={color}
      />
      <path
        d="M7.11 9.565a2.917 2.917 0 003.32 3.32l-3.32-3.32zM12.889 10.373L9.623 7.107a2.917 2.917 0 013.266 3.266z"
        fill={color}
      />
      <path
        d="M15.093 12.577a10.825 10.825 0 001.858-1.607 1.4 1.4 0 000-1.94C15.677 7.65 13.361 5.833 10 5.833c-.534 0-1.041.046-1.522.129l-1.39-1.39A10.519 10.519 0 0110 4.166c4.01 0 6.736 2.174 8.176 3.733a3.066 3.066 0 010 4.2 12.58 12.58 0 01-1.886 1.674l-1.197-1.197zM1.673 2.852a.833.833 0 111.179-1.179l15.482 15.482a.833.833 0 11-1.179 1.179L1.673 2.852z"
        fill={color}
      />
    </svg>
  );
}

EyeHide.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default EyeHide;
