import { MESSAGE_EVENTS } from 'constants/socket';
import { createCustomEventListener } from 'utils/event-listeners';
import { getPartnerSession } from 'utils/session';
import { checkIsItemInFooterDate } from 'utils/date-and-time';
import { FINISHED_WAITLIST_AVAILABILITY } from './constants';

export const customSocketEventForWaitlist = (waitlistItem: any, venueId: any) => {
  const { currentVenueId } = getPartnerSession();

  // Only fire event when current venue id is same as venueId of waitlist
  // Check is required because in funnel it's possible to create waitlist for another venue
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (waitlistItem?.id && +venueId === +currentVenueId) {
    const { id, startTimeWithDate, status } = waitlistItem;
    // for custom socket event, waitlistItem is passed to avoid hitting the getWaitlistItems API all the time there is an update or creation
    createCustomEventListener(MESSAGE_EVENTS.waitlist, {
      detail: {
        params: {
          id,
          date: startTimeWithDate,
          status,
          waitlistItem,
        },
      },
    });
  }
};

// finished waitlist availability id is always null so we replace it to better handle the ids
export const formatAvailability = (reservationAvailability: any) => {
  if (!reservationAvailability?.id) {
    return {
      id: FINISHED_WAITLIST_AVAILABILITY.id,
      name: reservationAvailability?.name || FINISHED_WAITLIST_AVAILABILITY.name,
    };
  }
  return reservationAvailability;
};

const addWaitlistItem = ({ waitlistItem, waitlists }: any) => {
  const { lists, availabilities } = waitlists;
  const currentAvailabilityId = waitlistItem.reservationAvailability.id;

  const currentAvailability = availabilities?.[currentAvailabilityId] || {};
  const reservationAvailabilityName = waitlistItem.reservationAvailability.name;

  return {
    lists: { ...lists, [waitlistItem.id]: waitlistItem },
    availabilities: {
      ...availabilities,
      [currentAvailabilityId]: {
        ...currentAvailability,
        reservationAvailabilityId: currentAvailabilityId,
        reservationAvailabilityName,
        list: [...(currentAvailability?.list || []), waitlistItem.id],
      },
    },
  };
};

export const removeWaitlistItemFromList = ({ waitlistId, waitlists }: any) => {
  const { lists, availabilities } = waitlists;
  const removedWaitlistItem = lists[waitlistId];
  const oldAvailabilityId = removedWaitlistItem.reservationAvailability.id;
  const oldAvailability = availabilities[oldAvailabilityId] || {};
  delete lists[waitlistId];
  return {
    ...waitlists,
    availabilities: {
      ...availabilities,
      [oldAvailabilityId]: {
        ...oldAvailability,
        list: [...(oldAvailability.list?.filter((id: any) => waitlistId !== id) || [])],
      },
    },
  };
};

const updateWaitlists = ({ oldWaitlistItem, waitlistItem, waitlists }: any) => {
  if (oldWaitlistItem.reservationAvailability?.id !== waitlistItem.reservationAvailability?.id) {
    const newWaitlists = removeWaitlistItemFromList({ waitlistId: oldWaitlistItem.id, waitlists });
    return addWaitlistItem({ waitlistItem, waitlists: newWaitlists });
  }
  return {
    ...waitlists,
    lists: {
      ...waitlists.lists,
      [waitlistItem.id]: waitlistItem,
    },
  };
};

export const waitlistSlice = ({ currentWaitlistItem, waitlists, footerDate }: any) => {
  // find if the current waitlist item is already in the reducer
  const waitlistItem = waitlists.lists[currentWaitlistItem?.id];
  currentWaitlistItem.reservationAvailability = formatAvailability(
    currentWaitlistItem.reservationAvailability
  );

  const { startTime } = currentWaitlistItem;

  if (waitlistItem?.id) {
    // Remove wailistitem if starttime date is not in the footerdate
    if (!checkIsItemInFooterDate(footerDate, startTime)) {
      return removeWaitlistItemFromList({ waitlistId: currentWaitlistItem.id, waitlists });
    }

    return updateWaitlists({
      oldWaitlistItem: waitlistItem,
      waitlistItem: currentWaitlistItem,
      waitlists,
    });
  }
  if (checkIsItemInFooterDate(footerDate, startTime)) {
    return addWaitlistItem({ waitlistItem: currentWaitlistItem, waitlists });
  }
  return waitlists;
};
