export const ACTIVE_QUEUE_KEY = 'activeQueues';
export const REMOVED_QUEUE_KEY = 'removedQueues';
export const REMOVED_QUEUE_STATUS = ['CANCELLED_QUEUE', 'REJECTED_QUEUE'];
export const CANCELLED_QUEUE = 'cancelled_queue';
export const ACTIVE_QUEUE = 'active';
export const REJECTED_QUEUE = 'rejected_queue';
export const QUEUE_TURN_CONFIRMATION_SMS_SENT = 'queue_turn_confirmation_sms_sent';
export const TABLE_READY_SMS_SENT = 'table_ready_sms_sent';
export const CUSTOM_TEMPLATE_SMS_SENT = 'custom_template_sms_sent';
export const HEAD_TO_VENUE_SMS_SENT = 'head_to_venue_sms_sent';
export const REJECTED = 'rejected';
export const QUEUE_STATUS_TEXT = {
  headToVenue: 'Head to venue msg',
  reSendHeadToVenue: 'Re-Send Head to venue msg',
  tableReady: 'Table ready msg',
  reSendTableReady: 'Re-Send Table Ready msg',
  selectTemplate: 'Select template',
  confirmationSms: 'Queue Confirmation msg',
  reSendConfirmationSms: 'Re-Send confirmation msg',
};
export const QUEUE_SMS_SUCCESS_TEXT = 'Guest notified successfully';
