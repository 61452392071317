import React, { lazy } from 'react';
import { useAppSelector } from 'hooks/redux';
import { selectIsVenueAccessBlocked } from 'modules/Partner/selectors';
import { ReactComponent as LogoIcon } from 'assets/images/icons/logo.svg';
import Layout from 'layouts/Layout';
import AccessMessage from 'components/shared/AccessMessage';

const MobileFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-mobile-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/MobileFooter'
    )
);

const TabletFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-tablet-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/TabletFooter'
    )
);

const Subscription = () => {
  const isVenueAccessBlocked = useAppSelector(selectIsVenueAccessBlocked);

  const renderBody = () => {
    // do not show the subscription wrapper if the venue is blocked, as we are showing a blocked popup already.
    if (isVenueAccessBlocked) {
      return null;
    }

    return (
      <AccessMessage
        header={<LogoIcon className="umai-logo" />}
        title="UMAI 360 bring your business into the future"
        description="Manage your reservations & tables and have it all connect to one single CRM that's shared across all UMAI products. Use our detailed analytics & automated guest-feedback to provide even better service!"
        subTitle="Speak to your UMAI account manager for help"
      />
    );
  };

  return (
    <Layout
      footer={<TabletFooter showGuestCount={false} />}
      mobileFooter={<MobileFooter hideAvailabilities hideMobileFilters />}
      customBodyClasses=""
    >
      {renderBody()}
    </Layout>
  );
};

export default Subscription;
