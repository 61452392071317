export const GET_CURRENT_RESERVATIONS_LIST = 'GET_CURRENT_RESERVATIONS_LIST';
export const GET_FINISHED_RESERVATIONS_LIST = 'GET_FINISHED_RESERVATIONS_LIST';
export const SET_RESERVATIONS_LIST_ERROR = 'SET_RESERVATIONS_LIST_ERROR';
export const SET_RESERVATIONS_LIST_LOADING = 'SET_RESERVATIONS_LIST_LOADING';
export const VIEW_RESERVATION = 'VIEW_RESERVATION';
export const UPDATE_RESERVATION = 'UPDATE_RESERVATION';
export const UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT';
export const UPDATE_RESERVATION_LIST_TYPE = 'UPDATE_RESERVATION_LIST_TYPE';
export const UPDATE_RESERVATION_FROM_SOCKET = 'UPDATE_RESERVATION_FROM_SOCKET';
export const SET_RESERVATION_STATUS_ERROR = 'SET_RESERVATION_STATUS_ERROR';
export const SET_STATUS_LOADING = 'SET_STATUS_LOADING';
export const GET_RESERVATION_ACTIVITIES = 'GET_RESERVATION_ACTIVITIES';
export const SET_RESERVATION_ACTIVITIES_LOADING = 'SET_RESERVATION_ACTIVITIES_LOADING';
export const EXPORT_RESERVATIONS = 'EXPORT_RESERVATIONS';
export const CANCEL_EXPORT_RESERVATIONS = 'CANCEL_EXPORT_RESERVATIONS';
