import * as ActionTypes from './action-types';
import type { OAuthResponse, User } from './types';

interface AuthState extends Omit<OAuthResponse, 'accessToken' | 'id'> {
  id: null | string;
  accessToken: null | string;
  currentUser: null | User;
  isPasswordResetSuccess: boolean;
  isValidPasswordToken: boolean | null;
  isAuthSubmitting: boolean;
  isLoadingCurrentUser: boolean;
}

const INITIAL_STATE: AuthState = {
  id: null,
  email: '',
  accessToken: null,
  tokenType: '',
  refreshToken: '',
  partner: {
    country: {
      name: '',
      smsEnabled: false,
      emailsEnabled: false,
      countryIsoCode: '',
      gdprEnabled: false,
      timeFormat: '',
      isNonGsmMessageSupported: false,
      defaultLonlat: '',
    },
  },
  currentUser: null,
  isPasswordResetSuccess: false,
  isValidPasswordToken: null,
  isAuthSubmitting: false,
  isLoadingCurrentUser: false,
  isOwner: false,
  role: null,
  permissions: [],
};

// eslint-disable-next-line default-param-last
const authReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return { ...state, ...action.payload };
    case ActionTypes.SET_CURRENT_SESSION:
      return { ...state, ...action.payload };
    case ActionTypes.LOGOUT:
      return { ...action.payload };
    case ActionTypes.RESET_PASSWORD:
      return {
        ...state,
        isPasswordResetSuccess: action.payload,
      };
    case ActionTypes.VALIDATE_TOKEN:
      return {
        ...state,
        isValidPasswordToken: action.payload,
      };
    case ActionTypes.AUTH_SUBMITTING:
      return {
        ...state,
        isAuthSubmitting: action.payload,
      };
    case ActionTypes.SET_CURRENT_USER_LOADING:
      return {
        ...state,
        isLoadingCurrentUser: action.payload,
      };
    case ActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case ActionTypes.UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    default:
      return state;
  }
};

export default authReducer;
