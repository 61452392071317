import { API_ENDPOINT } from 'constants/app';
import HttpRequest from 'utils/http-request';
import type { OAuthResponse, User } from './types';

class Authentication extends HttpRequest {
  constructor() {
    super(API_ENDPOINT);
  }

  login(data: any) {
    return this.create<OAuthResponse>('/oauth/token', data);
  }

  forgotPassword(email: any) {
    return this.create('users/passwords/send_reset_password_email', { email });
  }

  fetchCurrentUser() {
    return this.fetch<{ user: User }>('/partner/users/current_user_profile');
  }

  resetPassword(token: string, password: string) {
    return this.create('users/passwords/reset_password', { token, password });
  }

  validateToken(token: string | null) {
    return this.create('users/passwords/validate_password_token', { token });
  }
}

export default new Authentication();
