import React, { createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { ANTD_POPOVER_BACKDROP, ANTD_POPOVER_WRAPPER } from 'constants/app';

/*
 * TODO: remove backdrop logic after upgrading to antd v5
 * Antd popovers will be mounted inside this div -
 * popoverWrapper: to make the popover fixed and not scroll on page scrolling
 *
 * popoverBackdrop: Backdrop show when antd popovers are visible and onTouchEnd is used to prevent event on clicking the backdrop
 */
type PopoverLibContextProps = {
  popoverWrapper: React.RefObject<HTMLDivElement> | null;
  popoverBackdrop: React.RefObject<HTMLDivElement> | null;
};

const PopoverLibContext = createContext<PopoverLibContextProps>({
  popoverWrapper: null,
  popoverBackdrop: null,
});

interface PopoverProviderProps {
  children?: React.ReactNode;
}

export const PopoverLibProvider = ({ children }: PopoverProviderProps) => {
  const popoverWrapper = useRef<HTMLDivElement>(null);
  const popoverBackdrop = useRef<HTMLDivElement>(null);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <PopoverLibContext.Provider value={{ popoverWrapper, popoverBackdrop }}>
      {children}
      <div
        id={ANTD_POPOVER_BACKDROP}
        style={{ display: 'none' }}
        className="antd-popover-backdrop"
        ref={popoverBackdrop}
      />
      <div id={ANTD_POPOVER_WRAPPER} ref={popoverWrapper}></div>
    </PopoverLibContext.Provider>
  );
};

PopoverLibProvider.propTypes = {
  children: PropTypes.node,
};

export const usePopoverLibContext = () => {
  const context = useContext(PopoverLibContext);

  return context;
};
