import React from 'react';
import PublicLayout from 'layouts/PublicLayout';
import ResetPasswordModule from 'modules/Auth/components/ResetPassword';

function ResetPassword() {
  return (
    <PublicLayout>
      <ResetPasswordModule />
    </PublicLayout>
  );
}

export default ResetPassword;
