import React, { lazy } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { isWebview, reloadNativeApp } from '@umai/common';
import { ROUTES } from 'constants/routes';
import { selectCurrentUserId } from 'modules/Auth/selectors';
import { ReactComponent as LogoIcon } from 'assets/images/icons/logo.svg';
import Layout from 'layouts/Layout';
import AccessMessage from 'components/shared/AccessMessage';

const TabletFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-tablet-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/TabletFooter'
    )
);

const MobileFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-mobile-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/MobileFooter'
    )
);

const AuthorizationMessage = () => {
  const currentUserId = useAppSelector(selectCurrentUserId);
  const history = useHistory();

  return (
    <Layout
      footer={<TabletFooter showGuestCount={false} />}
      mobileFooter={<MobileFooter />}
      customBodyClasses=""
    >
      {/**
       * User information is required to display any part of our system due to user role permission restrictions.
       * That's why we show the message "UMAI couldn't load" to the user when the user API response fails, rather than "Access Denied."
       * */}
      {currentUserId ? (
        <AccessMessage
          header={<LogoIcon className="umai-logo" />}
          title="Access Denied"
          description="Sorry, you don't have the necessary authorization to access this page or resource. If you believe that you should have access, please get in touch with the administrator. Thank you."
          subTitle="Speak to your UMAI account manager for help"
          footer={
            <button
              type="button"
              className="go-to-umai-button"
              onClick={() => history.push(ROUTES.LOGOUT)}
            >
              Logout
            </button>
          }
        />
      ) : (
        <AccessMessage
          header={<LogoIcon className="umai-logo" />}
          title="For some reason, UMAI couldn't load 😥"
          description="Please refresh your browser and verify your internet connection. Should the problem persist,"
          subTitle="Speak to your UMAI account manager for help"
          footer={
            <div>
              <button
                type="button"
                className="go-to-umai-button"
                onClick={() => {
                  if (isWebview()) {
                    reloadNativeApp();
                    return;
                  }

                  window?.location?.reload?.();
                }}
              >
                Reload
              </button>
              <button
                type="button"
                className="go-to-umai-button"
                onClick={() => history.push(ROUTES.LOGOUT)}
              >
                Logout
              </button>
            </div>
          }
        />
      )}
    </Layout>
  );
};

export default AuthorizationMessage;
