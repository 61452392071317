/* eslint-disable camelcase */
import moment from 'moment-timezone';
import { setDataToStorage, getDataFromStorage } from '@umai/common';
import OrderNotification from 'utils/notification';
import { isSafari } from 'utils/devices';
import { ORDER_NOTIFICATION_AUDIO_ID } from './constants';
import { createEventListener } from 'utils/event-listeners';

const ordersFlashKey = 'orderIdsThatFlashes';
export const ordersFlashEvent = 'orderIdsToFlashInserted';

const addOrderTOFlashing = (id: any, venueId: any) => {
  const flashOrderId = +id;
  if (flashOrderId && venueId) {
    const ordersId = getDataFromStorage(ordersFlashKey) || {};
    if (ordersId?.[venueId]?.length) {
      if (!ordersId?.[venueId]?.includes?.(flashOrderId)) {
        ordersId[venueId] = [...ordersId[venueId], flashOrderId];
      }
    } else {
      ordersId[venueId] = [id];
    }
    setDataToStorage(ordersFlashKey, ordersId);
    createEventListener(ordersFlashEvent);
  }
};

export const getOrderCountToFlashing = (venueId: any) => {
  const orders = getDataFromStorage(ordersFlashKey) || {};
  return (venueId && orders?.[venueId]?.length) || 0;
};

export const orderUpdatesFromSocket = ({ message, venue: { id: venueId, name } }: any) => {
  const orderNotify = new OrderNotification({ audioId: ORDER_NOTIFICATION_AUDIO_ID });
  if (message?.params) {
    const { params } = message;
    if (params?.new_status === 'paid') {
      addOrderTOFlashing(params?.order_id, venueId);
      if (isSafari()) {
        orderNotify.show(
          `🎉 You have a new order on ${moment(params?.order_date).format('ddd, MMMM Do YYYY')}!`,
          {
            body: name,
            tag: params?.order_date || new Date().toString(),
            icon: `${process.env.PUBLIC_URL}/order_received_notify.png`,
            badge: `${process.env.PUBLIC_URL}/order_received_notify.png`,
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            sound: `${process.env.PUBLIC_URL}/order-notifcation-sound.mp3`,
            requireInteraction: true,
          }
        );
      }
    }
  }
};

export const getFeeKindLabel = (kind: any) => {
  switch (kind) {
    case 'included':
      return ' (incl.)';
    case 'excluded':
      return ' (excl.)';
    default:
      return '';
  }
};
