import { setIntercom } from 'utils/intercom';
import { removePartnerSession } from 'utils/session';
import { deleteNotificationToken } from 'services/firebase-messaging';

export const checkAuthorization = (allowedPermission: any, currentUser: any) => {
  if (typeof allowedPermission === 'string') {
    return currentUser?.permissions.includes(allowedPermission);
  }
  if (Array.isArray(allowedPermission)) {
    return allowedPermission.some((item) => currentUser?.permissions.includes(item));
  }
  throw Error('allowedPermission should be a string or an array of strings');
};

export const clearOnLogout = () => {
  setIntercom({ tag: 'shutdown' });
  deleteNotificationToken();
  removePartnerSession();
};
