/* eslint-disable camelcase, no-console  */
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { getGuestTags } from 'modules/Tag/actions';
import { MESSAGE_EVENTS } from 'constants/socket';

const useSocketGuestTags = ({ venueId }: any) => {
  const dispatch = useAppDispatch();
  const guestTagsUpdate = ({ detail = {} }) => {
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      const params = detail?.params || {};
      console.debug('TAGS: socket update: ', detail, params?.venue_id);
      if (+(params?.venue_id || 0) === +venueId) {
        dispatch(getGuestTags(venueId, true));
      }
    } catch (error) {
      console.error('Error in update tags from socket', error);
    }
  };

  useEffect(() => {
    // @ts-expect-error TS(2769): No overload matches this call.
    document.addEventListener(MESSAGE_EVENTS.tags, guestTagsUpdate, false);
    return () => {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(MESSAGE_EVENTS.tags, guestTagsUpdate, false);
    };
  }, [venueId]);
};

export default useSocketGuestTags;
