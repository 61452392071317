/* eslint-disable consistent-return, no-underscore-dangle, no-restricted-properties, no-undef, func-names */
import 'abortcontroller-polyfill';
import 'url-search-params-polyfill';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

// Polyfill for CustomEvent for Internet Explorer >= 9
(() => {
  if (typeof window.CustomEvent === 'function') return false;
  const CustomEvent = (
    event: string,
    params: {
      bubbles?: boolean;
      cancelable?: boolean;
      detail?: any;
    }
  ) => {
    params = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  };
  // @ts-expect-error TS(2322): Type '(event: string, params: {    bubbles?: boole... Remove this comment to see the full error message
  window.CustomEvent = CustomEvent;
})();

// Polyfill for globalThis, For more information: https://mathiasbynens.be/notes/globalthis
(function () {
  if (typeof globalThis === 'object') return;
  // @ts-expect-error TS(2339): Property '__defineGetter__' does not exist on type... Remove this comment to see the full error message
  Object.prototype.__defineGetter__('__magic__', function (this: any) {
    return this;
  });
  // @ts-expect-error TS(2304): Cannot find name '__magic__'.
  __magic__.globalThis = __magic__; // lolwat
  // @ts-expect-error TS(2339): Property '__magic__' does not exist on type 'Objec... Remove this comment to see the full error message
  delete Object.prototype.__magic__;
})();
