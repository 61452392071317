import React from 'react';
import PublicLayout from 'layouts/PublicLayout';
import LoginModule from 'modules/Auth/components/Login';

function Login() {
  return (
    <PublicLayout>
      <LoginModule />
    </PublicLayout>
  );
}

export default Login;
