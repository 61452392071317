import { combineReducers } from 'redux';
import { LOGOUT } from '../modules/Auth/action-types';
import authReducer from '../modules/Auth/reducers';
import guestReducer from '../modules/Guest/reducers';
import footerReducer from '../modules/Footer/reducers';
import partnerReducer from '../modules/Partner/reducers';
import reservationReducer from '../modules/ReservationsList/reducers';
import reservationFunnelReducer from '../modules/ReservationFunnel/reducers';
import roomReducer from '../modules/Room/reducers';
import waitlistReducer from '../modules/Waitlist/reducers';
import tagsReducer from '../modules/Tag/reducers';
import queueReducer from '../modules/QueueList/reducers';
import masterGuestReducer from '../modules/GuestsCrm/reducers';
import timelineReducer from '../modules/Timeline/reducers';
import filterReducer from '../modules/Filter/reducers';

const reducers = combineReducers({
  auth: authReducer,
  footer: footerReducer,
  partner: partnerReducer,
  reservation: reservationReducer,
  reservationFunnel: reservationFunnelReducer,
  room: roomReducer,
  guest: guestReducer,
  waitlist: waitlistReducer,
  tags: tagsReducer,
  queue: queueReducer,
  masterGuests: masterGuestReducer,
  timeline: timelineReducer,
  filter: filterReducer,
});

const rootReducer = (state: any, action: any) => {
  // reset all the reducers to the initial state when we log out
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return reducers(state, action);
};

export default rootReducer;
