import React, { lazy } from 'react';
import classNames from 'classnames';
import { isMobileDevice } from 'utils/devices';
import ThirdPartyAppLayout from 'layouts/ThirdPartyAppLayout';

const FeedbackAnalytics = lazy(
  () =>
    import(
      // @ts-expect-error TS(7016): Could not find a declaration file for module '@uma... Remove this comment to see the full error message
      /* webpackPrefetch: true, webpackChunkName: "umai-feedback-analytics" */ '@umai/feedback-analytics'
    )
);

function Feedback() {
  return (
    <ThirdPartyAppLayout
      id="feedback-wrapper"
      className={classNames('umaiFeedbackParent', { 'mobile-device': isMobileDevice })}
      hideMobileFilters
    >
      <FeedbackAnalytics />
    </ThirdPartyAppLayout>
  );
}

export default Feedback;
