export const SEARCH_GUEST = 'SEARCH_GUEST';
export const CREATE_GUEST_FROM_LISTING = 'CREATE_GUEST_FROM_LISTING';
export const UPDATE_GUEST = 'UPDATE_GUEST';
export const SET_MASTER_GUEST = 'SET_MASTER_GUEST';
export const FETCH_MASTER_GUEST = 'FETCH_MASTER_GUEST';
export const FETCH_MASTER_GUEST_RESERVATIONS = 'FETCH_MASTER_GUEST_RESERVATIONS';
export const FETCH_MASTER_GUEST_RESERVATION_SUMMARY = 'FETCH_MASTER_GUEST_RESERVATION_SUMMARY';
export const FETCH_MASTER_GUEST_LOYALTY_TRANSACTIONS = 'FETCH_MASTER_GUEST_LOYALTY_TRANSACTIONS';
export const SET_MASTER_GUEST_LOYALTY_SUBSCRIPTION = 'SET_MASTER_GUEST_LOYALTY_SUBSCRIPTION';
export const SET_MASTER_GUEST_LOYALTY_TIER = 'SET_MASTER_GUEST_LOYALTY_TIER';
export const SET_GUEST_LOADING = 'SET_GUEST_LOADING';
export const RESET_GUESTS = 'RESET_GUESTS';
export const SET_GUESTS = 'SET_GUESTS';
export const SET_MASTER_GUEST_LOADING = 'SET_MASTER_GUEST_LOADING';
export const RESET_MASTER_GUEST = 'RESET_MASTER_GUEST';
export const SET_GUEST_HISTORY_DATA = 'SET_GUEST_HISTORY_DATA';
