import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios';
import humps from 'humps';
import { getPartnerSession } from 'utils/session';

type RequestData<D> = Record<string, D>;
/**
 * This is need for File objects to work with formData..
 * because humps corrupts the File object on decamelizing the data
 * The following code has been taken from the link
 * @see https://github.com/domchristie/humps/issues/51#issuecomment-425113505
 *
 * @param {RequestData} object The data to convert to decamelize format
 * @returns {RequestData} The object into decamelized format
 *  */
const decamelizeThatDontBreaksFile = (object: RequestData<any>): RequestData<any> => {
  if (object && !(object instanceof File)) {
    if (object instanceof Array) {
      return object.map((item) => decamelizeThatDontBreaksFile(item));
    }
    if (object instanceof FormData) {
      const formData = new FormData();
      // @ts-expect-error TS(2802): Type 'IterableIterator<[string, FormDataEntryValue... Remove this comment to see the full error message
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of object.entries()) {
        formData.append(humps.decamelize(key), value);
      }
      return formData;
    }
    if (typeof object === 'object') {
      return Object.keys(object).reduce(
        (acc, next) => ({
          ...acc,
          [humps.decamelize(next)]: decamelizeThatDontBreaksFile(object[next]),
        }),
        {}
      );
    }
  }
  return object;
};

class HttpRequest {
  private axios: AxiosInstance;

  constructor(baseURL: string) {
    this.axios = axios.create({
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.responseInterceptor();
    this.requestInterceptor();
  }

  responseInterceptor() {
    // Add a response interceptor
    this.axios.interceptors.response.use(
      (response: AxiosResponse) => {
        // Do something with response data
        if (response.config.responseType === 'blob') {
          return response;
        }

        // Camelize response data
        const camelizedData = humps.camelizeKeys(response.data);
        // Update response data with camelized version
        response.data = camelizedData;

        // When response is paginated, information about Total and Per-Page is sent in headers
        if (response.headers instanceof AxiosHeaders) {
          const totalHeader = response.headers.get('Total');
          const perPageHeader = response.headers.get('Per-Page');

          if (typeof totalHeader === 'string') {
            response.data.total = parseInt(totalHeader, 10);
          }
          if (typeof perPageHeader === 'string') {
            response.data.perPage = parseInt(perPageHeader, 10);
          }
        }

        return response.data;
      },
      (error: AxiosError) => {
        // On cancel request pass complete error with reject,
        // so that actions can catch the error to check if its cancel and proceed to final block in tryCatch
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        // Do something with response error
        // @ts-expect-error TS(2339): Property 'response' does not exist on type 'never'... Remove this comment to see the full error message
        return Promise.reject(error.response || error.message);
      }
    );
  }

  requestInterceptor() {
    this.axios.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const { currentVenueId, accessToken, deviceId } = getPartnerSession();

        (config.headers as RawAxiosRequestHeaders) = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
          'device-identifier': deviceId,
        };

        config.params = {
          ...(config.params || {}),
          // Use default venueId when venueId is not provided as a param
          ...(!config.params?.venueId && currentVenueId && { venueId: currentVenueId }),
        };

        // decamelize parameters since server expected it in underscore format variable_name
        config.params = humps.decamelizeKeys(config.params);

        if (!config.params.no_decamelize || config.responseType !== 'blob') {
          config.data = decamelizeThatDontBreaksFile(config.data);
        }

        return config;
      },
      (error) =>
        // Do something with request error
        Promise.reject(error)
    );
  }

  isCancel(error: unknown) {
    return axios.isCancel(error);
  }

  fetch<T>(url: string, params?: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.axios.get(url, {
      params,
      ...config,
      cancelToken: config && config.cancelToken,
    });
  }

  create<T>(url: string, data?: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.axios.post(url, data, {
      ...config,
    });
  }

  update<T>(url: string, data?: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.axios.put(url, data, {
      ...config,
    });
  }

  updateForm<T>(url: string, data?: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.axios.putForm(url, data, { ...config });
  }

  patch<T>(url: string, data?: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.axios.patch(url, data, {
      ...config,
    });
  }

  remove<T>(url: string, params?: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.axios.delete(url, {
      params,
      ...config,
    });
  }
}

export default HttpRequest;
