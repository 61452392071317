export const getIsAllGuestsLoaded = (totalGuestCount: any, masterGuest = {}) =>
  totalGuestCount !== Object.keys(masterGuest).length;

export const mergeGuests = ({ guests = {}, currentGuests = {} }) =>
  Object.entries(guests).reduce((acc, [key, val]) => {
    // if the key is already in the hash, merge the masterguest Ids else it's new guest key
    if (key in currentGuests) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[key] = {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ...currentGuests[key],
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        masterGuests: currentGuests[key].masterGuests.concat(val.masterGuests),
      };
    } else {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[key] = val;
    }
    return acc;
  }, {});

export const updateGuest = ({ guest = {}, guestList = {} }) => {
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const { id, name, email, phone, guestNote, tags, hasLoyalty, profile, blacklisted } = guest;
  // @ts-expect-error TS(2339): Property 'masterGuestsList' does not exist on type... Remove this comment to see the full error message
  const currentList = guestList.masterGuestsList;
  const currentMasterGuest = currentList?.masterGuests;
  const currentGuest = currentMasterGuest?.[id];

  return {
    masterGuestsList: {
      ...currentList,
      masterGuests: {
        ...currentMasterGuest,
        [id]: {
          ...currentGuest,
          name,
          email,
          phone,
          guestNote,
          tags,
          hasLoyalty,
          photoUrl: profile?.photo,
          blacklisted,
        },
      },
    },
  };
};
