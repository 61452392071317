import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { getPartnerSession } from 'utils/session';
import { ROUTES } from 'constants/routes';
import { EMPTY_STATE_ICON_TYPE } from 'constants/app';
import { COLORS } from 'constants/colors';
import EmptyState from 'components/shared/EmptyState';
import ForwardArrowIcon from 'components/icons/ForwardArrowIcon';
import styles from './PageNotFound.module.scss';

function PageNotFound() {
  const history = useHistory();

  useEffect(() => {
    Sentry.captureMessage(`Page not found, with pathname: ${window.location.pathname}`, {
      tags: {
        environment: process.env.REACT_APP_ENVIRONMENT,
        ...getPartnerSession(),
        pathname: window.location.pathname,
      },
      extra: {
        routeHref: window.location.href,
      },
    });
  }, []);

  return (
    <EmptyState
      iconType={EMPTY_STATE_ICON_TYPE.pizza}
      title="Oops, We couldn't find this page!"
      description="It appears that we couldn't locate what you were searching for. The page you were looking for doesn't exist."
    >
      <button
        type="button"
        className={styles.goBackToHome}
        onClick={() => history.push(ROUTES.HOME)}
      >
        Go back to Home <ForwardArrowIcon color={COLORS.WHITE} />
      </button>
    </EmptyState>
  );
}

export default PageNotFound;
