import React from 'react';
import classNames from 'classnames';
import { useViewPortContext } from '@umai/common';
import GuestBasicInfoLoader from 'components/shared/GuestBasicInfo/Loader';
import GuestTransactionsSummaryLoader from 'components/shared/GuestTransactionsSummary/Loader';
import GuestLoyaltySummaryLoader from 'components/shared/GuestLoyaltySummary/Loader';
import PanelLoader from 'components/panels/Panel/PanelLoader';
import '../Guest.styles.scss';

function GuestViewLoader() {
  const { mobile } = useViewPortContext();

  return (
    <div className="guest-view-wrapper">
      <GuestBasicInfoLoader />
      <div
        className={classNames('guest-venue-and-partner-summary', {
          'in-two-rows': !mobile,
        })}
      >
        <GuestTransactionsSummaryLoader isPartner={false} header isPartnerVisible showZeroSpend />
        <GuestTransactionsSummaryLoader isPartner header isPartnerVisible showZeroSpend />
      </div>
      <GuestLoyaltySummaryLoader />
      <PanelLoader />
    </div>
  );
}

export default GuestViewLoader;
