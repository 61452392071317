import React, { Suspense, lazy, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import classnames from 'classnames';
import { SkeletonLoader } from '@umai/common';
import { selectIsQueueSubscribed } from 'modules/Partner/selectors';
import {
  selectIsQueueOpen,
  selectReservationSuccess,
  selectIsEditingQueue,
} from 'modules/ReservationFunnel/selectors';
import { resetReservationFunnel, setIsQueueOpen } from 'modules/ReservationFunnel/actions';
import { ReactComponent as LogoIcon } from 'assets/images/icons/logo.svg';
import AccessMessage from 'components/shared/AccessMessage';
import ModalPopup from 'components/modals/ModalPopup';
import './QueueFunnel.styles.scss';

const ReservationFunnelModule = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-reservation-funnel" */ 'modules/ReservationFunnel'
    )
);

const QueueFunnel = () => {
  const dispatch = useAppDispatch();
  const isQueueSubscribed = useAppSelector(selectIsQueueSubscribed);
  const isQueueOpen = useAppSelector(selectIsQueueOpen);
  const isEditingQueue = useAppSelector(selectIsEditingQueue);
  const reservationSuccess = useAppSelector(selectReservationSuccess);

  const handleCloseQueueFunnel = () => {
    document.body.style.position = 'unset';
    dispatch(resetReservationFunnel());
    dispatch(setIsQueueOpen(false));
  };

  useEffect(() => {
    if (reservationSuccess) {
      // to solve scrolling issue when we close the modal
      document.body.style.position = 'unset';
      dispatch(setIsQueueOpen(false));
    } else if (isEditingQueue) {
      dispatch(setIsQueueOpen(true));
    }
  }, [reservationSuccess, isEditingQueue]);

  return (
    <ModalPopup
      modalIsOpen={isQueueOpen}
      onRequestClose={handleCloseQueueFunnel}
      customClasses="queue-funnel-popup"
    >
      <div
        role="presentation"
        className={classnames('queue-funnel-wrapper', {
          'unsubscribed-wrapper': !isQueueSubscribed,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        {isQueueSubscribed ? (
          <Suspense fallback={<SkeletonLoader.List />}>
            <ReservationFunnelModule />
          </Suspense>
        ) : (
          <AccessMessage
            header={
              <span>
                <LogoIcon className="umai-logo" />
                <span>Queue</span>
              </span>
            }
            title="Grow your guest database & provide a better guest experience with our Queue module"
            description="Easily manage guests waiting for a table. Notify them when tables are available, collect their data, and use your CRM to enhance your business"
            subTitle="This is not part of your subscription package, speak with your contact at UMAI for more info"
          />
        )}
      </div>
    </ModalPopup>
  );
};

export default QueueFunnel;
