/* eslint-disable no-console  */
import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { MESSAGE_EVENTS } from 'constants/socket';
import * as ActionTypes from 'modules/Guest/action-types';
import { setMasterGuest } from 'modules/Guest/actions';
import { selectGuests, selectMasterGuestId } from 'modules/Guest/selectors';
import { selectGuestId } from 'modules/ReservationFunnel/selectors';
import guestApis from 'modules/Guest/apis';
import { updateGuestCrm, searchMasterGuests } from 'modules/GuestsCrm/actions';
import { selectSearchText } from 'modules/GuestsCrm/selectors';
import { useViewPortContext } from '@umai/common';

const useSocketMasterGuest = ({ venueId }: any) => {
  const dispatch = useAppDispatch();
  const { mobile } = useViewPortContext();
  const masterGuestId = useAppSelector(selectMasterGuestId);
  const funnelGuestId = useAppSelector(selectGuestId);
  const guests = useAppSelector(selectGuests);
  const searchText = useAppSelector(selectSearchText);

  const masterGuestUpdate = async ({ detail = {} }) => {
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      const masterGuestData = detail?.params || {};
      const searchGuest = guests.find((g: any) => g?.id === masterGuestData?.master_guest_id);
      let masterGuest: any = null;

      const getMasterGuest = async () => {
        const guestId = masterGuestData?.new_master_guest_id
          ? masterGuestData.new_master_guest_id
          : masterGuestData?.master_guest_id;
        if (!masterGuest) {
          const response = await guestApis.fetchMasterGuest(guestId, venueId);
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          masterGuest = response.masterGuest;
        }
        return masterGuest;
      };

      console.debug('MASTER GUEST: socket update: ', masterGuestData, masterGuestId);
      if (masterGuestId) {
        if (
          masterGuestData?.new_master_guest_id &&
          masterGuestData?.new_master_guest_id !== masterGuestId
        ) {
          dispatch(searchMasterGuests({ searchText, page: 1, venueId }));
        }
        if (masterGuestData?.master_guest_id === masterGuestId) {
          const guest = await getMasterGuest();
          batch(() => {
            dispatch(updateGuestCrm(guest));
            dispatch(setMasterGuest(guest));
          });
        }
      }

      console.debug('GUEST LIST: socket update: ', masterGuestData, searchGuest?.id);
      // find the guest in search list and update it, to avoid conflicts with new masterGuestId on guests array
      if (searchGuest?.id && masterGuestData?.new_master_guest_id) {
        const guest = await getMasterGuest();

        dispatch({
          type: ActionTypes.SET_GUESTS,
          payload: {
            guests: guests.map((g: any) => {
              if (g.id === searchGuest.id) return { ...g, ...guest };
              return g;
            }),
          },
        });
      }
    } catch (error) {
      console.error('Error in update master guest from socket', error);
    }
  };

  useEffect(() => {
    // @ts-expect-error TS(2769): No overload matches this call.
    document.addEventListener(MESSAGE_EVENTS.masterGuest, masterGuestUpdate, false);
    return () => {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(MESSAGE_EVENTS.masterGuest, masterGuestUpdate, false);
    };
  }, [venueId, masterGuestId, funnelGuestId, guests, mobile]);
};

export default useSocketMasterGuest;
