import { GUEST_RESERVATION_TYPE } from './constants';
import * as ActionTypes from './action-types';
import type {
  GuestSearchGroupList,
  GuestSearch,
  MasterGuest,
  GuestReservationSummary,
  GuestReservationHistory,
  GuestLoyaltyAccountDetails,
} from './types';

interface GuestState {
  guests: GuestSearch[];
  totalGuestCount: number;
  masterGuest: MasterGuest | null;
  reservationSummary: GuestReservationSummary | null;
  upcoming: GuestReservationHistory[];
  past: GuestReservationHistory[];
  loyaltyAccountDetails: GuestLoyaltyAccountDetails;
  isLoading: boolean;
  isLoadingMasterGuest: boolean;
  isLoadingReservationSummary: boolean;
  isLoadingLoyaltyTransaction: boolean;
  isLoadingPast: boolean;
  isLoadingUpcoming: boolean;
  guestHistory: null | object; // TODO: make guest history value based on different types like Reservation, Loyalty, Takeaway/Delivery
}

const INITIAL_STATE: GuestState = {
  guests: [],
  totalGuestCount: 0,
  masterGuest: null,
  reservationSummary: null,
  [GUEST_RESERVATION_TYPE.past]: [],
  [GUEST_RESERVATION_TYPE.upcoming]: [],
  loyaltyAccountDetails: {
    hasLoyaltyAccount: false,
    currentBalance: '-',
    availableReward: 0,
    totalRedeemed: '-',
  },
  isLoading: false,
  isLoadingMasterGuest: false,
  isLoadingReservationSummary: false,
  isLoadingLoyaltyTransaction: false,
  isLoadingPast: false,
  isLoadingUpcoming: false,
  guestHistory: null,
};

const formatGuestsSearch = (guests: GuestSearchGroupList) =>
  Object.values(guests)
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    .map((list) => list.masterGuests)
    .reduce((list1, list2) => list1.concat(list2), []);

// eslint-disable-next-line default-param-last
const guestReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionTypes.SEARCH_GUEST: {
      const { guests, totalGuestCount } = action.payload;
      return {
        ...state,
        guests: [...formatGuestsSearch(guests)],
        totalGuestCount,
        isLoading: false,
      };
    }
    case ActionTypes.CREATE_GUEST_FROM_LISTING: {
      const { guests } = state;
      return { ...state, guests: [action.payload].concat(guests) };
    }
    case ActionTypes.SET_GUESTS: {
      const { guests } = action.payload;
      return { ...state, guests };
    }
    case ActionTypes.SET_MASTER_GUEST: {
      return { ...state, masterGuest: action.payload };
    }
    case ActionTypes.FETCH_MASTER_GUEST: {
      return { ...state, masterGuest: action.payload, isLoadingMasterGuest: false };
    }
    case ActionTypes.FETCH_MASTER_GUEST_RESERVATION_SUMMARY: {
      return { ...state, reservationSummary: action.payload, isLoadingReservationSummary: false };
    }
    case ActionTypes.FETCH_MASTER_GUEST_RESERVATIONS: {
      return { ...state, ...action.payload };
    }
    case ActionTypes.FETCH_MASTER_GUEST_LOYALTY_TRANSACTIONS: {
      return {
        ...state,
        loyaltyAccountDetails: action.payload,
        isLoadingLoyaltyTransaction: false,
      };
    }
    case ActionTypes.SET_MASTER_GUEST_LOYALTY_SUBSCRIPTION: {
      return { ...state, masterGuest: action.payload };
    }
    case ActionTypes.SET_MASTER_GUEST_LOYALTY_TIER: {
      const { masterGuest } = state;
      // @ts-expect-error TS(2339): Property 'loyaltyTier' does not exist on type 'nul... Remove this comment to see the full error message
      const { loyaltyTier } = masterGuest;
      return {
        ...state,
        masterGuest: { ...masterGuest, loyaltyTier: { ...loyaltyTier, ...action.payload } },
      };
    }
    case ActionTypes.UPDATE_GUEST: {
      return { ...state, masterGuest: action.payload };
    }
    case ActionTypes.SET_GUEST_LOADING: {
      return { ...state, isLoading: action.payload };
    }
    case ActionTypes.SET_MASTER_GUEST_LOADING: {
      return { ...state, ...action.payload };
    }
    case ActionTypes.RESET_MASTER_GUEST: {
      return {
        ...state,
        masterGuest: null,
        reservationSummary: null,
        loyaltyAccountDetails: {},
        guestHistory: null,
      };
    }
    case ActionTypes.RESET_GUESTS:
      return { ...state, guests: [], totalGuestCount: 0 };

    case ActionTypes.SET_GUEST_HISTORY_DATA: {
      return { ...state, guestHistory: action.payload };
    }
    default:
      return state;
  }
};

export default guestReducer;
