import { matchPath } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { history } from 'routes/history';
import { getPartnerSession, isProductionEnv } from 'utils/session';
import { ROUTES } from 'constants/routes';
import { COLORS } from 'constants/colors';

// eslint-disable-next-line import/no-mutable-exports
export let sentryFeedbackDialog: Sentry.Feedback | null = null;

export const captureException = (error: Error) =>
  Sentry.captureException(error, {
    tags: {
      environment: process.env.REACT_APP_ENVIRONMENT,
      ...getPartnerSession(),
    },
  });

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `webrms@${process.env.REACT_APP_VERSION}`,
    environment: `webrms@${process.env.REACT_APP_ENVIRONMENT}`,
    ignoreErrors: [
      /ResizeObserver loop limit exceeded.*/,
      /ResizeObserver loop completed with undelivered notifications.*/,
      /SecurityError: The operation is insecure.*/,
      /SecurityError: Blocked a frame with origin.*/,
      /Can't find variable: PaymentAutofillConfig.*/,
    ],
    integrations: [],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: isProductionEnv() ? 0.1 : 0.6,
    // Capture Replay for 5% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.001,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const addSentryIntegrations = () => {
  const addIntegrations = async () => {
    const client = Sentry.getCurrentHub().getClient();
    const venueId = getPartnerSession().currentVenueId;

    if (typeof client?.addIntegration !== 'undefined') {
      const { Replay } = await import(
        /* webpackChunkName: "umai-sentry-replay" */
        /* webpackMode: "lazy" */
        '@sentry/react'
      );

      client.addIntegration(
        new Replay({
          blockAllMedia: false,
          maskAllText: false,
          block: ['iframe'],
        })
      );

      const { BrowserTracing } = await import(
        /* webpackChunkName: "umai-sentry-browser-tracing" */
        /* webpackMode: "lazy" */
        '@sentry/react'
      );
      client.addIntegration(
        new BrowserTracing({
          // How to integrate reac-router-dom v45 with sentry
          // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v4v5
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(
            history,
            Object.values(ROUTES).map((route) => ({ path: route })),
            matchPath
          ),
        })
      );

      const { Feedback } = await import(
        /* webpackChunkName: "umai-sentry-feedback" */
        /* webpackMode: "lazy" */
        '@sentry/react'
      );

      sentryFeedbackDialog = new Feedback({
        // Additional SDK configuration goes in here, for example:
        id: `${venueId}`,
        autoInject: false,
        colorScheme: 'light',
        showBranding: false,
        isEmailRequired: true,
        formTitle: 'Please share your valuable feedback',
        buttonLabel: 'Provide feedback',
        submitButtonLabel: 'Submit',
        themeLight: {
          inputOutlineFocus: COLORS.SECONDARY,
          cancelOutlineFocus: COLORS.SECONDARY,
          submitOutlineFocus: COLORS.GREEN_PRIMARY,
          submitBackground: COLORS.GREEN_PRIMARY,
          submitBackgroundHover: COLORS.GREEN_PRIMARY,
          success: COLORS.GREEN_PRIMARY,
          error: COLORS.DANGER,
        },
      });

      client.addIntegration(sentryFeedbackDialog);
    }
  };

  window.addEventListener('load', addIntegrations, false);
};
