import React from 'react';
import { SkeletonLoader } from '@umai/common';

// Loader UI is needed to lazy load the mobile footer for the suspense fallback
// However as for the date of commit, mobile footer is mounted in two layouts:
// 1. src/layouts/ThirdPartyAppLayout
// 2. src/layouts/MobileLayout
// NOTE: Rest of all the places the MobileFooter is passed as a prop, so we do not need to wrap the suspense around those
function MobileFooterLoader() {
  const renderButton = (width: any) => (
    <li key={width}>
      <SkeletonLoader.Skeleton
        style={{ width, height: '10px' }}
        enableAnimation
        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
        wrapper="button"
        baseColor="#1e1e36"
        highlightColor="#ebebeb"
        containerClassName="button footer-button"
      />
    </li>
  );

  const renderNavigation = (index: any) => (
    <li key={index} style={{ padding: '0px 10px' }}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
      <a>
        <SkeletonLoader.Skeleton
          style={{ width: '30px', height: '30px', margin: 0 }}
          baseColor="#06031a"
          highlightColor="#ebebeb"
          circle
        />
        <SkeletonLoader.Skeleton
          style={{ width: '50px', margin: 0 }}
          baseColor="#06031a"
          highlightColor="#ebebeb"
        />
      </a>
    </li>
  );

  return (
    <footer className="footer-menu-wrapper" id="mobile-footer">
      <nav className="footer-menu" id="footer-filters">
        <ul>{['80px', '120px', '70px'].map((width) => renderButton(width))}</ul>
      </nav>
      <nav id="main-footer-menu" className="footer-menu">
        <ul>{[1, 2, 3, 4, 5, 6, 7].map((index) => renderNavigation(index))}</ul>
      </nav>
    </footer>
  );
}

export default MobileFooterLoader;
