/* eslint-disable camelcase, no-console */
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { getVenueRooms } from 'modules/Room/actions';
import { MESSAGE_EVENTS } from 'constants/socket';

const useSocketRooms = ({ venueId }: any) => {
  const dispatch = useAppDispatch();
  let roomsController: any = null;

  const roomsUpdate = ({ detail = {} }) => {
    try {
      console.debug('ROOMS: socket update: ', venueId, detail);
      if (venueId) {
        roomsController = dispatch(getVenueRooms({ venueId }));
      }
    } catch (error) {
      console.error('Error in update reservation view from socket', error);
    }
  };

  useEffect(() => {
    // @ts-expect-error TS(2769): No overload matches this call.
    document.addEventListener(MESSAGE_EVENTS.rooms, roomsUpdate, false);
    return () => {
      roomsController?.abort();
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(MESSAGE_EVENTS.rooms, roomsUpdate, false);
    };
  }, [venueId]);
};

export default useSocketRooms;
