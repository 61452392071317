import React, { Suspense } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { MOBILE_APP_WRAPPER } from 'constants/app';
import SwipeableWrapper from 'components/ui/SwipeableWrapper';
import MobileFooterLoader from 'modules/Footer/components/MobileFooter/Loader';
import './MobileLayout.styles.scss';

interface MobileLayoutProps {
  className?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
  customBodyClasses?: string;
  onSwipedLeft?(...args: unknown[]): unknown;
  onSwipedRight?(...args: unknown[]): unknown;
  onSwipedUp?(...args: unknown[]): unknown;
  onSwipedDown?(...args: unknown[]): unknown;
}

const MobileLayout = ({
  header,
  children,
  footer,
  customBodyClasses = '',
  className,
  onSwipedLeft = () => {},
  onSwipedRight = () => {},
  onSwipedUp = () => {},
  onSwipedDown = () => {},
}: MobileLayoutProps) => (
  <main id={MOBILE_APP_WRAPPER} className={className}>
    {header}
    <SwipeableWrapper
      onSwipedLeft={onSwipedLeft}
      onSwipedRight={onSwipedRight}
      onSwipedUp={onSwipedUp}
      onSwipedDown={onSwipedDown}
      className={classnames('body', customBodyClasses)}
    >
      {children}
    </SwipeableWrapper>
    <Suspense fallback={<MobileFooterLoader />}>{footer}</Suspense>
  </main>
);

MobileLayout.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  customBodyClasses: PropTypes.string,
  onSwipedLeft: PropTypes.func,
  onSwipedRight: PropTypes.func,
  onSwipedUp: PropTypes.func,
  onSwipedDown: PropTypes.func,
};

export default MobileLayout;
