import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';

class Notification extends HttpRequest {
  constructor() {
    super(API_ENDPOINT_PARTNER_V2);
  }

  saveDeviceTokenToServer(data: any) {
    return this.create('/notifications', data);
  }
}

export default new Notification();
