import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface CloseIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
  onClick?(...args: unknown[]): unknown;
  className?: string;
}

function CloseIcon({
  width = 16,
  height = 16,
  color = COLORS.WHITE,
  onClick,
  className,
}: CloseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 16 16"
      onClick={onClick}
      className={className}
    >
      <title>Close</title>
      <path
        fill={color}
        d="M16 1.611L14.389 0 8 6.389 1.611 0 0 1.611 6.389 8 0 14.389 1.611 16 8 9.611 14.389 16 16 14.389 9.611 8 16 1.611z"
      ></path>
    </svg>
  );
}

CloseIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default CloseIcon;
