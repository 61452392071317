import { normalize, schema } from 'normalizr';
import { mergeGuests, updateGuest } from './services';
import * as ActionTypes from './action-types';

const masterGuestSchema = new schema.Entity('masterGuests');
const guestsSchema = new schema.Entity(
  'guests',
  { masterGuests: [masterGuestSchema] },
  {
    idAttribute: (entity) => entity.alphabet,
    processStrategy: (entity) => ({ ...entity, id: entity.alphabet }),
  }
);

const normalizeMasterGuest = (guests: any) => {
  const normalized = normalize(guests, [guestsSchema]);
  return normalized.entities;
};

const INITIAL_STATE = {
  masterGuestsList: { guests: {}, masterGuests: {} },
  totalMasterGuestsCount: 0,
  searchText: '',
  isLoading: true,
  masterGuestSuccess: false,
  isLoadingMore: false,
  pageNumber: 1,
  selectedMasterGuestId: null,
};

// eslint-disable-next-line default-param-last
const masterGuestReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case ActionTypes.SEARCH_MASTER_GUESTS: {
      const { masterGuests, totalMasterGuestsCount } = payload;
      return {
        ...state,
        isLoading: false,
        totalMasterGuestsCount,
        pageNumber: 2,
        masterGuestsList: normalizeMasterGuest(masterGuests),
      };
    }
    case ActionTypes.FETCH_MORE_MASTER_GUESTS: {
      const { guests, masterGuests } = normalizeMasterGuest(payload.masterGuests);
      const currentMasterGuestList = state.masterGuestsList;
      return {
        ...state,
        isLoadingMore: false,
        pageNumber: state.pageNumber + 1,
        masterGuestsList: {
          guests: {
            ...currentMasterGuestList.guests,
            ...mergeGuests({
              guests,
              currentGuests: currentMasterGuestList.guests,
            }),
          },
          masterGuests: { ...currentMasterGuestList.masterGuests, ...masterGuests },
        },
      };
    }
    case ActionTypes.UPDATE_GUEST_CRM:
      return {
        ...state,
        ...updateGuest({
          guest: payload,
          guestList: { masterGuestsList: state.masterGuestsList },
        }),
      };
    case ActionTypes.UPDATE_MASTER_GUESTS_SEARCH_TEXT: {
      const { searchText } = payload;
      return {
        ...state,
        isLoading: true,
        searchText,
      };
    }
    case ActionTypes.CREATE_NEW_MASTER_GUEST:
      return {
        ...state,
        masterGuestSuccess: true,
        selectedMasterGuestId: payload,
      };
    case ActionTypes.SET_MASTER_GUEST_ID:
      return {
        ...state,
        selectedMasterGuestId: payload,
      };
    case ActionTypes.SET_MASTER_GUESTS_LOADING:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.SET_LOADING_MORE_GUESTS:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.RESET_SELECTED_GUEST_ID:
      return {
        ...state,
        masterGuestSuccess: false,
      };
    default:
      return state;
  }
};

export default masterGuestReducer;
