import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobileDevice } from '@umai/common';
import { isTabletDevice } from 'utils/devices';

type AppContextType = {
  isVenueSettings: boolean;
  handleSettingsRouteChange: (path: any) => void;
  hasSideBar: () => boolean;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

interface AppProviderProps {
  children?: React.ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [isVenueSettings, setIsVenueSettings] = useState(false);

  const handleSettingsRouteChange = (path: any) => {
    setIsVenueSettings(/venue-settings/i.test(path));
  };

  const hasSideBar = () => {
    // We do not show the side bar on mobile or venue settings on tablet only
    if (isMobileDevice || (isTabletDevice && isVenueSettings)) {
      return false;
    }
    return true;
  };

  const appState = useMemo(
    () => ({ isVenueSettings, handleSettingsRouteChange, hasSideBar }),
    [isVenueSettings, handleSettingsRouteChange, hasSideBar]
  );

  return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node,
};
