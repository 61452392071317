import React, { useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { Formik, Field, ErrorMessage, Form, type FormikHelpers } from 'formik';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { hasError } from 'utils/errors';
import { LoginSchema } from 'modules/Auth/validations';
import { selectIsAuthSubmitting } from '../../selectors';
import { COLORS } from 'constants/colors';
import EmailIcon from 'components/icons/EmailIcon';
import EyeShow from 'components/icons/EyeShowIcon';
import EyeHide from 'components/icons/EyeHideIcon';
import ForgotPasswordLock from 'components/icons/ForgotPasswordLockIcon';
import type { LoginFormValues } from 'modules/Auth/types';

interface LoginFormProps {
  onSubmit(
    value: LoginFormValues,
    resetForm: Pick<FormikHelpers<LoginFormValues>, 'resetForm'>
  ): void;
}

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const [passwordType, setPassWordType] = useState('password');
  const isAuthSubmitting = useAppSelector(selectIsAuthSubmitting);
  const isInputPassword = passwordType === 'password';

  const handleLoginSubmit = async (
    values: LoginFormValues,
    { resetForm }: FormikHelpers<LoginFormValues>
  ) => {
    onSubmit(values, { resetForm });
  };

  return (
    <Formik<LoginFormValues>
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleLoginSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <div className="field">
            <Field
              name="email"
              className={classnames('input', {
                'input__warning-border': Boolean(errors.email && touched.email),
              })}
              placeholder="Email"
              autoFocus
              autoComplete="email"
              readOnly={isSubmitting}
            />
            <span className="login-input-icon">
              <EmailIcon color={COLORS.LIGHT_BLUE} width={24} height={20} />
            </span>
            <Field
              type={passwordType}
              name="password"
              className={classnames('input', {
                'input__warning-border': Boolean(errors.password && touched.password),
              })}
              placeholder="Password"
              autoComplete="current-password"
              readOnly={isSubmitting}
            />
            <button
              className="show-hide-password"
              type="button"
              onClick={() => setPassWordType(isInputPassword ? 'text' : 'password')}
            >
              {isInputPassword ? <EyeShow /> : <EyeHide />}
            </button>
            <span className="login-input-icon">
              <ForgotPasswordLock />
            </span>
          </div>
          {hasError(errors) ? (
            <div className="login-error">
              <ErrorMessage component="span" name="email" className="help is-danger" />
              <ErrorMessage component="span" name="password" className="help is-danger" />
            </div>
          ) : null}
          <button disabled={isAuthSubmitting} className="button is-primary" type="submit">
            {isAuthSubmitting ? 'Signing in...' : 'Sign in'}
          </button>
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default LoginForm;
