// NOTE: keep SOCKET_ACTION in sync with action in socket-worker.ts
export const SOCKET_ACTION = {
  subscribe: { command: 'subscribe' },
  emit: { command: 'emit' },
  checkState: { command: 'checkState' },
  unsubscribe: { command: 'unsubscribe' },
} as const;

export const CHANNELS = {
  generalChannel: 'GeneralChannel',
  userChannel: 'PartnerCable::UserChannel',
};

// NOTE: commented message_types socket are not used, but are available to use if required in future
export const MESSAGE_TYPES = {
  orders: 'order_status',
  masterGuest: 'master_guest',
  slots: 'slots',
  traffic: 'traffic',
  // loyalty: 'loyalty',
  // tables: 'tables',
  // feedback: 'feedback',
  tables: 'table_v2',
  blockedTable: 'blocked_table',
  rooms: 'rooms',
  venueUpdate: 'venue_update',
  notifyLists: 'notifylists',
  footer: 'footer',
  tags: 'tags',
  calendar: 'calendar_v2',
  offlineWaitList: 'offline_waitlist',
};

export const MESSAGE_TYPE_TO_AVOID = [MESSAGE_TYPES.slots, MESSAGE_TYPES.notifyLists];

export const MESSAGE_EVENTS = {
  reservation: 'update_reservation',
  rooms: 'update_rooms',
  venueAndAvailabilities: 'update_venue_and_availabilities',
  waitlist: 'update_waitlist',
  tables: 'update_tables',
  funnelTables: 'update_funnel_tables',
  slots: 'update_slots',
  availabilitySummary: 'update_availability_summary',
  tags: 'update_tags',
  calendar: 'update_calendar',
  masterGuest: 'update_master_guest',
  funnelCalendar: 'update_funnel_calendar',
  reservationActivities: 'update_reservation_activity',
  queueList: 'update_queue_list',
};
