import { batch } from 'react-redux';
import type { AppDispatch } from 'redux-store';
import * as ActionTypes from './action-types';
import { getPartnerSession, savePartnerSession } from 'utils/session';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage, showSuccessMessage } from 'utils/alerts';
import { updateBeamer } from 'utils/beamer';
import type { LoginFormValues } from './types';
import type { FormikHelpers } from 'formik';
import authApis from './apis';

// set current session stored in cookies to auth reducer
// TODO: get user info from backend and save in reducer using this action, for the cases where user deletes the cookies like email from devtools
// oauth_token_info GET /oauth/token/info
export const setCurrentSession = () => ({
  type: ActionTypes.SET_CURRENT_SESSION,
  payload: { accessToken: getPartnerSession().accessToken, email: getPartnerSession().email },
});

const authSubmitting = (isAuthSubmitting: boolean) => ({
  type: ActionTypes.AUTH_SUBMITTING,
  payload: isAuthSubmitting,
});

export const login =
  (
    { email, password }: LoginFormValues,
    { resetForm }: Pick<FormikHelpers<LoginFormValues>, 'resetForm'>
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      // trim the email for white-spaces and on error set trimmed email to form using "resetForm"
      email = email?.trim();
      dispatch(authSubmitting(true));
      const user = await authApis.login({
        password,
        username: email,
        grant_type: 'password',
      });
      const { accessToken, email: userEmail } = user;
      savePartnerSession({ accessToken, email: userEmail });
      batch(() => {
        dispatch(setCurrentSession());
        dispatch({ type: ActionTypes.LOGIN, payload: user });
      });
    } catch (err) {
      resetForm({ values: { email, password: '' } });
      showErrorMessage(getErrorMessage(err));
    } finally {
      dispatch(authSubmitting(false));
    }
  };

export const sendPasswordResetInstructions = (email: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(authSubmitting(true));
    await authApis.forgotPassword(email);
    showSuccessMessage('Your password reset instructions have been sent to your e-mail address.');
  } catch (err) {
    showErrorMessage(getErrorMessage(err));
  } finally {
    dispatch(authSubmitting(false));
  }
};

const setIsPasswordReset = (isPasswordResetSuccess: boolean) => ({
  type: ActionTypes.RESET_PASSWORD,
  payload: isPasswordResetSuccess,
});

export const resetPassword = (token: string, password: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(authSubmitting(true));
    await authApis.resetPassword(token, password);
    showSuccessMessage('Your password has been reset');
    dispatch(setIsPasswordReset(true));
  } catch (err) {
    showErrorMessage(getErrorMessage(err));
  } finally {
    dispatch(authSubmitting(false));
  }
};

export const validatePasswordToken = (token: string | null) => async (dispatch: AppDispatch) => {
  try {
    await authApis.validateToken(token);
    dispatch({
      type: ActionTypes.VALIDATE_TOKEN,
      payload: true,
    });
  } catch {
    dispatch({
      type: ActionTypes.VALIDATE_TOKEN,
      payload: false,
    });
  }
};

export const logout = () => ({ type: ActionTypes.LOGOUT, payload: { accessToken: null } });

const setCurrentUserLoading = (isLoadingCurrentUser: boolean) => ({
  type: ActionTypes.SET_CURRENT_USER_LOADING,
  payload: isLoadingCurrentUser,
});

export const getCurrentUser = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setCurrentUserLoading(true));
    const currentUserResponse = await authApis.fetchCurrentUser();
    dispatch({
      type: ActionTypes.SET_CURRENT_USER,
      payload: currentUserResponse.user,
    });

    updateBeamer({
      // ---------------Visitor Information---------------
      user_firstname: currentUserResponse.user.firstName,
      user_lastname: currentUserResponse.user.lastName,
      user_email: currentUserResponse.user.email,
      user_id: currentUserResponse.user.id,
    });
  } catch (error) {
    showErrorMessage(error);
  } finally {
    dispatch(setCurrentUserLoading(false));
  }
};

export const updateCurrentUser = (userUpdate: any) => ({
  type: ActionTypes.UPDATE_CURRENT_USER,
  payload: userUpdate,
});
