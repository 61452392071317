import { ACTIVE_QUEUE_KEY, REMOVED_QUEUE_KEY } from 'modules/QueueList/constants';
import { getPartnerSession } from 'utils/session';
import { MESSAGE_EVENTS } from 'constants/socket';
import { createCustomEventListener } from 'utils/event-listeners';
import { checkIsItemInFooterDate } from 'utils/date-and-time';
import { checkIsQueueRemoved } from 'modules/QueueList/services';

export const customSocketEventForQueue = (queueItem: any, venueId: any) => {
  // create event similar to socket to create/update queue in listing.. (safe call in-case socket are not working)
  // its primary purpose is to use in Funnel so if a queue is created/updated, we can fire an event listner for it
  const { currentVenueId } = getPartnerSession();
  // Only fire event when current venue id is same as venueId of queue
  // Check is required because in funnel it's possible to create queue for another venue
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (queueItem?.id && +venueId === +currentVenueId) {
    const { id, dateTimeStart, status, reservationId } = queueItem;
    // for custom socket event, queueItem is passed to avoid hitting the getQueueItem API
    createCustomEventListener(MESSAGE_EVENTS.queueList, {
      detail: {
        params: {
          id,
          dateTimeStart,
          status,
          queueItem,
          reservationId,
        },
      },
    });
  }
};

export const getQueueItemAndListKey = ({ queueId, queueLists }: any) => {
  const activeQueueItem = queueLists[ACTIVE_QUEUE_KEY]?.queue?.[queueId];
  if (activeQueueItem) {
    return { listKey: ACTIVE_QUEUE_KEY, queueItem: activeQueueItem };
  }

  const removedQueueItem = queueLists[REMOVED_QUEUE_KEY]?.queue?.[queueId];
  if (removedQueueItem) {
    return { listKey: REMOVED_QUEUE_KEY, queueItem: removedQueueItem };
  }

  return { listKey: null, queueItem: null };
};

const updateQueueList = ({ listKey, queueItem, queueLists }: any) => {
  const currentList = queueLists[listKey];
  return {
    [listKey]: {
      queue: {
        ...currentList.queue,
        [queueItem.id]: queueItem,
      },
    },
  };
};

export const removeQueueItemFromList = ({ listKey, queueId, queueLists }: any) => {
  const currentList = queueLists[listKey];
  delete currentList?.queue?.[queueId];
  return {
    [listKey]: {
      queue: { ...currentList.queue },
    },
  };
};

const upsertQueueList = ({ add, remove, queueLists }: any) => ({
  ...removeQueueItemFromList({ ...remove, queueLists }),
  ...updateQueueList({ ...add, queueLists }),
});

export const queuelistSlice = ({ queueId, currentQueueItem, queueLists, footerDate }: any) => {
  // find the listkey if the queueitem is already in the reducer
  const { listKey, queueItem } = getQueueItemAndListKey({ queueId, queueLists });
  const { dateTimeStart } = currentQueueItem;

  if (queueItem) {
    if (!checkIsItemInFooterDate(footerDate, dateTimeStart)) {
      // Remove queueitem if datestarttime is not in the footerdate
      return removeQueueItemFromList({ listKey, queueId, queueLists });
    }
    // if queueitem is in the reducer, then check if the currentItem.removed matches the queueItem.removed
    // and it's within today

    if (checkIsQueueRemoved(queueItem.status) === checkIsQueueRemoved(currentQueueItem.status)) {
      // if listKey and status matched then update the matched queueitem in the current listKey
      return updateQueueList({
        listKey,
        queueLists,
        queueItem: currentQueueItem,
      });
    }
    // if currentQueueItem and queueitem removed does not match, remove item from the list and add to the proper list
    if (listKey === ACTIVE_QUEUE_KEY) {
      return upsertQueueList({
        remove: { listKey, queueId },
        add: { listKey: REMOVED_QUEUE_KEY, queueItem: currentQueueItem },
        queueLists,
      });
    }
    return upsertQueueList({
      remove: { listKey, queueId },
      add: { listKey: ACTIVE_QUEUE_KEY, queueItem: currentQueueItem },
      queueLists,
    });
  }

  /* if the queue is not in the reducer yet and dateTimeStart is in footerdate, add to the active queueList
  All newly created queueItem will have status of active.. removed: false and within the day */
  if (checkIsItemInFooterDate(footerDate, dateTimeStart)) {
    return updateQueueList({
      listKey: ACTIVE_QUEUE_KEY,
      queueItem: currentQueueItem,
      queueLists,
    });
  }

  return null;
};
