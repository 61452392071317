import * as ActionTypes from './action-types';
import type { PaymentProfile, Venue, VenueId, Venues } from 'modules/Venue/types';

interface PartnerState {
  venues: Venues;
  venueIds: VenueId[];
  currentVenue: Venue;
  venueLoading: boolean;
  paymentProfiles: PaymentProfile;
}

// Define the action interfaces with inline payloads
interface GetPartnerVenuesAction {
  type: typeof ActionTypes.GET_PARTNER_VENUES;
  payload: { venues: Venues; venueIds: VenueId[] };
}

interface SetPartnerVenueAction {
  type: typeof ActionTypes.SET_PARTNER_VENUE;
  payload: Venues;
}

interface SetCurrentVenueAction {
  type: typeof ActionTypes.SET_CURRENT_VENUE;
  payload: Partial<Venue>;
}

interface SetVenueInfoAction {
  type: typeof ActionTypes.SET_VENUE_INFO;
  payload: Partial<Venue>;
}

interface SetVenueSubscriptionAction {
  type: typeof ActionTypes.SET_VENUE_SUBSCRIPTION;
  payload: Venue['productSubscriptions'];
}

interface SetVenueLoadingAction {
  type: typeof ActionTypes.SET_VENUE_LOADING;
  payload: boolean;
}

interface GetVenueLoyaltyTiersAction {
  type: typeof ActionTypes.GET_VENUE_LOYALTY_TIERS;
  payload: Venue['loyaltyTiers'];
}

interface SetPaymentProfileAction {
  type: typeof ActionTypes.SET_PAYMENT_PROFILE;
  payload: PaymentProfile;
}

interface SetVisiblePaymentWarningAction {
  type: typeof ActionTypes.SET_VISIBLE_PAYMENT_WARNING;
  payload: boolean;
}

// Create a union type of all action types
type PartnerActions =
  | GetPartnerVenuesAction
  | SetPartnerVenueAction
  | SetCurrentVenueAction
  | SetVenueInfoAction
  | SetVenueSubscriptionAction
  | SetVenueLoadingAction
  | GetVenueLoyaltyTiersAction
  | SetPaymentProfileAction
  | SetVisiblePaymentWarningAction;

const INITIAL_STATE: PartnerState = {
  venues: {} satisfies Venues,
  venueIds: [],
  currentVenue: {} as Venue,
  venueLoading: false,
  paymentProfiles: {} satisfies PaymentProfile,
};

// eslint-disable-next-line default-param-last
const partnerReducer = (state = INITIAL_STATE, action: PartnerActions): PartnerState => {
  switch (action.type) {
    case ActionTypes.GET_PARTNER_VENUES:
      return { ...state, venues: action.payload.venues, venueIds: action.payload.venueIds };
    case ActionTypes.SET_PARTNER_VENUE:
      return { ...state, venues: action.payload };
    case ActionTypes.SET_CURRENT_VENUE: {
      const { currentVenue } = state;
      return { ...state, currentVenue: { ...currentVenue, ...action.payload } };
    }
    case ActionTypes.SET_VENUE_INFO: {
      const { currentVenue } = state;
      return { ...state, currentVenue: { ...currentVenue, ...action.payload } };
    }
    case ActionTypes.SET_VENUE_SUBSCRIPTION: {
      const { currentVenue } = state;
      return { ...state, currentVenue: { ...currentVenue, productSubscriptions: action.payload } };
    }
    case ActionTypes.SET_VENUE_LOADING: {
      return { ...state, venueLoading: action.payload };
    }
    case ActionTypes.GET_VENUE_LOYALTY_TIERS: {
      const { currentVenue } = state;
      return { ...state, currentVenue: { ...currentVenue, loyaltyTiers: action.payload } };
    }
    case ActionTypes.SET_PAYMENT_PROFILE: {
      return { ...state, paymentProfiles: action.payload };
    }
    case ActionTypes.SET_VISIBLE_PAYMENT_WARNING: {
      const { paymentProfiles, currentVenue } = state;
      return {
        ...state,
        paymentProfiles: {
          ...paymentProfiles,
          [currentVenue.id]: { ...paymentProfiles[currentVenue.id], isVisible: action.payload },
        },
      };
    }
    default:
      return state;
  }
};

export default partnerReducer;
