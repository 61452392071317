import { normalize, schema } from 'normalizr';
import * as ActionTypes from './action-types';

const roomsSchema = new schema.Entity('rooms');

const blockedTableSchema = new schema.Entity(
  'blockedTables',
  {},
  {
    processStrategy: (entity, parent) => ({
      ...entity,
      tableId: parent.id,
      tableName: parent.name,
      availableOnline: parent.availableOnline,
      roomId: parent?.room?.id,
    }),
  }
);

const tablesSchema = new schema.Entity('tables', { blockedTables: [blockedTableSchema] });

export const normalizeRooms = (rooms: any) => {
  const normalized = normalize(rooms, [roomsSchema]);
  return { rooms: normalized.entities.rooms, roomIds: normalized.result };
};

export const normalizeTables = (tableList: any) => {
  const normalized = normalize(tableList, [tablesSchema]);
  const { tables = {}, blockedTables = {} } = normalized.entities;
  const tableIds = normalized.result || [];
  // generating structure to store rooms with tableIds
  const roomTables = tableIds.reduce((roomWithTableIds: any, tableId: any) => {
    const table = tables[tableId];
    const { id: roomId } = table?.room || {};

    if (roomId) roomWithTableIds[roomId] = [...(roomWithTableIds?.[roomId] || []), table.id];
    return roomWithTableIds;
  }, {});

  return {
    tables,
    tableIds,
    blockedTables,
    roomTables,
  };
};

const INITIAL_STATE = {
  rooms: {},
  roomIds: [],
  isRoomsLoading: false,
  tables: {},
  tableIds: [],
  isRoomTablesLoading: false,
  roomTables: {},
  blockedTables: {},
};

// eslint-disable-next-line default-param-last
const roomReducer = (state = INITIAL_STATE, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case ActionTypes.GET_VENUE_ROOMS: {
      const { rooms, roomIds } = payload;
      return { ...state, rooms, roomIds };
    }
    case ActionTypes.GET_ROOM_TABLES: {
      const { tables, tableIds, blockedTables, roomTables } = payload;
      return { ...state, tables, tableIds, blockedTables, roomTables };
    }
    case ActionTypes.SET_ROOMS_LOADING:
      return { ...state, isRoomsLoading: payload };

    case ActionTypes.SET_ROOM_TABLES_LOADING:
      return { ...state, isRoomTablesLoading: payload };

    case ActionTypes.SET_BLOCKED_TABLES: {
      const { tableId, table, blockedTables } = payload;
      return {
        ...state,
        tables: {
          ...state.tables,
          [tableId]: {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...(state.tables?.[tableId] || {}),
            ...table,
          },
        },
        blockedTables: { ...state.blockedTables, ...blockedTables },
      };
    }

    case ActionTypes.REMOVE_BLOCKED_TABLE: {
      const { tableId, table, blockedTableId, blockedTables } = payload;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      delete state.blockedTables[blockedTableId];
      return {
        ...state,
        tables: {
          ...state.tables,
          [tableId]: {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...(state.tables?.[tableId] || {}),
            ...table,
          },
        },
        blockedTables: { ...state.blockedTables, ...blockedTables },
      };
    }

    default:
      return state;
  }
};

export default roomReducer;
