import * as ActionTypes from './action-types';
import { FILTER_VIEW } from './constants';

const INITIAL_STATE = {
  filterView: FILTER_VIEW.OVERVIEW,
  selectedStatuses: [],
  selectedRooms: [],
  selectedPartySizes: {
    smallPartySize: [],
    bigPartySize: [],
    customPartySize: [],
  },
  hasTags: false,
  hasNotes: false,
  selectedFilters: {},
  isFiltersAdded: false,
  reservationFilters: {
    status: [],
    room: [],
    partySize: {},
    tags: false,
    notes: false,
  },
};

// eslint-disable-next-line default-param-last
const filterReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case ActionTypes.SET_FILTER_VIEW:
      return { ...state, filterView: payload };
    case ActionTypes.ADD_TO_FILTER: {
      const { selectedFilters } = state;
      return {
        ...state,
        selectedFilters: { ...selectedFilters, ...payload },
      };
    }
    case ActionTypes.FILTER_BY_RESERVATION_STATUS:
      return {
        ...state,
        selectedStatuses: payload,
      };
    case ActionTypes.FILTER_BY_ROOMS:
      return {
        ...state,
        selectedRooms: payload,
      };
    case ActionTypes.FILTER_BY_PARTY_SIZE: {
      const { selectedPartySizes } = state;
      const { key, value } = payload;
      return {
        ...state,
        selectedPartySizes: { ...selectedPartySizes, [key]: value },
      };
    }
    case ActionTypes.TOGGLE_ALL_PARTY_SIZES: {
      const { selectedPartySizes } = state;
      return {
        ...state,
        selectedPartySizes: { ...selectedPartySizes, ...payload },
      };
    }
    case ActionTypes.FILTER_BY_TAGS: {
      const { selectedFilters } = state;
      return {
        ...state,
        hasTags: payload,
        selectedFilters: { ...selectedFilters, tags: payload },
      };
    }
    case ActionTypes.FILTER_BY_NOTES: {
      const { selectedFilters } = state;
      return {
        ...state,
        hasNotes: payload,
        selectedFilters: { ...selectedFilters, notes: payload },
      };
    }
    case ActionTypes.APPLY_RESERVATION_FILTERS: {
      return { ...state, reservationFilters: payload };
    }
    case ActionTypes.RESET_FILTERS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default filterReducer;
