import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
import { getReservationAvailabilities, getVenueAvailabilitySummary } from 'modules/Footer/actions';

// one place which is used to fetch and update all the dependent data and view's on FOOTER DATE change
// view related to reservation list and waitlist are updated in there respective hooks
const useFooterDateChange = ({ venueId, footerDate }: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let availabilitiesController: any = null;
    let availabilitySummaryController: any = null;

    try {
      batch(() => {
        if (venueId && footerDate) {
          availabilitiesController = dispatch(
            getReservationAvailabilities({ venueId, footerDate, showLoader: true })
          );
          availabilitySummaryController = dispatch(
            getVenueAvailabilitySummary(venueId, footerDate)
          );
        }
      });
    } catch (error) {
      console.error('Error in change footer date from hook [useFooterDateChange]:', error);
    }

    return () => {
      availabilitiesController?.abort();
      availabilitySummaryController?.abort();
    };
  }, [footerDate]);
};

export default useFooterDateChange;
