import { useEffect } from 'react';
import { formatDateToServerDate } from 'utils/date-and-time';
import { batch } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
import {
  getCurrentReservations,
  getFinishedReservations,
  setReservationListLoading,
} from 'modules/ReservationsList/actions';
import { ALL_DAY_AVAILABILITY } from 'modules/ReservationsList/constants';

const useFetchReservationList = ({ venueId, footerDate }: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let finishedController: any = null;
    let currentController: any = null;

    if (venueId && footerDate) {
      const date = formatDateToServerDate(footerDate);
      batch(() => {
        dispatch(setReservationListLoading());
        currentController = dispatch(
          getCurrentReservations({ date, allDay: ALL_DAY_AVAILABILITY, venueId })
        );
        finishedController = dispatch(
          getFinishedReservations({ date, allDay: ALL_DAY_AVAILABILITY, venueId })
        );
      });
    }

    return () => {
      finishedController?.abort();
      currentController?.abort();
    };
  }, [dispatch, footerDate, venueId]);
};

export default useFetchReservationList;
