export const SET_FILTER_VIEW = 'SET_FILTER_VIEW';
export const RESET_FILTERS = 'RESET_FILTERS';
export const ADD_TO_FILTER = 'ADD_TO_FILTER';
export const FILTER_BY_RESERVATION_STATUS = 'FILTER_BY_RESERVATION_STATUS';
export const FILTER_BY_ROOMS = 'FILTER_BY_ROOMS';
export const FILTER_BY_PARTY_SIZE = 'FILTER_BY_PARTY_SIZE';
export const FILTER_BY_TAGS = 'FILTER_BY_TAGS';
export const FILTER_BY_NOTES = 'FILTER_BY_NOTES';
export const TOGGLE_ALL_PARTY_SIZES = 'TOGGLE_ALL_PARTY_SIZES';
export const APPLY_RESERVATION_FILTERS = 'APPLY_RESERVATION_FILTERS';
