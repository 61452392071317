import React from 'react';
import { useAppSelector } from 'hooks/redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ForgotPasswordSchema } from 'modules/Auth/validations';
import { selectIsAuthSubmitting } from '../../selectors';
import { COLORS } from 'constants/colors';
import EmailIcon from 'components/icons/EmailIcon';

interface ForgotPasswordFormProps {
  onSubmit?(...args: unknown[]): unknown;
}

const ForgotPasswordForm = ({ onSubmit }: ForgotPasswordFormProps) => {
  const isAuthSubmitting = useAppSelector(selectIsAuthSubmitting);
  const handleForgotPassword = (values: any, { resetForm }: any) => {
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onSubmit(values, { resetForm });
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={ForgotPasswordSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleForgotPassword}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <h1>Forgot Your Password ?</h1>
          <p>Enter your E-mail address to retrieve your password</p>
          <div className="field">
            <Field
              name="email"
              className={classnames('input', {
                'input__warning-border': Boolean(errors.email && touched.email),
              })}
              placeholder="Email"
              autoFocus
              autoComplete="email"
              readOnly={isSubmitting}
            />
            <span className="login-input-icon">
              <EmailIcon color={COLORS.LIGHT_BLUE} width={24} height={20} />
            </span>
          </div>
          <ErrorMessage component="span" name="email" className="help is-danger login-error" />
          <button disabled={isAuthSubmitting} className="button is-primary" type="submit">
            Reset password
          </button>
        </Form>
      )}
    </Formik>
  );
};
ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default ForgotPasswordForm;
