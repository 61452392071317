import React from 'react';
import { SkeletonLoader } from '@umai/common';
import Panel from './index';
import './Panel.styles.scss';

const PanelLoader = () => (
  <div className="panel-section">
    {[...new Array(4)].map((_, index) => (
      <Panel
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        header={<SkeletonLoader.Skeleton inline width="50%" />}
        className="skeleton"
      >
        <SkeletonLoader.Skeleton width="80%" />
        <SkeletonLoader.Skeleton width="60%" />
        <SkeletonLoader.Skeleton width="40%" />
        <SkeletonLoader.Skeleton width="20%" />
      </Panel>
    ))}
  </div>
);

export default PanelLoader;
