import React from 'react';
import PropTypes from 'prop-types';
import { useAppSelector } from 'hooks/redux';
import { selectIsElementAccessAuthorized } from 'modules/Auth/selectors';

interface ProtectedElementProps {
  children: React.ReactNode;
  allowedPermission: string | unknown[];
}

const ProtectedElement = ({ children, allowedPermission }: ProtectedElementProps) => {
  const isAccessAuthorized = useAppSelector(selectIsElementAccessAuthorized(allowedPermission));

  if (isAccessAuthorized) {
    // NOTE: this is done in order to wrap it as compoenent, otherwise typescript would shout.
    //       TODO: to find a proper fix for it.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
};

ProtectedElement.propTypes = {
  children: PropTypes.node.isRequired,
  allowedPermission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default ProtectedElement;
