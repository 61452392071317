//  For more information about paramter check here: https://www.getbeamer.com/docs/#parameters
export type BeamerConfig = {
  product_id: string;
  button: boolean;
  selector: string;
  language: string;
  multi_user: boolean;
  bounce: boolean;
  filter?: string;
  // ---------------Visitor Information---------------
  user_firstname?: string;
  user_lastname?: string;
  user_email?: string;
  user_id?: number;
  venue_email?: string;
  venue_id?: number;
  venue_name?: string;
};

export const umaiBeamerClassSelector = 'umai-beamer';

export function initBeamer() {
  window.beamer_config = {
    product_id: process.env.REACT_APP_BEAMER_ID, // DO NOT CHANGE
    button: false,
    selector: `.${umaiBeamerClassSelector}`,
    language: 'EN',
    multi_user: true,
    bounce: false,
  };

  const beamer = () => {
    if (window.Beamer?.init) {
      window.Beamer.init();
    }
  };

  window.addEventListener('load', beamer, false);
}

export function updateBeamer(beamerConfig: Partial<BeamerConfig>) {
  if (window.Beamer?.update) {
    window.Beamer.update({ ...window.beamer_config, ...beamerConfig });
  }
}
