import React, { Suspense, lazy } from 'react';
import { SkeletonLoader } from '@umai/common';

const ReservationFunnelModule = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-reservation-funnel" */ 'modules/ReservationFunnel'
    )
);

function ReservationFunnel() {
  return (
    <Suspense fallback={<SkeletonLoader.List />}>
      <ReservationFunnelModule />
    </Suspense>
  );
}

export default ReservationFunnel;
