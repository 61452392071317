import React from 'react';
import { PremiumModal } from '@umai/common';
import './Timeline.styles.scss';

function FreemiumTimeline() {
  return (
    <div className="freemium-timeline" aria-label="freemium timeline view">
      <PremiumModal modalIsOpen hideClose customClasses="freemium-timeline-portal" />
    </div>
  );
}

export default FreemiumTimeline;
