import { CURRENT_LIST_KEY, FINISHED_LIST_KEY } from 'modules/ReservationsList/constants';
import { MESSAGE_EVENTS } from 'constants/socket';
import { formatDateToServerDate, checkIsItemInFooterDate } from 'utils/date-and-time';
import { createCustomEventListener } from 'utils/event-listeners';
import { getPartnerSession } from 'utils/session';
import { getListKeyByReservationStatus } from '../../services/status';

const updateReservationInSlot = ({ listKey, reservationLists, reservation }: any) => {
  const reservationId = reservation.id;
  const currentList = reservationLists[listKey];
  const currentReservation = currentList.reservations?.[reservationId] || {};

  return {
    [listKey]: {
      ...currentList,
      reservations: {
        ...currentList.reservations,
        [reservationId]: { ...currentReservation, ...reservation },
      },
    },
  };
};

const addReservationInSlot = ({ listKey, reservationLists, reservation }: any) => {
  const reservationId = reservation.id;
  const { slotStartTime, slot } = reservation;
  const currentList = reservationLists[listKey];
  const currentSlot = currentList.slots?.[slotStartTime];

  return {
    [listKey]: {
      ...currentList,
      slots: {
        ...currentList.slots,
        [slotStartTime]: {
          ...(currentSlot || { ...slot, id: slotStartTime, startTime: slotStartTime }),
          reservations: currentSlot?.reservations?.length
            ? [...currentSlot.reservations, reservationId]
            : [reservationId],
        },
      },
      reservations: { ...currentList.reservations, [reservationId]: reservation },
    },
  };
};

const deleteEmptySlot = (key: any, reservationLists: any) => {
  const listSlots = reservationLists?.[key]?.slots;
  if (listSlots) {
    Object.keys(listSlots).forEach((slotId) => {
      if (!listSlots[slotId]?.reservations?.length) {
        delete listSlots[slotId];
      }
    });
  }
  return reservationLists;
};

const removeReservationFromSlot = ({
  listKey,
  reservationLists,
  reservationId,
  slotStartTime,
}: any) => {
  const currentList = reservationLists[listKey];
  const currentSlot = currentList.slots?.[slotStartTime] || {};
  delete currentList.reservations[reservationId];
  return deleteEmptySlot(listKey, {
    [listKey]: {
      ...currentList,
      slots: {
        ...currentList.slots,
        [slotStartTime]: {
          ...currentSlot,
          reservations: currentSlot.reservations?.filter((r: any) => r !== reservationId) || [],
        },
      },
      reservations: { ...currentList.reservations },
    },
  });
};

const upsertReservationInList = ({ remove, add, reservationLists }: any) => {
  const removedList = removeReservationFromSlot({ ...remove, reservationLists });
  return {
    ...removedList,
    ...addReservationInSlot({ ...add, reservationLists: { ...reservationLists, ...removedList } }),
  };
};

export const findReservationAndListKey = ({ reservationId, reservationLists }: any) => {
  let reservation = reservationLists[CURRENT_LIST_KEY].reservations?.[reservationId];
  let listKey = CURRENT_LIST_KEY;
  if (reservation) return { reservation, listKey };
  reservation = reservationLists[FINISHED_LIST_KEY].reservations?.[reservationId];
  listKey = FINISHED_LIST_KEY;
  if (reservation) return { reservation, listKey };
  return { reservation: null, listKey: null };
};

export const sortSlotsByDateTime = (a: any, b: any) =>
  // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
  new Date(a.startTime) - new Date(b.startTime);

export const customSocketEventForReservation = (reservation: any, venueId: any) => {
  // create event similar to socket to create/update reservation in listing.. (safe call in-case socket are not working)
  // its primary purpose is to use in Funnel so if a reservation is created/updated, we can fire an event listner for it
  const { currentVenueId } = getPartnerSession();
  // Only fire event when current venue id is same as venueId of reservation
  // Check is required because in funnel it's possible to create reservation for another venue
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (reservation?.id && +venueId === +currentVenueId) {
    const { id, status, slotStartTime } = reservation;
    createCustomEventListener(MESSAGE_EVENTS.reservation, {
      detail: {
        params: {
          venue_id: currentVenueId,
          shift_id: null,
          room_id: null,
          date: formatDateToServerDate(slotStartTime),
          reservation_id: id,
          reservation_status: status,
          reservation,
        },
      },
    });
  }
};

export const reservationListSlice = ({
  footerDate,
  reservationId,
  currentStatus,
  currentReservation,
  reservationLists,
}: any) => {
  // find reservation in the slots if its present in reducer
  const { reservation, listKey } = findReservationAndListKey({ reservationId, reservationLists });
  const currentReservationListKey = getListKeyByReservationStatus(currentStatus);

  // if reservation is present in reducer
  if (reservation?.id) {
    const { slotStartTime } = reservation;
    // remove the reservation from the slots as currentReservation slotStartTime is not in footerDate
    if (!checkIsItemInFooterDate(footerDate, currentReservation.slotStartTime)) {
      return removeReservationFromSlot({ listKey, reservationLists, reservationId, slotStartTime });
    }

    // if reservation list is same as the currentReservation list
    if (listKey === currentReservationListKey) {
      // if the slot start time is same then update the reservation directly
      if (slotStartTime === currentReservation.slotStartTime)
        return updateReservationInSlot({
          listKey,
          reservationLists,
          reservation: currentReservation,
        });

      // if the slot start time is different then remove the reservation from slot and add the currentReservation in the same list
      return upsertReservationInList({
        remove: { listKey, reservationId, slotStartTime },
        add: { listKey, reservation: currentReservation },
        reservationLists,
      });
    }

    // if reservation status are not same then we need remove the reservation from list and
    // and add currentReservation to its list..
    return upsertReservationInList({
      remove: { listKey, reservationId, slotStartTime },
      add: { listKey: currentReservationListKey, reservation: currentReservation },
      reservationLists,
    });
  }
  // reservation is not present in the reducer, so we need to add this currentReservation to the appropirate slot
  if (checkIsItemInFooterDate(footerDate, currentReservation.slotStartTime)) {
    // if the currentReservation is in the footerDate then add it in the slot.
    return addReservationInSlot({
      listKey: currentReservationListKey,
      reservationLists,
      reservation: currentReservation,
    });
  }

  return null;
};
