export const ADD_NOTES = 'ADD_NOTES';
export const CREATE_GUEST_FROM_QUERY = 'CREATE_GUEST_FROM_QUERY';
export const CREATE_RESERVATION = 'CREATE_RESERVATION';
export const GET_ASSIGNABLE_TABLES = 'GET_ASSIGNABLE_TABLES';
export const GET_RESERVATION_SLOTS = 'GET_RESERVATION_SLOTS';
export const GET_FUNNEL_TABLES = 'GET_FUNNEL_TABLES';
export const RESET_RESERVATION_FUNNEL = 'RESET_RESERVATION_FUNNEL';
export const SELECTED_TABLES_TOGGLE = 'SELECTED_TABLES_TOGGLE';
export const SELECT_DATE = 'SELECT_DATE';
export const SELECT_FUNNEL_STEP = 'SELECT_FUNNEL_STEP';
export const SELECT_GUEST = 'SELECT_GUEST';
export const SELECT_PARTY_SIZE = 'SELECT_PARTY_SIZE';
export const SELECT_TIME_SLOT = 'SELECT_TIME_SLOT';
export const SET_TURN_TIME = 'SET_TURN_TIME';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const EDIT_RESERVATION = 'EDIT_RESERVATION';
export const SET_RESERVATION_LOADING = 'SET_RESERVATION_LOADING';
export const SET_NOTIFY_TIME = 'SET_NOTIFY_TIME';
export const SET_TAGS = 'SET_TAGS';
export const START_WALKIN = 'START_WALKIN';
export const START_QUEUE_WALKIN = 'START_QUEUE_WALKIN';
export const CREATE_TEMPORARY_GUEST = 'CREATE_TEMPORARY_GUEST';
export const GOTO_PREVIOUS_STEP = 'GOTO_PREVIOUS_STEP';
export const SET_COMMUNICATION = 'SET_COMMUNICATION';
export const SET_EDITING_GUEST = 'SET_EDITING_GUEST';
export const SELECT_VENUE = 'SELECT_VENUE';
export const START_CALENDAR_LOADING = 'START_CALENDAR_LOADING';
export const GET_CALENDAR_DATA = 'GET_CALENDAR_DATA';
export const SET_SLOTS_LOADING = 'SET_SLOTS_LOADING';
export const SET_TABLES_LOADING = 'SET_TABLES_LOADING';
export const START_QUEUE = 'START_QUEUE';
export const SELECT_QUEUE_GUEST = 'SELECT_QUEUE_GUEST';
export const CREATE_QUEUE_GUEST = 'CREATE_QUEUE_GUEST';
export const CREATE_TEMPORARY_QUEUE_GUEST = 'CREATE_TEMPORARY_QUEUE_GUEST';
export const EDIT_QUEUE_GUEST = 'EDIT_QUEUE_GUEST';
export const SET_SELECTED_TABLE_IDS = 'SET_SELECTED_TABLE_IDS';
export const SET_LOCK_TABLES = 'SET_LOCK_TABLES';
export const SET_RESERVATION_FUNNEL_GUEST = 'SET_RESERVATION_FUNNEL_GUEST';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_MANUAL_PAYMENT = 'SET_MANUAL_PAYMENT';
export const SET_GUESTCRM_RESERVATION = 'SET_GUESTCRM_RESERVATION';
export const SET_FUNNEL_ON_DATE_CHANGE = 'SET_FUNNEL_ON_DATE_CHANGE';
export const GET_TABLES_COMBINATIONS = 'GET_TABLES_COMBINATIONS';
export const SET_CHARGING_FEES = 'SET_CHARGING_FEES';
export const SET_THIRD_PARTY = 'SET_THIRD_PARTY';
export const RESET_THIRD_PARTY = 'RESET_THIRD_PARTY';
export const SET_THIRD_PARTY_BOOKER_STEP = 'SET_THIRD_PARTY_BOOKER_STEP';
export const CREATE_BOOKER_GUEST_FROM_QUERY = 'CREATE_BOOKER_GUEST_FROM_QUERY';
export const SELECT_THIRD_PARTY_GUEST = 'SELECT_THIRD_PARTY_GUEST';
export const SET_PAYMENT_EXPIRE_HOURS = 'SET_PAYMENT_EXPIRE_HOURS';
export const SET_LAST_FETCH_PARAMS = 'SET_LAST_FETCH_PARAMS';
export const RESET_TIME_SLOT_ON_DATE_CHANGE = 'RESET_TIME_SLOT_ON_DATE_CHANGE';
export const SET_QUEUE_OPEN = 'SET_QUEUE_OPEN';
