import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';

class Order extends HttpRequest {
  constructor() {
    super(`${API_ENDPOINT_PARTNER_V2}/orders`);
  }

  getOrder({ venueId, id }: any) {
    return this.fetch(`/${id}`, { venueId });
  }
}

export default new Order();
