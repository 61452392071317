import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface EyeShowProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}

function EyeShow({ width = 20, height = 20, color = COLORS.BLACK }: EyeShowProps) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Show password</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.995 12.917a2.917 2.917 0 100-5.834 2.917 2.917 0 000 5.834zm0-1.672a1.245 1.245 0 110-2.49 1.245 1.245 0 010 2.49z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.167c-4.01 0-6.736 2.174-8.176 3.733a3.065 3.065 0 000 4.2c1.44 1.56 4.167 3.733 8.176 3.733 4.01 0 6.736-2.174 8.176-3.733a3.066 3.066 0 000-4.2C16.736 6.34 14.009 4.167 10 4.167zM3.049 9.03C4.324 7.65 6.639 5.833 10 5.833c3.36 0 5.676 1.817 6.951 3.197a1.4 1.4 0 010 1.94c-1.275 1.38-3.59 3.197-6.951 3.197-3.36 0-5.676-1.817-6.951-3.197a1.4 1.4 0 010-1.94z"
        fill={color}
      />
    </svg>
  );
}

EyeShow.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default EyeShow;
