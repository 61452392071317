import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import { getVenueLoyaltyTiers } from 'modules/Partner/actions';
import useSocketMasterGuest from './hooks/useSocketMasterGuest';
import useSocketGuestTags from './hooks/useSocketGuestTags';

const BootstrapGuests = () => {
  const dispatch = useAppDispatch();
  const venueId = useAppSelector(selectCurrentVenueId);
  useSocketGuestTags({ venueId });
  useSocketMasterGuest({ venueId });

  useEffect(() => {
    try {
      if (venueId) {
        dispatch(getVenueLoyaltyTiers(venueId));
      }
    } catch (error) {
      console.error('Error on getting venue loyalty tiers:', error);
    }
  }, [venueId]);

  return null;
};

export default BootstrapGuests;
