import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './ParticleEffect.module.scss';

interface ParticleEffectProps {
  children?: React.ReactNode;
  className?: string;
  effect?: string;
}

const ParticleEffect = ({ children, className, effect = 'confetti' }: ParticleEffectProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [particles, setParticles] = useState(null);
  const particleRef = useRef();

  const getRandomNumber = (min: any, max: any) => Math.floor(Math.random() * (max - min + 1)) + min;

  const fire = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const fireCount = (particleRef.current.offsetWidth / 50) * 10;
    const fireParticles = [];

    for (let i = 0; i <= fireCount; i++) {
      const size = getRandomNumber(6, 10);
      fireParticles.push(
        <span
          key={`fireCount-${fireCount}`}
          className={classNames(styles.particle, 'particle')}
          style={{
            top: `${getRandomNumber(50, 80)}%`,
            left: `${getRandomNumber(10, 90)}%`,
            width: `${size}px`,
            height: `${size}px`,
            animationDelay: `${getRandomNumber(0, 20) / 10}s`,
            pointerEvents: 'none',
          }}
        />
      );
    }
    // @ts-expect-error TS(2345): Argument of type 'Element[]' is not assignable to ... Remove this comment to see the full error message
    setParticles(fireParticles);
  };

  const bubble = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const bubbleCount = Math.floor((particleRef.current.offsetWidth / 50) * 10);
    const bubbleParticles = [];

    for (let i = 0; i <= bubbleCount; i++) {
      const size = getRandomNumber(4, 8);
      const particle = (
        <span
          key={`bubble-${i}`}
          className={classNames(styles.particle, 'particle')}
          style={{
            top: `${getRandomNumber(20, 80)}%`,
            left: `${getRandomNumber(0, 95)}%`,
            width: `${size}px`,
            height: `${size}px`,
            animationDelay: `${getRandomNumber(0, 30) / 10}s`,
            pointerEvents: 'none',
          }}
        ></span>
      );
      bubbleParticles.push(particle);
    }
    // @ts-expect-error TS(2345): Argument of type 'Element[]' is not assignable to ... Remove this comment to see the full error message
    setParticles(bubbleParticles);
  };

  const heart = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const heartCount = Math.floor((particleRef.current.offsetWidth / 50) * 2);
    const heartParticles = [];

    for (let i = 0; i <= heartCount; i++) {
      const size = getRandomNumber(6, 12);
      heartParticles.push(
        <span
          key={`heart-${i}`}
          className={classNames(styles.particle, 'particle')}
          style={{
            top: `${getRandomNumber(20, 80)}%`,
            left: `${getRandomNumber(0, 95)}%`,
            width: `${size}px`,
            height: `${size}px`,
            animationDelay: `${getRandomNumber(0, 30) / 10}s`,
            pointerEvents: 'none',
          }}
        />
      );
    }
    // @ts-expect-error TS(2345): Argument of type 'Element[]' is not assignable to ... Remove this comment to see the full error message
    setParticles(heartParticles);
  };

  const confetti = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const confettiCount = Math.floor((particleRef.current.offsetWidth / 50) * 10);
    const confettiParticles = [];
    for (let i = 0; i <= confettiCount; i++) {
      const confettiColor = getRandomNumber(1, 5);
      const colors = {};

      [1, 2, 3, 4, 5].forEach((index) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        colors[styles[`c${index}`]] = confettiColor === index;
      });

      const particle = (
        <span
          key={`confetti-${i}`}
          className={classNames(styles.particle, `particle`, colors)}
          style={{
            top: `${getRandomNumber(10, 50)}%`,
            left: `${getRandomNumber(0, 100)}%`,
            width: `${getRandomNumber(6, 8)}px`,
            height: `${getRandomNumber(3, 4)}px`,
            animationDelay: `${getRandomNumber(0, 20) / 10}s`,
            pointerEvents: 'none',
          }}
        ></span>
      );

      confettiParticles.push(particle);
    }
    // @ts-expect-error TS(2345): Argument of type 'Element[]' is not assignable to ... Remove this comment to see the full error message
    setParticles(confettiParticles);
  };

  const line = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const lineCount = Math.floor((particleRef.current.offsetWidth / 50) * 10);
    const lineParticles = [];

    for (let i = 0; i <= lineCount; i++) {
      const particle = (
        <span
          key={`line-${i}`}
          className={classNames(styles.particle, 'particle')}
          style={{
            top: `${getRandomNumber(-30, 30)}%`,
            left: `${getRandomNumber(-10, 110)}%`,
            width: `${getRandomNumber(1, 3)}px`,
            height: `${getRandomNumber(20, 80)}%`,
            animationDelay: `-${getRandomNumber(0, 30) / 10}s`,
            pointerEvents: 'none',
          }}
        ></span>
      );

      lineParticles.push(particle);
    }
    // @ts-expect-error TS(2345): Argument of type 'Element[]' is not assignable to ... Remove this comment to see the full error message
    setParticles(lineParticles);
  };

  const sunbeam = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const sunBeamCount = Math.floor((particleRef.current.offsetWidth / 50) * 7);
    const sunBeamParticles = [];
    for (let i = 0; i <= sunBeamCount; i++) {
      const particle = (
        <span
          key={`sunbeam-${i}`}
          className={classNames(styles.particle, 'particle')}
          style={{
            top: `${getRandomNumber(-50, 0)}%`,
            left: `${getRandomNumber(0, 100)}%`,
            width: `${getRandomNumber(1, 3)}px`,
            height: `${getRandomNumber(80, 160)}%`,
            animationDelay: `-${getRandomNumber(0, 30) / 10}s`,
            pointerEvents: 'none',
          }}
        ></span>
      );

      sunBeamParticles.push(particle);
    }
    // @ts-expect-error TS(2345): Argument of type 'Element[]' is not assignable to ... Remove this comment to see the full error message
    setParticles(sunBeamParticles);
  };

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
  };

  useEffect(() => {
    switch (effect) {
      case 'fire':
        fire();
        break;
      case 'bubble':
        bubble();
        break;
      case 'heart':
        heart();
        break;
      case 'confetti':
        confetti();
        break;
      case 'line':
        line();
        break;
      case 'sunbeam':
        sunbeam();
        break;
      default:
        fire();
    }
  }, []);

  return (
    <span
      // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
      ref={particleRef}
      className={classNames(
        styles.particlesWrapper,
        {
          [styles.fire]: effect === 'fire',
          [styles.bubble]: effect === 'bubble',
          [styles.heart]: effect === 'heart',
          [styles.confetti]: effect === 'confetti',
          [styles.line]: effect === 'line',
          [styles.sunbeam]: effect === 'sunbeam',
        },
        className
      )}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onFocus={handleMouseOver}
      onBlur={handleMouseOut}
      onTouchStart={handleMouseOver}
      onTouchEnd={handleMouseOut}
    >
      {children}
      {isMouseOver ? particles : null}
    </span>
  );
};

ParticleEffect.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  effect: PropTypes.string,
};

export default ParticleEffect;
