import React from 'react';
import { SkeletonLoader } from '@umai/common';

function Loader() {
  return (
    <div className="guest-basic-info-component skeleton">
      <SkeletonLoader.Skeleton count={1} height={100} width={100} circle />
      <div className="skeleton-bar">
        <SkeletonLoader.Skeleton width={'60%'} />
        <SkeletonLoader.Skeleton width={'45%'} />
        <SkeletonLoader.Skeleton width={'25%'} />
      </div>
    </div>
  );
}

export default Loader;
