/* eslint-disable react/jsx-props-no-spreading  */
import React from 'react';
import RcDrawer from 'rc-drawer';
import PropTypes from 'prop-types';
import { IDrawerProps } from 'rc-drawer/lib/IDrawerPropTypes';

interface DrawerProps extends IDrawerProps {
  open?: boolean;
  width?: string;
  onClose?(...args: unknown[]): unknown;
  showMask?: boolean;
  wrapperClassName?: string;
  duration?: string;
  children?: React.ReactNode;
}

const Drawer = ({
  children,
  open,
  width = '60vh',
  placement = 'right',
  onClose,
  handler = false,
  level = '',
  showMask = false,
  wrapperClassName = '',
  duration = '.4s',
  ...props
}: DrawerProps) => (
  <RcDrawer
    open={open}
    onClose={onClose}
    width={width}
    placement={placement}
    handler={handler}
    level={level}
    duration={duration}
    showMask={showMask}
    wrapperClassName={wrapperClassName}
    {...props}
  >
    {children}
  </RcDrawer>
);

Drawer.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.string,
  placement: PropTypes.string,
  onClose: PropTypes.func,
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  handler: PropTypes.node,
  showMask: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  duration: PropTypes.string,
  children: PropTypes.node,
};

export default Drawer;
