import React from 'react';
import PropTypes from 'prop-types';
import { ORDER_NOTIFICATION_AUDIO_ID } from 'modules/Order/constants';

interface NotificationAudioProps {
  src?: string;
  id?: string;
}

const NotificationAudio = ({ id = ORDER_NOTIFICATION_AUDIO_ID, src }: NotificationAudioProps) => (
  // we need to add a audio element and mark it muted on initial state as required by new policy by browsers
  // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
  // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  // Solution: change UX to perform action on web to enable sound for orders etc...
  <audio id={id} src={src} style={{ display: 'none' }} autoPlay muted />
);

NotificationAudio.propTypes = {
  src: PropTypes.string,
  id: PropTypes.string,
};

export default NotificationAudio;
