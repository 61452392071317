import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { isMobileDevice } from '@umai/common';
import { ROUTES } from 'constants/routes';
import { selectIsRouteAccessAuthorized, selectIsAuthenticated } from 'modules/Auth/selectors';
import { selectProductSubscription } from 'modules/Partner/selectors';
import { FEATURES, FeatureType, isProductFeatureAvailable } from 'services/subscription';
import AuthorizationMessage from 'pages/Authorization';
import Subscription from 'pages/Subscription';

interface PrivateRouteProps {
  children: React.ReactNode;
  path: string;
  feature?: FeatureType;
  allowedPermission?: string | unknown[];
  isMobileOnly?: boolean;
}

const PrivateRoute = ({
  children,
  path,
  feature,
  allowedPermission,
  isMobileOnly = false,
  ...restOfProps
}: PrivateRouteProps) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAccessAuthorized = useAppSelector(selectIsRouteAccessAuthorized(allowedPermission, path));
  const productSubscriptions = useAppSelector(selectProductSubscription);

  if (isAuthenticated) {
    if (isAccessAuthorized) {
      if (isMobileOnly && !isMobileDevice) {
        return <Redirect to={ROUTES.HOME} />;
      }

      // Home route is just used for redirection
      if (!isProductFeatureAvailable(feature, productSubscriptions) && path !== ROUTES.HOME) {
        // we need components rather routes because its also used in timeline listing to show
        return <Subscription />;
      }

      return <Route path={path}>{children}</Route>;
    }
    return <AuthorizationMessage />;
  }

  return (
    <Redirect
      to={{
        pathname: ROUTES.LOGIN,
        // @ts-expect-error TS(2339): Property 'location' does not exist on type '{}'.
        state: { from: restOfProps.location },
      }}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  feature: PropTypes.oneOf(Object.keys(FEATURES)),
  allowedPermission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default PrivateRoute;
