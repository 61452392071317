import React from 'react';
import PropTypes from 'prop-types';
import { useViewPortContext } from '@umai/common';
import MobileLayout from '../MobileLayout';
import TabletLayout from '../TabletLayout';

interface LayoutProps {
  className?: string;
  mobileHeader?: React.ReactNode;
  mobileFooter?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  customBodyClasses?: string;
  hasSidebar?: boolean;
  onSwipedLeft?(...args: unknown[]): unknown;
  onSwipedRight?(...args: unknown[]): unknown;
  onSwipedUp?(...args: unknown[]): unknown;
  onSwipedDown?(...args: unknown[]): unknown;
}

const Layout = ({
  mobileHeader,
  mobileFooter,
  header,
  footer,
  children,
  className,
  customBodyClasses,
  hasSidebar = true,
  onSwipedLeft = () => {},
  onSwipedRight = () => {},
  onSwipedUp = () => {},
  onSwipedDown = () => {},
}: LayoutProps) => {
  const { mobile } = useViewPortContext();

  if (mobile) {
    return (
      <MobileLayout
        className={className}
        header={mobileHeader}
        footer={mobileFooter}
        customBodyClasses={customBodyClasses}
        onSwipedLeft={onSwipedLeft}
        onSwipedRight={onSwipedRight}
        onSwipedUp={onSwipedUp}
        onSwipedDown={onSwipedDown}
      >
        {children}
      </MobileLayout>
    );
  }

  return (
    <TabletLayout
      className={className}
      header={header}
      footer={footer}
      customBodyClasses={customBodyClasses}
      hasSidebar={hasSidebar}
      onSwipedLeft={onSwipedLeft}
      onSwipedRight={onSwipedRight}
      onSwipedUp={onSwipedUp}
      onSwipedDown={onSwipedDown}
    >
      {children}
    </TabletLayout>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  mobileHeader: PropTypes.node,
  mobileFooter: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
  customBodyClasses: PropTypes.string,
  hasSidebar: PropTypes.bool,
  onSwipedLeft: PropTypes.func,
  onSwipedRight: PropTypes.func,
  onSwipedUp: PropTypes.func,
  onSwipedDown: PropTypes.func,
};

export default Layout;
