/* eslint-disable no-console  */
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import humps from 'humps';
import { MESSAGE_EVENTS } from 'constants/socket';
import { updateCalendarData } from 'modules/Footer/actions';

const useSocketCalendar = ({ venueId }: any) => {
  const dispatch = useAppDispatch();
  const calendarUpdate = ({ detail = {} }) => {
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      const calendarData = detail?.params || {};
      console.debug('CALENDAR: socket update: ', calendarData);
      if (calendarData) {
        dispatch(updateCalendarData(humps.camelizeKeys(calendarData)));
      }
    } catch (error) {
      console.error('Error in update calendar from socket', error);
    }
  };

  useEffect(() => {
    // @ts-expect-error TS(2769): No overload matches this call.
    document.addEventListener(MESSAGE_EVENTS.calendar, calendarUpdate, false);
    return () => {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(MESSAGE_EVENTS.calendar, calendarUpdate, false);
    };
  }, [venueId]);
};

export default useSocketCalendar;
