import * as ActionTypes from './action-types';

const INITIAL_STATE = {
  isItemDragging: false,
  isListOpen: false,
  width: '0px',
  previousWidth: '0px',
  window: {},
  roomsShowNested: {
    noTablesRoom: false,
    waitlistRoom: false,
  },
};

// eslint-disable-next-line default-param-last
const timelineReducer = (state = INITIAL_STATE, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_ITEM_DRAGGING:
      return { ...state, isItemDragging: payload };
    case ActionTypes.SET_LIST_WIDTH: {
      return { ...state, previousWidth: state.width, width: payload };
    }
    case ActionTypes.SET_RANGE: {
      return { ...state, window: payload };
    }
    case ActionTypes.SET_ROOMS_SHOW_NESTED: {
      return { ...state, roomsShowNested: { ...state.roomsShowNested, ...payload } };
    }
    default:
      return state;
  }
};

export default timelineReducer;
