import React from 'react';
import { SkeletonLoader } from '@umai/common';
import GuestViewLoader from 'modules/Guest/components/Loader';
import '../../GuestsCrm.styles.scss';

function GuestCrmListViewLoader() {
  return (
    <div className="details-view-wrapper">
      <header id="guest-details-header">
        <div className="left-buttons">
          <SkeletonLoader.Blocks
            numBlocks={1}
            lines={['90%']}
            // @ts-expect-error TS(2322): Type '{ width: string; height: string; margin: str... Remove this comment to see the full error message
            blockStyles={{ width: '80px', height: '40px', margin: '0px 5px' }}
          />
        </div>
        <div className="right-buttons">
          <SkeletonLoader.Blocks
            numBlocks={1}
            lines={['90%']}
            // @ts-expect-error TS(2322): Type '{ width: string; height: string; margin: str... Remove this comment to see the full error message
            blockStyles={{ width: '80px', height: '40px', margin: '0px' }}
          />
        </div>
      </header>
      <div className="guest-list-view-wrapper">
        <GuestViewLoader />
      </div>
    </div>
  );
}

export default GuestCrmListViewLoader;
