import moment from 'moment-timezone';
import { QUEUE_DATE_FORMAT } from 'constants/time-and-date';
import { checkStringMatch } from 'utils/string-helpers';
import { getReservationGuest, getReservationGuestName } from 'modules/ReservationsList/services';
import { CANCELLED_QUEUE, REJECTED } from 'modules/QueueList/constants';
import { formatDateToServerDate } from 'utils/date-and-time';

export const getQueueCount = (queueReservation = {}) => Object.keys(queueReservation).length;

export const getQueueGuestCount = (queueReservation = {}) =>
  // @ts-expect-error TS(2769): No overload matches this call.
  Object.values(queueReservation).reduce((total, { guestCount }) => total + guestCount, 0);

export const formatQueueDate = (date: any) => moment(date).format(QUEUE_DATE_FORMAT);

// index key is added to the queue items to track it's position in the queue.
// the index is maintained regardless of new position after filteration
const addQueueIndex = (queueItems: any) =>
  // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
  Object.values(queueItems).map((queueItem, index) => ({ ...queueItem, index }));

export const applyQueueFilter = (queueItems = {}, searchText = '') => {
  const filteredItem = addQueueIndex(queueItems).filter((queueItem) => {
    const { firstName, lastName, email, phone, temporaryGuest } = queueItem;
    const tempGuest = temporaryGuest ? [firstName, lastName, email, phone] : [];
    const isStringMatched = checkStringMatch(
      [
        ...Object.values(getReservationGuest(queueItem)),
        getReservationGuestName(queueItem),
        ...tempGuest,
      ],
      searchText
    );
    return isStringMatched;
  });

  return { ...filteredItem };
};

// A queue is removed if the status is either rejected or canelled
export const checkIsQueueRemoved = (status: any) =>
  status === REJECTED || status === CANCELLED_QUEUE;

export const getQueueCacheKey = ({ venueId, date }: any) =>
  `${venueId}-${formatDateToServerDate(date)}`;
