/* eslint-disable no-console */
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { getRoomTables } from 'modules/Room/actions';
import { MESSAGE_EVENTS } from 'constants/socket';

const useSocketTables = ({ venueId }: any) => {
  const dispatch = useAppDispatch();
  let tablesController: any = null;

  const tablesUpdate = ({ detail = {} }) => {
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      if (detail?.params) {
        // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
        console.debug('TABLES LIST: socket update: ', detail.params, venueId);
        console.log('fetching tables from sockets');
        if (venueId) {
          // Abort the action before firing the tables api again with slow internet,
          // used to abort the call when we block / unblock a table on timeline
          tablesController?.abort();
          tablesController = dispatch(getRoomTables({ venueId }));
        }
      }
    } catch (error) {
      console.error('Error in update tables list from socket', error);
    }
  };

  useEffect(() => {
    if (venueId) {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.addEventListener(MESSAGE_EVENTS.tables, tablesUpdate, false);
    }
    return () => {
      tablesController?.abort();
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(MESSAGE_EVENTS.tables, tablesUpdate, false);
    };
  }, [venueId]);
};

export default useSocketTables;
