import React, { Suspense, lazy } from 'react';
import { SkeletonLoader } from '@umai/common';
import MobileLayout from 'layouts/MobileLayout';

const MobileFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-mobile-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/MobileFooter'
    )
);

const NotificationCenter = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "umai-notification-center-mobile" */ 'modules/NotificationCenter'
    )
);

const NotificationsMobile = () => (
  <MobileLayout footer={<MobileFooter hideMobileFilters />} className="notification-center-mobile">
    <Suspense fallback={<SkeletonLoader.List circle />}>
      <NotificationCenter />
    </Suspense>
  </MobileLayout>
);

export default NotificationsMobile;
