import Cookies from 'js-cookie';
import { ENVIRONMENT } from 'constants/app';
import {
  getDataFromSessionStorage,
  removeDataFromSessionStorage,
  setDataToSessionStorage,
} from '@umai/common';
import { VenueId } from 'modules/Venue/types';

const DEVICE_IDENTIFIER = 'device-identifier';
const COOKIE_EXPIRY_DAYS = 365;
const WEBRMS_ACCESS_TOKEN = 'webrms_accessToken';
const WEBRMS_CURRENT_VENUE_ID = 'webrms_currentVenueId';
const WEBRMS_CURRENT_VENUE_NAME = 'webrms_currentVenueName';
const WEBRMS_LOGIN_EMAIL = 'webrms_email';
const INTERCOM_SESSION = `intercom-session-${process.env.REACT_APP_INTERCOM_APP_ID}`;
const DOMAIN_NAME = '.letsumai.com';

export type PartnerSession = {
  accessToken: string;
  currentVenueId: VenueId;
  currentVenueName: string;
  email: string;
  deviceId: string;
};

export const savePartnerSession = ({
  accessToken,
  currentVenueId,
  currentVenueName,
  email,
  deviceId,
}: Partial<PartnerSession>) => {
  if (accessToken)
    Cookies.set(WEBRMS_ACCESS_TOKEN, accessToken, {
      expires: COOKIE_EXPIRY_DAYS,
    });
  if (currentVenueId)
    Cookies.set(WEBRMS_CURRENT_VENUE_ID, `${currentVenueId}`, {
      expires: COOKIE_EXPIRY_DAYS,
    });
  if (currentVenueName)
    Cookies.set(WEBRMS_CURRENT_VENUE_NAME, currentVenueName, {
      expires: COOKIE_EXPIRY_DAYS,
    });
  if (email)
    Cookies.set(WEBRMS_LOGIN_EMAIL, email, {
      expires: COOKIE_EXPIRY_DAYS,
    });

  if (deviceId) setDataToSessionStorage(DEVICE_IDENTIFIER, deviceId);
};

export const getPartnerSession = () => {
  const accessToken = Cookies.get(WEBRMS_ACCESS_TOKEN);
  const currentVenueId = Cookies.get(WEBRMS_CURRENT_VENUE_ID);
  const currentVenueName = Cookies.get(WEBRMS_CURRENT_VENUE_NAME);
  const email = Cookies.get(WEBRMS_LOGIN_EMAIL);
  const deviceId = getDataFromSessionStorage(DEVICE_IDENTIFIER);
  return {
    accessToken,
    currentVenueId: currentVenueId ? +currentVenueId : undefined,
    currentVenueName,
    email,
    deviceId,
  };
};

export const removePartnerSession = ({ all = true } = {}) => {
  if (all) {
    Cookies.remove(WEBRMS_ACCESS_TOKEN);
    Cookies.remove(WEBRMS_CURRENT_VENUE_ID);
    Cookies.remove(WEBRMS_CURRENT_VENUE_NAME);
    Cookies.remove(WEBRMS_LOGIN_EMAIL);
    Cookies.remove(INTERCOM_SESSION, { domain: DOMAIN_NAME });
    removeDataFromSessionStorage(DEVICE_IDENTIFIER);
  } else {
    // by default remove only token during logout
    Cookies.remove(WEBRMS_ACCESS_TOKEN);
  }
};

export const removeAllCookies = () => {
  const allCookies = Cookies.get();
  Object.keys(allCookies).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });

  // eslint-disable-next-line no-console
  console.info('All cookies removed');
};

export const isProductionEnv = () => process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.PRODUCTION;
