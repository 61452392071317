import { STATUSES, ATTENTION_STATUS, FINISHED_STATUSES } from 'modules/ReservationsList/constants';

export const FILTER_VIEW = {
  OVERVIEW: 'Overview',
  RESERVATION_STATUS: 'ReservationStatus',
  RESERVATION_AVAILABILITY: 'ReservationAvailability',
  ROOMS: 'Rooms',
  PARTY_SIZE: 'PartySize',
  CUSTOM_PARTY_SIZE: 'CustomPartySize',
};
export const RESERVATION_QUICK_FILTERS = [STATUSES.RESERVED, STATUSES.CONFIRMED, STATUSES.SEATED];

export const FINISHED_QUICK_FILTERS = [STATUSES.FINISH, STATUSES.CANCELLED, STATUSES['NO-SHOW']];

export const RESERVATION_QUICK_FILTERS_TABLET = [
  ...RESERVATION_QUICK_FILTERS,
  STATUSES.LATE,
  STATUSES.AWAITING_PAYMENT,
  ATTENTION_STATUS,
];
export const FINISHED_QUICK_FILTERS_TABLET = [...FINISHED_STATUSES];

export const SMALL_PARTY_FILTER = [1, 2, 3, 4, 5];
export const BIG_PARTY_FILTER = [6, 7, 8, 9, '10+'];
export const PARTY_SIZE_FILTER = [...SMALL_PARTY_FILTER, ...BIG_PARTY_FILTER];
export const CUSTOM_PARTY_SIZE = 'customPartySize';
