import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TABLET_APP_WRAPPER } from 'constants/app';
import SwipeableWrapper from 'components/ui/SwipeableWrapper';
import SidebarLoader from '../Sidebar/Loader';
import TabletFooterLoader from 'modules/Footer/components/TabletFooter/Loader';
import './TabletLayout.styles.scss';

const Sidebar = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-sidebar" */
      /* webpackFetchPriority: "high" */
      '../Sidebar'
    )
);

interface TabletLayoutProps {
  className?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
  customBodyClasses?: string;
  hasSidebar?: boolean;
  onSwipedLeft?(...args: unknown[]): unknown;
  onSwipedRight?(...args: unknown[]): unknown;
  onSwipedUp?(...args: unknown[]): unknown;
  onSwipedDown?(...args: unknown[]): unknown;
}

const TabletLayout = ({
  header,
  children,
  footer,
  className,
  customBodyClasses = '',
  hasSidebar = true,
  onSwipedLeft = () => {},
  onSwipedRight = () => {},
  onSwipedUp = () => {},
  onSwipedDown = () => {},
}: TabletLayoutProps) => (
  <main id="tablet-and-desktop-layout-wrapper" className={TABLET_APP_WRAPPER}>
    {hasSidebar && (
      <Suspense fallback={<SidebarLoader />}>
        <Sidebar />
      </Suspense>
    )}
    <section id="main-content" className={className}>
      {header}
      <SwipeableWrapper
        onSwipedLeft={onSwipedLeft}
        onSwipedRight={onSwipedRight}
        onSwipedUp={onSwipedUp}
        onSwipedDown={onSwipedDown}
        className={classnames('body', customBodyClasses)}
      >
        {children}
      </SwipeableWrapper>
      <Suspense fallback={<TabletFooterLoader />}>{footer}</Suspense>
    </section>
  </main>
);

TabletLayout.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  customBodyClasses: PropTypes.string,
  hasSidebar: PropTypes.bool,
  onSwipedLeft: PropTypes.func,
  onSwipedRight: PropTypes.func,
  onSwipedUp: PropTypes.func,
  onSwipedDown: PropTypes.func,
};

export default TabletLayout;
