// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from 'redux-store';

// Typed versions of the Redux hooks

// Use instead of the `useAppDispatch`
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Use instead of the `useSelector`
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
