/* eslint-disable no-console */
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { MESSAGE_EVENTS } from 'constants/socket';
import { selectIsQueueSubscribed } from 'modules/Partner/selectors';
import { updateQueueFromSocket } from 'modules/QueueList/actions';
import { checkIsItemInFooterDate, formatDateToServerDateTime } from 'utils/date-and-time';
import queueApis from 'modules/QueueList/apis';

const useSocketQueueList = ({ venueId, footerDate }: any) => {
  const isQueueSubscribed = useAppSelector(selectIsQueueSubscribed);
  const dispatch = useAppDispatch();

  const queueUpdate = async ({ detail = {} }) => {
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      if (detail?.params) {
        // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
        const { params } = detail;
        console.debug('QUEUELIST: socket update: ', params);

        // only update queue item in the current footerdate
        if (
          params?.id &&
          checkIsItemInFooterDate(footerDate, formatDateToServerDateTime(params?.date_time_start))
        ) {
          let queueItem = params?.queueItem;

          // for custom event (customSocketEventForQueue) the queueItem is passed as param
          // this is to avoid hitting queueApis.getQueueItem API on queueitem creation or update
          if (!queueItem?.id) {
            queueItem = await queueApis.getQueueItem({ id: params.id, venueId });
          }
          dispatch(
            updateQueueFromSocket({
              footerDate,
              queueId: params.id,
              currentQueueItem: queueItem,
              reservationId: params.reservation_id,
            })
          );
        }
      }
    } catch (error) {
      console.error('Error in update queueList from socket', error);
    }
  };

  useEffect(() => {
    if (venueId && isQueueSubscribed) {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.addEventListener(MESSAGE_EVENTS.queueList, queueUpdate, false);
    }
    return () => {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(MESSAGE_EVENTS.queueList, queueUpdate, false);
    };
  }, [venueId, footerDate, isQueueSubscribed]);
};

export default useSocketQueueList;
