import { difference } from 'lodash-es';
import { formatTimeSlotTime } from 'utils/date-and-time';
import {
  getSelectedTableCombination,
  getTableSizes,
  getSelectedTableNames,
  getBlockedOrLockedTablesNames,
  isMultipleTables,
} from './services';

export const validateTableSelection = ({
  mobile,
  selectedTables,
  partySize,
  selectedTableIds,
  isWalkin,
  blockedTables,
  occupiedTables,
  upcomingTables,
  isEdit,
  selectedTableIdsPreEdit,
  upcomingTablesTime,
  tableCombinations,
  isFreemium,
  isTablesPresent,
}: any) => {
  const selectedCombination = getSelectedTableCombination(tableCombinations, selectedTableIds);
  const { minTableSize, maxTableSize } = getTableSizes(selectedTables, selectedCombination);

  const occupiedTablesNames = getSelectedTableNames(
    occupiedTables,
    // When editing a reservation we exclude the selected tables from original reservation
    // as we don't want to show the error when the tables of the original reservation are selected
    isEdit ? difference(selectedTableIdsPreEdit, selectedTableIds) : selectedTableIds
  );
  const upcomingTablesNames = getSelectedTableNames(upcomingTables, selectedTableIds);
  const blockedTablesNames = blockedTables
    .filter((table: any) => table?.length > 0)
    .map((table: any) => getBlockedOrLockedTablesNames(table));

  const confirmLabel = () => {
    if (isEdit) return 'Confirm edit';
    if (isWalkin) return 'Confirm walk-in';
    return 'Confirm reservation';
  };

  // Take the upcoming time of the first selected upcoming table
  const firstUpcomingTable = upcomingTablesTime.find((el: any) => el && el.length);

  // The order of the if statement is very important
  if (!selectedTables.length) {
    return {
      validationErrorMessage: 'No table has been selected',
      buttonErrorMessage: `${mobile ? 'Continue' : confirmLabel()} without table`,
      isButtonDisabled: false,
    };
  }
  if (blockedTablesNames.length) {
    return {
      validationErrorMessage: `Table${
        isMultipleTables(blockedTablesNames) ? 's' : ''
      } '${blockedTablesNames}' ${
        isMultipleTables(blockedTablesNames) ? 'are' : 'is'
      } blocked, unblock it to continue`,
      buttonErrorMessage: 'Confirm table selection',
      isButtonDisabled: true,
    };
  }
  if (occupiedTablesNames.length) {
    return {
      validationErrorMessage: `Table${
        isMultipleTables(occupiedTables) ? 's' : ''
      } '${occupiedTablesNames}' ${isMultipleTables(occupiedTables) ? 'are' : 'is'} occupied`,
      buttonErrorMessage: `Confirm table${isMultipleTables(occupiedTables) ? 's' : ''} selection`,
      isButtonDisabled: false,
    };
  }
  if (upcomingTablesNames.length) {
    return {
      validationErrorMessage: `Table${
        isMultipleTables(upcomingTables) ? 's' : ''
      } '${upcomingTablesNames}' ${
        isMultipleTables(upcomingTables) ? 'have' : 'has'
      } a reservation coming up at ${formatTimeSlotTime(firstUpcomingTable[0]?.slotStartTime)}`,
      buttonErrorMessage: `Confirm table${isMultipleTables(upcomingTables) ? 's' : ''} selection`,
      isButtonDisabled: false,
    };
  }
  if (partySize > maxTableSize && isTablesPresent && !isFreemium) {
    return {
      validationErrorMessage: `The selected table${isMultipleTables(selectedTables) ? 's' : ''} ${
        isMultipleTables(selectedTables) ? 'are' : 'is'
      } too small`,
      buttonErrorMessage: `Continue with small table${isMultipleTables(selectedTables) ? 's' : ''}`,
      isButtonDisabled: false,
    };
  }
  if (partySize < minTableSize && isTablesPresent && !isFreemium) {
    return {
      validationErrorMessage: `The selected table${isMultipleTables(selectedTables) ? 's' : ''} ${
        isMultipleTables(selectedTables) ? 'are' : 'is'
      } too large`,
      buttonErrorMessage: `Continue with large table${isMultipleTables(selectedTables) ? 's' : ''}`,
      isButtonDisabled: false,
    };
  }
  return {
    validationErrorMessage: '',
    buttonErrorMessage: 'Confirm table selection',
    isButtonDisabled: false,
  };
};
