export const createEventListener = (eventName: string) => {
  // Create the event using the Event constructor.
  const event = new Event(eventName, { bubbles: true, cancelable: true });

  // Dispatch the event.
  if (document?.dispatchEvent) document.dispatchEvent(event);
};

// NOTE: CustomEvent can only pass data inside the detail object.. cannot pass anything outside
export const createCustomEventListener = (
  eventName: string,
  params: {
    bubbles?: boolean;
    cancelable?: boolean;
    detail?: unknown;
  } = { bubbles: false, cancelable: false, detail: null }
) => {
  if (document?.dispatchEvent) document.dispatchEvent(new CustomEvent(eventName, params));
};

export const onVisibilityChange = (callback: (visible: boolean) => void) => {
  let visible = true;

  if (!callback) {
    console.error('no callback given');
  }

  function focused() {
    if (!visible) {
      callback((visible = true));
    }
  }

  function unfocused() {
    if (visible) {
      callback((visible = false));
    }
  }

  if ('hidden' in document) {
    document.addEventListener(
      'visibilitychange',
      () => {
        (document.visibilityState === 'hidden' ? unfocused : focused)();
      },
      false
    );
  } else if ('onfocusin' in document) {
    // @ts-expect-error TS(2551): Property 'onfocusin' does not exist on type 'Docum... Remove this comment to see the full error message
    document.onfocusin = focused;
    // @ts-expect-error TS(2551): Property 'onfocusout' does not exist on type 'Docu... Remove this comment to see the full error message
    document.onfocusout = unfocused;
  } else {
    // eslint-disable-next-line no-multi-assign
    window.onpageshow = window.onfocus = focused;
    // eslint-disable-next-line no-multi-assign
    window.onpagehide = window.onblur = unfocused;
  }
};
