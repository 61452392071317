import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';

class Venue extends HttpRequest {
  constructor() {
    super(API_ENDPOINT_PARTNER_V2);
  }

  getVenueReservationAvailabilities({ venueId, date }: any, config: any) {
    return this.fetch('/reservation_availabilities', { venueId, date }, config);
  }

  getVenue(venueId: any) {
    return this.fetch(`/venues/${venueId}`);
  }

  getVenueAvailabilitySummary(venueId: any, { date }: any, config: any) {
    return this.fetch(`/venues/${venueId}/footer_v2`, { date }, config);
  }

  getCalendarAvailability({ venueId, startDate, endDate, partySize }: any, config: any) {
    return this.fetch(`/venues/${venueId}/calendar`, { startDate, endDate, partySize }, config);
  }

  getVenueLoyaltyTiers(venueId: any) {
    return this.fetch('/master_guests/loyalty_tiers', { venueId });
  }
}

export default new Venue();
