import type { AppDispatch, AppGetState } from 'redux-store';
import moment from 'moment-timezone';
import { showErrorMessage } from 'utils/alerts';
import { getErrorMessage } from 'utils/errors';
import reservationApis from 'modules/ReservationsList/apis';
import { updateWaitlistItem } from 'modules/Waitlist/actions';
import { updateReservation, dispatchReservationUpdate } from '../ReservationsList/actions';
import { DATE_FORMAT } from 'constants/time-and-date';
import { DEFAULT_GROUP } from './constants';
import * as ActionTypes from './action-types';

// remove fromTable and add toTable to tableIds, and if table is dragged to no-table then remove that table too..
const changeTableIds = ({ tableIds = [], fromTable, toTable }: any) =>
  // @ts-expect-error TS(7006): Parameter 't' implicitly has an 'any' type.
  [...tableIds.filter((t) => t !== fromTable), toTable].filter(
    (t) => t !== DEFAULT_GROUP.noTables.id
  );

const linkTableIds = ({ tableIds = [], tableId }: any) =>
  tableId === DEFAULT_GROUP.noTables.id ? tableIds : [...tableIds, tableId];

const fieldsForChangeTime = ['slot', 'slotStartTime', 'startTime', 'endTime', 'turnTime'];

const fieldsForChangeTable = ['tables', 'rooms'];

export const setTimelineWindow = (window: any) => ({
  type: ActionTypes.SET_RANGE,
  payload: window,
});

export const setListWidth = (width: any) => ({
  type: ActionTypes.SET_LIST_WIDTH,
  payload: width,
});

export const setIsItemDragging = (status: any) => ({
  type: ActionTypes.SET_ITEM_DRAGGING,
  payload: status,
});

export const updateWaitlistTime =
  ({ toItem }: any) =>
  (dispatch: AppDispatch) => {
    const { start, end, waitlistId } = toItem;
    const momentStart = moment(start);
    const formattedStartTime = momentStart.format();
    const dateTime = {
      startTime: formattedStartTime,
      endTime: end ? moment(end).format() : formattedStartTime,
      notifyDate: momentStart.format(DATE_FORMAT),
    };
    dispatch(updateWaitlistItem(waitlistId, dateTime));
  };

export const swapTablesAndTime =
  ({ fromItem, swapItem, venueId, swapTime, swapOnlyTime }: any) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const { reservationId: idFrom, group: tableFrom } = fromItem;
      const { reservationId: idTo, group: tableTo } = swapItem;
      const params = {
        idFrom,
        idTo,
        tableFrom: tableFrom === DEFAULT_GROUP.noTables.id ? '' : tableFrom,
        tableTo: tableTo === DEFAULT_GROUP.noTables.id ? '' : tableTo,
        swapTime,
        swapOnlyTime,
        venueId,
      };
      // @ts-expect-error TS(2461): Type 'unknown' is not an array type.
      const [reservation1, reservation2] = await reservationApis.swapTableAndTime(params);
      // @ts-expect-error TS(2345): Argument of type '{ dispatch: import("/home/murtaz... Remove this comment to see the full error message
      dispatchReservationUpdate({ dispatch, getState, reservation: reservation1 });
      // @ts-expect-error TS(2345): Argument of type '{ dispatch: import("/home/murtaz... Remove this comment to see the full error message
      dispatchReservationUpdate({ dispatch, getState, reservation: reservation2 });
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

export const changeTimeAndLinkTables =
  ({ toItem, venueId }: any) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const { start, group: tableId, tableIds, reservationId } = toItem;
    const requestPayload = {
      venueId,
      reservationDateTime: moment(start).format(),
      timeOverride: true,
      reservation: { tableIds: linkTableIds({ tableIds, tableId }), errorOverride: true },
    };

    updateReservation({
      reservationId,
      data: requestPayload.reservation,
      venueId,
      dispatch,
      getState,
      fields: [...fieldsForChangeTable, ...fieldsForChangeTime],
      payload: requestPayload,
    });
  };

export const changeTimeAndTable =
  ({ toItem, fromItem, venueId }: any) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const { group: fromTable } = fromItem;
    const { start, group: toTable, tableIds, reservationId } = toItem;
    const requestPayload = {
      venueId,
      reservationDateTime: moment(start).format(),
      timeOverride: true,
      reservation: {
        tableIds: changeTableIds({ tableIds, fromTable, toTable }),
        errorOverride: true,
      },
    };
    updateReservation({
      reservationId,
      data: requestPayload.reservation,
      venueId,
      dispatch,
      getState,
      fields: [...fieldsForChangeTable, ...fieldsForChangeTime],
      payload: requestPayload,
    });
  };

export const changeLinkTables =
  ({ toItem, venueId }: any) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const { group: tableId, tableIds, reservationId } = toItem;
    const requestPayload = {
      venueId,
      timeOverride: true,
      reservation: { tableIds: linkTableIds({ tableIds, tableId }), errorOverride: true },
    };

    updateReservation({
      reservationId,
      data: requestPayload.reservation,
      venueId,
      dispatch,
      getState,
      fields: fieldsForChangeTable,
      payload: requestPayload,
    });
  };

export const changeTime =
  ({ toItem, venueId }: any) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const { start, end, reservationId } = toItem;
    const requestPayload = {
      venueId,
      reservationDateTime: moment(start).format(),
      timeOverride: true,
      reservation: {
        turnTime: moment(end).diff(moment(start), 'minutes'),
        errorOverride: true,
      },
    };
    updateReservation({
      reservationId,
      data: requestPayload.reservation,
      venueId,
      dispatch,
      getState,
      fields: fieldsForChangeTime,
      payload: requestPayload,
    });
  };

export const changeTable =
  ({ toItem, fromItem, venueId }: any) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const { group: fromTable } = fromItem;
    const { group: toTable, tableIds, reservationId } = toItem;
    const requestPayload = {
      venueId,
      timeOverride: true,
      reservation: {
        tableIds: changeTableIds({ tableIds, fromTable, toTable }),
        errorOverride: true,
      },
    };
    updateReservation({
      reservationId,
      data: requestPayload.reservation,
      venueId,
      dispatch,
      getState,
      fields: fieldsForChangeTable,
      payload: requestPayload,
    });
  };

export const setRoomsShowNested = (roomsShowNestedValues: any) => ({
  type: ActionTypes.SET_ROOMS_SHOW_NESTED,
  payload: roomsShowNestedValues,
});
