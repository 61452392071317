import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { FUNNEL_ROUTES } from 'constants/routes';
import { AppContext } from 'contexts/app';
import Warning from './Warning';
import styles from './WarningsContainer.module.scss';

// NOTE: this class name should be the same as in the @mixin is-venue-in-warning
const VENUE_WARNING_CLASS = 'venue-in-warning';

interface Props {
  warnings: {
    messages?: string[];
    buttonLabel: string;
    buttonAction: () => void;
    closeAction: () => void;
    isVisible: boolean;
    id: string;
  }[];
}

function WarningsContainer({ warnings }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { hasSideBar } = useContext(AppContext);

  useLayoutEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      // this will get called whenever div dimension changes
      entries.forEach(() => {
        if (!containerRef.current) return;
        document.documentElement.style.setProperty(
          '--payment-warning-height',
          `${containerRef.current.offsetHeight}px`
        );
      });
    });

    // observer for height changes when the payment warning resizes, so that we can adjust the screen with warning height
    if (containerRef.current) {
      myObserver.observe(containerRef.current);
    }

    return () => {
      //  stop listening to changes
      myObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (warnings.length > 0) {
      bodyClassList.add(VENUE_WARNING_CLASS);
    } else {
      bodyClassList.remove(VENUE_WARNING_CLASS);
    }

    return () => {
      bodyClassList.remove(VENUE_WARNING_CLASS);
    };
  }, [warnings.length]);

  return (
    <div
      ref={containerRef}
      className={classnames(styles.warningsContainer, {
        [styles.fullWidth]:
          (FUNNEL_ROUTES as ReadonlyArray<string>).includes(location.pathname) || !hasSideBar(),
      })}
      data-testid="warnings-container"
    >
      {warnings.map(({ id, messages, buttonLabel, buttonAction, closeAction }) => (
        <Warning
          key={id}
          messages={messages}
          buttonLabel={buttonLabel}
          onButtonClick={buttonAction}
          onClose={closeAction}
        />
      ))}
    </div>
  );
}

export default WarningsContainer;
