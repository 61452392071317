import React, { Suspense, lazy, useState } from 'react';
import { SkeletonLoader } from '@umai/common';
import Layout from 'layouts/Layout';
import GuestCrmHeader from 'modules/GuestsCrm/components/GuestCrmHeader';

const TabletFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-tablet-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/TabletFooter'
    )
);

const MobileFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-mobile-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/MobileFooter'
    )
);

const GuestModule = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "umai-guest" */ 'modules/GuestsCrm')
);

const Guests = () => {
  const [hideNavigation, setHideNavigation] = useState(false);
  const onFocus = () => setHideNavigation(true);
  const onBlur = () => setHideNavigation(false);

  return (
    <Layout
      header={<GuestCrmHeader />}
      footer={<TabletFooter isGuestList />}
      mobileHeader={<GuestCrmHeader onFocus={onFocus} onBlur={onBlur} />}
      mobileFooter={<MobileFooter hideNavigation={hideNavigation} isGuestList hideMobileFilters />}
      customBodyClasses="guest-list-layout"
    >
      <Suspense fallback={<SkeletonLoader.List circle />}>
        <GuestModule />
      </Suspense>
    </Layout>
  );
};

export default Guests;
