import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useBlockDevTools } from '@umai/common';
import { isProductionEnv } from 'utils/session';
import { ReactComponent as LogoIcon } from 'assets/images/icons/logo.svg';
import ModalPopup from 'components/modals/ModalPopup';
import styles from './PaymentProfile.module.scss';

// NOTE: this class name should be the same as in the @mixin is-venue-access-blocked
const venueAccessBlockedClass = 'venue-access-blocked';

interface BlockedAccessProps {
  handleBillingPage(...args: unknown[]): unknown;
  isVenueAccessBlocked: boolean;
}

function BlockedAccess({ handleBillingPage, isVenueAccessBlocked }: BlockedAccessProps) {
  useBlockDevTools({
    isEnabled: isProductionEnv(),
    recordQuerySelector: '.blocked-access-modal, #blocked-access-overlay, #blocked-access-content',
    // @ts-expect-error TS(2322): Type '(node: any) => boolean' is not assignable to... Remove this comment to see the full error message
    removeNodeCheck: (
      node: any // modal of react-modal ( is parent-node of overlay )
    ) =>
      node?.firstChild?.id === 'blocked-access-overlay' ||
      // overlay of react-modal
      node.id === 'blocked-access-overlay' ||
      // content of react-modal
      node.id === 'blocked-access-content',
  });

  useEffect(() => {
    const bodyClassList = document.body.classList;

    if (isVenueAccessBlocked) {
      bodyClassList.add(venueAccessBlockedClass);
    } else {
      bodyClassList.remove(venueAccessBlockedClass);
    }

    return () => {
      bodyClassList.remove(venueAccessBlockedClass);
    };
  }, [isVenueAccessBlocked]);

  return (
    <ModalPopup
      id="blocked-access-content"
      modalIsOpen
      hideClose
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      customClasses={classnames('blocked-access-modal', styles.blockedAccessModal)}
      overlayRef={(node: any) => {
        if (node) {
          node.id = 'blocked-access-overlay';
          node.testingId = 'blocked-access-overlay';
        }
      }}
    >
      <section className={styles.blockedAccess}>
        <LogoIcon />
        <h3 className={styles.title}>Your service has been paused due to an outstanding payment</h3>
        <p className={styles.description}>
          After numerous attempts to collect payment we’ve had to pause your UMAI services due to an
          outstanding payment on an invoice.
          <br />
          <br />
          To avoid this in the future we recommend setting up an auto payment method, that way
          everything is automatically paid as you go and we can avoid having to pause any services.
          <br />
          <br />
          You can see a detailed invoice, pay your outstanding amount, and set up auto payment in
          your billing settings.
        </p>
        <button type="button" className={styles.goToBillingButton} onClick={handleBillingPage}>
          Go to billing settings
        </button>
      </section>
    </ModalPopup>
  );
}

BlockedAccess.propTypes = {
  handleBillingPage: PropTypes.func.isRequired,
  isVenueAccessBlocked: PropTypes.bool.isRequired,
};

export default BlockedAccess;
