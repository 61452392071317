import React, { lazy } from 'react';
import ThirdPartyAppLayout from 'layouts/ThirdPartyAppLayout';

const TrafficAnalytics = lazy(
  () =>
    import(
      // @ts-expect-error TS(7016): Could not find a declaration file for module '@uma... Remove this comment to see the full error message
      /* webpackPrefetch: true, webpackChunkName: "umai-traffic-analytics" */ '@umai/traffic-analytics'
    )
);

function Analytics() {
  return (
    <ThirdPartyAppLayout id="analytics-wrapper" className="umaiAnalyticsParent">
      <TrafficAnalytics />
    </ThirdPartyAppLayout>
  );
}

export default Analytics;
