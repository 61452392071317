import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';

class Tags extends HttpRequest {
  constructor() {
    super(`${API_ENDPOINT_PARTNER_V2}/tags`);
  }

  getTags({ venueId, tagType }: any) {
    return this.fetch('', {
      venueId,
      tagType,
      source: 'reservation',
    });
  }
}

export default new Tags();
