import { useEffect } from 'react';
import { formatDateToServerDate } from 'utils/date-and-time';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getWaitlistItems } from 'modules/Waitlist/actions';
import { selectFooterDate, selectFooterDateRange } from 'modules/Footer/selectors';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import { TIMELINE_FUTURE_DAYS } from 'modules/Timeline/constants';
import { getTimelineInFooterDate } from 'modules/Timeline/services';
import { getWaitlistCacheKey } from 'modules/Waitlist/services';

const useFetchWaitlist = ({ isTimeline = false } = {}) => {
  const footerDate = useAppSelector(selectFooterDate);
  const footerDateRange = useAppSelector(selectFooterDateRange);
  const venueId = useAppSelector(selectCurrentVenueId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let waitlistController: any = null;

    const fetchWaitlist = () => {
      const date = formatDateToServerDate(footerDate);
      waitlistController = dispatch(
        getWaitlistItems({
          date,
          allDay: true,
          venueId,
          newCacheKey: getWaitlistCacheKey({ venueId, date: footerDate }),
          showLoader: true,
        })
      );
    };

    if (venueId) {
      // DO NOT fetch waitlist api on timeline if footer date in past days...
      if (isTimeline) {
        if (TIMELINE_FUTURE_DAYS.includes(getTimelineInFooterDate(footerDateRange))) {
          fetchWaitlist();
        }
      } else {
        fetchWaitlist();
      }
    }

    return () => waitlistController?.abort();
  }, [dispatch, footerDate, venueId]);
};

export default useFetchWaitlist;
