/* eslint-disable no-console */
import { useEffect } from 'react';
import { formatDateToServerDate } from 'utils/date-and-time';
import { useAppDispatch } from 'hooks/redux';
import { MESSAGE_EVENTS } from 'constants/socket';
import { updateWaitlistFromSocket } from 'modules/Waitlist/actions';
import waitlistApis from 'modules/Waitlist/apis';

const useSocketWaitlist = ({ venueId, footerDate }: any) => {
  const dispatch = useAppDispatch();

  const waitlistUpdate = async ({ detail = {} }) => {
    const fDate = formatDateToServerDate(footerDate);
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      if (detail?.params) {
        // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
        const { params } = detail;
        let waitlistItem = params?.waitlistItem;
        console.debug('WAITLIST: socket update: ', params, params?.date, fDate);
        // All the scenario for adding, removing, updating waitlist item are handle inside the waitlist slice base on the waitlist start time.
        if (params?.id) {
          // for a custom event (customSocketEventForWaitlist) we are passing waitlistItem in the params
          // to avoid api hit on waitlistApis.getWailist
          if (!waitlistItem?.id) {
            const response = await waitlistApis.getWaitlist(params.id);
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            waitlistItem = response.notifylist;
          }
          dispatch(
            updateWaitlistFromSocket({
              footerDate,
              id: +params.id,
              currentStatus: params?.status,
              waitlistItem,
            })
          );
        }
      }
    } catch (error) {
      console.error('Error in update waitlist from socket', error);
    }
  };

  useEffect(() => {
    if (venueId) {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.addEventListener(MESSAGE_EVENTS.waitlist, waitlistUpdate, false);
    }
    return () => {
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(MESSAGE_EVENTS.waitlist, waitlistUpdate, false);
    };
  }, [venueId, footerDate]);
};

export default useSocketWaitlist;
