import React from 'react';
import PropTypes from 'prop-types';
import { openUmaiLink } from 'utils/url-helpers';
import './AccessMessage.style.scss';

interface AccessMessageProps {
  title: React.ReactNode;
  description: React.ReactNode;
  subTitle: React.ReactNode;
  header: React.ReactNode;
  footer?: React.ReactNode;
}

const AccessMessage = ({ title, description, subTitle, header, footer }: AccessMessageProps) => {
  const renderFooter = () => {
    if (typeof footer !== 'undefined') {
      return footer;
    }

    return (
      <button
        type="button"
        className="go-to-umai-button"
        onClick={() => {
          openUmaiLink();
        }}
      >
        Go to UMAI.io
      </button>
    );
  };

  return (
    <section className="access-message">
      <h2 className="header">{header}</h2>
      <h3 className="title">{title}</h3>
      <p className="description">{description}</p>
      <h4 className="sub-title">{subTitle}</h4>
      <footer className="footer">{renderFooter()}</footer>
    </section>
  );
};

AccessMessage.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  subTitle: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

export default AccessMessage;
