import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ModalPopup } from '@umai/common';
import './ModalPopup.styles.scss';

type ModalPopupProps = React.ComponentProps<typeof ModalPopup>;

const ModalPopupWrapper = ({ children, customClasses, ...props }: ModalPopupProps) => (
  <ModalPopup
    customClasses={classnames('webrmsCloseButtonOverride', customClasses)}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </ModalPopup>
);

ModalPopupWrapper.propTypes = {
  children: PropTypes.node,
  customClasses: PropTypes.node,
  props: PropTypes.object,
};

export default ModalPopupWrapper;
