import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useViewPortContext, SkeletonLoader } from '@umai/common';
import './GuestTransactionsSummary.styles.scss';

interface GuestTransactionsSummaryLoaderProps {
  header?: boolean | React.ReactNode;
  isPartnerVisible?: boolean;
  isPartner?: boolean;
  showZeroSpend?: boolean;
}

function GuestTransactionsSummaryLoader({
  header,
  isPartner,
  isPartnerVisible,
  showZeroSpend,
}: GuestTransactionsSummaryLoaderProps) {
  const { mobile } = useViewPortContext();

  return (
    <section
      className="guest-transactions-summary-wrapper"
      aria-label="Loading transaction summary"
    >
      {header && isPartnerVisible ? (
        <header className="guest-transactions-summary-header">
          <SkeletonLoader.Skeleton
            style={{
              width: '150px',
              height: '10px',
            }}
            count={1}
          />
        </header>
      ) : null}
      <SkeletonLoader.Blocks
        className={classNames('guest-transactions-summary skeleton-summary', {
          'in-two-rows': mobile || isPartnerVisible,
          'show-border': !mobile && isPartner && isPartnerVisible,
        })}
        // @ts-expect-error TS(2322): Type '{ margin: string; }' is not assignable to ty... Remove this comment to see the full error message
        blockStyles={{ margin: '5px' }}
        numBlocks={showZeroSpend ? 6 : 3}
        lines={['75%', '60%']}
      />
    </section>
  );
}

GuestTransactionsSummaryLoader.propTypes = {
  header: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  isPartnerVisible: PropTypes.bool,
  isPartner: PropTypes.bool,
  showZeroSpend: PropTypes.bool,
};

export default GuestTransactionsSummaryLoader;
