export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000';
export const API_ENDPOINT_PARTNER_V2 = `${API_ENDPOINT}${
  process.env.REACT_APP_API_ENDPOINT_PARTNER_V2 || '/partner/v2'
}`;
export const API_ENDPOINT_PARTNER_V3 = `${API_ENDPOINT}${
  process.env.REACT_APP_API_ENDPOINT_PARTNER_V3 || '/partner/v3'
}`;
export const ANTD_POPOVER_WRAPPER = 'umai-antd-popovers-wrapper';
export const ANTD_POPOVER_BACKDROP = 'umai-antd-popovers-backdrop';
export const UMAI_START_YEAR = 2017;
export const TABLET_APP_WRAPPER = 'main-app-wrapper';
export const MOBILE_APP_WRAPPER = 'mobile-layout-wrapper';
export const LOCAL_STORAGE_PREFIX_SETTINGS = 'settings:';
export const EMPTY_STATE_ICON_TYPE = {
  pizza: 'pizza',
  happy: 'happy',
  unhappy: 'unhappy',
  notification: 'notification',
  balloons: 'balloons',
};

export const SIDEBAR_WIDTH_TABLET = 65; // pixels

export const COUNTRY_HELP_PHONE_NUMBERS = {
  MY: '+60162999861',
  PT: '+351308811360',
  DK: '+4591300086',
} as const;

export const APPLE_REVIEW_PARTNER_ID = 4;

export {
  DEFAULT_LANGUAGE,
  DEFAULT_CURRENCY,
  DEFAULT_COUNTRY,
  HELP_CHAT_INTERCOM_ID,
  HELP_CENTER_URL,
  ENVIRONMENT,
} from '@umai/common';
