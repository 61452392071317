import { useEffect } from 'react';
import { useAppSelector } from 'hooks/redux';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import { setDataToStorage, getDataFromStorage } from '@umai/common';
import { getPartnerSession } from 'utils/session';
import { selectIsAuthenticated } from 'modules/Auth/selectors';

// Add more here countries to log unwanted usage of app;
const UNWANTED_COUNTRIES = ['TW', 'CN'];
const IP_INFO_COUNTRY_KEY = 'ipinfo_country';
const IP_INFO_TOKEN_INDEX = 'ipinfo_tokenIndex';

// Function to get the next token index from localStorage or initialize it
function getTokenIndex() {
  const index = getDataFromStorage(IP_INFO_TOKEN_INDEX);
  return index !== null ? parseInt(index, 10) : 0;
}

// Function to save the current token index to localStorage
function saveTokenIndex(index: any) {
  setDataToStorage(IP_INFO_TOKEN_INDEX, index);
}

// Function to get the next token
function getNextToken() {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const tokens = process.env.REACT_APP_IP_INFO_TOKEN.split(', ');
  let index = getTokenIndex();
  const token = tokens[index];
  index = (index + 1) % tokens.length; // Increment and wrap around
  saveTokenIndex(index); // Save the new index for next use
  return token;
}

// IP info token is only for prod, temporary for checking unwanted access of 360 in countries like china etc...
export default function useGetIpInfo() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const fetchUserIp = async () => {
    try {
      const token = getNextToken(); // Get the next token
      const request = await fetch(`https://ipinfo.io/json?token=${token}`);
      const data = await request.json();

      // We have the data, let's cache it in cookies for 2 days
      if (data?.country) {
        Cookies.set(IP_INFO_COUNTRY_KEY, data.country, {
          expires: 2,
        });
      }

      if (
        data?.privacy?.vpn ||
        data?.privacy?.tor ||
        data?.privacy?.proxy ||
        data?.privacy?.relay
      ) {
        Sentry.captureMessage(`ALERT!, UMAI360 accessed from (vpn/tor/proxy/relay)`, {
          tags: {
            environment: process.env.REACT_APP_ENVIRONMENT,
            ...getPartnerSession(),
          },
          extra: {
            data,
          },
        });
      }

      if (UNWANTED_COUNTRIES.includes(data?.country)) {
        Sentry.captureMessage(`ALERT!, UMAI360 accessed from: ${data?.country}`, {
          tags: {
            environment: process.env.REACT_APP_ENVIRONMENT,
            ...getPartnerSession(),
          },
          extra: {
            data,
          },
        });
      }
    } catch (error) {
      console.error('Something went wrong fetching ipinfo.io', error);
    }
  };

  useEffect(() => {
    // fetch only for logged in users
    if (!isAuthenticated) return;

    // avoid hitting ipinfo apis for bots
    if (navigator.userAgent.match(/bot|spider/i)) return;

    // Let's check if we have the value in cookies
    // Already have the value in cookies, no need to make a call to IPinfo
    if (Cookies.get(IP_INFO_COUNTRY_KEY)) return;

    // No cached data, let's get it from IPinfo, free 50k request per token
    fetchUserIp();
  }, [isAuthenticated]);
}
