import type { RootState } from 'redux-store';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { WEEKDAYS } from 'constants/time-and-date';
import { ALL_DAY_AVAILABILITY } from 'modules/ReservationsList/constants';

export const selectAvailabilityId = ({ footer }: RootState) => footer.availabilityId;

export const selectAvailabilityName = ({ footer }: RootState) => footer.availabilityName;

export const selectAvailabilities = ({ footer }: RootState) => footer.availabilities;

export const selectAvailabilityById = (id: any) =>
  createSelector(selectAvailabilities, (availabilities) =>
    availabilities.find((s: any) => s.id === id)
  );

export const selectActiveAvailabilities = createSelector([selectAvailabilities], (availabilities) =>
  availabilities.filter((availability: any) => availability.active)
);

export const selectFooterDate = ({ footer }: RootState) => footer.date;

export const selectWeekday = ({ footer }: RootState) =>
  WEEKDAYS[moment(footer.date).isoWeekday() - 1];

export const selectAvailabilitySummary = ({ footer }: RootState) => footer.availabilitySummary;

export const selectCalendarData = ({ footer }: RootState) => footer.calendarData;

export const selectFooterDateRange = createSelector(selectFooterDate, (footerDate) => {
  const footerStartDate = footerDate.clone().startOf('day').add(6, 'hours');
  const footerEndDate = footerStartDate.clone().add(1, 'day');
  return {
    // start date to be 6:00 am
    footerStartDate,
    // end date to be 1 day ahead at 6:00 am
    footerEndDate,
    // subtracting 1 minute to make within a 5:59 am
    footerEndWithinDate: footerEndDate.clone().subtract(1, 'minute'),
  };
});

export const selectAvailabilitiesLoading = ({ footer }: RootState) =>
  footer.isAvailabilitiesLoading;

export const selectFilteredAvailabilityRange = createSelector(
  selectWeekday,
  selectAvailabilityId,
  selectAvailabilities,
  (weekday, availabilityId, availabilities) => {
    if (availabilities.length) {
      if (availabilityId === ALL_DAY_AVAILABILITY) {
        const activeAvailability = availabilities.filter(({ active }: any) => !!active)?.[0];
        if (activeAvailability?.id) {
          const { id, name, startTime, endTime, timeRange } = activeAvailability;
          return {
            id,
            name,
            startTime: timeRange[weekday]?.startTime || startTime,
            endTime: timeRange[weekday]?.endTime || endTime,
          };
        }
      }
      const selectedAvailability = availabilities.find((s: any) => s.id === availabilityId);
      if (selectedAvailability?.id) {
        const { id, name, startTime, endTime, timeRange } = selectedAvailability;
        return {
          id,
          name,
          startTime: timeRange[weekday]?.startTime || startTime,
          endTime: timeRange[weekday]?.endTime || endTime,
        };
      }
    }
    return {};
  }
);

export const selectHasSimpleSlotSystem = createSelector([selectAvailabilities], (availabilities) =>
  availabilities.some((availability: any) => availability.slotSystem === 'simple')
);
