import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LAZY_LOADED_ROUTES } from 'constants/routes';
import { ENVIRONMENT } from 'constants/app';
import { isWebview, reloadNativeApp } from 'utils/devices';

const CacheBuster = () => {
  const location = useLocation();
  const parseVersion = (str?: string) => +String(str).replace(/\D/g, '');

  useEffect(() => {
    if (
      process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.LOCAL &&
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      LAZY_LOADED_ROUTES.includes(location.pathname)
    ) {
      fetch(`/meta.json?v=${+new Date()}`, { cache: 'no-cache' })
        .then((response) => response.json())
        .then((meta) => {
          if (meta?.version) {
            const latestVersion = parseVersion(meta.version);
            const currentVersion = parseVersion(process.env.REACT_APP_VERSION);
            if (currentVersion < latestVersion) {
              if (isWebview()) {
                reloadNativeApp();
                return;
              }

              window?.location?.reload?.();
            }
          }
        })
        .catch((error) => {
          console.error('something went wrong fetching meta.json', error);
        });
    }
  }, [location]);

  return null;
};

export default CacheBuster;
