import type { RootState } from 'redux-store';
import { createSelector } from 'reselect';
import { OPERATIONAL_PERMISSIONS } from 'constants/operational-permission';
import { selectIsElementAccessAuthorized } from 'modules/Auth/selectors';
import { GUEST_RESERVATION_TYPE, HISTORY_TRANSACTION_TYPE } from './constants';

const selectAllGuest = ({ guest }: RootState) => guest;

export const selectGuests = ({ guest }: RootState) => guest.guests || [];

export const selectMasterGuest = ({ guest }: RootState) => guest.masterGuest;

export const selectGuestReservationSummary = ({ guest }: RootState) => guest.reservationSummary;

export const selectMasterGuestId = ({ guest }: RootState) => guest.masterGuest?.id;

export const selectMasterGuestLoading = ({ guest }: RootState) => guest.isLoadingMasterGuest;

export const selectGuestReservationSummaryLoading = ({ guest }: RootState) =>
  guest.isLoadingReservationSummary;

export const selectIsLoading = ({ guest }: RootState) => guest.isLoading;

export const selectLoyaltyAccountLoading = ({ guest }: RootState) =>
  guest.isLoadingLoyaltyTransaction;

export const selectLoyaltyAccountDetails = ({ guest }: RootState) => guest.loyaltyAccountDetails;

export const selectGuestHasLoyalty = ({ guest }: RootState) => guest.masterGuest?.hasLoyalty;

export const selectGuestHistoryItems = (type: any) =>
  createSelector(
    selectAllGuest,
    selectIsElementAccessAuthorized(OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS),
    selectIsElementAccessAuthorized(OPERATIONAL_PERMISSIONS.TAKEAWAY_AND_DELIVERY_OPERATIONS),
    (guest, hasReservationOperationPermission, hasTakeawayAndDeliveryPermission) =>
      // We filter history items based on type and user permissions
      guest?.[type].filter((item: any) => {
        if (
          item.type === HISTORY_TRANSACTION_TYPE.reservation &&
          hasReservationOperationPermission
        ) {
          return true;
        }
        if (
          [
            HISTORY_TRANSACTION_TYPE.delivery,
            HISTORY_TRANSACTION_TYPE.takeaway,
            HISTORY_TRANSACTION_TYPE.giftCard,
          ].includes(item.type) &&
          hasTakeawayAndDeliveryPermission
        ) {
          return true;
        }
        if (
          [
            HISTORY_TRANSACTION_TYPE.redemption,
            HISTORY_TRANSACTION_TYPE.deduction,
            HISTORY_TRANSACTION_TYPE.collection,
            HISTORY_TRANSACTION_TYPE.refund,
            HISTORY_TRANSACTION_TYPE.addition,
          ].includes(item.type)
        ) {
          return true;
        }
        return false;
      })
  );

export const selectGuestHistoryLoading = createSelector(
  [(_, type) => type, selectAllGuest],
  (type, guest) =>
    type === GUEST_RESERVATION_TYPE.upcoming ? guest.isLoadingUpcoming : guest.isLoadingPast
);

export const selectGuestHistoryDetails = ({ guest }: RootState) => guest.guestHistory;
