export type Routes = (typeof ROUTES)[keyof typeof ROUTES];

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  RESERVATIONS: '/reservations',
  FINISHED_RESERVATIONS: '/finished-reservations',
  WAITLIST: '/waitlist',
  GUEST_LIST: '/guests',
  RESERVATION_FUNNEL: '/reservation-funnel',
  WALKIN_FUNNEL: '/walk-in-funnel',
  CALENDAR: '/calendar',
  FEEDBACK_ANALYTICS: '/feedback-analytics',
  TRAFFIC_ANALYTICS: '/analytics',
  // We include a trailing slash in the routes ('/settings/') rather than without ('/settings'),
  // because when navigating to routes within settings, such as '/settings/?page=reservations',
  // refreshing the page results in 'location.pathname' being '/settings/' inside 360, which needs to match ROUTES.SETTINGS.
  // Hence, the trailing slash is necessary.
  SETTINGS: '/settings/',
  SHOP_DASHBOARD: '/shop-dashboard',
  QUEUE_LIST: '/queue',
  TIMELINE: '/timeline',
  NOTIFICATIONS: '/notifications',
} as const;

export type RoutePath = (typeof ROUTES)[keyof typeof ROUTES];

export const SETTINGS_ACCOUNT_PAGE = `${ROUTES.SETTINGS}?page=account`;

export const NO_PERMISSION_REQUIRE_ROUTES = [
  ROUTES.HOME,
  ROUTES.SETTINGS,
  ROUTES.LOGIN,
  ROUTES.LOGOUT,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.RESET_PASSWORD,
  ROUTES.NOTIFICATIONS,
] as const;

export const LAZY_LOADED_ROUTES = [
  ROUTES.CALENDAR,
  ROUTES.FEEDBACK_ANALYTICS,
  ROUTES.TRAFFIC_ANALYTICS,
  ROUTES.SETTINGS,
  ROUTES.SHOP_DASHBOARD,
  ROUTES.TIMELINE,
];

export const RESERVATION_ROUTES = [
  ROUTES.HOME,
  ROUTES.RESERVATIONS,
  ROUTES.FINISHED_RESERVATIONS,
  ROUTES.WAITLIST,
  ROUTES.QUEUE_LIST,
];

export const THIRD_PARTY_BOOKER_ROUTES = [ROUTES.HOME, ROUTES.RESERVATIONS, ROUTES.TIMELINE];

export const FUNNEL_ROUTES = [ROUTES.RESERVATION_FUNNEL, ROUTES.WALKIN_FUNNEL];

export const PUBLIC_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.LOGOUT,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.RESET_PASSWORD,
];
