export {
  LOCALE_DATE_FORMAT,
  TIME_FORMAT_24,
  TIME_FORMAT_12,
  WEEKDAYS,
  SERVER_DATE_TIME,
  SERVER_TIME,
  DATE_FORMAT,
  DAY_START_OFFSET,
} from '@umai/common';

export const TIME_SLOT_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';

export const TURN_TIME_FORMAT = 'H[h] mm[m]';

export const DATE_FORMAT_CALENDAR = 'YYYYMMDD'; // For moment
export const DATE_FORMAT_CALENDAR_NEW = 'yyyyMMdd'; // for date-fns

export const QUEUE_DATE_FORMAT = 'ddd, DD/MM/YYYY, LT';

export const QUEUE_TIME_FORMAT = 'H:mm:ss';

// default interval is set to 1 second
export const DEFAULT_INTERVAL = 1000;

export const TIME_SLOT_SERVER_FORMAT = 'YYYY-MM-DD HH:mm:ss ZZ';
