import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Redirect, useLocation } from 'react-router-dom';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import classnames from 'classnames';
import { hasError } from 'utils/errors';
import { ROUTES } from 'constants/routes';
import { ResetPasswordSchema } from 'modules/Auth/validations';
import { RESET_PASSWORD_TOKEN } from 'modules/Auth/constants';
import { resetPassword, validatePasswordToken } from 'modules/Auth/actions';
import {
  selectIsAuthenticated,
  selectIsAuthSubmitting,
  selectIsPasswordResetSuccess,
  selectIsValidPasswordToken,
} from 'modules/Auth/selectors';
import { ReactComponent as LogoIcon } from 'assets/images/icons/logo.svg';
import ForgotPasswordLockIcon from 'components/icons/ForgotPasswordLockIcon';

const ResetPassword = () => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get(RESET_PASSWORD_TOKEN);
  const dispatch = useAppDispatch();
  const isAuthSubmitting = useAppSelector(selectIsAuthSubmitting);
  const isPasswordResetSuccess = useAppSelector(selectIsPasswordResetSuccess);
  const isValidPasswordToken = useAppSelector(selectIsValidPasswordToken);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  // @ts-expect-error TS(2339): Property 'from' does not exist on type 'unknown'.
  const { from } = location.state || { from: { pathname: ROUTES.HOME } };

  const handleResetPasswordSubmit = ({ password, confirmPassword }: any, { resetForm }: any) => {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 3.
    dispatch(resetPassword(token, password, confirmPassword));
    resetForm({ values: { password, confirmPassword } });
  };

  useEffect(() => {
    dispatch(validatePasswordToken(token));
  }, []);

  if (isAuthenticated) return <Redirect to={from} />;
  if (isPasswordResetSuccess) return <Redirect to={ROUTES.LOGIN} />;
  if (isValidPasswordToken === null) {
    return null;
  }

  return (
    <>
      <div id="logo-wrapper">
        <LogoIcon />
      </div>
      <div className="login-component" id="form-wrapper">
        {!isValidPasswordToken ? (
          <>
            <h1>Link not valid or token expired</h1>
            <p>Try getting a new link</p>
          </>
        ) : (
          <>
            <h1>Create new password</h1>
            <p>Your new password must be different from previous used password</p>
            <Formik
              initialValues={{ password: '', confirmPassword: '' }}
              validationSchema={ResetPasswordSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleResetPasswordSubmit}
            >
              {({ handleSubmit, isSubmitting, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="field">
                    <Field
                      type="password"
                      name="password"
                      className={classnames('input', {
                        'input__warning-border': Boolean(errors.password && touched.password),
                      })}
                      placeholder="Password"
                      autoComplete="current-password"
                      autoFocus
                      readOnly={isSubmitting}
                    />
                    <span className="login-input-icon">
                      <ForgotPasswordLockIcon />
                    </span>
                    <Field
                      type="password"
                      name="confirmPassword"
                      className={classnames('input', {
                        'input__warning-border': Boolean(
                          errors.confirmPassword && touched.confirmPassword
                        ),
                      })}
                      placeholder="Confirm new password"
                      autoComplete="new-password"
                      // data-lpignore attribute disable LastPass autofill.
                      data-lpignore="true"
                      readOnly={isSubmitting}
                    />
                    <span className="login-input-icon">
                      <ForgotPasswordLockIcon />
                    </span>
                  </div>
                  {hasError(errors) ? (
                    <div className="login-error">
                      <ErrorMessage component="span" name="password" className="help is-danger" />
                      <ErrorMessage
                        component="span"
                        name="confirmPassword"
                        className="help is-danger"
                      />
                    </div>
                  ) : null}

                  <div>
                    <button
                      disabled={isAuthSubmitting}
                      className="button is-primary "
                      type="submit"
                    >
                      Set new password
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

export default ResetPassword;
