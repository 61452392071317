import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { SkeletonLoader } from '@umai/common';
import './ReservationsHeader.styles.scss';

interface ReservationsHeaderLoaderProps {
  isMobile?: boolean;
}

const ReservationsHeaderLoader = ({ isMobile }: ReservationsHeaderLoaderProps) => (
  <header
    className={classnames('reservations-header-loader columns', { 'is-tablet-nav': !isMobile })}
    id="reservations-header"
  >
    <nav className={classnames('column header__nav', { 'is-tablet-nav': !isMobile })}>
      <ul className="header__nav-list">
        {['current', 'finished', 'waitlist'].map((name) => (
          <li key={name} className="header__nav-item">
            <SkeletonLoader.Skeleton
              enableAnimation
              // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
              wrapper="button"
              baseColor="#fff"
              highlightColor="#e2e2e2"
              containerClassName="button"
              height="20px"
            />
          </li>
        ))}
      </ul>
    </nav>
    <section
      className={classnames('column search-and-filters-wrapper', { 'is-tablet-nav': !isMobile })}
    >
      <ul className="search-and-filters-list">
        <li className="search-input">
          <SkeletonLoader.Skeleton
            enableAnimation
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
            wrapper="div"
            height="40px"
            baseColor="#fff"
            highlightColor="#e2e2e2"
          />
        </li>
        <li className="header-button button1">
          <SkeletonLoader.Skeleton
            enableAnimation
            containerClassName="button"
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
            wrapper="span"
            baseColor="#fff"
            highlightColor="#e2e2e2"
            width="40px"
          />
        </li>
        <li className="header-button button2">
          <SkeletonLoader.Skeleton
            enableAnimation
            containerClassName="button green-dot"
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
            wrapper="span"
            baseColor="#fff"
            highlightColor="#e2e2e2"
            width="40px"
          />
        </li>
        <li className="header-button button3">
          <SkeletonLoader.Skeleton
            enableAnimation
            containerClassName="button"
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'FunctionC... Remove this comment to see the full error message
            wrapper="span"
            baseColor="#fff"
            highlightColor="#e2e2e2"
            width="40px"
          />
        </li>
      </ul>
    </section>
  </header>
);

ReservationsHeaderLoader.propTypes = {
  isMobile: PropTypes.bool,
};

export default ReservationsHeaderLoader;
