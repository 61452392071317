import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface EmailIconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}

function EmailIcon({
  width = 22,
  height = 14,
  color = COLORS.LIGHT_BLUE,
  className,
}: EmailIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.00077 9.54013C8.00447 9.47215 7.99406 9.40414 7.97023 9.34058C7.9464 9.27701 7.90969 9.21934 7.8625 9.17136C7.81532 9.12338 7.75875 9.08617 7.6965 9.0622C7.63426 9.03822 7.56776 9.02802 7.50139 9.03226H3.50195C3.43548 9.02785 3.36886 9.03793 3.30649 9.06183C3.24411 9.08573 3.1874 9.12291 3.14009 9.17091C3.09279 9.21892 3.05598 9.27665 3.03208 9.34029C3.00818 9.40394 2.99775 9.47205 3.00147 9.54013C2.99807 9.60802 3.00874 9.67589 3.03278 9.73926C3.05682 9.80263 3.09369 9.86008 3.14096 9.90784C3.18822 9.95559 3.24482 9.99256 3.30704 10.0163C3.36925 10.0401 3.43568 10.0501 3.50195 10.0457H7.50139C7.56757 10.05 7.63387 10.0398 7.69595 10.016C7.75804 9.99213 7.8145 9.95513 7.86164 9.90739C7.90879 9.85966 7.94555 9.80227 7.96953 9.73897C7.9935 9.67568 8.00415 9.60792 8.00077 9.54013Z"
        fill={color}
      />
      <path
        d="M6.5004 7.00107C6.5041 6.93309 6.4937 6.86508 6.46987 6.80151C6.44603 6.73795 6.40932 6.68028 6.36214 6.6323C6.31496 6.58431 6.25838 6.54711 6.19614 6.52313C6.13389 6.49916 6.0674 6.48895 6.00102 6.4932H2.00158C1.93512 6.48879 1.8685 6.49887 1.80612 6.52277C1.74374 6.54667 1.68703 6.58385 1.63973 6.63185C1.59243 6.67985 1.55561 6.73758 1.53171 6.80123C1.50782 6.86487 1.49738 6.93299 1.5011 7.00107C1.4977 7.06896 1.50838 7.13682 1.53242 7.2002C1.55646 7.26357 1.59332 7.32102 1.64059 7.36877C1.68786 7.41652 1.74446 7.4535 1.80667 7.47727C1.86889 7.50104 1.93531 7.51106 2.00158 7.50668H6.00102C6.0672 7.5109 6.1335 7.50075 6.19559 7.47691C6.25767 7.45306 6.31413 7.41606 6.36128 7.36833C6.40842 7.32059 6.44519 7.2632 6.46916 7.19991C6.49314 7.13662 6.50378 7.06886 6.5004 7.00107Z"
        fill={color}
      />
      <path
        d="M5.00004 4.46396C5.00374 4.39598 4.99333 4.32797 4.9695 4.26441C4.94567 4.20084 4.90895 4.14317 4.86177 4.09519C4.81459 4.04721 4.75802 4.01 4.69577 3.98602C4.63353 3.96205 4.56703 3.95184 4.50066 3.95609H0.501214C0.434751 3.95168 0.368131 3.96176 0.305754 3.98566C0.243377 4.00956 0.186664 4.04674 0.139362 4.09474C0.092059 4.14274 0.0552432 4.20047 0.0313464 4.26412C0.00744969 4.32776 -0.00298411 4.39588 0.000734318 4.46396C-0.00266176 4.53185 0.008011 4.59972 0.0320516 4.66309C0.0560922 4.72646 0.0929566 4.78391 0.140224 4.83166C0.187491 4.87942 0.244091 4.91639 0.306307 4.94016C0.368522 4.96393 0.434946 4.97395 0.501214 4.96957H4.50066C4.56683 4.97379 4.63314 4.96364 4.69522 4.9398C4.7573 4.91595 4.81377 4.87895 4.86091 4.83122C4.90806 4.78348 4.94482 4.72609 4.9688 4.6628C4.99277 4.59951 5.00342 4.53175 5.00004 4.46396Z"
        fill={color}
      />
      <path
        d="M19.2004 2.53377L13.8997 6.64628C13.6301 6.84446 13.3066 6.95105 12.9747 6.95105C12.6428 6.95105 12.3192 6.84446 12.0496 6.64628L6.75003 2.53377C6.698 2.49234 6.63837 2.46205 6.57467 2.44468C6.51098 2.42732 6.44453 2.42325 6.37926 2.4327C6.314 2.44215 6.25126 2.46495 6.19478 2.49972C6.13829 2.53449 6.08921 2.58054 6.05045 2.63512C6.00955 2.68784 5.9796 2.74855 5.96243 2.81354C5.94526 2.87853 5.94124 2.94643 5.95061 3.01306C5.95998 3.0797 5.98255 3.14368 6.01693 3.20107C6.05131 3.25846 6.09678 3.30806 6.15055 3.34682L11.5007 7.4582C11.9331 7.79101 12.4602 7.96942 13.0011 7.96607C13.5432 7.97727 14.0727 7.79803 14.5014 7.4582L19.7999 3.34682C19.8982 3.26116 19.9627 3.14172 19.9811 3.01089C19.9995 2.88007 19.9707 2.74686 19.9 2.63625C19.8146 2.53635 19.697 2.47117 19.5686 2.45257C19.4402 2.43397 19.3096 2.46319 19.2004 2.5349"
        fill={color}
      />
      <path
        d="M20.4995 0.401399H4.50065C4.43419 0.396991 4.36757 0.407071 4.30519 0.430973C4.24282 0.454874 4.1861 0.492054 4.1388 0.540055C4.0915 0.588056 4.05468 0.645786 4.03079 0.709432C4.00689 0.773077 3.99646 0.84119 4.00017 0.909269C3.99678 0.977165 4.00745 1.04503 4.03149 1.1084C4.05553 1.17177 4.0924 1.22922 4.13966 1.27698C4.18693 1.32473 4.24353 1.3617 4.30575 1.38547C4.36796 1.40924 4.43439 1.41926 4.50065 1.41489H20.4995C20.566 1.41048 20.6326 1.42056 20.695 1.44446C20.7574 1.46836 20.8141 1.50554 20.8614 1.55354C20.9087 1.60154 20.9455 1.65927 20.9694 1.72292C20.9933 1.78657 21.0037 1.85468 21 1.92276V12.0768C21.0039 12.145 20.9936 12.2132 20.9697 12.277C20.9459 12.3408 20.9091 12.3986 20.8618 12.4467C20.8145 12.4949 20.7577 12.5322 20.6953 12.5561C20.6328 12.5801 20.5661 12.5902 20.4995 12.5858H4.50065C4.43288 12.5811 4.36489 12.5908 4.30092 12.6142C4.23694 12.6376 4.17834 12.6741 4.12873 12.7216C4.07913 12.7692 4.03958 12.8266 4.01254 12.8904C3.98551 12.9542 3.97156 13.023 3.97156 13.0925C3.97156 13.1621 3.98551 13.2309 4.01254 13.2947C4.03958 13.3585 4.07913 13.4159 4.12873 13.4634C4.17834 13.5109 4.23694 13.5475 4.30092 13.5709C4.36489 13.5942 4.43288 13.6039 4.50065 13.5993H20.4995C20.6966 13.6038 20.8927 13.5677 21.0758 13.4929C21.259 13.4182 21.4256 13.3065 21.5656 13.1643C21.7057 13.0222 21.8163 12.8527 21.8909 12.6658C21.9655 12.479 22.0026 12.2786 21.9999 12.0768V1.92388C22.0026 1.72203 21.9655 1.5217 21.8909 1.33484C21.8163 1.14798 21.7057 0.978431 21.5656 0.836321C21.4256 0.694212 21.259 0.582456 21.0758 0.50773C20.8927 0.433004 20.6966 0.39684 20.4995 0.401399Z"
        fill={color}
      />
      <path
        d="M16.1492 7.66181C16.1018 7.7074 16.0641 7.76245 16.0383 7.82355C16.0125 7.88465 15.9991 7.95052 15.9991 8.01709C15.9991 8.08366 16.0125 8.14953 16.0383 8.21063C16.0641 8.27173 16.1018 8.32678 16.1492 8.37237L19.1488 11.4128C19.2437 11.505 19.3679 11.559 19.4986 11.5649C19.6289 11.5608 19.7535 11.5089 19.8495 11.4185C19.8969 11.3729 19.9346 11.3178 19.9604 11.2567C19.9862 11.1956 19.9996 11.1298 19.9996 11.0632C19.9996 10.9966 19.9862 10.9307 19.9604 10.8696C19.9346 10.8085 19.8969 10.7535 19.8495 10.7079L16.8499 7.66181C16.8045 7.61372 16.7502 7.57547 16.6901 7.54935C16.63 7.52323 16.5654 7.50977 16.5001 7.50977C16.4348 7.50977 16.3702 7.52323 16.3101 7.54935C16.25 7.57547 16.1957 7.61372 16.1503 7.66181"
        fill={color}
      />
    </svg>
  );
}

EmailIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default EmailIcon;
