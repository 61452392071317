import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import PrivateRoutes from './PrivateRoutes';

const Routes = () => (
  <Switch>
    <Route path={ROUTES.FORGOT_PASSWORD}>
      <ForgotPassword />
    </Route>

    <Route path={ROUTES.RESET_PASSWORD}>
      <ResetPassword />
    </Route>

    <Route path={ROUTES.LOGIN}>
      <Login />
    </Route>

    <Route path={ROUTES.LOGOUT}>
      <Logout />
    </Route>

    <PrivateRoutes />

    <Route path="*">
      <Redirect to={{ pathname: ROUTES.LOGIN, state: null }} />
    </Route>
  </Switch>
);

export default Routes;
