/* eslint-disable */
// We pre-filled your app ID in the widget URL: `https://widget.intercom.io/widget/{process.env.REACT_APP_INTERCOM_APP_ID}`
export default () => {
  (function () {
    var w = window;
    // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      // @ts-expect-error TS(2339): Property 'intercomSettings' does not exist on type... Remove this comment to see the full error message
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        // @ts-expect-error TS(2339): Property 'c' does not exist on type '() => void'.
        i.c(arguments);
      };
      // @ts-expect-error TS(2339): Property 'q' does not exist on type '() => void'.
      i.q = [];
      // @ts-expect-error TS(2339): Property 'c' does not exist on type '() => void'.
      i.c = function (args: any) {
        // @ts-expect-error TS(2339): Property 'q' does not exist on type '() => void'.
        i.q.push(args);
      };
      // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.defer = true;
        s.src = `https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}`;
        var x = d.getElementsByTagName('script')[0];
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        x.parentNode.insertBefore(s, x);
      };
      // @ts-expect-error TS(2339): Property 'attachEvent' does not exist on type 'Win... Remove this comment to see the full error message
      if (w.attachEvent) {
        // @ts-expect-error TS(2339): Property 'attachEvent' does not exist on type 'Win... Remove this comment to see the full error message
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export const setIntercom = ({ tag, options }: any) => {
  // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
  if (window.Intercom) {
    if (!options) {
      // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
      window.Intercom(tag);
      return;
    }
    // @ts-expect-error TS(2339): Property 'Intercom' does not exist on type 'Window... Remove this comment to see the full error message
    window.Intercom(tag, {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      ...options,
    });
  }
};
