import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Panel.styles.scss';

interface PanelProps {
  header: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  onClick?(...args: unknown[]): unknown;
  nestedPanel?: React.ReactNode;
}

const Panel = ({ header, children, className, onClick, nestedPanel }: PanelProps) => (
  <div
    className={classnames('panel', className, { 'is-clickable': !!onClick })}
    onClick={onClick}
    role="presentation"
    aria-label="panel"
  >
    <div className="panel-header">{header}</div>
    <div className="panel-body">{children}</div>
    {nestedPanel}
  </div>
);

Panel.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  nestedPanel: PropTypes.node,
};

export default Panel;
