import React, { cloneElement, lazy, Suspense, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SkeletonLoader } from '@umai/common';
import { AppContext } from 'contexts/app';
import { isMobileDevice } from 'utils/devices';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import { selectFooterDate } from 'modules/Footer/selectors';
import { selectAccessToken, selectIsAuthenticated } from 'modules/Auth/selectors';
import { TABLET_APP_WRAPPER } from 'constants/app';
import { formatDateToServerDate } from 'utils/date-and-time';
import SidebarLoader from '../Sidebar/Loader';
import TabletFooterLoader from 'modules/Footer/components/TabletFooter/Loader';
import MobileFooterLoader from 'modules/Footer/components/MobileFooter/Loader';
import './ThirdPartyAppLayout.styles.scss';

const MobileFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-mobile-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/MobileFooter'
    )
);

const TabletFooter = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-tablet-footer" */
      /* webpackFetchPriority: "high" */
      'modules/Footer/components/TabletFooter'
    )
);

const Sidebar = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "umai-sidebar" */
      /* webpackFetchPriority: "high" */
      '../Sidebar'
    )
);

interface ThirdPartyAppLayoutProps {
  id?: string;
  className?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
  hasNoFooter?: boolean;
  hideMobileFilters?: boolean;
}

const ThirdPartyAppLayout = ({
  id,
  className,
  header,
  children,
  footer,
  hasNoFooter = false,
  hideMobileFilters = false,
}: ThirdPartyAppLayoutProps) => {
  let config = {};
  const history = useHistory();
  const { hasSideBar } = useContext(AppContext);
  const footerDate = useAppSelector(selectFooterDate);
  const userAccessToken = useAppSelector(selectAccessToken);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const currentVenueId = useAppSelector(selectCurrentVenueId);

  const handleRouteChange = ({ pathname, search, state = {} }: any) => {
    history.push({
      pathname,
      search, // query string
      state,
    });
  };

  if (isAuthenticated) {
    config = {
      isUmai360: true,
      currentVenueId,
      userAccessToken,
      footerDate: formatDateToServerDate(footerDate),
      handleRouteChange,
    };
  }

  const renderFooter = () => {
    if (isMobileDevice) {
      return (
        <Suspense fallback={<MobileFooterLoader />}>
          <MobileFooter hideAvailabilities hideMobileFilters={hideMobileFilters} />
        </Suspense>
      );
    }

    return (
      <Suspense fallback={<TabletFooterLoader />}>
        <TabletFooter hasNoFooter={hasNoFooter} showGuestCount={false} />
      </Suspense>
    );
  };

  return (
    <div id="tablet-and-desktop-thirdparty-layout-wrapper" className={TABLET_APP_WRAPPER}>
      {hasSideBar() ? (
        <Suspense fallback={<SidebarLoader />}>
          <Sidebar />
        </Suspense>
      ) : null}
      <main id="main-content" className={classnames({ 'has-side-bar': hasSideBar() })}>
        {header}
        <div
          key={`thirdPartyAppLayout-${currentVenueId}`}
          className={classnames('body', {
            'has-no-footer': hasNoFooter,
          })}
        >
          <Suspense fallback={<SkeletonLoader.List />}>
            {currentVenueId ? (
              <div id={id} className={className}>
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                {cloneElement(children, { config })}
              </div>
            ) : (
              <SkeletonLoader.List />
            )}
          </Suspense>
        </div>
        {
          // feat(dashboard): up-961 use footer for shop-dashboard otherwise use webrms Footer
          footer || renderFooter()
        }
      </main>
    </div>
  );
};

ThirdPartyAppLayout.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  hasNoFooter: PropTypes.bool,
  hideMobileFilters: PropTypes.bool,
};

export default ThirdPartyAppLayout;
