import type { AppDispatch, AppGetState } from 'redux-store';
import debounce from 'lodash-es/debounce';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage, showSuccessMessage } from 'utils/alerts';
import * as ActionTypes from './action-types';
import waitlistApis from './apis';
import waitlistV3Apis from './apisV3';
import { VenueId } from 'modules/Venue/types';

const getWaitlistItemsDebounce = debounce(
  async ({ venueId, date, allDay, newCacheKey, showLoader = false, dispatch, signal }) => {
    try {
      const response = await waitlistApis.getItems({ venueId, date, allDay }, { signal });

      dispatch({
        type: ActionTypes.GET_WAITLIST_ITEMS,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        payload: { notifylists: response.notifylists, cacheKey: newCacheKey },
      });
    } catch (error) {
      if (waitlistApis.isCancel(error)) return;

      dispatch({ type: ActionTypes.SET_WAITLIST_ERROR, payload: { error } });
      showErrorMessage(getErrorMessage(error));
    } finally {
      if (showLoader) {
        dispatch({ type: ActionTypes.SET_WAITLIST_LOADING, payload: { isLoading: false } });
      }
    }
  },
  400
);

/**
 * Action to dispatch amd fetch waitlist items from api
 * uses a `newCacheKey` that comprises of `venueId + footerDate`, and compares it with the stored `cacheKey` in store
 *
 * If matched then does not perform this action and would use waitlist data prefetched in the store,
 * otherwise would hit api and store the latest waitlist based on the keys / params.
 *
 * @namespace Action
 * @param params Parameter
 * @param params.newCacheKey - New cache key generated from venue-id and footer-date
 * @param params.showLoader - Boolean to check if want to show loader where used. Like on detail or timeline
 * @param params.venueId - current venue id
 * @param params.date - current footer date
 *
 * @returns fetch the waitlist items from api and set in the store
 *
 */

export const getWaitlistItems = (params: any) => (dispatch: AppDispatch) => {
  const { showLoader } = params;

  const controller = new AbortController();

  if (showLoader) {
    dispatch({ type: ActionTypes.SET_WAITLIST_LOADING, payload: { isLoading: true } });
  }

  getWaitlistItemsDebounce({ ...params, dispatch, signal: controller.signal });

  return controller;
};

export const viewWaitlistItem = (waitlistId: number) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionTypes.VIEW_WAITLIST_ITEM,
    payload: { waitlistId },
  });
};

const dispatchWaitlistUpdate = ({ dispatch, getState, waitlistItem }: any) => {
  dispatch({
    type: ActionTypes.UPDATE_WAITLIST_ITEM,
    payload: { waitlistItem, footerDate: getState().footer.date },
  });
};

export const updateWaitlistItem =
  (id: any, data: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const response = await waitlistV3Apis.updateItem(data, id);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      dispatchWaitlistUpdate({ dispatch, getState, waitlistItem: response.notifylist });
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    }
  };

export const changeWaitlistStatus =
  (id: any, status: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const requestPayload = {
        status: status.toLowerCase(),
      };
      const response = await waitlistApis.updateStatus(requestPayload, id);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      dispatchWaitlistUpdate({ dispatch, getState, waitlistItem: response.notifylist });
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    }
  };

export const updateWaitlistInfo =
  (data: any, id: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const response = await waitlistV3Apis.updateItem(data, id);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      dispatchWaitlistUpdate({ dispatch, getState, waitlistItem: response.notifylist });
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    }
  };

export const updateWaitlistSearchText =
  (waitlistSearchText: any) => async (dispatch: AppDispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_WAITLIST_SEARCH_TEXT,
      payload: { waitlistSearchText },
    });
  };

export const updateWaitlistFromSocket = (socketWaitlist: any) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_WAITLIST_FROM_SOCKET,
    payload: socketWaitlist,
  });
};

export const updateWaitlistGuest =
  (waitlist: any) => (dispatch: AppDispatch, getState: AppGetState) => {
    dispatchWaitlistUpdate({ dispatch, getState, waitlistItem: waitlist });
  };

export const removeWaitlistItem = (waitlistId: number) => ({
  type: ActionTypes.REMOVE_FROM_WAITLIST,
  payload: { waitlistId },
});

export const resetWaitlistCache = () => ({
  type: ActionTypes.RESET_WAITLIST_CACHE,
});

export const sendWaitlistEmailConfirmation = async ({
  waitlistId,
  venueId,
}: {
  waitlistId: number;
  venueId: VenueId;
}) => {
  try {
    await waitlistApis.sendEmailConfirmation(waitlistId, venueId);
    showSuccessMessage(`Email notification sent to the guest!`);
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};
