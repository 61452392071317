import { useAppSelector } from 'hooks/redux';
import React, { createContext, useContext, useMemo, useState, ReactNode } from 'react';
import { toggleItem } from 'utils/array-helpers';
import { selectCurrentVenueId } from 'modules/Partner/selectors';
import { NotificationQueryKey, NotificationType } from './types';
import { NOTIFICATIONS_QUERY_KEY } from './constants';

type NotificationsContextType = {
  activeFilters: NotificationType[];
  toggleFilter: (filter: NotificationType) => void;
  notificationsQueryKey: NotificationQueryKey;
  isPopoverOpen: boolean;
  setIsPopoverOpen: (isOpen: boolean) => void;
};

const NotificationsContext = createContext<NotificationsContextType | null>(null);

type ProviderProps = {
  children: ReactNode;
};

export const NotificationsContextProvider = ({ children }: ProviderProps) => {
  const [activeFilters, setActiveFilters] = useState<NotificationType[]>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const venueId = useAppSelector(selectCurrentVenueId);
  const notificationsQueryKey = [
    venueId,
    NOTIFICATIONS_QUERY_KEY,
    activeFilters,
  ] satisfies NotificationQueryKey;

  const toggleFilter = (filter: NotificationType) => {
    setActiveFilters(toggleItem(activeFilters, filter));
  };

  const value = useMemo(
    () => ({
      activeFilters,
      toggleFilter,
      notificationsQueryKey,
      isPopoverOpen,
      setIsPopoverOpen,
    }),
    [activeFilters, toggleFilter, notificationsQueryKey]
  );

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error('useNotificationsContext has to be used within <NotificationsContextProvider>');
  }

  return context;
};
