import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import HttpRequest from 'utils/http-request';
import { VenueWarningsResponse } from './types';
import type { Venue } from 'modules/Venue/types';

class Partner extends HttpRequest {
  constructor() {
    super(API_ENDPOINT_PARTNER_V2);
  }

  fetchPartnerVenues() {
    return this.fetch<{ venues: Venue[] }>('/venues');
  }

  fetchPaymentProfiles() {
    return this.fetch('/maxio_subscriptions/warnings');
  }

  fetchVenueWarnings() {
    return this.fetch<VenueWarningsResponse>('/venue_warnings');
  }
}

export default new Partner();
