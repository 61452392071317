import parsePhoneNumberFromString from 'libphonenumber-js/min';
import { replaceAll } from 'utils/string-helpers';
import { TWO_ZEROS_PHONE_NUMBER } from 'modules/ReservationsList/constants';

const MIN_PHONE_NUMBER_LENGTH = 5;

export const parseSearchText = (searchText: string) => {
  const noSpaceSearchText = replaceAll(searchText, ' ', '');
  if (Number.isNaN(+noSpaceSearchText)) {
    return searchText;
  }
  if (noSpaceSearchText.slice(0, 2) === TWO_ZEROS_PHONE_NUMBER) {
    return `+${noSpaceSearchText.slice(2)}`;
  }
  return noSpaceSearchText;
};

export const addPlusSign = (phone: string) => {
  if (phone.indexOf('+') === -1) {
    return `+${phone}`;
  }
  return phone;
};

export const formatPhoneNumber = (number: string) => {
  if (typeof number === 'string' && number.length > MIN_PHONE_NUMBER_LENGTH) {
    const phoneNumber = parsePhoneNumberFromString(number);
    // parsePhoneNumberFromString returns undefined when number can't be parsed
    if (!phoneNumber) {
      return '';
    }
    // When input number is not same as parsed number show the original number
    // It might be the case the an additional 0 gets stripped by the `parsePhoneNumberFromString` function,
    // but the restaurant always wants to see the original number
    if (phoneNumber.number !== number) {
      return number;
    }
    return phoneNumber.formatInternational() || '';
  }
  return number;
};

export const isPhoneNumberValid = (number: string) => {
  if (typeof number === 'string' && number.length > MIN_PHONE_NUMBER_LENGTH) {
    const phoneNumber = parsePhoneNumberFromString(number);
    return phoneNumber?.isValid();
  }
  return false;
};

export const parsePhoneNumberOnSearch = (phone = '', callingCode = '') => {
  if (phone?.length && callingCode) {
    const callingCodeWithoutPlus = callingCode.substring(1);
    const firstTwoNumberOfPhone = phone.slice(0, 2);
    const firstNumberOfPhone = phone.slice(0, 1);

    if (firstNumberOfPhone === '+') {
      return phone;
    }
    if (callingCodeWithoutPlus === phone.slice(0, callingCodeWithoutPlus.length)) {
      return `+${phone}`;
    }
    if (firstTwoNumberOfPhone === TWO_ZEROS_PHONE_NUMBER) {
      return `${callingCode}${phone.substring(2)}`;
    }
    // we are assuming if the number is added without a calling code, its always in the calling code of the venue
    if (firstNumberOfPhone === '0') {
      return `${callingCode}${phone.substring(1)}`;
    }
    // we are assuming if the number is added without a calling code, its always in the calling code of the venue
    return `${callingCode}${phone}`;
  }

  return phone;
};

export const getPhoneError = (phone: string) => {
  if (phone[0] !== '+') {
    return `The phone number ${phone} is invalid, start the country code with + or 00`;
  }

  return `The phone number ${phone} is invalid, please enter a valid number`;
};
