import { normalize, schema } from 'normalizr';
import * as ActionTypes from 'modules/ReservationsList/action-types';
import {
  RESERVATION_LIST_TYPES,
  CURRENT_LIST_KEY,
  FINISHED_LIST_KEY,
} from 'modules/ReservationsList/constants';
import { findReservationAndListKey, reservationListSlice } from 'modules/ReservationsList/slice';

const reservationSchema = new schema.Entity('reservations');
const slotSchema = new schema.Entity(
  'slots',
  { reservations: [reservationSchema] },
  {
    idAttribute: (entity) => entity.startTime,
    processStrategy: (entity) => ({ ...entity, id: entity.startTime }),
  }
);

const normalizeSlots = (slots: any) => {
  // normalize the data so reducers can responded to a predictable payload,
  // in this case: `payload = { slots: {} reservations: {} }`
  const normalized = normalize(slots, [slotSchema]);
  return normalized.entities;
};

const INITIAL_STATE = {
  [CURRENT_LIST_KEY]: { slots: {}, reservations: {} },
  [FINISHED_LIST_KEY]: { slots: {}, reservations: {} },
  selectedReservationId: null,
  reservationListType: RESERVATION_LIST_TYPES.RESERVED,
  currentListLoading: true,
  finishedListLoading: true,
  searchText: '',
  error: null,
  statusError: false,
  activities: [],
  activitiesLoading: false,
  isExportingReservations: false,
  abortController: null,
};

// eslint-disable-next-line default-param-last
const reservationReducer = (state = INITIAL_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_CURRENT_RESERVATIONS_LIST: {
      const { reservationList } = payload;
      return {
        ...state,
        currentListLoading: false,
        [CURRENT_LIST_KEY]: normalizeSlots(reservationList?.slots || []),
      };
    }
    case ActionTypes.GET_FINISHED_RESERVATIONS_LIST: {
      const { reservationList } = payload;
      return {
        ...state,
        finishedListLoading: false,
        [FINISHED_LIST_KEY]: normalizeSlots(reservationList?.slots || []),
      };
    }
    case ActionTypes.SET_RESERVATIONS_LIST_ERROR: {
      const { error, loading = {} } = payload;
      return { ...state, error, ...loading };
    }
    case ActionTypes.SET_RESERVATIONS_LIST_LOADING: {
      const { loading = {} } = payload;
      return { ...state, ...loading };
    }
    case ActionTypes.VIEW_RESERVATION: {
      const { reservationId } = payload;
      return { ...state, selectedReservationId: reservationId };
    }
    case ActionTypes.UPDATE_RESERVATION: {
      const { reservation, footerDate } = payload;
      let { reservation: currentReservation } = findReservationAndListKey({
        reservationId: reservation.id,
        reservationLists: {
          [CURRENT_LIST_KEY]: state[CURRENT_LIST_KEY],
          [FINISHED_LIST_KEY]: state[FINISHED_LIST_KEY],
        },
      });

      if (!currentReservation) return state;

      currentReservation = { ...currentReservation, ...reservation };

      return {
        ...state,
        ...(reservationListSlice({
          footerDate,
          reservationId: currentReservation.id,
          currentStatus: currentReservation.status,
          currentReservation,
          reservationLists: {
            [CURRENT_LIST_KEY]: state[CURRENT_LIST_KEY],
            [FINISHED_LIST_KEY]: state[FINISHED_LIST_KEY],
          },
        }) || {}),
      };
    }
    case ActionTypes.UPDATE_RESERVATION_FROM_SOCKET: {
      const { footerDate, reservationId, currentStatus, currentReservation } = payload;

      return {
        ...state,
        ...(reservationListSlice({
          footerDate,
          reservationId,
          currentStatus,
          currentReservation,
          reservationLists: {
            [CURRENT_LIST_KEY]: state[CURRENT_LIST_KEY],
            [FINISHED_LIST_KEY]: state[FINISHED_LIST_KEY],
          },
        }) || {}),
      };
    }

    case ActionTypes.UPDATE_SEARCH_TEXT: {
      const { searchText } = payload;
      return { ...state, searchText };
    }
    case ActionTypes.UPDATE_RESERVATION_LIST_TYPE: {
      const { reservationListType } = payload;
      return { ...state, reservationListType };
    }
    case ActionTypes.SET_RESERVATION_STATUS_ERROR: {
      const { error } = payload;
      return { ...state, statusError: error };
    }
    case ActionTypes.SET_STATUS_LOADING: {
      return { ...state, ...payload };
    }
    case ActionTypes.GET_RESERVATION_ACTIVITIES: {
      return { ...state, activities: payload };
    }
    case ActionTypes.SET_RESERVATION_ACTIVITIES_LOADING: {
      return { ...state, activitiesLoading: payload };
    }
    case ActionTypes.EXPORT_RESERVATIONS:
      return { ...state, ...action.payload };
    case ActionTypes.CANCEL_EXPORT_RESERVATIONS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reservationReducer;
