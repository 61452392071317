/* eslint-disable camelcase, no-console */
import OrderNotification from 'utils/notification';
import { ORDER_NOTIFICATION_AUDIO_ID } from 'modules/Order/constants';

const firebaseMessagingSw = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
      .then((registration) => {
        console.debug('Registration successful, scope is:', registration.scope);
      })
      .catch((err) => {
        console.debug('Service worker registration failed, error:', err);
      });

    navigator.serviceWorker.addEventListener('message', (event) => {
      console.debug('Received a message from service worker: ', event.data);
      const onMessageOrderNotify = new OrderNotification({ audioId: ORDER_NOTIFICATION_AUDIO_ID });
      const { postMessageType } = event?.data || {};
      switch (postMessageType) {
        case 'show-notification':
          onMessageOrderNotify.playSound();
          break;
        case 'close-notification':
          onMessageOrderNotify.pauseSound();
          break;
        default:
          break;
      }
    });
  }
};

export default firebaseMessagingSw;
