import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PropTypes from 'prop-types';
import { closeDrawer, openDrawer, useDrawer } from 'contexts/drawer';
import {
  selectSearchText,
  selectMasterGuestSuccess,
  selectSelectedMasterGuestId,
} from 'modules/GuestsCrm/selectors';
import { updateMasterGuestsSearchText, resetSelectedGuestId } from 'modules/GuestsCrm/actions';
import SearchInput from 'components/ui/SearchInput';
import './GuestCrmHeader.styles.scss';

interface GuestCrmHeaderProps {
  onFocus?(): void;
  onBlur?(): void;
}

const GuestCrmHeader = ({ onFocus, onBlur }: GuestCrmHeaderProps) => {
  const dispatch = useAppDispatch();
  const searchText = useAppSelector(selectSearchText);
  const masterGuestSuccess = useAppSelector(selectMasterGuestSuccess);
  const guestId = useAppSelector(selectSelectedMasterGuestId);
  const {
    dispatch: drawerDispatch,
    state: { data: drawerData },
  } = useDrawer();
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const isAddGuestDrawerOpen = drawerData.item === 'add_guest';

  // Open the drawer to create a new guest, then open guest details after after adding the guest
  const openAddGuestDrawer = () =>
    drawerDispatch(
      openDrawer({
        drawerComponent: 'ADD_NEW_GUEST',
        data: { item: 'add_guest' },
      })
    );

  const openDetailsDrawer = () =>
    drawerDispatch(
      openDrawer({
        drawerComponent: 'MASTER_GUEST_DETAILS',
        data: { guestId },
      })
    );

  const handleButtonClick = () => {
    dispatch(resetSelectedGuestId());
    if (isAddGuestDrawerOpen) {
      drawerDispatch(closeDrawer());
    } else {
      openAddGuestDrawer();
    }
  };

  // open guest details drawer after creating guest
  useEffect(() => {
    if (masterGuestSuccess) {
      openDetailsDrawer();
    }
  }, [masterGuestSuccess]);

  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateMasterGuestsSearchText(e.target.value));
    // If there is no search text, then show the mobile navigation in footer.
    // This is done for the case, where users manually close keyboard, they can clear the text and navigate on mobile
    if (!e.target.value.length && onBlur) {
      onBlur();
    } else if (onFocus) {
      onFocus();
    }
  };

  return (
    <header id="master-guests-header" className="columns">
      <nav className="column" id="guest-search-and-filter">
        <ul>
          <li className="search-input" title="Search guest name, phone, or email">
            <SearchInput
              placeholder="Search guest name, phone, or email"
              value={searchText}
              onChange={onSearchTextChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </li>
        </ul>
      </nav>
      <nav className="column" id="create-new-guest-wrapper">
        <ul>
          <li className="header-button">
            <button className="button" type="button" onClick={handleButtonClick}>
              Create new guest
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

GuestCrmHeader.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
export default GuestCrmHeader;
