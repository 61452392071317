import { API_ENDPOINT_PARTNER_V3 } from 'constants/app';
import HttpRequest from 'utils/http-request';

class WaitlistV3 extends HttpRequest {
  constructor() {
    super(`${API_ENDPOINT_PARTNER_V3}/notifylists`);
  }

  updateItem(data: any, id: any) {
    return this.update(`/${id}`, data);
  }

  createWaitlistReservation(data: any) {
    return this.create('', data);
  }
}

export default new WaitlistV3();
