import React from 'react';
import PublicLayout from 'layouts/PublicLayout';
import ForgotPasswordModule from 'modules/Auth/components/ForgotPassword';

function ForgotPassword() {
  return (
    <PublicLayout>
      <ForgotPasswordModule />
    </PublicLayout>
  );
}

export default ForgotPassword;
