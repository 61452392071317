/* eslint-disable camelcase, no-console */
import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useAppDispatch } from 'hooks/redux';
import { getVenueDetails } from 'modules/Partner/actions';
import { getReservationAvailabilities, getVenueAvailabilitySummary } from 'modules/Footer/actions';
import { MESSAGE_EVENTS } from 'constants/socket';
import { DAY_STATE } from 'modules/DayAvailability/constants';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationsContext } from 'modules/NotificationCenter/context';
import { NOTIFICATIONS_UNREAD_QUERY_KEY } from 'modules/NotificationCenter/constants';

const useSocketVenueAndAvailabilities = ({ venueId, footerDate }: any) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  let availabilitiesController: any = null;
  let availabilitySummaryController: any = null;
  const { notificationsQueryKey } = useNotificationsContext();

  const venueAndAvailabilitiesUpdate = ({ detail = {} }) => {
    try {
      // @ts-expect-error TS(2339): Property 'params' does not exist on type '{}'.
      const params = detail?.params || {};
      console.debug('VENUE and AVAILABILITIES: socket update: ', detail, params?.venue_id);
      if (+(params?.venue_id || 0) === +venueId) {
        if (params?.availability_changed) {
          queryClient.invalidateQueries({ queryKey: [DAY_STATE] });
        }

        // Clear notifications cache when socket update contains notification
        if (params.new_notification) {
          queryClient.invalidateQueries({ queryKey: notificationsQueryKey });
          queryClient.invalidateQueries({ queryKey: [venueId, NOTIFICATIONS_UNREAD_QUERY_KEY] });
        }

        batch(() => {
          dispatch(getVenueDetails(venueId));
          // if there is a change in reservation availability then fetch footer and availabilities
          if (params.reservation_availability_id) {
            availabilitiesController = dispatch(
              getReservationAvailabilities({ venueId, footerDate })
            );
            availabilitySummaryController = dispatch(
              getVenueAvailabilitySummary(venueId, footerDate)
            );
          }
        });
      }
    } catch (error) {
      console.error('Error in update reservation view from socket', error);
    }
  };

  useEffect(() => {
    if (venueId)
      // @ts-expect-error TS(2769): No overload matches this call.
      document.addEventListener(
        MESSAGE_EVENTS.venueAndAvailabilities,
        venueAndAvailabilitiesUpdate,
        false
      );
    return () => {
      availabilitiesController?.abort();
      availabilitySummaryController?.abort();
      // @ts-expect-error TS(2769): No overload matches this call.
      document.removeEventListener(
        MESSAGE_EVENTS.venueAndAvailabilities,
        venueAndAvailabilitiesUpdate,
        false
      );
    };
  }, [venueId]);
};

export default useSocketVenueAndAvailabilities;
