import React, { useEffect, useRef, useState } from 'react';
import { showOfflineNotification, showOnlineNotification } from 'utils/alerts';
import { socketWorker } from 'workers';
import { SOCKET_ACTION } from 'constants/socket';

const NetworkStatus = () => {
  const networkBackdrop = useRef<HTMLDivElement>(null);
  const [isOline, setIsOnline] = useState(true);

  const showNetworkBackdrop = () => {
    if (networkBackdrop.current) {
      networkBackdrop.current.style.display = 'block';
    }
  };

  const hideNetworkBackdrop = () => {
    if (networkBackdrop.current) {
      networkBackdrop.current.style.display = 'none';
    }
  };

  const handleOnlineState = () => {
    showOnlineNotification();
    hideNetworkBackdrop();
    socketWorker.postMessage({
      ...SOCKET_ACTION.checkState,
      refetchDataIfSocketClosed: false,
      fetchDataOnReconnection: true, // this is used to fetch data on refresh here
    });
  };

  const handleOfflineState = () => {
    showOfflineNotification();
    showNetworkBackdrop();
  };

  const checkApiStatus = async () => {
    // check first if its offline
    if (!window?.navigator?.onLine) {
      setIsOnline(false);
      return false;
    }

    // navigator.onLine cannot be trusted: there's situation where you appear to be online (connect to a network with no internet)
    // but still cannot access the internet.
    // So to fix: we request to our own origin to avoid CORS errors
    const url = new URL(window.location.origin);
    //  with random value to prevent cached responses
    url.searchParams.set('network-status-online', Date.now().toString());

    try {
      const response = await fetch(url.toString(), { method: 'HEAD', cache: 'no-cache' });
      setIsOnline(response.ok);
      return response.ok;
    } catch {
      setIsOnline(false);
      return false;
    }
  };

  const checkInternetConnection = async () => {
    if (await checkApiStatus()) {
      handleOnlineState();
    } else {
      handleOfflineState();
    }
  };

  const handleOnlineOnVisibleChange = async () => {
    // if the page is visible and state was offline
    if (document.visibilityState === 'visible' && !isOline) {
      // on visiting the page again if the state is offline and network is online, then show online alert
      if (await checkApiStatus()) {
        handleOnlineState();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleOnlineOnVisibleChange, false);

    return () => {
      document.removeEventListener('visibilitychange', handleOnlineOnVisibleChange, false);
    };
  }, [isOline]);

  useEffect(() => {
    window.addEventListener('online', checkInternetConnection);
    window.addEventListener('offline', checkInternetConnection);

    return () => {
      window.removeEventListener('online', checkInternetConnection);
      window.removeEventListener('offline', checkInternetConnection);
    };
  }, []);

  return (
    <div
      style={{ display: 'none' }}
      onTouchEnd={(e) => {
        // to prevent action on touch devices when clicked on overlay..
        e.preventDefault();
        e.stopPropagation();
      }}
      id="network-notification-backdrop"
      ref={networkBackdrop}
      aria-label="network status"
    ></div>
  );
};

export default NetworkStatus;
