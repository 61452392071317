import ActionCable from 'actioncable';

export class SocketConnection {
  cable: any;

  constructor(socketHostUrl = '') {
    this.cable = ActionCable.createConsumer(socketHostUrl);
  }

  subscribe = ({ onReceivedData }: any, channelData = {}) =>
    this.cable.subscriptions.create(channelData, {
      // eslint-disable-next-line
      connected: () => console.debug(`socket connection connected`),
      // eslint-disable-next-line
      disconnected: () => console.debug(`socket connection disconnected`),
      received: onReceivedData,
      // eslint-disable-next-line
      rejected: () => console.debug(`socket connection rejected`),
    });

  unsubscribe = (subscription: any) => subscription?.unsubscribe();

  disconnect = () => this.cable.disconnect();
}
