import moment from 'moment-timezone';
import { ROUTES } from 'constants/routes';
import {
  STATUSES,
  UNFINISHED_STATUSES,
  FINISHED_STATUSES,
  RESERVED_SUBSTATUSES,
  RESERVATION_LIST_TYPES,
  GRACE_TIME_FOR_RESERVATION_STARTTIME,
  GRACE_TIME_FOR_RESERVATION_ENDTIME,
  GRACE_TIME_FOR_RESERVATION_LATE,
  ALL_DAY_AVAILABILITY,
} from 'modules/ReservationsList/constants';
import { CHARGE_TYPES, REFUND_CHARGE_TYPES, PAYMENT_STATUS } from 'constants/charge';
import {
  SERVER_DATE_TIME,
  SERVER_TIME,
  TIME_SLOT_FORMAT,
  DAY_START_OFFSET,
} from 'constants/time-and-date';
import { checkStringMatch } from 'utils/string-helpers';
import { combineTagArrays } from 'utils/array-helpers';
import { formatTimeToNumber, isCurrentOrPreviousDay } from 'utils/date-and-time';

export const getReservationGuest = (reservation: any) => {
  const { overrideGuestInfo } = reservation || {};
  const primaryGuest = reservation?.primaryGuest || reservation?.masterGuest;
  const {
    isDiffer = false,
    differences: {
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      firstName: overrideFirstName,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      lastName: overrideLastName,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      phone: overridePhone,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      email: overrideEmail,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      name: overrideName,
    } = {},
  } = overrideGuestInfo || { differences: {} };

  const { firstName, lastName, phone, email, name } = primaryGuest || {};

  if (isDiffer) {
    return {
      firstName: overrideFirstName || firstName,
      lastName: overrideLastName || lastName,
      email: overrideEmail || email,
      phone: overridePhone || phone,
      name: overrideName || name,
    };
  }
  return {
    firstName,
    lastName,
    email,
    phone,
    name,
  };
};

export const getOverRideGuest = (reservation = {}) => {
  // @ts-expect-error TS(2339): Property 'overrideGuestInfo' does not exist on typ... Remove this comment to see the full error message
  const { overrideGuestInfo } = reservation;
  // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
  const { isDiffer = false, differences: { firstName, lastName, phone, email, name } = {} } =
    overrideGuestInfo || {
      differences: {},
    };

  return {
    isDiffer,
    overrideFirstName: firstName || '',
    overrideLastName: lastName || '',
    overrideEmail: email || '',
    overridePhone: phone || '',
    overrideName: name || '',
  };
};

export const getReservationGuestName = (reservation = {}) => {
  // @ts-expect-error TS(2339): Property 'walkin' does not exist on type '{}'.
  const { walkin = false, temporaryGuest } = reservation;
  // @ts-expect-error TS(2339): Property 'primaryGuest' does not exist on type '{}... Remove this comment to see the full error message
  const hasAttachedGuest = !!reservation?.primaryGuest?.id;
  const { name } = getReservationGuest(reservation);
  if (temporaryGuest) {
    return 'Temporary guest';
  }
  if (walkin && !hasAttachedGuest) {
    return 'Walk-in';
  }
  return name?.trim();
};

export const getRoomNames = (rooms = []) => {
  // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
  const roomNames = (rooms || []).map((room) => room.name);
  // @ts-expect-error TS(2802): Type 'Set<any>' can only be iterated through when ... Remove this comment to see the full error message
  return [...new Set(roomNames)].join(', ');
};

// @ts-expect-error TS(2339): Property 'note' does not exist on type '{}'.
export const getGuestNotes = (notes = {}) => (notes || {}).note || '';

export const getTableNames = (tables = []) => `${tables.map(({ name }) => name).join(',')}`;

export const getPartySize = (tables = []) => {
  let maxSize = 0;
  let minSize = 0;
  if (tables.length) {
    // @ts-expect-error TS(2339): Property 'maxSize' does not exist on type 'never'.
    maxSize = tables.reduce((sum, table) => (table?.maxSize || 0) + sum, 0);
    // @ts-expect-error TS(2339): Property 'minSize' does not exist on type 'never'.
    minSize = Math.min(...tables.map((t) => t.minSize));
  }
  return { maxSize, minSize };
};

export const getTableLockStatus = (tables = []) => {
  if (tables.length > 0) {
    // @ts-expect-error TS(2339): Property 'locked' does not exist on type 'never'.
    return tables[0].locked;
  }
  return false;
};

export const getAttentionOnReservation = (
  startWarning: any,
  endWarning: any,
  slotStartTime: any,
  reservationEndTime: any,
  status: any
) => {
  const reservationStatus = status?.toUpperCase() || '';
  let result = false;

  if (UNFINISHED_STATUSES.includes(reservationStatus)) {
    if (reservationEndTime && isCurrentOrPreviousDay(reservationEndTime)) {
      if (
        moment().isAfter(
          moment(reservationEndTime).add(GRACE_TIME_FOR_RESERVATION_ENDTIME, 'minutes')
        )
      ) {
        result = true;
      }
    }
  }
  if ([STATUSES.RESERVED, STATUSES.CONFIRMED].includes(reservationStatus)) {
    if (slotStartTime && isCurrentOrPreviousDay(slotStartTime)) {
      if (
        moment().isAfter(moment(slotStartTime).add(GRACE_TIME_FOR_RESERVATION_STARTTIME, 'minutes'))
      ) {
        result = true;
      }
    }
  }

  return (startWarning || endWarning) && result;
};

const checkIsGuestCountMatched = (guestCount: any, selectedPartySizes: any) => {
  const { smallPartySize = [], bigPartySize = [], customPartySize = [] } = selectedPartySizes;
  // the order is important, if both big and small partsizes are selected, return all else start with custom party size
  if (smallPartySize.length && bigPartySize.length) return true;
  if (customPartySize.length && smallPartySize.length) {
    // if small partsize and custom are selected, for example, small part size and 7 parties
    if (customPartySize.includes('10+') && guestCount >= 10) return true;
    return customPartySize.includes(guestCount) || guestCount <= 5;
  }
  if (customPartySize.length && bigPartySize.length) {
    // if big partsize and custom are selected, for example, big part size and 3 parties
    if (customPartySize.includes('10+') && guestCount >= 10) return true;
    return customPartySize.includes(guestCount) || guestCount >= 6;
  }
  if (customPartySize.length) {
    // if 10+ party size is selected, then return all party sizes that are more than or equal 10
    if (customPartySize.includes('10+') && guestCount >= 10) return true;
    return customPartySize.includes(guestCount);
  }
  if (smallPartySize.length) return guestCount <= 5;
  if (bigPartySize.length) return guestCount >= 6;
  return true;
};

const filterByAvailabilities = (reservations = [], availabilityId = ALL_DAY_AVAILABILITY) =>
  reservations.filter((reservation) => {
    // @ts-expect-error TS(2339): Property 'slot' does not exist on type 'never'.
    const { reservationAvailabilityId } = reservation?.slot || {};
    const isAvailabilitySelected =
      reservationAvailabilityId === availabilityId || availabilityId === ALL_DAY_AVAILABILITY;

    return isAvailabilitySelected;
  });

const filterBySearchText = (reservations: any, strToSearch = '') => {
  // remove extra whitespaces
  const searchText = String(strToSearch)?.toLowerCase()?.trim();
  if (!searchText?.length) {
    return reservations;
  }

  return reservations.filter((reservation: any) => {
    let tempGuest: any = [];
    const { firstName, lastName, email, phone, temporaryGuest } = reservation;
    if (temporaryGuest) tempGuest = [firstName, lastName, email, phone];
    const isStringMatched = checkStringMatch(
      [
        ...Object.values(getReservationGuest(reservation)),
        getReservationGuestName(reservation),
        ...tempGuest,
      ],
      searchText
    );

    return isStringMatched;
  });
};

const filterByStatuses = (reservations: any, statusFilters: any) => {
  // Don't perform any search if no status is selected or the slot is not available
  if (!statusFilters.length) {
    return reservations;
  }

  return reservations.filter((reservation: any) => {
    const { status = '', startWarning, endWarning, slotStartTime, endTime } = reservation;
    // @ts-expect-error TS(2339): Property 'ATTENTION' does not exist on type '{ RES... Remove this comment to see the full error message
    const isAttentionReservation = statusFilters.includes(STATUSES.ATTENTION)
      ? getAttentionOnReservation(startWarning, endWarning, slotStartTime, endTime, status)
      : false;
    // if attention reservation filter is selected, then match all status that are attention reservation
    const isStatusMatched = isAttentionReservation || statusFilters.includes(status.toUpperCase());

    return isStatusMatched;
  });
};

const filterByPartySizes = (reservations: any, partySizeFilters: any) =>
  reservations.filter((reservation: any) => {
    const { guestCount } = reservation;
    const isGuestCountMatched = checkIsGuestCountMatched(guestCount, partySizeFilters);

    return isGuestCountMatched;
  });

const filterByRooms = (reservations: any, roomFilters: any) => {
  if (!roomFilters.length) {
    return reservations;
  }

  return reservations.filter((reservation: any) => {
    let isRoomMatched = false;
    const { rooms = [] } = reservation;
    if (rooms?.length) {
      const roomNames = rooms.map(({ name }: any) => name);
      // if any of the reservation rooms is selected, show the reservation visible
      isRoomMatched = roomNames.some((room: any) => roomFilters.includes(room));
    }

    return isRoomMatched;
  });
};

const filterByTags = (reservations: any, tagsFilter: any) => {
  if (!tagsFilter) {
    return reservations;
  }

  return reservations.filter((reservation: any) => {
    const { automatedTags = [], tags = [], primaryGuest = {} } = reservation;
    const { tags: guestTags = [], automatedTags: automatedGuestTags = [] } = primaryGuest || {};

    const combinedGuestTags = combineTagArrays({ autoTags: automatedGuestTags, tags: guestTags });
    const combinedRsvTags = combineTagArrays({ autoTags: automatedTags, tags });
    // We filtering both the visit and guest tags
    const isTagsSelected = !!combinedGuestTags.length || !!combinedRsvTags.length;

    return isTagsSelected;
  });
};

const filterByNotes = (reservations: any, notesFilter: any) => {
  // Note filter is always false by default
  if (!notesFilter) {
    return reservations;
  }

  return reservations.filter((reservation: any) => {
    const { notes = '', primaryGuest = {} } = reservation;
    const guestNote = primaryGuest?.guestNote;
    let guestNotesList = {};
    if (guestNote) {
      // @ts-expect-error TS(2339): Property 'note' does not exist on type 'String'.
      guestNotesList = Object.keys(guestNote).map(({ note }) => note);
    }
    // We filtering both the visit and guest notes
    // @ts-expect-error TS(2339): Property 'length' does not exist on type '{}'.
    const isNoteSelected = !!notes || !!guestNotesList.length;

    return isNoteSelected;
  });
};

export const applyFilters = ({ slots, availabilityId, searchText, reservationFilters }: any) => {
  const {
    status = [],
    room = [],
    partySize = {},
    tags = false,
    notes = false,
  } = reservationFilters;

  const filteredSlots = slots.map((slot: any) => {
    const { reservations = [] } = slot;

    if (!reservations?.length) return slot;

    /*
     * order of filter matters on applying filters:
     * first is availability and then the rest filter applies with availability filter
     */
    const filters = [
      { fn: filterByAvailabilities, args: availabilityId },
      { fn: filterBySearchText, args: searchText },
      { fn: filterByStatuses, args: status },
      { fn: filterByRooms, args: room },
      { fn: filterByPartySizes, args: partySize },
      { fn: filterByTags, args: tags },
      { fn: filterByNotes, args: notes },
      // ... (other filters)
    ];

    return {
      ...slot,
      reservations: filters.reduce(
        (result, filter) => filter.fn(result, filter.args),
        reservations
      ),
    };
  });

  return filteredSlots.filter((slot: any) => slot.reservations.length);
};

export const getGuestCount = (reservationList: any) => {
  let count = 0;
  if (reservationList?.length) {
    const reducer = (accumulator: any, slot: any) =>
      accumulator +
      slot.reservations.reduce((acc: any, item: any) => acc + (item?.guestCount || 0), 0);
    count = reservationList.reduce(reducer, 0);
  }
  return count;
};

export const reservationCount = (reservationList: any) => {
  let count = 0;
  if (reservationList?.length) {
    count = reservationList.reduce(
      (acc: any, slot: any) => acc + (slot.reservations?.length || 0),
      0
    );
  }
  return count;
};

export const getReservationListType = (route: any) => {
  switch (route) {
    case ROUTES.FINISHED_RESERVATIONS: {
      return RESERVATION_LIST_TYPES.FINISHED;
    }
    case ROUTES.RESERVATIONS:
    case ROUTES.HOME: {
      return RESERVATION_LIST_TYPES.RESERVED;
    }
    case ROUTES.WAITLIST:
      return RESERVATION_LIST_TYPES.WAITLIST;
    case ROUTES.QUEUE_LIST:
      return RESERVATION_LIST_TYPES.QUEUE;
    default: {
      return null; // returning null to make sure we only fetchReservations when its a reservation list route.
    }
  }
};

const createTimerForReservation = (duration: any, graceTime: any, callback: any) => {
  let timer = null;
  if (duration <= graceTime) {
    timer = setTimeout(callback, Math.abs(duration * 60000));
  }
  return timer;
};

export const setTimeoutToUpdateAttentionOnReservation = (
  slotStartTime: any,
  reservationEndTime: any,
  status: any,
  callback: any
) => {
  const reservationStatus = status?.toUpperCase() || '';
  let startTimer: any = null;
  let endTimer: any = null;

  if (UNFINISHED_STATUSES.includes(reservationStatus)) {
    if (reservationEndTime && isCurrentOrPreviousDay(reservationEndTime)) {
      endTimer = createTimerForReservation(
        moment
          .duration(
            moment().diff(
              moment(reservationEndTime).add(GRACE_TIME_FOR_RESERVATION_ENDTIME, 'minutes')
            )
          )
          .asMinutes(),
        GRACE_TIME_FOR_RESERVATION_ENDTIME,
        callback
      );
    }
  }
  if ([STATUSES.RESERVED, STATUSES.CONFIRMED].includes(reservationStatus)) {
    if (slotStartTime && isCurrentOrPreviousDay(slotStartTime)) {
      startTimer = createTimerForReservation(
        moment
          .duration(
            moment().diff(
              moment(slotStartTime).add(GRACE_TIME_FOR_RESERVATION_STARTTIME, 'minutes')
            )
          )
          .asMinutes(),
        GRACE_TIME_FOR_RESERVATION_STARTTIME,
        callback
      );
    }
  }

  return () => {
    clearTimeout(endTimer);
    clearTimeout(startTimer);
  };
};

export const statusesToMarkReservationLate = (status = '') =>
  [...RESERVED_SUBSTATUSES.filter((s) => s !== STATUSES['NO-SHOW']), STATUSES.RESERVED].includes(
    status.toUpperCase()
  );

export const checkReservationIsLate = (reservationStartDate: any, status: any) => {
  if (statusesToMarkReservationLate(status)) {
    if (reservationStartDate && isCurrentOrPreviousDay(reservationStartDate)) {
      return moment().isAfter(
        moment(reservationStartDate).add(GRACE_TIME_FOR_RESERVATION_LATE, 'minutes')
      );
    }
  }
  return false;
};

export const setTimeoutToUpdateReservationToLate = (
  reservationStartDate: any,
  status: any,
  callback: any
) => {
  let timer = null;
  if (statusesToMarkReservationLate(status)) {
    if (reservationStartDate && isCurrentOrPreviousDay(reservationStartDate)) {
      timer = createTimerForReservation(
        moment
          .duration(
            moment().diff(
              moment(reservationStartDate).add(GRACE_TIME_FOR_RESERVATION_LATE, 'minutes')
            )
          )
          .asMinutes(),
        GRACE_TIME_FOR_RESERVATION_LATE,
        callback
      );
    }
  }
  return timer;
};

export const isFailedReservation = (
  status: any,
  paymentStatus: any,
  reservationPaymentStatus: any
) => {
  if (status === STATUSES.FAILED) {
    return true;
  }

  // when reservation is created as free (on cancellation) and payment status is failed
  if (paymentStatus === PAYMENT_STATUS.failed && reservationPaymentStatus === PAYMENT_STATUS.free) {
    return true;
  }

  return paymentStatus === PAYMENT_STATUS.failed;
};

export const isReservationChargedOrRefunded = (payableType: any, paymentStatus: any) => {
  if (payableType === CHARGE_TYPES.cancellation) {
    return paymentStatus === PAYMENT_STATUS.success;
  }
  if (REFUND_CHARGE_TYPES.includes(payableType)) {
    return paymentStatus === PAYMENT_STATUS.refund;
  }
  return false;
};

export const isGuestHaveOtherReservations = ({
  status = '',
  reservationsInOtherRestaurantsForSameGuest = [],
}) => {
  if (!status) return false;
  if (FINISHED_STATUSES.includes(status)) return false;
  return !!reservationsInOtherRestaurantsForSameGuest?.length;
};

export const getAvailabilityEndTimeOfReservation = ({ availability, slotStartTime = '' }: any) => {
  if (availability?.startTime && availability?.endTime && slotStartTime) {
    const availabilityEndDateTime = moment().set({
      h: 0,
      m: formatTimeToNumber(availability?.endTime, 'minutes'),
      s: 0,
    });
    // if availability end time lies  in the next day from 00:00 AM to 05:59 AM
    // [ means inclusive, ) mean exclusive
    if (
      moment(availability?.endTime, SERVER_TIME).isBetween(
        moment().startOf('day'),
        moment().set({ h: 6, m: 0, s: 0 }),
        'minute',
        '[)'
      )
    )
      availabilityEndDateTime.add(1, 'day');

    return availabilityEndDateTime.diff(
      moment(slotStartTime, [SERVER_DATE_TIME, TIME_SLOT_FORMAT]),
      'minutes'
    );
  }
  return null;
};

const getStartTimeOfAvailability = (startTime = '') => {
  // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
  const start = moment.duration(startTime, SERVER_TIME).asMinutes();
  if (start >= 0 && start < DAY_START_OFFSET * 60)
    // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
    return moment.duration(startTime, SERVER_TIME).add(1, 'day').asMinutes();
  return start;
};

export const sortAvailabilitiesByStartTime = (availabilities: any) =>
  availabilities
    .map((av: any) => ({
      ...av,
      order: getStartTimeOfAvailability(av.startTime),
    }))
    .sort((a: any, b: any) => +a.order - +b.order || a.id.localeCompare(b.id));

export const calculateOverallRatingForFeedback = ({
  foodRating,
  serviceRating,
  atmosphereRating,
  valueRating,
  overallRating,
}: any = {}) => {
  let sumOfRatings = 0;
  let lengthOfRatings = 0;

  if (+overallRating) {
    return overallRating;
  }

  if (+foodRating) {
    sumOfRatings += +foodRating;
    lengthOfRatings++;
  }

  if (+serviceRating) {
    sumOfRatings += +serviceRating;
    lengthOfRatings++;
  }

  if (+valueRating) {
    sumOfRatings += +valueRating;
    lengthOfRatings++;
  }

  if (+atmosphereRating) {
    sumOfRatings += +atmosphereRating;
    lengthOfRatings++;
  }

  return (sumOfRatings / lengthOfRatings || 0).toFixed(2);
};
