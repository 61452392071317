import { useEffect, useState } from 'react';
import humps from 'humps';
import { getPartnerSession } from 'utils/session';
import { SocketConnection } from 'utils/socket-connection';

const useSocket = () => {
  const [cable, setCableConnection] = useState(null);
  const [channelSubscription, setChannelSubscription] = useState(null);

  const subscribeChannel = ({ channel, data, onReceived }: any) => {
    const { accessToken } = getPartnerSession();
    if (!channel || !onReceived || !data) {
      console.error('channel, onReceived, and data are required for subscribing to a channel');
      return;
    }
    if (!cable && !accessToken) {
      console.error('Token is required for creating a new socket connection');
      return;
    }

    const handleReceivedData = (receivedData: any) => {
      try {
        const parsedData = humps.camelizeKeys(JSON.parse(receivedData.params));
        onReceived(parsedData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };

    if (cable) {
      // @ts-expect-error TS(2339): Property 'subscribe' does not exist on type 'never... Remove this comment to see the full error message
      const subscription = cable.subscribe(
        {
          onReceivedData: handleReceivedData,
        },
        { channel, data }
      );
      setChannelSubscription(subscription);
    } else {
      const socketURL = `${process.env.REACT_APP_ACTION_CABLE_SOCKET_URL}?token=${accessToken}`;
      const cableConnection = new SocketConnection(socketURL);
      const subscription = cableConnection.subscribe(
        {
          onReceivedData: handleReceivedData,
        },
        { channel, data }
      );
      // @ts-expect-error TS(2345): Argument of type 'SocketConnection' is not assigna... Remove this comment to see the full error message
      setCableConnection(cableConnection);
      setChannelSubscription(subscription);
    }
  };

  const unsubscribeChannel = () => {
    // @ts-expect-error TS(2339): Property 'unsubscribe' does not exist on type 'nev... Remove this comment to see the full error message
    cable?.unsubscribe(channelSubscription);
  };

  useEffect(
    () => () => {
      // @ts-expect-error TS(2339): Property 'disconnect' does not exist on type 'neve... Remove this comment to see the full error message
      cable?.disconnect();
    },
    [cable]
  );

  return {
    subscribeChannel,
    unsubscribeChannel,
  };
};

export default useSocket;
