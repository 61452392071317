import { API_ENDPOINT_PARTNER_V2 } from 'constants/app';
import { VenueId } from 'modules/Venue/types';
import HttpRequest from 'utils/http-request';

class Waitlist extends HttpRequest {
  constructor() {
    super(`${API_ENDPOINT_PARTNER_V2}/notifylists`);
  }

  getItems({ date, allDay, venueId }: any, config: any) {
    return this.fetch('/ipad_list', { date, allDay, venueId }, config);
  }

  updateStatus(data: any, id: number) {
    return this.update(`/${id}/update_status`, data);
  }

  getWaitlist(id: number) {
    return this.fetch(`/${id}`);
  }

  sendEmailConfirmation(id: number, venueId: VenueId) {
    return this.fetch<{ message: string }>(`/${id}/send_confirmation_email`, { venueId });
  }
}

export default new Waitlist();
