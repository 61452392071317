/**
 * Color name are taken from src/styles/global/_color.scss,
 * while rest of colors which are not present will use from link below:
 * Its a simple tool to generate color names:  https://color-name-generator.com/
 * */

// IMPORTANT!
// NOTE: Do not replicate the colors of STATUSES_COLOR or TIMELINE_STATUSES_COLOR in COLORS
export const COLORS = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  SECONDARY: '#5d6572',
  DISABLED: '#d9d9d9', // also used as a border color too
  LOYALTY: '#E0E0E0',
  DANGER: '#E65359',
  ATTENTION: '#eb6464',
  GREEN_PRIMARY: '#19A69A',
  GREY_BACKGROUND: '#d8e1e8',
  GREY_PRIMARY: '#f4f4f4',
  GREY_LIGHT: '#e2e2e2',
  BLUE_DARK: '#2381c0',
  TRANSPARENT: 'transparent',
  VIOLET: '#ac43bf',
  GOLDEN_YELLOW: '#FFD503',
  EDIT_YELLOW: '#f2b673',
  BLUE_LIGHT: '#7297ee',
  // below are the names take from color-name-generator.com
  LIGHT_BLUE: '#859198',
  ICONIC_SKY: '#d1edeb',
  CHAIR_PINK: '#bf3b8c',
};

export const STATUSES_COLOR = {
  ARRIVED: '#c072b2',
  AWAITING_PAYMENT: '#CEB37D',
  REJECTED: '#686868',
  EXPIRED: '#313B7E',
  BILL_DROPPED: '#db7d23',
  CANCELLED: '#686868',
  CLEARED: '#e5ab39',
  CONFIRMED: '#46b4b5',
  DESSERT: '#5c76aa',
  FINISH: '#47c871',
  LATE: '#844dd6',
  LEFT_MESSAGE: '#fc7a41',
  MAIN: '#81abcc',
  'NO-SHOW': '#404040',
  PAID: '#719f44',
  PARTIALLY_SEATED: '#ac4098',
  RESERVED: '#2980b9',
  SEATED: '#e55aaf',
  SENT_MESSAGE: '#fc8f02',
  STARTER: '#3f7b84',
  NOTIFY: '#3F3B73',
  EXPIRED_NOTIFY: '#8B89AB',
  CANCELLED_NOTIFY: '#686868',
  FAILED: '#1F414A',
  ACTIVE: '#313B7E',
  REMOVED: '#686868',
  REJECTED_QUEUE: '#404040',
  CANCELLED_QUEUE: '#686868',
  QUEUE_TURN_CONFIRMATION_SMS_SENT: '#fc7a41',
  HEAD_TO_VENUE_SMS_SENT: '#fc7a41',
  TABLE_READY_SMS_SENT: '#fc7a41',
  CUSTOM_TEMPLATE_SMS_SENT: '#fc7a41',
  BLOCKED: '#3B4252',
  ATTENTION: '#c22d2d',
};

// NOTE: keep statues in sync with constants/reservation --> STATUSES
// this was needed because the colors used in timeline are different
export const TIMELINE_STATUSES_COLOR = {
  ARRIVED: '#C987BE',
  AWAITING_PAYMENT: '#D5BE91',
  REJECTED: '#7F7F7F',
  EXPIRED: '#9C9BB8',
  BILL_DROPPED: '#E09144',
  CANCELLED: '#7F7F7F',
  CLEARED: '#E9B857',
  CONFIRMED: '#62BFC0',
  DESSERT: '#748BB7',
  FINISH: '#63D086',
  LATE: '#9668DC',
  LEFT_MESSAGE: '#FC8E5D',
  MAIN: '#94B8D4',
  'NO-SHOW': '#5D5D5D',
  PAID: '#86AD60',
  PARTIALLY_SEATED: '#B85DA7',
  RESERVED: '#4993C4',
  SEATED: '#E973BB',
  SENT_MESSAGE: '#fc8f02', // not found figma
  STARTER: '#5C8F96',
  NOTIFY: '#5C5888',
  EXPIRED_NOTIFY: '#9C9BB8',
  CANCELLED_NOTIFY: '#7F7F7F',
  FAILED: '#415D65',
  QUEUE: '#5A6298',
  REMOVED: '#686868', // not found figma
  BLOCKED: '#3B4252',
};

export const venueColors = [
  COLORS.GREEN_PRIMARY,
  STATUSES_COLOR.CLEARED,
  STATUSES_COLOR.SEATED,
  STATUSES_COLOR.PAID,
  STATUSES_COLOR.BILL_DROPPED,
  '#3a8abd',
  STATUSES_COLOR.NOTIFY,
  STATUSES_COLOR.PARTIALLY_SEATED,
  STATUSES_COLOR.LEFT_MESSAGE,
  STATUSES_COLOR.LATE,
  STATUSES_COLOR.EXPIRED_NOTIFY,
  STATUSES_COLOR.FAILED,
  STATUSES_COLOR.ACTIVE,
  STATUSES_COLOR.REJECTED_QUEUE,
  STATUSES_COLOR.CANCELLED_QUEUE,
];
