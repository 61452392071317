import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'constants/colors';

interface ForwardArrowIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
  title?: string;
  className?: string;
}

function ForwardArrowIcon({
  width = 10,
  height = 17,
  color = COLORS.GREEN_PRIMARY,
  title = 'Forward',
  className,
}: ForwardArrowIconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <path
        d="M.1 15.586 1.515 17 10 8.515 1.515.029.1 1.444l7.072 7.07L.1 15.587v-.001Z"
        fill={color}
      />
    </svg>
  );
}

ForwardArrowIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default ForwardArrowIcon;
