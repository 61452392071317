import type { AppDispatch, AppGetState } from 'redux-store';
import debounce from 'lodash-es/debounce';
import moment from 'moment-timezone';
import { batch } from 'react-redux';
import pick from 'lodash-es/pick';
import reservationFunnelApis from 'modules/ReservationFunnel/apis';
import guestApis from 'modules/Guest/apis';
import waitlistV3Apis from 'modules/Waitlist/apisV3';
import venueApis from 'modules/Venue/apis/venue';
import queueApis from 'modules/QueueList/apis';
import { dispatchReservationUpdate } from 'modules/ReservationsList/actions';
import { searchMasterGuests } from 'modules/GuestsCrm/actions';
import * as ActionTypes from './action-types';
import { REMOVE_FROM_QUEUE } from 'modules/QueueList/action-types';
import { RESET_GUESTS } from 'modules/Guest/action-types';
import { RESERVATION_FUNNEL_STEPS, UNLOCK_TABLE_ID } from 'modules/ReservationFunnel/constants';
import { DATE_FORMAT } from 'constants/time-and-date';
import {
  formatDateToServerDateTime,
  determineFunnelDateOnEdit,
  adjustDayAfterMidNight,
} from 'utils/date-and-time';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage } from 'utils/alerts';
import {
  transformAvailabilityAndSlotsAPIResponse,
  findFirstAvailableDay,
  getAvailabilityStartDate,
  getAvailabilityEndDate,
} from 'modules/ReservationFunnel/services';
import { customSocketEventForReservation } from 'modules/ReservationsList/slice';
import { customSocketEventForQueue } from 'modules/QueueList/slice';
import { customSocketEventForWaitlist } from 'modules/Waitlist/slice';
import { getTimelineWaitlistFunnelPayload } from 'modules/Timeline/services';
import { getReservationGuest } from 'modules/ReservationsList/services';
import {
  selectReservationPayload,
  selectWaitlistReservationPayload,
  selectReservationSuccess,
  selectIsWaitlist,
  selectFunnelVenueId,
  selectQueueReservationPayload,
  selectSelectedTablesTurnTime,
} from 'modules/ReservationFunnel/selectors';
import { selectWaitlistById } from 'modules/Waitlist/selectors';
import { removeWaitlistItem } from 'modules/Waitlist/actions';
import type { Template } from './types';

export const setFunnelStep = (step: any) => ({
  type: ActionTypes.SELECT_FUNNEL_STEP,
  payload: step,
});

export const setEditingGuest = ({ isEditingGuest = false }) => ({
  type: ActionTypes.SET_EDITING_GUEST,
  payload: isEditingGuest,
});

export const gotoPreviousStep = () => ({
  type: ActionTypes.GOTO_PREVIOUS_STEP,
});

// @ts-expect-error TS(7006): Parameter 'partySize' implicitly has an 'any' type... Remove this comment to see the full error message
export const selectPartySizeAction = (partySize, mobile, step) => ({
  type: ActionTypes.SELECT_PARTY_SIZE,

  payload: {
    partySize,
    mobile,
    step,
  },
});

// @ts-expect-error TS(7006): Parameter 'date' implicitly has an 'any' type.
export const selectDate = (date, mobile) => ({
  type: ActionTypes.SELECT_DATE,

  payload: {
    date,
    mobile,
  },
});

export const selectTime = (
  { timeSlot = null, availabilityId = null, isCustomTime = false, tables, squeezeTime }: any,
  // @ts-expect-error TS(7006): Parameter 'mobile' implicitly has an 'any' type.
  mobile,
  // @ts-expect-error TS(7006): Parameter 'step' implicitly has an 'any' type.
  step
) => ({
  type: ActionTypes.SELECT_TIME_SLOT,

  payload: {
    timeSlot: timeSlot?.startTime || timeSlot,
    availabilityId,
    isCustomTime,
    ...(squeezeTime && {
      turnTime: squeezeTime,
      selectedTableIds: tables.map(({ id }: any) => id),
    }),
    isSqueeze: !!squeezeTime,
    mobile,
    step,
  },
});

export const setSelectedTableIds = (tableIds: any) => ({
  type: ActionTypes.SET_SELECTED_TABLE_IDS,
  payload: tableIds,
});

export const setTurnTime = (turnTime: any) => ({
  type: ActionTypes.SET_TURN_TIME,

  payload: {
    turnTime,
  },
});

const setReservationLoading = (loading: any) => ({
  type: ActionTypes.SET_RESERVATION_LOADING,
  payload: loading,
});

export const updateSelectedTables =
  (tableId: any) => (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch({
      type: ActionTypes.SELECTED_TABLES_TOGGLE,
      payload: { id: tableId },
    });
    // selectSelectedTablesTurnTime need to be called after SELECTED_TABLES_TOGGLE action to get the latest selected table ids
    //  that allow us to get the correct turn time of those selected tables.
    const state = getState();
    const tableTurnTime = selectSelectedTablesTurnTime(state);

    dispatch(setTurnTime(tableTurnTime));
  };

const handleUnprocessableEntityError = (dispatch: AppDispatch, error: any) => {
  const { status, data } = error;
  const errors = Array.isArray(data) ? data : data?.errors;
  if (status === 422 && errors) {
    dispatch({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: { reservationLoading: false, errorMessage: errors?.join(', ') },
    });
  } else {
    showErrorMessage(getErrorMessage(error));
  }
};

const createQueueReservation = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const funnelState = state.reservationFunnel;
  const venueId = selectFunnelVenueId(state);
  const payload = selectQueueReservationPayload(state);
  const queueId = funnelState.offlineWaitlistId;
  let queueItem = {};
  try {
    if (queueId) {
      // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type '{}'.
      queueItem = await queueApis.updateQueue(payload, venueId, queueId);
    } else {
      // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type '{}'.
      queueItem = await queueApis.createQueue(payload, { venueId });
    }
    dispatch({ type: ActionTypes.CREATE_RESERVATION });
    customSocketEventForQueue(queueItem, venueId);
  } catch (error) {
    handleUnprocessableEntityError(dispatch, error);
  }
};

export const guestSelectionAction = (guest: any, mobile: any) => (dispatch: AppDispatch) => {
  // make the guest object similar to master guest object from api
  const guestPayload = { profile: { photo: guest?.photoUrl || '' }, ...guest };
  dispatch({
    type: ActionTypes.SELECT_GUEST,
    payload: { ...guestPayload, mobile },
  });
};

export const queueGuestSelectionAction = (guest: any) => (dispatch: AppDispatch) => {
  // make the guest object similar to master guest object from api
  const guestPayload = { profile: { photo: guest?.photoUrl || '' }, ...guest };
  batch(() => {
    dispatch({ type: ActionTypes.SELECT_QUEUE_GUEST, payload: guestPayload });
    dispatch(createQueueReservation());
  });
};

export const createGuestFromQuery = (query: any) => ({
  type: ActionTypes.CREATE_GUEST_FROM_QUERY,

  payload: {
    query,
  },
});

// @ts-expect-error TS(7006): Parameter 'query' implicitly has an 'any' type.
export const createTempGuest = (query, mobile) => ({
  type: ActionTypes.CREATE_TEMPORARY_GUEST,

  payload: {
    query,
    mobile,
  },
});

export const createQueueTempGuest = (query: any) => (dispatch: AppDispatch) => {
  dispatch({
    type: ActionTypes.CREATE_TEMPORARY_QUEUE_GUEST,
    payload: {
      query,
    },
  });
  dispatch(createQueueReservation());
};

export const createGuest = (data: any, mobile: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await guestApis.createGuest(data);
    batch(() => {
      dispatch({
        type: ActionTypes.SELECT_GUEST,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        payload: { ...(response.masterGuest || {}), mobile },
      });
      dispatch(searchMasterGuests({ searchText: '', page: 1, venueId: data.venueId }));
    });
  } catch (err) {
    showErrorMessage(getErrorMessage(err));
  }
};

export const createQueueGuest = (data: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await guestApis.createGuest(data);
    batch(() => {
      dispatch({
        type: ActionTypes.SELECT_QUEUE_GUEST,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        payload: response.masterGuest || {},
      });
      dispatch(createQueueReservation());
      dispatch(searchMasterGuests({ searchText: '', page: 1, venueId: data.venueId }));
    });
  } catch (err) {
    showErrorMessage(getErrorMessage(err));
  }
};

const createReservation = (mobile: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const funnelState = state.reservationFunnel;
  const { offlineWaitlistId: queueId, notifylistId: waitlistId, idempotencyKey } = funnelState;
  const reservationPayload = selectReservationPayload(state, mobile);
  const venueId = selectFunnelVenueId(state);
  let response = {};
  try {
    if (funnelState.id) {
      // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type '{}'.
      response = await reservationFunnelApis.updateReservation(
        reservationPayload,
        { venueId },
        funnelState.id
      );
    } else {
      // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type '{}'.
      response = await reservationFunnelApis.createReservation(
        reservationPayload,
        { venueId },
        idempotencyKey
      );
    }
    dispatch({ type: ActionTypes.CREATE_RESERVATION });
    // @ts-expect-error TS(2339): Property 'reservation' does not exist on type '{}'... Remove this comment to see the full error message
    customSocketEventForReservation(response?.reservation, venueId);
    // if a queue or waitlist Item is converted to reservation, remove the item from the reducer
    if (queueId) {
      dispatch({
        type: REMOVE_FROM_QUEUE,
        payload: { queueId },
      });
    }
    if (waitlistId) {
      dispatch(removeWaitlistItem(waitlistId));
    }
  } catch (error) {
    handleUnprocessableEntityError(dispatch, error);
  }
};

export const updateTableLockStatus =
  (reservationId: any, venueId: any, tableId = [UNLOCK_TABLE_ID]) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    // tableId:[0] -> unlock tables for this reservation
    // tableId:[1,2,3] -> lock tables 1, 2, 3 for this reservation
    const tableStatusPayload = {
      reservation: {
        errorOverride: 1,
        lockTables: tableId,
      },
    };

    try {
      // @ts-expect-error TS(2339): Property 'reservation' does not exist on type 'unk... Remove this comment to see the full error message
      const { reservation } = await reservationFunnelApis.updateReservation(
        tableStatusPayload,
        { venueId },
        reservationId
      );
      const updatedReservationData = pick(reservation, ['id', 'tables', 'rooms']);
      batch(() => {
        dispatch({ type: ActionTypes.CREATE_RESERVATION });
        // @ts-expect-error TS(2345): Argument of type '{ dispatch: import("/home/murtaz... Remove this comment to see the full error message
        dispatchReservationUpdate({ dispatch, getState, reservation: updatedReservationData });
      });
    } catch (error) {
      handleUnprocessableEntityError(dispatch, error);
    }
  };

const createWaitlistReservation = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const venueId = selectFunnelVenueId(state);
  try {
    const payload = selectWaitlistReservationPayload(state);
    // @ts-expect-error TS(2339): Property 'notifylist' does not exist on type 'unkn... Remove this comment to see the full error message
    const { notifylist } = await waitlistV3Apis.createWaitlistReservation(payload, { venueId });
    dispatch({ type: ActionTypes.CREATE_RESERVATION });
    customSocketEventForWaitlist(notifylist, venueId);
  } catch (error) {
    handleUnprocessableEntityError(dispatch, error);
  }
};

export const confirmReservation =
  (mobile: any) => async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const reservationSuccess = selectReservationSuccess(state);
    const isWaitlist = selectIsWaitlist(state);
    if (!reservationSuccess) {
      dispatch(setReservationLoading(true));
      if (isWaitlist) {
        dispatch(createWaitlistReservation());
      } else {
        dispatch(createReservation(mobile));
      }
    }
  };

export const setSlotsLoading = (loading = true) => ({
  type: ActionTypes.SET_SLOTS_LOADING,
  payload: loading,
});

const getReservationSlotsDebounced = debounce(
  async ({ partySize, date, venueId, dispatch, signal }) => {
    try {
      // Transform API response for the UI
      const availabilityAndSlotsResponse = transformAvailabilityAndSlotsAPIResponse(
        await reservationFunnelApis.getSlots({ partySize, date, venueId }, { signal })
      );

      dispatch({ type: ActionTypes.GET_RESERVATION_SLOTS, payload: availabilityAndSlotsResponse });
    } catch (error) {
      if (reservationFunnelApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
    } finally {
      dispatch(setSlotsLoading(false));
    }
  },
  400
);

export const getReservationSlots = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  getReservationSlotsDebounced({ ...params, dispatch, signal: controller.signal });
  return controller;
};

export const getChargingFees =
  ({ venueId, guestCount, startDateTime }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      const chargingFees = await reservationFunnelApis.getChargingFees({
        venueId,
        guestCount,
        startDateTime,
      });
      dispatch({ type: ActionTypes.SET_CHARGING_FEES, payload: chargingFees });
    } catch (err) {
      dispatch({ type: ActionTypes.SET_CHARGING_FEES, payload: null });
      // when changing fees doesn't exist or not enabled backend always throw client error responses(4xx)
      // and we don't want to show that error message to the user
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (err.status >= 500) {
        showErrorMessage(getErrorMessage(err));
      }
    }
  };

const setTablesLoading = (loading = true) => ({
  type: ActionTypes.SET_TABLES_LOADING,
  payload: loading,
});

export const getFunnelTables =
  ({ venueId, showLoader = false }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      if (showLoader) {
        dispatch(setTablesLoading(true));
      }

      const response = await reservationFunnelApis.getFunnelTables({ venueId });

      dispatch({
        type: ActionTypes.GET_FUNNEL_TABLES,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        payload: (response.tables || []).sort((a: any, b: any) => a.order - b.order),
      });
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    } finally {
      if (showLoader) {
        dispatch(setTablesLoading(false));
      }
    }
  };

export const getTablesCombination = (params: any) => async (dispatch: AppDispatch) => {
  try {
    // @ts-expect-error TS(2339): Property 'rooms' does not exist on type 'unknown'.
    const { rooms } = await reservationFunnelApis.getTablesCombination(params);
    const tablesCombinations = rooms?.reduce((arr: any, { combinations = [] }) => {
      if (combinations.length) {
        combinations.map((combo) => arr.push(combo));
      }
      return arr;
    }, []);
    dispatch({
      type: ActionTypes.GET_TABLES_COMBINATIONS,
      payload: tablesCombinations || [],
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

export const getAssignableTables = (params: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await reservationFunnelApis.getAssignableTables(params);
    dispatch({
      type: ActionTypes.GET_ASSIGNABLE_TABLES,
      payload: response || [],
    });
  } catch (err) {
    showErrorMessage(getErrorMessage(err));
  }
};

export const resetReservationFunnel = () => (dispatch: AppDispatch) => {
  dispatch({ type: ActionTypes.RESET_RESERVATION_FUNNEL });
  dispatch({ type: RESET_GUESTS });
};

export const editReservation =
  ({
    reservation,
    step,
    isTimeEdit,
    hasDateUpdated,
    isEdit = true,
    notifyTimes = {},
    isCustomTime = false,
    communicationSettings,
    isManualPaymentReactivation = false,
  }: any) =>
  (dispatch: AppDispatch) => {
    const {
      id,
      slotStartTime,
      primaryGuest = {},
      guestCount,
      tables = [],
      turnTime,
      notes,
      walkin,
      tags,
      temporaryGuest,
      firstName,
      lastName,
      email,
      phone,
      slot,
      smsBlock,
      emailBlock,
      paymentExpireHours,
      sourceOfReservation,
      status = '',
    } = reservation;
    // From after midnight until 5:59 AM, we always fetch the availability of the previous day to obtain slots that occur after midnight of that day.
    const date = determineFunnelDateOnEdit(isTimeEdit, slotStartTime);
    const temporaryGuestQuery = [firstName, lastName, phone, email]
      .filter((value) => !!value)
      .join(' ');
    const selectedTableIds = (tables || []).map((t: any) => t.id);

    const previousStep = (() => {
      switch (step) {
        case RESERVATION_FUNNEL_STEPS.SELECT_TABLES:
          return RESERVATION_FUNNEL_STEPS.SELECT_GUEST;
        case RESERVATION_FUNNEL_STEPS.SELECT_GUEST:
          return RESERVATION_FUNNEL_STEPS.FIRST_STEP;
        default:
          return null;
      }
    })();

    const payload = {
      id,
      date,
      assignableTables: {
        available: [],
        notAvailable: [],
        upcoming: [],
      },
      errorMessage: '',
      guest: { ...primaryGuest, ...getReservationGuest(reservation) },
      guestQuery: temporaryGuest ? temporaryGuestQuery : primaryGuest?.name || '',
      notes,
      partySize: guestCount,
      reservationSuccess: false,
      roomAreaId: null,
      roomId: null,
      availabilityId: slot?.reservationAvailabilityId,
      selectedTableIds,
      // Required for checking whether selected tables are same as pre edit
      selectedTableIdsPreEdit: selectedTableIds,
      availabilityAndSlots: [],
      tables: [],
      timeSlot: (!isTimeEdit && slotStartTime) || null,
      turnTime,
      step,
      previousStep,
      reservationLoading: false,
      isWalkin: walkin,
      tagIds: tags?.map((tag: any) => tag.id) || [],
      isTemporaryGuest: temporaryGuest,
      communication: {
        sms: communicationSettings?.sms ?? !smsBlock,
        email: communicationSettings?.email ?? !emailBlock,
      },
      isCustomTime,
      notifyTimes,
      isEdit,
      hasDateUpdated,
      paymentExpireHours,
      sourceOfReservation,
      isManualPaymentReactivation,
      status,
    };

    dispatch({
      type: ActionTypes.EDIT_RESERVATION,
      payload,
    });
  };

export const setReservationFunnelWaitlist = (
  {
    guestCount,
    startTimeWithDate,
    masterGuest,
    id,
    tags,
    notes,
    suggestionId,
    sourceOfCreate,
  }: any,
  mobile: any
) => {
  const data = {
    notes,
    tagIds: tags?.map((tag: any) => tag.id) || [],
    partySize: guestCount,
    // From midnight until 5:59 AM, we always fetch the availability of the previous day to obtain slots that occur after midnight.
    date: adjustDayAfterMidNight({
      time: startTimeWithDate,
      action: 'subtract',
    }).format(DATE_FORMAT),
    guest: { ...masterGuest },
    step: mobile ? RESERVATION_FUNNEL_STEPS.SELECT_TIME_SLOT : RESERVATION_FUNNEL_STEPS.FIRST_STEP,
    notifylistId: id,
    hasDateUpdated: true,
    suggestionId,
    sourceOfReservation: sourceOfCreate,
  };

  return {
    type: ActionTypes.EDIT_RESERVATION,
    payload: data,
  };
};

export const addNotes = (notes: any) => ({
  type: ActionTypes.ADD_NOTES,
  payload: { notes },
});

export const setNotifyTime = (notifyTime: any) => ({
  type: ActionTypes.SET_NOTIFY_TIME,
  payload: notifyTime,
});

export const setTags = (tagIds: any) => ({
  type: ActionTypes.SET_TAGS,
  payload: tagIds,
});

export const setLockTables = (lockTableIds = [UNLOCK_TABLE_ID]) => ({
  // lockTableIds:[0] -> unlock tables for this reservation
  // lockTablesIds:[1,2,3] -> lock tables 1, 2, 3 for this reservation
  type: ActionTypes.SET_LOCK_TABLES,
  payload: lockTableIds,
});

export const startWalkin = () => ({
  type: ActionTypes.START_WALKIN,
  payload: { date: formatDateToServerDateTime(moment()) },
});

export const setCommunication = (confirmation: any) => ({
  type: ActionTypes.SET_COMMUNICATION,
  payload: confirmation,
});

export const setManualPayment = (isManualPayment: any) => ({
  type: ActionTypes.SET_MANUAL_PAYMENT,
  payload: isManualPayment,
});

export const selectVenue = (venueId: any) => ({
  type: ActionTypes.SELECT_VENUE,
  payload: venueId,
});

export const getFunnelCalendarData =
  ({ venueId, startDate, endDate }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
      const res = await venueApis.getCalendarAvailability({ venueId, startDate, endDate });
      dispatch({
        type: ActionTypes.GET_CALENDAR_DATA,
        payload: res,
      });
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    }
  };

export const setLastCalendarFetchParams = (lastCalendarFetchParams: any) => ({
  type: ActionTypes.SET_LAST_FETCH_PARAMS,
  payload: lastCalendarFetchParams,
});

const setIsCalendarDataLoading = (loading = true) => ({
  type: ActionTypes.START_CALENDAR_LOADING,
  payload: loading,
});

const getYearCalendarDataDebounced = debounce(
  async ({
    venueId,
    date,
    numRequests = 2,
    partySize = 2,
    isMobile,
    dispatch,
    signal,
    getState,
  }) => {
    const monthsPerFirstRequest = 2;
    const monthsPerRequest = Math.floor((12 - monthsPerFirstRequest) / numRequests);

    dispatch(setIsCalendarDataLoading());

    if (!date) {
      date = moment().format(DATE_FORMAT);
    }

    try {
      // the first API call is used to update the UI to avoid delay while the rest is called in parallel
      const response = await venueApis.getCalendarAvailability(
        {
          venueId,
          startDate: getAvailabilityStartDate(date, monthsPerFirstRequest),
          endDate: getAvailabilityEndDate(date, monthsPerFirstRequest),
          partySize,
        },
        { signal }
      );
      // Only on the first fetch find the first available date
      const firstAvailableDay = findFirstAvailableDay(response);
      dispatch({
        type: ActionTypes.GET_CALENDAR_DATA,
        payload: {
          calendarData: response,
          ...(firstAvailableDay && !isMobile && { date: firstAvailableDay }),
        },
      });
      // By setting `isCalendarDataLoading` to false here, we enable the UI to be shown to the user
      // as soon as we receive the first API response data. This way, we don't need to wait for all
      // calendar data to load before displaying the UI, which could take some time.
      dispatch(setIsCalendarDataLoading(false));
      // the rest of the API calls will be called in parallel using promise.all
      const promises = [];
      const dateAfterFirstRequest = moment(date, DATE_FORMAT)
        .add(monthsPerFirstRequest, 'months')
        .format(DATE_FORMAT);

      for (let i = 0; i < numRequests; i += 1) {
        promises.push(
          venueApis.getCalendarAvailability(
            {
              venueId,
              startDate: getAvailabilityStartDate(dateAfterFirstRequest, monthsPerRequest, i),
              endDate: getAvailabilityEndDate(dateAfterFirstRequest, monthsPerRequest, i),
              partySize,
            },
            { signal }
          )
        );
      }
      const res = await Promise.all(promises);
      // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
      const calendarData = res.reduce((acc, data) => ({ ...acc, ...data }), {});
      dispatch({
        type: ActionTypes.GET_CALENDAR_DATA,
        payload: {
          calendarData,
        },
      });
    } catch (error) {
      if (venueApis.isCancel(error)) {
        const state = getState();
        // By setting `lastCalendarFetchParams` to an empty string here, we signal the system to fetch the funnel calendar data again the next time the user visits the calendar selection step.
        // We need to re-fetch because when loading, the calendar data is reset to an empty object {}, and the request was aborted before we received the updated calendar data.
        if (Object.keys(state.reservationFunnel.calendarData).length === 0) {
          dispatch(setLastCalendarFetchParams(''));
        }
        return;
      }
      showErrorMessage(getErrorMessage(error));
    } finally {
      dispatch(setIsCalendarDataLoading(false));
    }
  },
  500
);

export const getYearCalendarData =
  (params: any) => (dispatch: AppDispatch, getState: AppGetState) => {
    const controller = new AbortController();
    getYearCalendarDataDebounced({
      ...params,
      dispatch,
      getState,
      signal: controller.signal,
    });
    return controller;
  };

export const startQueue = () => ({
  type: ActionTypes.START_QUEUE,
});

export const setWalkinFunnelQueueList = (selectedQueueItem: any, mobile: any) => {
  const { id, guestCount, primaryGuest, temporaryGuest, tags, firstName, lastName, phone, email } =
    selectedQueueItem;
  const guestQuery = [firstName, lastName, phone, email].join(' ');

  const payload = {
    partySize: guestCount,
    guest: { ...primaryGuest },
    date: mobile ? null : formatDateToServerDateTime(moment()),
    isTemporaryGuest: temporaryGuest,
    offlineWaitlistId: id,
    tagIds: tags?.map((tag: any) => tag.id) || [],
    guestQuery,
  };

  return {
    type: ActionTypes.START_QUEUE_WALKIN,
    payload,
  };
};

export const editQueueGuest = (selectedQueueItem: any) => {
  const { id, guestCount, primaryGuest, notes, tags } = selectedQueueItem;

  const payload = {
    partySize: guestCount,
    guest: { ...primaryGuest },
    isQueue: true,
    step: RESERVATION_FUNNEL_STEPS.SELECT_QUEUE_PARTY_AND_GUEST,
    offlineWaitlistId: id,
    guestQuery: primaryGuest?.name || '',
    notes,
    tagIds: tags?.map((tag: any) => tag.id) || [],
  };

  return {
    type: ActionTypes.EDIT_QUEUE_GUEST,
    payload,
  };
};

export const setReservationFunnelGuest = (guestInfo: any) => ({
  type: ActionTypes.SET_RESERVATION_FUNNEL_GUEST,
  payload: guestInfo,
});

export const setTemplate = (template: Template) => ({
  type: ActionTypes.SET_TEMPLATE,
  payload: template,
});

export const setGuestCrmReservation = () => ({
  type: ActionTypes.SET_GUESTCRM_RESERVATION,
});

export const updateFunnelOnDateChange = (data: any) => ({
  type: ActionTypes.SET_FUNNEL_ON_DATE_CHANGE,
  payload: data,
});

export const setTimelineWaitlistToFunnel =
  (toItem: any) => (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const waitlistItem = selectWaitlistById(toItem.waitlistId)(state);

    dispatch({
      type: ActionTypes.EDIT_RESERVATION,
      payload: getTimelineWaitlistFunnelPayload(toItem, waitlistItem),
    });
  };

export const setThirdPartyBookerStep = (step: any) => ({
  type: ActionTypes.SET_THIRD_PARTY_BOOKER_STEP,
  payload: step,
});

export const createBookerGuestFromQuery = (query: any) => ({
  type: ActionTypes.CREATE_BOOKER_GUEST_FROM_QUERY,

  payload: {
    query,
  },
});

export const createThirdPartyGuest = (data: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await guestApis.createGuest(data);
    dispatch({
      type: ActionTypes.SELECT_THIRD_PARTY_GUEST,
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      payload: { ...(response.masterGuest || {}) },
    });
  } catch (err) {
    showErrorMessage(getErrorMessage(err));
  }
};

export const thirdPartGuestSelectionAction = (guest: any) => (dispatch: AppDispatch) => {
  // make the guest object similar to master guest object from api
  const guestPayload = { profile: { photo: guest?.photoUrl || '' }, ...guest };
  dispatch({
    type: ActionTypes.SELECT_THIRD_PARTY_GUEST,
    payload: { ...guestPayload },
  });
};

export const resetThirdPartyGuest = () => ({
  type: ActionTypes.RESET_THIRD_PARTY,
});

export const setPaymentExpireHours = (paymentExpireHours: any) => ({
  type: ActionTypes.SET_PAYMENT_EXPIRE_HOURS,
  payload: paymentExpireHours,
});

export const resetTimeSlotOnDateChange = (mobile: any) => ({
  type: ActionTypes.RESET_TIME_SLOT_ON_DATE_CHANGE,

  payload: {
    timeSlot: null,
    step: mobile ? RESERVATION_FUNNEL_STEPS.SELECT_TIME_SLOT : RESERVATION_FUNNEL_STEPS.FIRST_STEP,
    availabilityId: null,
    turnTime: null,
    selectedTableIds: [],
    isSqueeze: false,
  },
});

export const setIsQueueOpen = (payload: any) => ({
  type: ActionTypes.SET_QUEUE_OPEN,
  payload,
});
