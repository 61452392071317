import type { AppDispatch } from 'redux-store';
import debounce from 'lodash-es/debounce';
import reservationApis from 'modules/ReservationsList/apis';
import orderApis from 'modules/Order/apis';
import guestApis from './apis';
import { HISTORY_TRANSACTION_TYPE, GUEST_RESERVATION_TYPE } from './constants';
import { parseSearchText } from 'utils/phone-number';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage } from 'utils/alerts';
import * as ActionTypes from './action-types';
import type { VenueId } from 'modules/Venue/types';

export const setGuestLoading = ({ isLoading = false }) => ({
  type: ActionTypes.SET_GUEST_LOADING,
  payload: isLoading,
});

const getGuestListDebounce = debounce(async ({ searchText, venueId, exactPhone }, dispatch) => {
  try {
    const response = await guestApis.searchGuest({
      searchText: parseSearchText(searchText),
      venueId,
      shortResponse: true,
      exactPhone,
    });
    dispatch({
      type: ActionTypes.SEARCH_GUEST,
      payload: {
        guests: response.masterGuests || [],
        totalGuestCount: response.totalGuestCount,
      },
    });
  } catch (error) {
    setGuestLoading({ isLoading: false });
    showErrorMessage(getErrorMessage(error));
  }
}, 500);

export const searchGuest = (params: any) => async (dispatch: AppDispatch) => {
  getGuestListDebounce(params, dispatch);
};

export const createGuest = (data: any) => async (dispatch: AppDispatch) => {
  try {
    const response = await guestApis.createGuest(data);
    dispatch({
      type: ActionTypes.CREATE_GUEST_FROM_LISTING,
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      payload: response.masterGuest || {},
    });
  } catch (error) {
    showErrorMessage(getErrorMessage(error));
  }
};

export const setMasterGuest = (masterGuest: any) => ({
  type: ActionTypes.SET_MASTER_GUEST,
  payload: masterGuest,
});

export const updateMasterGuest =
  ({ masterGuestId, guest, venueId, data = {} }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      // @ts-expect-error TS(2339): Property 'masterGuest' does not exist on type 'unk... Remove this comment to see the full error message
      const { masterGuest } = await guestApis.updateMasterGuest(masterGuestId, {
        venueId,
        ...(guest && { guest }),
        ...(data || {}),
      });

      dispatch({
        type: ActionTypes.UPDATE_GUEST,
        payload: masterGuest || {},
      });
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

export const updateMasterGuestNote =
  (masterGuestId: any, data: any, venueId: any) => async (dispatch: AppDispatch) => {
    try {
      // @ts-expect-error TS(2339): Property 'masterGuest' does not exist on type 'unk... Remove this comment to see the full error message
      const { masterGuest } = await guestApis.updateMasterGuestNote(masterGuestId, {
        venueId,
        guestNote: { ...data },
      });
      dispatch({
        type: ActionTypes.UPDATE_GUEST,
        payload: masterGuest || {},
      });

      // return to save primary guest on updating from reservation info panel
      return masterGuest;
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
      return null;
    }
  };

export const updateMasterGuestTags =
  (masterGuestId: any, tagIds: any, venueId: any) => async (dispatch: AppDispatch) => {
    try {
      // @ts-expect-error TS(2339): Property 'masterGuest' does not exist on type 'unk... Remove this comment to see the full error message
      const { masterGuest } = await guestApis.updateMasterGuestTags(masterGuestId, {
        venueId,
        masterGuest: { tagIds },
      });
      dispatch({
        type: ActionTypes.UPDATE_GUEST,
        payload: masterGuest || {},
      });

      // return to save primary guest on updating from reservation info panel
      return masterGuest;
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
      return null;
    }
  };

export const updateMasterGuestProfile =
  (masterGuestId: any, guestData: any, venueId: any) => async (dispatch: AppDispatch) => {
    try {
      // @ts-expect-error TS(2339): Property 'masterGuest' does not exist on type 'unk... Remove this comment to see the full error message
      const { masterGuest } = await guestApis.updateMasterGuestProfile(masterGuestId, {
        venueId,
        partner_master_guest_profile: guestData,
      });
      dispatch({
        type: ActionTypes.UPDATE_GUEST,
        payload: masterGuest || {},
      });
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    }
  };

const fetchMasterGuestDebounced = debounce(
  async ({ masterGuestId, venueId }, dispatch, { signal }) => {
    try {
      // @ts-expect-error TS(2339): Property 'masterGuest' does not exist on type 'unk... Remove this comment to see the full error message
      const { masterGuest } = await guestApis.fetchMasterGuest(masterGuestId, venueId, {
        signal,
      });
      dispatch({
        type: ActionTypes.FETCH_MASTER_GUEST,
        payload: masterGuest || {},
      });
    } catch (error) {
      if (guestApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
    } finally {
      dispatch({
        type: ActionTypes.SET_MASTER_GUEST_LOADING,
        payload: { isLoadingMasterGuest: false },
      });
    }
  },
  500
);

export const fetchMasterGuest = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  fetchMasterGuestDebounced(params, dispatch, controller);
  return controller;
};

const fetchMasterGuestReservationSummaryDebounced = debounce(
  async ({ masterGuestId, venueId, scope }, dispatch, { signal }) => {
    try {
      const response = await guestApis.fetchMasterGuestReservationSummary(
        { masterGuestId, venueId, scope },
        { signal }
      );

      dispatch({ type: ActionTypes.FETCH_MASTER_GUEST_RESERVATION_SUMMARY, payload: response });
    } catch (error) {
      if (guestApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
    } finally {
      dispatch({
        type: ActionTypes.SET_MASTER_GUEST_LOADING,
        payload: { isLoadingReservationSummary: false },
      });
    }
  },
  500
);

export const fetchMasterGuestReservationSummary = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  fetchMasterGuestReservationSummaryDebounced(params, dispatch, controller);
  return controller;
};

const fetchUpcomingHistoryItemsDebounced = debounce(
  async ({ masterGuestId, currentVenueId }, dispatch, { signal }) => {
    try {
      const reservations = await guestApis.fetchMasterGuestReservations(
        masterGuestId,
        currentVenueId,
        GUEST_RESERVATION_TYPE.upcoming,
        { signal }
      );

      dispatch({
        type: ActionTypes.FETCH_MASTER_GUEST_RESERVATIONS,
        payload: { [GUEST_RESERVATION_TYPE.upcoming]: reservations },
      });
    } catch (error) {
      if (guestApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
    } finally {
      dispatch({
        type: ActionTypes.SET_MASTER_GUEST_LOADING,
        payload: { isLoadingUpcoming: false },
      });
    }
  },
  500
);

export const fetchUpcomingHistoryItems = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  fetchUpcomingHistoryItemsDebounced(params, dispatch, controller);
  return controller;
};

const fetchPastHistoryItemsDebounced = debounce(
  async ({ masterGuestId, currentVenueId }, dispatch, { signal }) => {
    try {
      const reservations = await guestApis.fetchMasterGuestReservations(
        masterGuestId,
        currentVenueId,
        GUEST_RESERVATION_TYPE.past,
        { signal }
      );

      dispatch({
        type: ActionTypes.FETCH_MASTER_GUEST_RESERVATIONS,
        payload: { [GUEST_RESERVATION_TYPE.past]: reservations },
      });
    } catch (error) {
      if (guestApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
    } finally {
      dispatch({
        type: ActionTypes.SET_MASTER_GUEST_LOADING,
        payload: { isLoadingPast: false },
      });
    }
  },
  500
);

export const fetchPastHistoryItems = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  fetchPastHistoryItemsDebounced(params, dispatch, controller);
  return controller;
};

const fetchMasterGuestLoyaltyTransactionsDebounced = debounce(
  async ({ masterGuestId, venueId }, dispatch, { signal }) => {
    try {
      const response = await guestApis.fetchMasterGuestLoyaltyTransactions(masterGuestId, venueId, {
        signal,
      });

      dispatch({
        type: ActionTypes.FETCH_MASTER_GUEST_LOYALTY_TRANSACTIONS,
        payload: response.loyaltyAccountDetails || {},
      });
    } catch (error) {
      if (guestApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
    } finally {
      dispatch({
        type: ActionTypes.SET_MASTER_GUEST_LOADING,
        payload: { isLoadingLoyaltyTransaction: false },
      });
    }
  },
  500
);

export const fetchMasterGuestLoyaltyTransactions = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  fetchMasterGuestLoyaltyTransactionsDebounced(params, dispatch, controller);
  return controller;
};

export const setMasterGuestLoyaltySubscription =
  (masterGuestId: any, venueId: any) => async (dispatch: AppDispatch) => {
    try {
      const response = await guestApis.setMasterGuestLoyaltySubscription(masterGuestId, venueId);
      dispatch({
        type: ActionTypes.SET_MASTER_GUEST_LOYALTY_SUBSCRIPTION,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        payload: response.masterGuest || {},
      });
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

export const setMasterGuestLoyaltyTier =
  (masterGuestId: number, loyaltyTier: number, venueId: VenueId) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await guestApis.setMasterGuestLoyaltyTier(
        masterGuestId,
        loyaltyTier,
        venueId
      );
      dispatch({
        type: ActionTypes.SET_MASTER_GUEST_LOYALTY_TIER,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        payload: response?.loyaltyTierSetting || {},
      });
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };

export const resetGuests = () => ({
  type: ActionTypes.RESET_GUESTS,
});

export const resetMasterGuest = () => ({
  type: ActionTypes.RESET_MASTER_GUEST,
});

export const getMasterGuestHistoryData =
  ({ history, masterGuestId, venueId }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      const { type = '', id } = history;
      let response = {};

      switch (type) {
        case HISTORY_TRANSACTION_TYPE.giftCard:
        case HISTORY_TRANSACTION_TYPE.delivery:
        case HISTORY_TRANSACTION_TYPE.takeaway: {
          // @ts-expect-error TS(2339): Property 'order' does not exist on type 'unknown'.
          const { order } = await orderApis.getOrder({ venueId, id });
          response = order;
          break;
        }
        case HISTORY_TRANSACTION_TYPE.reservation: {
          // @ts-expect-error TS(2339): Property 'reservation' does not exist on type 'unk... Remove this comment to see the full error message
          const { reservation } = await reservationApis.getReservation({ id, venueId });
          response = reservation;
          break;
        }
        default: {
          // @ts-expect-error TS(2339): Property 'loyaltyTransaction' does not exist on ty... Remove this comment to see the full error message
          const { loyaltyTransaction } = await guestApis.fetchMasterGuestLoyalty(
            masterGuestId,
            venueId,
            id
          );
          response = loyaltyTransaction;
          break;
        }
      }

      dispatch({ type: ActionTypes.SET_GUEST_HISTORY_DATA, payload: response });
    } catch (error) {
      showErrorMessage(getErrorMessage(error));
    }
  };
