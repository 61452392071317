import * as Yup from 'yup';

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  password: Yup.string().min(6).required('Please enter your password'),
});

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(6).required('Password is required'),
  confirmPassword: Yup.string().when('password', {
    is: (val?: string) => val && val.length > 0,
    then: () =>
      Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
  }),
});
