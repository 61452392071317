import {
  MAX_RESERVATION_NOTE_LENGTH_DESKTOP,
  MAX_RESERVATION_NOTE_LENGTH,
  MAX_GUEST_NOTE_LENGTH_DESKTOP,
  MAX_GUEST_NOTE_LENGTH,
  MAX_RESERVATION_TAGS_DESKTOP,
  MAX_RESERVATION_TAGS,
  MAX_GUEST_TAGS_DESKTOP,
  MAX_GUEST_TAGS,
} from 'modules/ReservationsList/constants';
import { isMobileDevice, device } from '@umai/common';

export {
  isMobileOrTablet, // used majorly for touched device check
  isSafari,
  isWebview,
  reloadNativeApp,
  setWebView,
} from '@umai/common';

export const isDesktopDevice = device.desktop();

export const isTabletDevice = device.tablet();

export const generateDeviceId = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const largeDesktopLimits = (largeDesktop: boolean) => ({
  maxRsvNotes: largeDesktop ? MAX_RESERVATION_NOTE_LENGTH_DESKTOP : MAX_RESERVATION_NOTE_LENGTH,
  maxGuestNotes: largeDesktop ? MAX_GUEST_NOTE_LENGTH_DESKTOP : MAX_GUEST_NOTE_LENGTH,
  maxRsvTags: largeDesktop ? MAX_RESERVATION_TAGS_DESKTOP : MAX_RESERVATION_TAGS,
  maxGuestTags: largeDesktop ? MAX_GUEST_TAGS_DESKTOP : MAX_GUEST_TAGS,
});

export { isMobileDevice };
