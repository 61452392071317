import type { AppDispatch } from 'redux-store';
import debounce from 'lodash-es/debounce';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage } from 'utils/alerts';
import { setRoomsShowNested } from 'modules/Timeline/actions';
import roomApis from './apis';
import * as ActionTypes from './action-types';
import { normalizeRooms, normalizeTables } from './reducers';

const getVenueRoomsDebounce = debounce(
  async ({ venueId, showLoader = false }, dispatch, { signal }) => {
    try {
      if (showLoader) dispatch({ type: ActionTypes.SET_ROOMS_LOADING, payload: true });

      // @ts-expect-error TS(2339): Property 'rooms' does not exist on type 'unknown'.
      const { rooms } = await roomApis.getVenueRooms(venueId, { signal });

      dispatch({
        type: ActionTypes.GET_VENUE_ROOMS,
        // NOTE: important to keep an order because to keep it sorted while dragging on timeline view
        payload: normalizeRooms(
          (rooms || []).sort((a: any, b: any) => a.order - b.order || a.id - b.id)
        ),
      });

      dispatch(
        setRoomsShowNested({
          ...rooms.reduce(
            // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
            (acc, { id }: any) => ({
              [id]: true,
              ...acc,
            }),
            {}
          ),
        })
      );
    } catch (error) {
      if (roomApis.isCancel(error)) return;
      showErrorMessage(getErrorMessage(error));
    } finally {
      if (showLoader) {
        dispatch({ type: ActionTypes.SET_ROOMS_LOADING, payload: false });
      }
    }
  },
  400
);

export const getVenueRooms = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  getVenueRoomsDebounce(params, dispatch, controller);
  return controller;
};

// NOTE: not debouncing as its not firing the api from sockets when we block or unblock a table from timeline
const getRoomTablesController = async (
  { venueId, showLoader = false }: any,
  dispatch: AppDispatch,
  { signal }: any
) => {
  try {
    if (showLoader) dispatch({ type: ActionTypes.SET_ROOM_TABLES_LOADING, payload: true });

    // @ts-expect-error TS(2339): Property 'tables' does not exist on type 'unknown'... Remove this comment to see the full error message
    const { tables } = await roomApis.getRoomTables(venueId, { signal });

    dispatch({
      type: ActionTypes.GET_ROOM_TABLES,
      // NOTE: important to keep an order because to keep it sorted while dragging on timeline view
      payload: normalizeTables(
        (tables || []).sort((a: any, b: any) => a.order - b.order || a.id - b.id)
      ),
    });
  } catch (error) {
    if (roomApis.isCancel(error)) return;
    showErrorMessage(getErrorMessage(error));
  } finally {
    if (showLoader) {
      dispatch({ type: ActionTypes.SET_ROOM_TABLES_LOADING, payload: false });
    }
  }
};

export const getRoomTables = (params: any) => (dispatch: AppDispatch) => {
  const controller = new AbortController();
  getRoomTablesController(params, dispatch, controller);
  return controller;
};

export const blockTable =
  ({ tableId, blockTimeStart, blockTimeEnd }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      const table = await roomApis.blockTable({ tableId, blockTimeStart, blockTimeEnd });
      const { blockedTables, tables } = normalizeTables([table]);
      dispatch({
        type: ActionTypes.SET_BLOCKED_TABLES,
        payload: { blockedTables, tableId, table: tables[tableId] },
      });
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    }
  };

export const unBlockTable =
  ({ tableId, blockedTableId }: any) =>
  async (dispatch: AppDispatch) => {
    try {
      const table = await roomApis.unBlockTable({ tableId, blockedTableId });
      const { blockedTables, tables } = normalizeTables([table]);
      dispatch({
        type: ActionTypes.REMOVE_BLOCKED_TABLE,
        payload: { blockedTableId, blockedTables, tableId, table: tables[tableId] },
      });
    } catch (err) {
      showErrorMessage(getErrorMessage(err));
    }
  };
