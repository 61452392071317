import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SOCKET_ACTION } from 'constants/socket';
import { setCurrentSession, getCurrentUser } from 'modules/Auth/actions';
import { getPartnerVenues } from 'modules/Partner/actions';
import { onMessageHandler, initializeOrderNotify } from 'services/firebase-messaging';
import { socketWorkerMessage, unSubscribeSocketWorker } from 'services/socket-message';
import { onVisibilityChange } from 'utils/event-listeners';
import { generateDeviceId } from 'utils/devices';
import { savePartnerSession } from 'utils/session';
import { initWootric } from 'utils/wootric';
import { selectIsAuthenticated } from 'modules/Auth/selectors';
import { socketWorker } from 'workers';

const useBootStrap = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const hasInitialized = useRef(false);

  // bootstrap functions required on the mount of the application
  useEffect(() => {
    dispatch(setCurrentSession());
    savePartnerSession({ deviceId: generateDeviceId() });
    initializeOrderNotify();
    onMessageHandler();
    onVisibilityChange((visible) => {
      if (visible) socketWorker.postMessage(SOCKET_ACTION.checkState);
    });

    return () => {
      if (hasInitialized.current) {
        unSubscribeSocketWorker();
      }
    };
  }, [dispatch]);

  // bootstrap functions required after the successful login of the application
  useEffect(() => {
    if (isAuthenticated) {
      initWootric();
      dispatch(getPartnerVenues());
      dispatch(getCurrentUser());
      socketWorker.addEventListener('message', socketWorkerMessage, false);
      hasInitialized.current = true;
    }
  }, [dispatch, isAuthenticated]);
};

export default useBootStrap;
