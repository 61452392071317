import { ALL_DAY_AVAILABILITY, ALL_DAY } from 'modules/ReservationsList/constants';
import * as ActionTypes from './action-types';
import { getInitialDate } from './services';

const INITIAL_STATE = {
  date: getInitialDate(),
  availabilities: [],
  availabilityId: ALL_DAY_AVAILABILITY,
  availabilityName: ALL_DAY,
  availabilitySummary: { shifts: [], tags: [], guestCount: 0, reservationCount: 0 },
  calendarData: {},
  isAvailabilitiesLoading: false,
};

const transformDaysData = (days = []) =>
  days.reduce((obj, day) => {
    // @ts-expect-error TS(2339): Property 'date' does not exist on type 'never'.
    if (day.date) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      obj[day.date] = day;
    }
    return obj;
  }, {});

const updateCalendarData = (calendarData: any) =>
  calendarData.reduce((obj: any, { month, data }: any) => {
    obj[month] = transformDaysData(data);
    return obj;
  }, {});

// eslint-disable-next-line default-param-last
const footerReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionTypes.CHANGE_DATE:
      return { ...state, date: action.payload };
    case ActionTypes.CHANGE_AVAILABILITY:
      return { ...state, ...action.payload };
    case ActionTypes.GET_VENUE_AVAILABILITIES:
      return { ...state, availabilities: action.payload };
    case ActionTypes.SET_AVAILABILITIES_LOADING:
      return { ...state, isAvailabilitiesLoading: action.payload };
    case ActionTypes.GET_VENUE_AVAILABILITY_SUMMARY:
      return { ...state, availabilitySummary: action.payload };
    case ActionTypes.UPDATE_CALENDAR_DATA:
      return {
        ...state,
        calendarData: {
          ...state.calendarData,
          ...updateCalendarData(action.payload),
        },
      };
    case ActionTypes.RESET_CALENDAR_DATA: {
      return { ...state, calendarData: {} };
    }
    default:
      return state;
  }
};

export default footerReducer;
